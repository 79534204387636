import {
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import { analytics } from '../services/analytics_service'
import Product, { IProduct } from '../models/billing/product'
import { stripe } from '../services/stripe_service'
import Config from '../config'
import BankAccount, { IBankAccount } from 'models/billing/bank_account'
import { firebase } from 'services/firebase_service'
import { DocumentType } from 'models/document/document'
import { IPrice } from 'models/billing/price'

// The Billing Store is responsible for state related to Stripe data, plan allowances and payments
export const BillingStoreModel = types
  .model('BillingStore')
  .props({
    products: types.optional(types.array(Product), []),
    // subscriptions: types.optional(types.array(Subscription), []),
    // invoices: types.optional(types.array(InternalInvoice), []),
    bankAccounts: types.optional(types.array(BankAccount), []),
  })
  .volatile((store) => ({
    unsubscribeFromInvoicesFunction: () => {},
  }))
  // Views are read-only derived data of the state tree
  .views((store) => ({

    // Returns array of invoices in cronological order
    // get sortedInvoices() {
    //   return store.invoices
    //     .slice()
    //     .sort((invoiceA, invoiceB) => invoiceB.created - invoiceA.created)
    // },

    // Returns all available subscription IDs
    // get subscriptionIds() {
    //   return store.subscriptions.map((subscription) => subscription.id)
    // },

    // Returns the current subscription
    // get activeSubscription() {
    //   return store.subscriptions.find(
    //     (subscription) => subscription.status === 'active'
    //   )
    // },

    // Returns the products for valid subscriptions
    // get subscriptionProduct() {
    //   return store.products.find(
    //     (product) => product.active && product.metadata?.type === 'recurring'
    //   )
    // },

    // Returns the products for valid one off payments
    // get oneOffProduct() {
    //   return store.products.find(
    //     (product) => product.active && product.metadata?.type === 'one_off'
    //   )
    // },

    // Pretty obvious
    // get isSubscribed() {
    //   return this.activeSubscription !== undefined
    // },

    // Returns the metadata of the Stripe price where we store the allowance in number of claims
    // get entitlement() {
    //   const activePriceId = this.activeSubscription?.price
    //   if (!activePriceId) return 0
    //   const entitlementMetadata = this.subscriptionProduct?.prices.find(
    //     (price) => price.id === activePriceId
    //   )?.metadata?.entitlement
    //   if (!entitlementMetadata) return 0
    //   return typeof entitlementMetadata == 'string'
    //     ? parseInt(entitlementMetadata)
    //     : entitlementMetadata
    // },

    // Returns the next tier price by looking in Stripe's metadata
    // get upgradePriceId(): string | undefined {
    //   const activePriceId = this.activeSubscription?.price
    //   const priceIndex = parseInt(
    //     this.subscriptionProduct?.prices
    //       .find((price) => price.id === activePriceId)
    //       ?.metadata?.index!.toString()!
    //   )
    //   return this.subscriptionProduct?.prices.find(
    //     (price) => price.metadata?.index === (priceIndex + 1).toString()
    //   )?.id
    // },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    // Initializes by fetching products from Stripe and setting them here
    async init(member: boolean) {
      if (Config.ENV === 'emulator') return
      try {
        this.setProducts(await stripe.getProducts())
        // if (!member) {
        //   this.subscribeToSubscriptions()
        // }
        this.subscribeToBankAccounts()
      } catch (error) {
        console.log('Error initializing BillingStore: ', error)
        analytics.crash(error)
      }
    },

    // Listens to changes in subscriptions collection, only subscribes to Invoices when subscriptions are retrieved
    // subscribeToSubscriptions() {
    //   if (Config.ENV === 'emulator') return
    //   try {
    //     const accountId = getRootStore(store).authStore.user?.account_id!
    //     stripe.subscribeToSubscriptions(accountId, (snapshot) => {
    //       const subscriptionsData = snapshot.docs.map((doc) => ({
    //         id: doc.id,
    //         product: doc.data().product.id,
    //         price: doc.data().price.id,
    //         status: doc.data().status,
    //         stripeLink: doc.data().stripeLink,
    //         cancel_at_period_end: doc.data().cancel_at_period_end,
    //         created: doc.data().created,
    //         current_period_end: doc.data().current_period_end,
    //         current_period_start: doc.data().current_period_start,
    //         ended_at: doc.data().ended_at,
    //         cancel_at: doc.data().cancel_at,
    //         canceled_at: doc.data().canceled_at,
    //         metadata: doc.data().metadata,
    //       }))

    //       if (subscriptionsData && subscriptionsData.length > 0) {
    //         const subscriptions: ISubscription[] = subscriptionsData.map(
    //           (subscriptionData) => Subscription.create(subscriptionData)
    //         )
    //         this.setSubscriptions(subscriptions)
    //         this.subscribeToInvoices()
    //       } else {
    //         this.clearSubscriptions()
    //       }
    //     })
    //   } catch (error) {
    //     analytics.crash(error)
    //   }
    // },

    // Listens to changes in invoices collection
    // subscribeToInvoices() {
    //   if (Config.ENV === 'emulator') return
    //   try {
    //     const accountId = getRootStore(store).authStore.user?.account_id!
    //     store.unsubscribeFromInvoicesFunction =
    //       stripe.subscribeToMultipleInvoices(
    //         accountId,
    //         store.subscriptionIds,
    //         (subscriptionId, snapshot) => {
    //           snapshot.forEach((doc) => {
    //             try {
    //               const invoice = doc.data() as IInternalInvoice
    //               this.updateInvoice(invoice)
    //             } catch (error) {
    //               const uid = getRootStore(store).authStore.user?.account_id
    //               const claimId = getRootStore(store).claimStore.currentClaimId
    //               analytics.error(error, uid, claimId ?? undefined)
    //             }
    //           })
    //         }
    //       )
    //   } catch (error) {
    //     const uid = getRootStore(store).authStore.user?.account_id
    //     const claimId = getRootStore(store).claimStore.currentClaimId
    //     analytics.error(error, uid, claimId ?? undefined)
    //   }
    // },

    subscribeToBankAccounts() {
      const user = getRootStore(store).user
      firebase.subscribeToBankAccounts(user.account_id, (snapshot) => {
        const bankAccounts: IBankAccount[] = []
        snapshot.forEach((doc) => {
          const bankAccount = doc.data() as IBankAccount
          bankAccounts.push(bankAccount)
        })
        this.setBankAccounts(bankAccounts)
      })
    },
    setBankAccounts(bankAccounts: IBankAccount[]) {
      store.bankAccounts.replace(bankAccounts)
    },
    addBankAccount(bankAccount: IBankAccount) {
      store.bankAccounts.push(bankAccount)
    },
    async createBankAccount(name: string, type: string, sortCode: string, accountNumber: string): Promise<boolean> {
      const user = getRootStore(store).user
      try {
        const bankAccountId = await firebase.getUniqueIdForCollection([
          'users',
          user.account_id,
          'bank_accounts',
        ])
        const bankAccount = BankAccount.create({
          id: bankAccountId,
          bank_account_type: type,
          bank_account_sort_code: sortCode,
          bank_account_number: accountNumber,
          bank_account_name: name,
        })
        await firebase.createBankAccount(user.account_id, bankAccount)
        this.addBankAccount(bankAccount)
        return true
      } catch (error) {
        console.error('Error in createBankAccount:', error)
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, user?.uid, claimId ?? undefined)
      }
      return false
    },

    // Returns the index of the invoice if it exists, false if it doesn't
    // invoiceExists(invoiceId: string) {
    //   const existingInvoiceIndex = store.invoices.findIndex(
    //     (invoice) => invoice.id === invoiceId
    //   )
    //   return existingInvoiceIndex === -1 ? false : existingInvoiceIndex
    // },

    // Updates invoice in local state, not Firestore/Stripe
    // updateInvoice(invoice: IInternalInvoice) {
    //   if (Config.ENV === 'emulator') return
    //   try {
    //     const invoiceExists: number | boolean = this.invoiceExists(invoice.id!)
    //     if (invoiceExists !== false) {
    //       store.invoices[invoiceExists].update(invoice)
    //     } else {
    //       store.invoices.push(invoice)
    //     }
    //   } catch (error) {
    //     const uid = getRootStore(store).authStore.user?.account_id
    //     const claimId = getRootStore(store).claimStore.currentClaimId
    //     analytics.error(error, uid, claimId ?? undefined)
    //   }
    // },

    // Calls Stripe to generate a checkout session
    async createCheckoutSession(
      priceId: string,
      mode: 'subscription' | 'payment',
      metadata?: any
    ): Promise<string | undefined> {
      if (Config.ENV === 'emulator') return
      try {
        const accountId = getRootStore(store).authStore.user?.account_id
        if (!accountId) return
        console.log('createCheckoutSession', priceId, mode, metadata)
        analytics.track('Started payment', {
          document_id: metadata.document_id,
          claim_id: metadata.claim_id,
        })
        return await stripe.createCheckoutSession(accountId, priceId, mode, metadata)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        console.log('Error in createCheckoutSession:', error)
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Calls Stripe to generate a portal (subscription management) session
    async createPortalSession(): Promise<string | undefined> {
      if (Config.ENV === 'emulator') return
      try {
        return await stripe.createPortalSession()
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Does what it says it does
    setProducts(products: IProduct[]) {
      if (Config.ENV === 'emulator') return
      store.products.replace(products)
      console.log('products', products)
    },

    // Same same
    // setSubscriptions(subscriptions: ISubscription[]) {
    //   if (Config.ENV === 'emulator') return
    //   store.subscriptions.replace(subscriptions)
    // },

    // Same same but in reverse
    // clearSubscriptions() {
    //   store.subscriptions.clear()
    // },

    // Returns subscription for a given ID
    // getSubscription(subscriptionId: string) {
    //   if (Config.ENV === 'emulator') return
    //   return store.subscriptions.find(
    //     (subscription) => subscription.id === subscriptionId
    //   )
    // },

    // Returns price for a given ID
    getPrice(priceId: string) {
      if (Config.ENV === 'emulator') return
      console.log('getPrice', priceId)
      return store.products
        .find((product) => product.active)
        ?.prices.find((price) => price.id === priceId)
    },

    getPriceForDocumentType(documentType: DocumentType): IPrice | undefined {
      if (Config.ENV === 'emulator') return
      console.log('getPriceForDocumentType', documentType.toString())
      console.log(store.products)
      return store.products
        .find((product) => product.metadata?.type === documentType.toString())
        ?.prices[0]
    }
  }))

export interface BillingStore extends Instance<typeof BillingStoreModel> {}
export interface BillingStoreSnapshot extends SnapshotOut<typeof BillingStoreModel> {}
