import { useEffect, useRef } from 'react'
import {
  Paper,
  Timeline,
  Text,
  Group,
  Avatar,
  useMantineTheme,
  Loader,
  Stack,
  Flex,
  Space,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
//@ts-ignore
import logo from '../../assets/images/garfield-logo.svg'
import { useInView } from 'react-intersection-observer'
import { observer } from 'mobx-react'
import { print } from '../../utils/print'
import { Timestamp } from 'firebase/firestore'
import DialogueMessage from './dialogue_message'
import { IClaim } from '../../models/claim/claim'
import { ErrorBoundary } from 'react-error-boundary'
import MessageError from '../errors/message_error'
import Message, { IMessage } from '../../models/dialogue/message'
import { modal } from '../../utils/modal'
import { isAlive } from 'mobx-state-tree' // for email selector testing purposes
import { firebase } from '../../services/firebase_service'

export interface ChatTimelineProps {
  claim: IClaim
}

function ChatTimeline({ claim }: ChatTimelineProps) {
  const { dialogueStore, claimStore, navStore } = useStores()
  const theme = useMantineTheme()
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    const loadMessages = async () => {
      dialogueStore.unsubscribeFromMessages()
      dialogueStore.clearMessages()
      const batchSize = messages.length + 10
      dialogueStore.subscribeToMessagesForClaim(
        claimStore.currentClaimId!,
        batchSize
      )
    }
    if (inView) {
      loadMessages()
    }
  }, [inView])

  const messages = dialogueStore.messages
    .slice()
    .sort(
      (a, b) =>
        (b.created_date instanceof Timestamp
          ? b.created_date.toDate().getTime()
          : 0) -
        (a.created_date instanceof Timestamp
          ? a.created_date.toDate().getTime()
          : 0)
    )

  const allMessagesLoaded =
    messages.length > 0 &&
    messages[messages.length - 1].text ===
      "Hi! I'm Garfield, your legal assistant. I can help you collect debts of less than £10k.\n\nTo start, either describe your claim in the message bar below, or drag and drop your invoice (and contract, if any) onto this window, or attach those documents using the paperclip."

  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (!messagesEndRef.current) return
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (messages.length <= 10) {
      scrollToBottom()
    }
  }, [messages.length])

  const checkEmailApproval = async () => {
    const emailMessages = messages
      .filter((message: IMessage) => {
        if (isAlive(message)) {
          return (
            message.creator === 'email' &&
            message.email_decision === 'undecided'
          )
        }
        return false
      })
      .reverse()
    const allDecisionsMade = emailMessages.every(
      (message) =>
        claimStore.getEmailApprovalTickBox(message.message_id ?? '') !==
        'undecided'
    )

    if (!allDecisionsMade || emailMessages.length === 0) return

    const updateEmailSelection = async () => {
      const all_attachment_ids: string[] = []
      let combined_text = ''
      emailMessages.forEach(async (message) => {
        const email_decision = claimStore.getEmailApprovalTickBox(
          message.message_id ?? ''
        )
        if (email_decision === 'approved') {
          all_attachment_ids.push(...(message.attachment_ids ?? []))
          combined_text += (message.text ?? '') + '\n\n'
        }
        await firebase.setEmailDecision(
          message.account_id ?? '',
          message.claim_id ?? '',
          message.message_id ?? '',
          email_decision
        )
      })

      if (!combined_text) return

      const message = emailMessages[0]
      const message_id = await firebase.getUniqueMessageId(
        message.account_id ?? '',
        message.claim_id ?? ''
      )
      const combinedMessage = Message.create({
        text: combined_text,
        creator: 'user',
        created_date: new Date(),
        claim_id: message.claim_id,
        account_id: message.account_id,
        message_id: message_id,
        visible: false,
        attachment_ids: all_attachment_ids,
      })
      await firebase.sendMessageDirect(combinedMessage)

      claimStore.clearEmailApprovalTickBoxesForClaim()
    }

    modal.open(
      '',
      '',
      'confirm_emails',
      async () => {
        await updateEmailSelection()
        modal.close()
      },
      () => {},
      { emailMessages: emailMessages }
    )
  }

  const getEmailApprovalToString = () => {
    const emailApprovalTickBoxesString = messages
      .map((message) => {
        const decision = claimStore.getEmailApprovalTickBox(
          message.message_id ?? ''
        )
        return `${message.message_id}:${decision}`
      })
      .join('; ')
    return emailApprovalTickBoxesString
  }
  const emailApprovalString = getEmailApprovalToString()

  useEffect(() => {
    checkEmailApproval()
  }, [emailApprovalString])

  return (
    <Paper my={0} bg={theme.colors.white[2]}>
      <Timeline
        //h={documents.length > 0 ? height - 230 : height - 190}
        h={'100%'}
        p={'xl'}
        reverseActive
        lineWidth={0}
        bulletSize={0}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          // overflow: 'auto',
        }}
      >
        {/* Invisible element at the end of the messages */}
        <div ref={messagesEndRef} />
        {messages[0] && messages[0].creator === 'user' ? (
          <Timeline.Item key={'-1'} mt={'xl'}>
            <Flex justify='start' align='start'>
              <Avatar
                src={logo}
                bg={theme.colors.black[5]}
                color='white'
                size={40}
                p={'xs'}
                radius='xl'
              />
              <Space w={'md'}></Space>
              <Stack gap={'xs'}>
                <Group>
                  <Text size='md' mt={0} fw={700}>
                    {'Garfield'}
                  </Text>
                </Group>
                <Group>
                  <Loader color='gray' type='dots' />
                </Group>
              </Stack>
            </Flex>
          </Timeline.Item>
        ) : null}
        {messages.map((message, index) => (
          <ErrorBoundary
            key={message.message_id}
            FallbackComponent={MessageError}
            onError={(error) => print(error)}
            onReset={(details) => {
              // Reset the state of your app so the error doesn't happen again
            }}
          >
            <DialogueMessage
              message={message}
              emailSelector={false}
            ></DialogueMessage>
          </ErrorBoundary>
        ))}
        <Timeline.Item key={'loader'} m={0} p={0} h={10}>
          {!allMessagesLoaded ? (
            <Loader ref={ref} color='gray' type='dots' />
          ) : null}
        </Timeline.Item>
      </Timeline>
    </Paper>
  )
}

export default observer(ChatTimeline)
