import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Flex,
  Container,
  Text,
  Space,
  TextInput,
  Title,
  Stack,
  Paper,
  Anchor,
  PasswordInput,
  Stepper,
} from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { Group, ScrollArea } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import {
  IconLogout,
} from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../utils/use_stores'
import { GarfieldLogoText } from '../components/shared/garfield_logo'
//@ts-ignore
import background_image from '../assets/images/register.webp'

function SignInPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore: accountStore } = useStores()

  const desktop = useMediaQuery('(min-width: 56.25em)')

  const navigate = useNavigate()

  const [authPassword, setAuthPassword] = useState('')
  const [authEmail, setAuthEmail] = useState('')

  async function signIn() {
    await accountStore.signIn(authEmail, authPassword)
  }

  return (
    <Flex justify='start' m={0} p={0} gap={'sm'} bg={theme.colors.white[5]}>
      {desktop ? (
        <Paper
          w={'100%'}
          // bg={theme.colors.black[5]}
          m={'sm'}
          radius={'md'}
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          }}
        >
          <Paper
            w={'100%'}
            h={'100%'}
            p={'xl'}
            style={{
              background: `rgba(0, 0, 0, 0.5)`,
            }}
          >
            <Stack justify='space-between' h={'100%'}>
              <GarfieldLogoText
                size={'md'}
                variant={'light'}
                onClick={() => navigate('/')}
                type='full'
              />
              <Text c={theme.colors.gray[4]} size='sm' ta='start' mt={5}>
                New to Garfield?{' '}
                <Anchor
                  size='sm'
                  component='button'
                  fw={600}
                  c={theme.colors.white[5]}
                  onClick={() => navigate('/signup')}
                >
                  Sign up
                </Anchor>
              </Text>
            </Stack>
          </Paper>
        </Paper>
      ) : null}

      <Container
        h={height - 25}
        mah={height - 25}
        w={'100%'}
        my={'sm'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          miw={desktop ? 500 : 100}
          maw={desktop ? 600 : 1920}
          h={'100%'}
          radius={'sm'}
          p={'xl'}
        >
          <ScrollArea>
            <Stack justify='space-between' mah={900} mih={300}>
              <Stepper
                size='xs'
                active={0}
                // onStepClick={setCurrentStep}
                allowNextStepsSelect={false}
                iconSize={0}
                style={{ marginLeft: '-20px' }}
              >
                <Stepper.Step>
                  <form>
                    <Paper ml={'20px'}>
                      <Space h={'xs'} />
                      <Group>
                        <IconLogout size={32}></IconLogout>
                        <Title order={2}>Welcome back!</Title>
                      </Group>
                      <Space h={'lg'} />
                      <Space h={'lg'} />
                      <TextInput
                        id='email-input'
                        label="Your company's email address"
                        placeholder='john.smith@acme.com'
                        onChange={(e) => setAuthEmail(e.target.value)}
                        data-testid='email-input'
                      />
                      <PasswordInput
                        id='password-input'
                        label='Your password'
                        placeholder='password'
                        mt='md'
                        onChange={(e) => setAuthPassword(e.target.value)}
                        data-testid='password-input'
                      />
                    </Paper>
                  </form>
                </Stepper.Step>
              </Stepper>
            </Stack>
          </ScrollArea>
          <Group justify='start' mt='xl' gap={'xs'}>
            <Button
              rightSection={<IconLogout size={22} />}
              onClick={signIn}
              disabled={false}
              data-testid='signin-button-click'
            >
              {'Sign in'}
            </Button>
          </Group>
        </Paper>
      </Container>
    </Flex>
  )
}

export default observer(SignInPage)

{
  /* <Container size={420} my={40}>
      <Title ta='center'>Welcome back!</Title>
      <Text c='dimmed' size='sm' ta='center' mt={5}>
        New to Garfield?{' '}
        <Anchor
          size='sm'
          component='button'
          onClick={() => navigate('/signup')}
        >
          Sign up
        </Anchor>
      </Text>

      <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
        <TextInput
          onChange={(e) => setAuthEmail(e.target.value)}
          label='Email'
          placeholder='john.smith@acme.com'
          required
        />
        <PasswordInput
          label='Password'
          placeholder='password'
          required
          mt='md'
          onChange={(e) => setAuthPassword(e.target.value)}
        />
        <Button fullWidth mt='xl' onClick={signIn}>
          Sign in
        </Button>
      </Paper>
    </Container> */
}
