// defence_details.ts
import { Instance, types } from 'mobx-state-tree'

const DefenceItem = types.model({
  defendant_argument: types.maybeNull(types.string),
  claimant_response: types.maybeNull(types.string),
  additional_evidence_required: types.maybeNull(types.boolean),
})

const DefenceDetails = types
  .model('DefenceDetails', {
    raw_defence: types.maybeNull(types.string),
    defence_items: types.optional(types.array(DefenceItem), []),
  })
  .actions((self) => ({
    addDefenceItem(item: IDefenceItem) {
      self.defence_items.push(item)
    },
    removeDefenceItem(index: number) {
      self.defence_items.splice(index, 1)
    },
  }))

export default DefenceDetails
export interface IDefenceDetails extends Instance<typeof DefenceDetails> {}
export interface IDefenceItem extends Instance<typeof DefenceItem> {}
