import { useEffect, useState } from 'react'
import {
  Timeline,
  Text,
  Group,
  Avatar,
  useMantineTheme,
  Stack,
  Flex,
  Space,
  Button,
  TypographyStylesProvider,
  Card,
  Modal,
  Tooltip,
  Indicator,
} from '@mantine/core'
import { useDisclosure, useHover, useViewportSize } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
//@ts-ignore
import logo from '../../assets/images/garfield-logo.svg'
import { observer } from 'mobx-react'
import { print } from '../../utils/print'
import {
  IconDownload,
  IconFileUnknown,
  IconLoader,
  IconPaperclip,
  IconPlayerPause,
  IconPlayerPlay,
  IconPlayerStop,
  IconVolume,
} from '@tabler/icons-react'
import { IMessage } from '../../models/dialogue/message'
import { firebase } from '../../services/firebase_service'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Document, Page } from 'react-pdf'
import { isAlive } from 'mobx-state-tree'
import { IDocument } from '../../models/document/document'
import React from 'react'
import AttachmentThumbnail from './attachment_thumbnail'
import { ErrorBoundary } from 'react-error-boundary'
import ThumbnailError from '../errors/thumbnail_error'
import { IconMail } from '@tabler/icons-react'
import { IconCheck, IconX } from '@tabler/icons-react'
import { analytics } from 'services/analytics_service'

interface DialogueMessageProps {
  message: IMessage
  emailSelector: boolean
}
interface CustomSpanProps extends React.HTMLProps<HTMLSpanElement> {
  explanation?: string
}

function DialogueMessage(props: DialogueMessageProps) {
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const { height, width } = useViewportSize()
  const { claimStore, memberStore, dialogueStore, documentStore, navStore } =
    useStores()
  const [highlightEnabled, setHighlightEnabled] = useState(true)
  const theme = useMantineTheme()

  // const message = props.message
  const messageIndex = dialogueStore.messageExists(props.message.message_id!)
  const message = messageIndex
    ? dialogueStore.messages[messageIndex]
    : props.message

  // const email_selector_key = props.emailSelector ? 'email_selector' : (claimStore.currentClaimId ?? '')
  // const email_approve_id = claimStore.currentClaimId + (message.message_id ?? '') + (props.emailSelector ? 'email' : '')

  const getInitials = (name: string) => {
    if (name === null || name === undefined) return ''
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase() // Optional: Convert to uppercase
  }

  const { hovered, ref } = useHover()

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  //   const [isPaused, setIsPaused] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [dimmedText, setDimmedText] = useState('')
  const [highlightedText, setHighlightedText] = useState(message.text!)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([])
  const [currentTimepointIndex, setCurrentTimepointIndex] = useState(0)
  const [timepoints, setTimepoints] = useState<number[]>([])
  const [selectedAttachment, setSelectedAttachment] = useState<IDocument>()
  const [
    attachmentModal,
    { open: openAttachmentModal, close: closeAttachmentModal },
  ] = useDisclosure(false)

  useEffect(() => {
    // getAudio()

    return () => {
      if (audio) {
        audio.removeEventListener('ended', () => {
          setIsPlaying(false)
          setIsPaused(false)
          setIsLoading(false)
        })
      }
    }
  }, [])

  const documentsForMessage = documentStore.documentsForMessage(
    message.message_id!
  )

  const getAudio = async () => {
    if (!message || !message.audio) return
    // Only prepare the Audio if an audio path already exists. Avoids downloading audios for ALL messages
    const audioUrl = await firebase.getStorageUrl(message.audio)
    const newAudio = new Audio(audioUrl)
    newAudio.preload = 'auto'
    setAudio(newAudio)
  }

  const playAudio = async () => {
    setIsLoading(true)
    setIsPlaying(false)
    setIsPaused(false)
    const audio = await dialogueStore.getAudio(message)
    if (audio) {
      const newAudio = new Audio(audio.url)

      newAudio.preload = 'auto'
      setAudio(newAudio)

      // Add an event listener for the 'ended' event
      newAudio.addEventListener('ended', () => {
        setIsPlaying(false)
        setIsPaused(false)
        setIsLoading(false)
      })

      const timepoints = audio.timepoints
      setTimepoints(timepoints)
      const text = message.text!
      const words = text!.split(' ')

      timeouts.forEach(clearTimeout)
      const newTimeouts: NodeJS.Timeout[] = []
      const startTime = Date.now()

      timepoints.forEach((timepoint: number, index: number) => {
        const timeout = setTimeout(
          () => {
            const firstHalf = words.slice(0, index + 1).join(' ')
            const secondHalf = words.slice(index + 1).join(' ')
            setHighlightedText(firstHalf)
            setDimmedText(secondHalf)
            setCurrentTimepointIndex(index)
          },
          timepoint * 1000 - (Date.now() - startTime)
        ) // Adjust for any delay in starting the timeouts

        newTimeouts.push(timeout)
      })

      setTimeouts(newTimeouts)

      newAudio?.play()
      setIsLoading(false)
      setIsPaused(false)
      setIsPlaying(true)
    }
  }

  const stopAudio = async () => {
    if (!audio) return
    audio.pause()
    audio.currentTime = 0
    setIsLoading(false)
    setIsPlaying(false)
    setIsPaused(false)
  }

  const pauseAudio = async () => {
    if (!audio) return
    audio.pause()
    timeouts.forEach(clearTimeout)
    setIsLoading(false)
    setIsPlaying(true)
    setIsPaused(true)
  }

  const resumeAudio = async () => {
    if (!audio) return
    audio.play()

    // Resume timeouts for text highlighting
    const words = message.text!.split(' ')
    const newTimeouts = []
    const resumeTime = Date.now()
    const elapsedTime = audio.currentTime * 1000 // Convert to milliseconds

    for (let i = currentTimepointIndex + 1; i < timepoints.length; i++) {
      const remainingTime = timepoints[i] * 1000 - elapsedTime
      if (remainingTime > 0) {
        const timeout = setTimeout(() => {
          const firstHalf = words.slice(0, i + 1).join(' ')
          const secondHalf = words.slice(i + 1).join(' ')
          setHighlightedText(firstHalf)
          setDimmedText(secondHalf)
          setCurrentTimepointIndex(i)
        }, remainingTime)

        newTimeouts.push(timeout)
      }
    }

    setTimeouts(newTimeouts)

    setIsLoading(false)
    setIsPaused(false)
    setIsPlaying(true)
  }

  const openAttachment = async (document: IDocument | null) => {
    if (!document || !document.document_id) return
    documentStore.selectSidebarDocument(document.document_id)
    // setSelectedAttachment(document)
    // openAttachmentModal()
  }

  const downloadAttachment = async () => {
    if (!selectedAttachment || !selectedAttachment.url) return

    try {
      const response = await fetch(selectedAttachment.url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = selectedAttachment.name || 'download'
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      analytics.error(error)
    }
  }

  //@ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const renderers = {
    // Custom renderer for `span` elements
    span: (props: CustomSpanProps) => {
      // Check if highlighting is enabled and the span has the class indicating it should be highlighted
      if (navStore.termHighlightActive && props.className === 'highlight') {
        // Apply custom styling to highlight the span
        return (
          <Tooltip multiline w={220} label={props.explanation} withArrow>
            <span
              style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                color: theme.colors.gray[8],
              }}
              {...props}
            />
          </Tooltip>
        )
      }
      // For spans not marked for highlighting or when highlighting is disabled, render them normally
      return <span {...props} />
    },
    // No need to customize other elements if they don't require changes
  }

  const toggleHighlight = () => {
    setHighlightEnabled(!highlightEnabled)
  }

  const displayAttachment = () => {
    if (!selectedAttachment?.url) return

    if (selectedAttachment?.mime_type === 'application/pdf') {
      return (
        <Document
          file={selectedAttachment?.url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            print(error)
            alert('Error while loading document! ' + error.message)
          }}
          onSourceError={(error) => {
            print(error)
            alert('Error while retrieving document source! ' + error.message)
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <React.Fragment key={`page_${index + 1}`}>
              <Page width={700} pageIndex={index} />
              {index !== numPages! - 1 && (
                <div style={{ margin: '1rem 0' }} />
              )}{' '}
              {/* This adds a gap between pages */}
            </React.Fragment>
          ))}
        </Document>
      )
    }
    if (['image/jpeg', 'image/png'].includes(selectedAttachment?.mime_type!)) {
      return (
        <img
          src={selectedAttachment.url}
          alt='attachment'
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      )
    }
  }

  // This was created from a git conflict, not sure if it's the new or old version so leaving it here ~ James
  // const displayAttachment = () => {
  //   if (!selectedAttachment?.url) return

  //   if (selectedAttachment?.mime_type === 'application/pdf') {
  //     return (
  //       <Document
  //         file={selectedAttachment?.url}
  //         onLoadSuccess={onDocumentLoadSuccess}
  //         onLoadError={(error) => {
  //           print(error)
  //           alert('Error while loading document! ' + error.message)
  //         }}
  //         onSourceError={(error) => {
  //           print(error)
  //           alert('Error while retrieving document source! ' + error.message)
  //         }}
  //       >
  //         {Array.from(new Array(numPages), (el, index) => (
  //           <React.Fragment key={`page_${index + 1}`}>
  //             <Page width={700} pageIndex={index} />
  //             {index !== numPages! - 1 && (
  //               <div style={{ margin: '1rem 0' }} />
  //             )}{' '}
  //             {/* This adds a gap between pages */}
  //           </React.Fragment>
  //         ))}
  //       </Document>
  //     )
  //   }
  //   if (['image/jpeg', 'image/png'].includes(selectedAttachment?.mime_type!)) {
  //     return (
  //       <img
  //         src={selectedAttachment.url}
  //         alt='attachment'
  //         style={{ maxWidth: '100%', maxHeight: '100%' }}
  //       />
  //     )
  //   }
  // }

  // May want to show email messages in red instead of making them transparent
  if (message.visible === false || message.email_decision === 'rejected')
    return null

  if (!message || !isAlive(message)) return null

  return (
    <>
      <Modal
        centered
        size='80%'
        // w={700}
        opened={attachmentModal}
        onClose={closeAttachmentModal}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        withCloseButton={true}
        title={
          <Group gap={'sm'}>
            <IconPaperclip color={theme.colors.gray[5]} size={20} stroke={2} />
            <Text size='md' fw={400} c={theme.colors.gray[5]}>
              Attachment:
            </Text>
            <Text size='lg' fw={500}>
              {selectedAttachment?.name}
            </Text>
            <Button
              variant='default'
              size='sm'
              onClick={downloadAttachment}
              leftSection={
                <IconDownload
                  // color={theme.colors.gray[5]}
                  size={16}
                  stroke={2}
                />
              }
            >
              Download
            </Button>
          </Group>
        }
        // p={'md'}
        // bg={theme.colors.black[5]}
        // c={theme.colors.black[5]}
      >
        <Stack
          align='center'
          justify='center'
          p={'md'}
          bg={theme.colors.gray[3]}
          style={{ borderRadius: 5 }}
        >
          {displayAttachment()}
        </Stack>
      </Modal>
      <Timeline.Item mt={'xl'} ref={ref}>
        <Flex
          justify='start'
          align='start'
          style={{
            backgroundColor:
              message.creator === 'email' &&
              message.email_decision !== 'approved' &&
              claimStore.getEmailApprovalTickBox(message.message_id ?? '') !==
                'approved' &&
              claimStore.getEmailApprovalTickBox(message.message_id ?? '') !==
                'rejected'
                ? 'rgba(128, 128, 128, 0.05)'
                : 'transparent',
          }}
        >
          {message.creator === 'email' &&
            message.email_decision !== 'approved' &&
            claimStore.getEmailApprovalTickBox(message.message_id ?? '') !==
              'approved' &&
            claimStore.getEmailApprovalTickBox(message.message_id ?? '') !==
              'rejected' && (
              <Indicator
                inline
                color='red'
                position='middle-center'
                size={8}
                processing
              />
            )}
          {message.creator === 'email' ? (
            <Avatar
              bg={theme.colors.gray[5]}
              color='white'
              size={40}
              radius='xl'
            >
              <IconMail size={24} />
            </Avatar>
          ) : (
            <Avatar
              src={message.creator === 'garfield' ? logo : null}
              bg={
                message.creator === 'garfield'
                  ? theme.colors.black[5]
                  : theme.colors.gray[5]
              }
              color='white'
              size={40}
              p={message.creator === 'garfield' ? 'xs' : 0}
              radius='xl'
            >
              {message.creator === 'user'
                ? getInitials(memberStore.getOwner()?.name!)
                : message.creator === 'email'
                  ? 'E'
                  : null}
            </Avatar>
          )}
          <Space w={'md'}></Space>
          <Stack gap={'xs'}>
            <Group>
              <Text size='md' mt={0} fw={700}>
                {message.creator === 'user'
                  ? memberStore.getOwner()?.name!
                  : message.creator === 'email'
                    ? 'Incoming email'
                    : 'Garfield'}
              </Text>
              <span
                style={{
                  margin: '0 0px',
                  height: '5px',
                  width: '5px',
                  backgroundColor: theme.colors.gray[4],
                  borderRadius: '50%',
                  display: 'inline-block',
                  alignSelf: 'center',
                }}
              ></span>
              <Text size='xs' p={0} fw='normal' c={theme.colors.gray[5]}>
                {message.readableCreatedDate}
              </Text>
              {message.creator === 'email' &&
                message.email_decision !== 'approved' && (
                  <IconCheck
                    size={24}
                    color={
                      claimStore.getEmailApprovalTickBox(
                        message.message_id ?? ''
                      ) === 'approved'
                        ? 'green'
                        : theme.colors.gray[5]
                    }
                    onClick={() => {
                      claimStore.setEmailApprovalTickBox(
                        message.message_id ?? '',
                        claimStore.getEmailApprovalTickBox(
                          message.message_id ?? ''
                        ) === 'approved'
                          ? 'undecided'
                          : 'approved'
                      )
                    }}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = 'scale(1.2)')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = 'scale(1)')
                    }
                  />
                )}
              {message.creator === 'email' &&
                message.email_decision !== 'approved' && (
                  <IconX
                    size={24}
                    color={
                      claimStore.getEmailApprovalTickBox(
                        message.message_id ?? ''
                      ) === 'rejected'
                        ? 'red'
                        : theme.colors.gray[5]
                    }
                    onClick={() => {
                      claimStore.setEmailApprovalTickBox(
                        message.message_id ?? '',
                        claimStore.getEmailApprovalTickBox(
                          message.message_id ?? ''
                        ) === 'rejected'
                          ? 'undecided'
                          : 'rejected'
                      )
                    }}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = 'scale(1.2)')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = 'scale(1)')
                    }
                  />
                )}
              {isPlaying ? (
                <Group>
                  <Button
                    size={'compact-xs'}
                    justify='start'
                    variant='subtle'
                    color={theme.colors.black[5]}
                    leftSection={
                      isPaused ? (
                        <IconPlayerPlay size={16} />
                      ) : (
                        <IconPlayerPause size={16} />
                      )
                    }
                    onClick={isPaused ? resumeAudio : pauseAudio}
                  >
                    <Text p={0} size='xs'>
                      {isPaused ? 'Resume' : 'Pause'}
                    </Text>
                  </Button>
                  <Button
                    size={'compact-xs'}
                    justify='start'
                    variant='subtle'
                    color={theme.colors.black[5]}
                    leftSection={<IconPlayerStop size={16} />}
                    onClick={stopAudio}
                  >
                    <Text p={0} size='xs'>
                      Stop
                    </Text>
                  </Button>
                </Group>
              ) : hovered ? (
                isLoading ? (
                  <Button
                    size={'compact-xs'}
                    justify='start'
                    variant='subtle'
                    color={theme.colors.black[5]}
                    leftSection={<IconLoader size={16} />}
                    onClick={playAudio}
                  >
                    <Text p={0} size='xs'>
                      Loading...
                    </Text>
                  </Button>
                ) : (
                  <Button
                    size={'compact-xs'}
                    justify='start'
                    variant='subtle'
                    color={theme.colors.black[5]}
                    leftSection={<IconVolume size={16} />}
                    onClick={playAudio}
                  >
                    <Text p={0} size='xs'>
                      Play
                    </Text>
                  </Button>
                )
              ) : null}
            </Group>

            <Group>
              {isPlaying ? (
                <>
                  <Text size='md'>
                    {highlightedText + ' '}
                    <Text span c='dimmed' size='md'>
                      {dimmedText}
                    </Text>
                  </Text>
                </>
              ) : (
                <TypographyStylesProvider m={0} p={0}>
                  <ReactMarkdown
                    children={message.highlighted_text ?? message.text}
                    remarkPlugins={[gfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={renderers}
                  />
                </TypographyStylesProvider>
              )}
            </Group>
            {message.attachment_ids.length > 0 ? (
              <Stack gap={'xs'}>
                <Group justify='flex-start' gap={'xs'}>
                  <IconPaperclip
                    color={theme.colors.gray[5]}
                    size={14}
                    stroke={3}
                  ></IconPaperclip>
                  <Text c={theme.colors.gray[5]} fw={600} size='xs'>
                    Attachments
                  </Text>
                </Group>

                <Group>
                  {documentsForMessage.length > 0 ? (
                    documentsForMessage!
                      .sort((a, b) => {
                        if (
                          a.mime_type === 'application/pdf' &&
                          b.mime_type !== 'application/pdf'
                        )
                          return -1
                        if (
                          a.mime_type !== 'application/pdf' &&
                          b.mime_type === 'application/pdf'
                        )
                          return 1
                        return 0
                      })
                      .map((document, index) => {
                        if (!document.document_id)
                          return <Text key={index}>Missing attachment</Text>
                        return (
                          <ErrorBoundary
                            key={index}
                            FallbackComponent={ThumbnailError}
                            onError={(error) => print(error)}
                            onReset={(details) => {
                              // Reset the state of your app so the error doesn't happen again
                            }}
                          >
                            <AttachmentThumbnail
                              index={index}
                              documentId={document.document_id}
                              onClick={openAttachment}
                            />
                          </ErrorBoundary>
                        )
                      })
                  ) : (
                    <Card withBorder p={'xs'} bg={theme.colors.gray[1]}>
                      <Group justify='flex-start' gap={'xs'}>
                        <IconFileUnknown
                          color={theme.colors.gray[7]}
                          stroke={1.5}
                          size={24}
                        />
                        <Text color={theme.colors.gray[8]} size='xs'>
                          Missing attachments
                        </Text>
                      </Group>
                    </Card>
                  )}
                </Group>
              </Stack>
            ) : null}
          </Stack>
        </Flex>
      </Timeline.Item>
    </>
  )
}

export default observer(DialogueMessage)
