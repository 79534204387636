import {
  Text,
  Button,
  Card,
  Stack,
  useMantineTheme,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import {
  IconCampfire,
  IconCandle,
  IconMatchstick,
} from '@tabler/icons-react'

export const DeletionModal = ({
  text,
  title,
  onClick,
}: {
  text: React.ReactNode
  title: string
  onClick: () => {}
}) => {
  const theme = useMantineTheme()
  const { billingStore } = useStores()

  // const price = billingStore.getPrice(billingStore.upgradePriceId!)

  const icons = [
    <IconMatchstick color={theme.colors.white[5]} />,
    <IconCandle color={theme.colors.white[5]} />,
    <IconCampfire color={theme.colors.white[5]} />,
  ]

  return (
    <Card p={0} bg={'transparent'}>
      <Stack justify='center' align='center'>
        <Text fw={600} size='xl'>
          {title}
        </Text>
        <Text size='sm'>{text}</Text>
        <Button maw={200} bg={theme.colors.red[6]} onClick={onClick}>
          Yes, delete it
        </Button>
      </Stack>
    </Card>
  )
}
