import React, { FunctionComponent } from 'react'
import { modals } from '@mantine/modals'
import { LoadingModal } from '../components/modals/loading_modal'
// import { UpgradeModal } from '../components/modals/upgrade_modal'
import { DeletionModal } from '../components/modals/deletion_modal'
import { ManualDeleteModal } from '../components/modals/manual_delete_modal'
import { SignatureModal } from '../components/modals/signature_modal'
import { ConfirmSignatureModal } from '../components/modals/confirm_signature_modal'
import { CrashModal } from '../components/modals/crash_modal'
import { FileUploadModal } from '../components/modals/file_upload_modal'
import InviteMembersModal from '../components/modals/invite_users_modal'
import DeleteMembersModal from '../components/modals/delete_members_modal'
import { ConfirmEmailsModal } from '../components/modals/confirm_emails_modal'
import LetterheadModal from '../components/modals/letterhead_modal'
import BankAccountModal from '../components/modals/bank_account_modal'

const ModalType = {
  loading: LoadingModal,
  crash: CrashModal,
  // upgrade: UpgradeModal,
  delete: DeletionModal,
  signature: SignatureModal,
  confirm_signature: ConfirmSignatureModal,
  letterhead: LetterheadModal,
  file_upload: FileUploadModal,
  manual_delete: ManualDeleteModal,
  invite_members: InviteMembersModal,
  delete_members: DeleteMembersModal,
  confirm_emails: ConfirmEmailsModal,
  bank_account: BankAccountModal,
}

class Modal {
  isLoading = false

  loading(state: boolean) {
    if (state) {
      this.isLoading = state
      modals.open({
        styles: {
          body: { backgroundColor: 'transparent', padding: 0 },
          root: { backgroundColor: 'transparent', padding: 0 },
          content: { backgroundColor: 'transparent', padding: 0 },
          header: { backgroundColor: 'transparent', padding: 0 },
        },
        withCloseButton: false,
        centered: true,
        closeOnClickOutside: false,
        closeOnEscape: false,
        fullScreen: true,
        transitionProps: { duration: 0 },
        padding: 0,
        overlayProps: { opacity: 0.0 },
        children: React.createElement(ModalType['loading'], {}, null),
      })
    } else {
      if (this.isLoading) {
        modals.closeAll()
        this.isLoading = false
      }
    }
  }

  crash(error: Error) {
    modals.open({
      // styles: {
      //   body: { backgroundColor: 'transparent', padding: 0 },
      //   root: { backgroundColor: 'transparent', padding: 0 },
      //   content: { backgroundColor: 'transparent', padding: 0 },
      //   header: { backgroundColor: 'transparent', padding: 0 },
      // },
      withCloseButton: false,
      centered: true,
      closeOnClickOutside: false,
      closeOnEscape: false,
      fullScreen: true,
      transitionProps: { duration: 0 },
      padding: 0,
      overlayProps: { opacity: 1.0 },
      children: React.createElement(
        ModalType['crash'],
        {
          error: error,
        },
        null
      ),
    })
  }

  close() {
    modals.closeAll()
  }

  open(
    title: string,
    text: React.ReactNode,
    type:
      | 'loading'
      | 'crash'
      // | 'upgrade'
      | 'delete'
      | 'signature'
      | 'confirm_signature'
      | 'letterhead'
      | 'file_upload'
      | 'manual_delete'
      | 'invite_members'
      | 'delete_members'
      | 'confirm_emails'
      | 'bank_account',
    onClick: () => {},
    onClose?: () => void,
    extraProps?: any
  ) {
    modals.open({
      size: 'auto',
      styles: {
        // overlay: { backgroundColor: 'white', opacity: 0.5 },
        // header: { backgroundColor: 'red' },
      },
      withCloseButton: false,
      centered: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      overlayProps: { blur: 1.5 },
      fullScreen: false,
      children: React.createElement(
        ModalType[type] as FunctionComponent,
        { title, text, onClick, ...extraProps },
        null
      ),
      onClose: onClose,
    })
  }
}

export const modal = new Modal()
