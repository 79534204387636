import { print } from '../utils/print'
import * as Sentry from '@sentry/react'
import { modal } from '../utils/modal'
import { notification } from '../utils/notification'
import mixpanel from 'mixpanel-browser'
import Config from '../config'
import { v4 as uuidv4 } from 'uuid'

class AnalyticsService {
  init() {
    try {
      Sentry.init({
        dsn: Config.SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    } catch (error) {
      print(error)
    }

    try {
      mixpanel.init(Config.MIXPANEL_PROJECT_TOKEN, {
        debug: true,
        track_pageview: true,
        persistence: 'localStorage',
      })
    } catch (error) {
      print(error)
    }
  }

  identify(uid: string, email?: string) {
    try {
      Sentry.setUser({ id: uid, email: email })
    } catch (error) {
      print(error)
    }
    try {
      mixpanel.identify(uid)
    } catch (error) {
      print(error)
    }
  }

  error(error: any, uid?: string, claimId?: string) {
    const errorId = uuidv4().split('-')[0]
    notification.error(error, errorId, uid, claimId)
    try {
      Sentry.captureException(error, {
        tags: {
          uid: uid,
          claim_id: claimId,
          error_id: errorId,
          error_type: 'non_fatal',
        },
      })
    } catch (error) {
      print(error)
    }
    print(error)

    try {
      window.newrelic.noticeError(error)
    } catch (error) {
      print(error)
    }
  }

  crash(error: any) {
    modal.crash(error)
    try {
      Sentry.captureException(error, { tags: { error_type: 'fatal' } })
    } catch (error) {
      print(error)
    }
    try {
      window.newrelic.noticeError(error)
    } catch (error) {
      print(error)
    }

    print(error)
  }

  track(event: string, properties?: any) {
    try {
      mixpanel.track(event, properties)
    } catch (error) {
      print(error)
    }
  }

  register() {}
}

export const analytics = new AnalyticsService()
