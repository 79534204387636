import { MouseEventHandler } from 'react'
import {
  ActionIcon,
  Card,
  Overlay,
  Flex,
  useMantineTheme,
  Tooltip,
  Center,
  Loader,
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { print } from '../../utils/print'
import { observer } from 'mobx-react'
import { MIME_TYPES } from '@mantine/dropzone'
import { Document as PDF, Thumbnail } from 'react-pdf'

function FileThumbail({
  onClick,
  file,
  index,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>
  file: File
  index: number
}) {
  const { hovered, ref } = useHover()
  const theme = useMantineTheme()
  const mimeType = file.type

  const getThumbnail = () => {
    if (mimeType === MIME_TYPES.pdf) {
      return (
        <PDF
          file={file}
          onLoadError={(error) => {
            print(error)
            alert('Error while loading document! ' + error.message)
          }}
          onSourceError={(error) => {
            print(error)
            alert('Error while retrieving document source! ' + error.message)
          }}
          loading={
            <Flex h={50} w={30} align={'center'} justify={'center'}>
              <Center>
                <Loader color={theme.colors.gray[6]} size={'sm'} />
              </Center>
            </Flex>
          }
        >
          <Thumbnail pageNumber={1} height={50} />
        </PDF>
      )
    }
    if (
      [MIME_TYPES.jpeg as string, MIME_TYPES.png as string].includes(mimeType)
    ) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
          }}
        />
      )
    }
  }

  return (
    <Tooltip label={file.name}>
      <Card ref={ref} withBorder p={2} bg={theme.colors.gray[2]} h={50}>
        <Flex align='center' justify='center' h='100%'>
          {getThumbnail()}
          {hovered && (
            <Overlay
              gradient='linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))'
              opacity={0.6}
              zIndex={1}
            >
              <Flex align={'center'} justify={'center'} h={'100%'}>
                <ActionIcon
                  variant='subtle'
                  aria-label='Settings'
                  onClick={onClick}
                >
                  <IconTrash
                    style={{
                      width: '70%',
                      height: '70%',
                      color: theme.colors.red[7],
                    }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Flex>
            </Overlay>
          )}
        </Flex>
      </Card>
    </Tooltip>
  )
}

export default observer(FileThumbail)
