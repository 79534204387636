import {
  Title,
  List,
  ListItem,
  Space,
} from '@mantine/core'


const TermsText = () => {
  return (
    <>
      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>1.</strong> Registration and Access
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>1.1.</strong> To register, access and/or use Garfield: (1) you represent and
          warrant that you have read and you consent to our Privacy Notice; (2)
          you must be, and you represent and warrant that you are, at least
          eighteen years old; and (3) you must have, and on an going basis
          during your use of Garfield you represent and warrant that you have,
          capacity within the meaning of the Mental Capacity Act 2005.
        </ListItem>
        <ListItem>
          <strong>1.2.</strong> If you use Garfield on behalf of another person or entity, you
          must have their authority to accept these Terms on their behalf and
          you hereby represent and warrant to us on every occasion where you
          access and/or use Garfield that you have their authority.
        </ListItem>
        <ListItem>
          <strong>1.3.</strong> You must provide accurate and complete information to register
          for an account. You may not make your account, username or password
          available to anyone else and you are responsible for all activities
          that occur using your account, username and password.
        </ListItem>
        <ListItem>
          <strong>1.4.</strong> You may access, and we grant you a non-exclusive and
          non-transferable right to use, Garfield in accordance with these
          Terms. You shall comply with these Terms and all applicable laws and
          regulations when using Garfield.
        </ListItem>
        <ListItem>
          <strong>1.5.</strong> By using Garfield, you agree both for yourself and on behalf of
          your Organisation to comply with and be bound by these Terms, and
          agree that you have read and understood them and the documents they
          refer to. If you or your Organisation do not agree with these Terms,
          you must not access or use Garfield.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>2.</strong> Your responsibilities and Garfield's scope
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>2.1.</strong> You must: (1) only Input truthful and accurate information into
          Garfield; (2) only provide Input in the English language; (3) provide
          in a timely fashion and in electronic format any documents or other
          information required to progress your Claim; (4) carefully check any
          Output provided for factual errors (and correct any Output where
          necessary); (5) not permit any Output to be sent to any third party if
          it is not factually accurate; (6) not mislead any other party; (7)
          only initiate and/or pursue a Claim providing the debt(s) in issue are
          genuine bonafide debt(s) incurred properly in the course of business
          or trade; (9) not seek to enter any malicious Input; and (10) not seek
          to use Garfield other than for the purpose of commencing and/or
          progressing a Claim.
        </ListItem>
        <ListItem>
          <strong>2.2.</strong> You accept that machine learning and artificial intelligence are
          rapidly advancing areas and that due to the probabilistic and/or
          statistical nature of machine learning, use of Garfield may in some
          situations result in so-called "hallucinations" or Output that does
          not accurately reflect fact. As stated in clause 2.1, it is your
          responsibility to check facts before using any Output.
        </ListItem>
        <ListItem>
          <strong>2.3.</strong> You accept that Garfield is not a lawyer (or a human). In respect
          of a Claim, Garfield can assist you in populating chasing letters and
          letters before action, in answering any questions you have about
          pursuing a small debt claim and in preparing responses to any letter
          from the debtor. Garfield does not assist you in pursuing any Claim
          beyond a pre-action stage and cannot assist you in commencing or
          pursuing a small claim in Court. Garfield cannot and does not provide
          merits advice to you, i.e. Garfield cannot tell you if you are going
          to win or lose any Claim or predict the outcome (including but not
          limited to giving you percentage or other prospects of success). If
          you require merits advice, you agree to consult a solicitor or
          barrister admitted in England and Wales. You accept that Garfield
          should not be considered a substitute for the advice of a solicitor or
          barrister or other appropriately qualified professional. You agree
          that we have no obligation to provide any solicitor or barrister to
          act for you in any Claim and that the sole provision of services to
          you is by way of permitting you access to Garfield on these Terms.
        </ListItem>
        <ListItem>
          <strong>2.4.</strong> Garfield may include features which integrate with a third
          party's applications, software, or services or otherwise allows
          Garfield to interoperate with the same. Garfield disclaims any and all
          liability relating to the availability or use of such third party's
          applications, software or services and the functionality of such
          integrations.
        </ListItem>
        <ListItem>
          <strong>2.5.</strong> We do not permit you or any other party to bring onto Garfield
          any Claim that has not been commenced and progressed via any process
          other than by use of Garfield.
        </ListItem>
        <ListItem>
          <strong>2.6.</strong> Regrettably, we only support the English language and so: (a) all
          prompts entered into Garfield must be in the English language; and (b)
          any Claim pursued by Garfield in Wales will have to be in the English
          language.
        </ListItem>
        <ListItem>
          <strong>2.7.</strong> Unless otherwise expressly permitted by us in writing, you may
          not: (1) sell, sublicense, distribute, display, store, copy, modify,
          decompile or disassemble, reverse engineer, translate or transfer
          Garfield or the materials Garfield produces, in whole or in part, or
          as a component of any other product of service, (2) use Garfield or
          the materials on it to create any derivative works or competitive
          products; (3) allow any third parties to access, use or benefit from
          Garfield or materials on it in any way; and/or (4) use any
          programmatic, scripted or other mechanical means to access Garfield or
          the materials on it or otherwise deploy software applications to run
          automated tasks on Garfield; (5) use Garfield in a manner that, in our
          reasonable opinion, is abusive, illegal or threatens the integrity or
          performance of Garfield.
        </ListItem>
        <ListItem>
          <strong>2.8.</strong> You represent and warrant that you will use Garfield only in
          compliance with applicable laws and regulations. Although we have no
          obligation to monitor your use of Garfield, we retain the right to do
          so and may prohibit any use of Garfield that we believe (in our sole
          discretion) may be (or alleged to be) in violation of these Terms.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>3.</strong> Relationship
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>3.1.</strong> Nothing in these Terms is intended to, or shall be deemed to,
          establish any partnership or joint venture between you and us,
          constitute us the agent of you, constitute you the agent of us, or
          authorise you to enter into any commitments for and on our behalf.
        </ListItem>
        <ListItem><strong>3.2.</strong> We do not owe you any fiduciary duty.</ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>4.</strong> Payments
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>4.1.</strong> You will pay all fees charged to your account according to the
          prices and terms on the applicable pricing page of our website, or as
          otherwise agreed with us, inclusive of any applicable VAT. You will
          provide a valid and authorised payment method. We will charge that
          payment method on an agreed-upon periodic basis. You authorise us
          and/or our payment provider(s) to charge your payment method for these
          fees.
        </ListItem>
        <ListItem>
          <strong>4.2.</strong> If you pay us in another currency other than Pounds Sterling, we
          will convert the payment at our bank's standard exchange rates into
          Pounds Sterling. You will indemnify us for any charges we incur in
          receiving such funds. You remain liable for any shortfall after
          conversion and deductions.
        </ListItem>
        <ListItem>
          <strong>4.3.</strong> We do not accept payments from third parties including, but not
          limited to, other parties to any Claim. Any Defendant or other party
          that is to pay any sum to you should pay you directly.
        </ListItem>
        <ListItem><strong>4.4.</strong> We do not accept or make cash payments.</ListItem>
        <ListItem><strong>4.5.</strong> We do not operate a client bank account.</ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>5.</strong> Confidentiality
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>5.1.</strong> We shall keep the information you put on Garfield confidential{' '}
          <strong>(Confidential Information)</strong> save that:
          <List
            withPadding
            size={'md'}
            styles={(theme) => ({
              item: {
                listStyleType: 'none',
                paddingLeft: 0,
                marginBottom: theme.spacing.xs,
              },
            })}
          >
            <ListItem><strong>5.1.1.</strong> File sharing is done at your risk;</ListItem>
            <ListItem>
              <strong>5.1.2.</strong> Information that is already in the public domain or comes
              into the public domain through no fault of ours no longer needs to
              be kept confidential by us;
            </ListItem>
            <ListItem>
              <strong>5.1.3.</strong> We may share Confidential Information with our regulator,
              our service providers, our insurers, our auditors or as required
              by law (providing our service providers, our insurers and/or our
              auditors have undertaken a confidentiality obligation to us);
            </ListItem>
            <ListItem>
              <strong>5.1.4.</strong> We utilise third party service providers (including those
              that offer "cloud" services) to facilitate the provision of
              Garfield. We require all third-party service providers to adhere
              to: (1) industry standard frameworks for information security; (2)
              applicable data protection laws and (3) have undertaken a
              confidentiality obligation to us;
            </ListItem>
            <ListItem>
              <strong>5.1.5.</strong> We may use the documents and information uploaded onto
              Garfield to refine our models, algorithms and processes providing
              that the confidential information does not thereby become
              available to any other user.
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>6.</strong> Data and document retention
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>6.1.</strong> We shall store all documents and information digitally. After six
          years, we may permanently delete those documents and information.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>7.</strong> Intellectual property rights
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>7.1.</strong> We own and reserve all intellectual property rights in Garfield
          (including, but not limited to, all copyright) and you agree that you
          will not do anything to infringe or prejudice our rights. We shall
          retain all right, title and interest in Garfield including (but not
          limited to) all improvements, enhancements and modifications thereto.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>8.</strong> Indemnity
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>8.1.</strong> You and your Organisation indemnify and hold us harmless for any
          loss or damage we suffer arising out of or connected to any breach by
          you of any provision of these Terms including, but not limited to, any
          claim against us by any other party.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>9.</strong> Insurance
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>9.1.</strong> We maintain professional indemnity insurance with Hiscox
          Insurance Company Ltd. The territorial scope of the insurance is:
          worldwide.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>10.</strong> Disclaimer
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>10.1.</strong> Garfield may be temporarily unavailable for scheduled
          maintenance or for unscheduled emergency maintenance, either by us or
          by third-party providers or because of other causes beyond our
          reasonable control (including but not limited to malicious activities
          of third parties). We do not warrant that Garfield will be
          uninterrupted or error free, nor do we make any warranty as to the
          results or outcomes that may be obtained from use of Garfield. Except
          as expressly set forth in these Terms and as required by applicable
          law and regulation, Garfield is provided "as is" and we disclaim all
          warranties, express or implied, including but not limited to
          warranties of merchantability and fitness for a particular purpose and
          non-infringement.
        </ListItem>
        <ListItem>
          <strong>10.2.</strong> We do not give any warranty that Garfield and our means of
          delivering it are compatible with your software or computer
          configuration.
        </ListItem>
        <ListItem>
          <strong>10.3.</strong> We have no obligation to provide you with any support or
          maintenance in connection with Garfield.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>11.</strong> Limitation of Liability
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>11.1.</strong> We will only provide Garfield to you and we will not accept any
          liability to any person other than you and/or your Organisation.
        </ListItem>
        <ListItem>
          <strong>11.2.</strong> If a claim arises, connected to Garfield, you can only claim
          against us, not against any of our employees, consultants,
          shareholders, directors, agents or barristers. Each category of
          persons referenced in this clause can enforce it under the Contracts
          (Rights of Third Parties) Act 1999.
        </ListItem>
        <ListItem>
          <strong>11.3.</strong> We will not be liable to you for any indirect or consequential
          loss or damage or for any loss of profits.
        </ListItem>
        <ListItem>
          <strong>11.4.</strong> Subject to clauses 11.1, 11.2 and 11.3, and save as stated in
          clause 11.5, our total liability to you and your Organisation for any
          loss, liability or damage in respect of any Claim, howsoever caused
          (whether in contract or tort, including but not limited to negligence,
          misrepresentation, restitution or breach of statutory duty) shall be
          limited to £10,000.
        </ListItem>
        <ListItem>
          <strong>11.5.</strong> Nothing in this clause 11 shall limit or exclude any liability
          that by law or regulation cannot be limited or excluded such as
          (without limitation) for fraud on our part, or for death or personal
          injury caused by our negligence.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>12.</strong> Complaints
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>12.1.</strong> Any complaints or concerns about Garfield should initially be
          raised by email to:{' '}
          <a href='mailto:complaints@projectgarfield.co.uk'>
            complaints@projectgarfield.co.uk
          </a>
          . If that does not resolve the problem, you should contact our
          Complaints Director, by post at our registered office address. If the
          problem remains unresolved, the complaints procedure will be
          implemented. A copy of the procedure is available on request.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>13.</strong> Entire agreement
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>13.1.</strong> These Terms are the only terms between us and supersede and
          cancel all previous written and oral agreements, promises, assurances,
          communications and other understandings relating to the subject matter
          of these Terms. You agree that in entering into these Terms you do not
          rely on and shall have no remedies in respect of any statement,
          representation, assurance or warranty (whether made innocently or
          negligently) that is not set out in these Terms.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>14.</strong> Invalidity
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>14.1.</strong> If any provision or part of a provision in these Terms is found
          to be unenforceable or invalid either as a matter of law or as a
          matter of regulation, then it shall be deemed changed insofar as it is
          necessary to make the provision enforceable and/or valid. If that is
          not possible, then the provision or offending part thereof shall be
          deemed deleted.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>15.</strong> Force majeure
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>15.1.</strong> We shall have no liability to you under these Terms if we are
          prevented from or delayed in performing our obligations under these
          Terms, or from carrying on our business, by acts, events, omissions or
          accidents beyond our reasonable control including, without limitation,
          strikes, lock-outs or other industrial disputes (whether involving our
          workforce or any other party), epidemic, pandemic, failure of a
          utility service or transport or telecommunications network, act of
          God, war, riot, civil commotion, malicious damage, compliance with any
          law or government order, rule, regulation or direction, accident,
          breakdown of plant or machinery, fire, flood, storm or default of
          suppliers or sub-contractors.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>16.</strong> Transfer of our rights
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem><strong>16.1.</strong> We may transfer our rights under these Terms.</ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>17.</strong> Changes to these Terms
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>17.1.</strong> We may make changes to these Terms from time to time and such
          changes shall be effective from the next time you use Garfield. Your
          sole remedy for any such change shall be you terminating your account.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>18.</strong> Termination
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>18.1.</strong> Both you and we have the right to terminate your use of Garfield
          by notice in writing at any time. You may terminate your use of
          Garfield at any time and without cause. Such termination will not
          prejudice our accrued rights and remedies. We may terminate on giving
          you reasonable notice and we shall only terminate for a good reason.
          "Good reason" shall non-exhaustively include any breach by you of any
          provision of these Terms.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>19.</strong> Applicable and jurisdiction
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>19.1.</strong> These Terms and all non-contractual rights and any dispute
          arising out of these Terms are governed by English law.
        </ListItem>
        <ListItem>
          <strong>19.2.</strong> You and we irrevocably agree to be subject to the exclusive
          jurisdiction of the courts of England and Wales.
        </ListItem>
      </List>

      <Space h={'lg'} />
      <Title order={3} size={'h3'} mb='xs'>
        <strong>20.</strong> Interpretation
      </Title>
      <Space h={'sm'} />
      <List
        withPadding
        size={'md'}
        styles={(theme) => ({
          item: {
            listStyleType: 'none',
            paddingLeft: 0,
            marginBottom: theme.spacing.xs,
          },
        })}
      >
        <ListItem>
          <strong>20.1.</strong> <strong>Claim</strong> means a Small Debt Claim.
        </ListItem>
        <ListItem>
          <strong>20.2.</strong> <strong>Garfield</strong> means the web and/or app based
          provision by us of a software implemented machine learning based
          system to you for assisting a claimant to pursue a Claim. Based on
          input by you, it includes the preparation of letters chasing debts and
          letters before action, the preparation of correspondence in reply to
          letters sent by a debtor responding to chasing letters and letters
          before action, and the provision by way of machine learning of answers
          to questions you may ask about small debt Claims. It does not include
          the provision to you of any:
          <List
            withPadding
            size={'md'}
            styles={(theme) => ({
              item: {
                listStyleType: 'none',
                paddingLeft: 0,
                marginBottom: theme.spacing.xs,
              },
            })}
          >
            <ListItem>
              <strong>20.2.1.</strong> services relating to the commencement or pursuit of a
              small debt claim in the English Courts;
            </ListItem>
            <ListItem><strong>20.2.2.</strong> advocacy service in Court;</ListItem>
            <ListItem><strong>20.2.3.</strong> merits advice;</ListItem>
            <ListItem>
              <strong>20.2.4.</strong> enforcement of any English Court Order or judgment;
            </ListItem>
            <ListItem>
              <strong>20.2.5.</strong> the appeal from any English Court Order or judgment;
            </ListItem>
            <ListItem>
              <strong>20.2.6.</strong> other service (including but not limited to legal
              services, tax services or accounting services).
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <strong>20.3.</strong> <strong>Input</strong> means input you have provided to Garfield
          being, namely, information, data and/or documents you provide and/or
          upload to Garfield.
        </ListItem>
        <ListItem>
          <strong>20.4.</strong> <strong>Output</strong> means input you have provided to Garfield
          being, namely, information, data and/or documents you provide and/or
          upload to Garfield.
        </ListItem>
        <ListItem>
          <strong>20.5.</strong> <strong>Small Debt Claim</strong> means a debt claim for less
          than £10,000 (inclusive of interest thereon) pursued by you or your
          Organisation against a debtor, issued via Garfield by way of a
          pre-action letter before action.
        </ListItem>
        <ListItem>
          <strong>20.6.</strong> <strong>Terms</strong> means these Terms of Services, as may be
          amended from time to time pursuant to clause 18.1.
        </ListItem>
        <ListItem>
          <strong>20.7.</strong> <strong>We</strong> or <strong>Us</strong> means Project Garfield
          Limited, a company incorporated in England under company number
          14848790.
        </ListItem>
        <ListItem>
          <strong>20.8.</strong> <strong>You</strong> means the user who is registering or has
          registered to use Garfield.
        </ListItem>
        <ListItem>
          <strong>20.9.</strong> <strong>Your Organisation</strong> means the organisation you
          work for.
        </ListItem>
      </List>
    </>
  )
}

export default TermsText
