import { Instance, SnapshotOut, applySnapshot, types } from 'mobx-state-tree'
import { AuthStoreModel } from './auth_store'
import { NavStoreModel } from './nav_store'
import { ClaimStoreModel } from './claim_store'
import { IUser } from '../models/auth/user'
import { MemberStoreModel } from './member_store'
import { DialogueStoreModel } from './dialogue_store'
import { BillingStoreModel } from './billing_store'
import { DocumentStoreModel } from './document_store'

// The Root Store is a composition of different sub-stores. Mostly used for stores to get access to its siblings.
export const RootStoreModel = types
  .model('RootStore')
  .props({
    authStore: types.optional(AuthStoreModel, {}),
    memberStore: types.optional(MemberStoreModel, {}),
    billingStore: types.optional(BillingStoreModel, {}),
    navStore: types.optional(NavStoreModel, {}),
    claimStore: types.optional(ClaimStoreModel, {}),
    dialogueStore: types.optional(DialogueStoreModel, {}),
    documentStore: types.optional(DocumentStoreModel, {}),
  })
  .views((self) => ({
    // Returns the current account for easy access from other stores
    get user() {
      return self.authStore.user as IUser
    },
  }))
  .actions((self) => ({
    // Called on Sign Out or Delete Account, it resets the state across the entire app
    reset() {
      applySnapshot(self.authStore, {})
      applySnapshot(self.memberStore, {})
      applySnapshot(self.navStore, {})
      applySnapshot(self.claimStore, {})
      applySnapshot(self.dialogueStore, {})
      applySnapshot(self.documentStore, {})
      applySnapshot(self.billingStore, {})
    },
  }))

// Root store instance
export interface RootStore extends Instance<typeof RootStoreModel> {}

// Snapshot of the data in the root store
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
