import {
  Container,
  Text,
  Divider,
  useMantineTheme,
  Title,
  Card,
  List,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMediaQuery } from '@mantine/hooks'

const privacyContent = (
  <Text>
            <p>We currently collect and process the following information:</p>
            <List>
              <List.Item>
                Personal identifiers, contacts and characteristics (for example,
                name and contact details)
              </List.Item>
              <List.Item>Customer financial information</List.Item>
              <List.Item>Employee data</List.Item>
              <List.Item>
                Details of the customer's business and its debtors
              </List.Item>
              <List.Item>
                Information about the customer's debtors including their names,
                contact details, financial information and other related
                information.
              </List.Item>
            </List>
            <h1> How we get the personal information and why we have it</h1>
            <Divider />
            <p>
              Most of the personal information we process is provided to us
              directly by you for one of the following reasons:
            </p>
            <List>
              <List.Item>
                To enable our app to work, to assist you in bringing the small
                claims that you wish to bring. We also receive personal
                information indirectly, from the following sources in the
                following scenarios:
              </List.Item>
              <List.Item>From the HM Court Service</List.Item>
              <List.Item>From HM Companies House</List.Item>
              <List.Item>
                From your debtors when they send documents to us relating to
                your small claims
              </List.Item>
            </List>
            <p>
              We use the information that you have given us in order to help you
              progress your small claims and in order that we can provide you
              with a better user experience.
            </p>
            <p>
              We may share this information with relevant government agencies.
            </p>
            <p>
              Under the UK General Data Protection Regulation (UK GDPR), the
              lawful bases we rely on for processing this information are:
            </p>
            <List type='ordered'>
              <List.Item>
                {' '}
                Your consent. You are able to remove your consent at any time.
                You can do this by contacting us at the details above. If you do
                so, we may no longer be able to help you with our app.
              </List.Item>
              <List.Item>We have a contractual obligation.</List.Item>
              <List.Item>We have a legal obligation.</List.Item>
              <List.Item>We have a vital interest.</List.Item>
              <List.Item>We need it to perform a public task.</List.Item>
              <List.Item>We have a legitimate interest.</List.Item>
            </List>
            <h1>How we store your personal information</h1>
            <Divider />
            <p>Your information is securely stored.</p>
            We keep information stored for no more than twelve years. We store
            it securely on appropriately secured databases. We will then dispose
            your information by permanently deleting it.
            <h1>Your data protection rights</h1>
            <Divider />
            <p>Under data protection law, you have rights including:</p>
            <strong>Your right of access</strong> - You have the right to ask us
            for copies of your personal information.<br></br>
            <strong>Your right to rectification</strong> - You have the right to
            ask us to rectify personal information you think is inaccurate. You
            also have the right to ask us to complete information you think is
            incomplete.<br></br>
            <strong>Your right to erasure</strong> - You have the right to ask
            us to erase your personal information in certain circumstances.
            <br></br>
            <strong>Your right to restriction of processing</strong> - You have
            the right to ask us to restrict the processing of your personal
            information in certain circumstances.<br></br>
            <strong>Your right to object to processing</strong> - You have the
            the right to object to the processing of your personal information
            in certain circumstances.<br></br>
            <strong>Your right to data portability</strong> - You have the right
            to ask that we transfer the personal information you gave us to
            another organisation, or to you, in certain circumstances.<br></br>
            <p>
              You are not required to pay any charge for exercising your rights.
              If you make a request, we have one month to respond to you.
            </p>
            Please contact us at our details above if you wish to make a
            request.
            <h1>How to complain</h1>
            <Divider />
            <p>
              If you have any concerns about our use of your personal
              information, you can make a complaint to us at our contact details
              below.
            </p>
            You can also complain to the ICO if you are unhappy with how we have
            used your data.
            <h1>Our contact details</h1>
            <Divider />
            Name: Philip Young
            <br />
            Address: 20-22 Wenlock Road, London, England N1 7GU
            <br />
            Phone Number:
            <br />
            <a href='mailto:philip@projectgarfield.co.uk'>
              E-mail: philip@projectgarfield.co.uk
            </a>
            <h1>ICO’s contact details</h1>
            <Divider />
            Information Commissioner’s Office:
            <br />
            Wycliffe House
            <br />
            Water Lane
            <br />
            Wilmslow
            <br />
            Cheshire
            <br />
            SK9 5AF
            <p></p>
            Helpline number: 0303 123 1113 <br />
            ICO website:{' '}
            <a href='https://www.ico.org.uk'>https://www.ico.org.uk</a>
            <p />
          </Text>
)

function DesktopPrivacyPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        // paddingLeft: '16px',
        backgroundColor: theme.colors.white[5],
      }}
      p={'md'}
      m={0}
      fluid
    >
      <Card
        shadow='sm'
        withBorder
        p={'xl'}
        radius={'md'}
        maw={width / 2}
        mx={'auto'}
      >
        <Card.Section p={'xl'}>
          <Title py={'md'} order={1} fw={800}>
            Privacy Policy
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'xl'}>
          {privacyContent}
        </Card.Section>
      </Card>
    </Container>
  )
}

function MobilePrivacyPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        backgroundColor: theme.colors.white[5],
      }}
      p={'xs'}
      m={0}
      fluid
    >
      <Card shadow='sm' withBorder p={'md'} radius={'md'}>
        <Card.Section p={'md'}>
          <Title py={'sm'} order={2} fw={700}>
            Privacy Policy
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'md'}>
          {privacyContent}
        </Card.Section>
      </Card>
    </Container>
  )
}

function PrivacyPage() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return isMobile ? <MobilePrivacyPage /> : <DesktopPrivacyPage />
}

export default PrivacyPage
