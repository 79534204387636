import { Button, CloseButton, Combobox, FloatingPosition, Group, Input, InputBase, Text, useCombobox } from '@mantine/core';
import { observer } from 'mobx-react';
import { IBankAccount } from 'models/billing/bank_account';
import { useStores } from 'utils/use_stores';
import { IconPlus } from '@tabler/icons-react'
import * as changeCase from 'change-case'
import { redact } from 'utils/strings';
import { IDocument } from 'models/document/document';
import { modal } from 'utils/modal';


function SelectOption({ bank_account_name, bank_account_number, bank_account_sort_code, bank_account_type }: IBankAccount) {
  const maskedNumber = redact(bank_account_number, 4)

  return (
    <Group >
      <div>
      <Text fz="h4" fw={600} ta="center" style={{ display: 'flex', justifyContent: 'center' }}>
          {maskedNumber.split('').map((char, index) => (
            <span key={index} style={{ display: 'inline-flex', alignItems: 'center', width: '1em' }}>{char}</span>
          ))}
        </Text>
        <Text fz="xs" opacity={0.6}>
            {bank_account_name + " - " + changeCase.capitalCase(bank_account_type)}
        </Text>
      </div>
    </Group>
  );
}

function NonSelectedOption() {
  return (
    <Group>
      <div>
      <Text fz="h6" fw={500} c="dimmed">
          No bank account selected
        </Text>
      </div>
    </Group>
  );
}

function AddNewOption() {
  return (
    <Group py="xs">
        <IconPlus size={18} color='gray' />
        <Text fz="h6" fw={500} c="dimmed">
          Add new one
        </Text>
    </Group>
  );
}

interface BankAccountSelectionBoxProps {
  // target: (toggleDropdown: () => void) => ReactNode
  document: IDocument | null
  onClick?: (bankAccountId: string) => Promise<void>
  offset?: number
  width?: number
  position?: FloatingPosition
}

function BankAccountSelectionBox({
  document,
  onClick,
  offset,
  width,
  position,
}: BankAccountSelectionBoxProps) {
  const { authStore, billingStore } = useStores()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const openBankAccountModal = () => {
    modal.open(
      'Add new bank account',
      '',
      'bank_account',
      async () => {},
      () => {},
      {
        closeModal: () => modal.close(),
      }
    )
  }

  const bankAccounts: IBankAccount[] = billingStore.bankAccounts

  const selectedBankAccount = document ? billingStore.bankAccounts.find(
    (account) => account.id === (document.wanted_bank_account_id ?? authStore.user?.last_bank_account_used)
  ) : null

  // const [value, setValue] = useState<string | null>(null);
  const selectedOption = selectedBankAccount ?bankAccounts.find((bankAccount) => bankAccount.id === selectedBankAccount?.id) : null

  const options = [...bankAccounts.map((bankAccount) => (
    <Combobox.Option value={bankAccount.id} key={bankAccount.id}>
      <SelectOption {...bankAccount} />
    </Combobox.Option>
  )),
    <Combobox.Option value={'new'} key={'new'}>
      <AddNewOption />
    </Combobox.Option>
  
  ]

  return (
    <Combobox
      store={combobox}
      // withinPortal={false}
      onOptionSubmit={async (val) => {
        if (val === 'new') {
          combobox.closeDropdown();
          openBankAccountModal()
          return
        }
        await onClick?.(val)
        combobox.closeDropdown();
      }}
      withinPortal={true}
      // width={width ?? 350}
      offset={offset ?? 40}
      position={position ?? 'left-start'}
    >
      <Combobox.Target >
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={
            selectedOption !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => onClick?.("-1")}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          onClick={() => combobox.toggleDropdown()}
          // rightSectionPointerEvents="none"
          multiline
          w={"100%"}
        >
          {selectedOption ? (
            <SelectOption {...selectedOption} />
          ) : (
            <NonSelectedOption/>
          )}
        </InputBase>
      </Combobox.Target>

       {/* <Combobox.Target>
         {target(() => {
           combobox.toggleDropdown()
         })}
       </Combobox.Target> */}

      <Combobox.Dropdown>
        <Combobox.Options>{ options }</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default observer(BankAccountSelectionBox)