import { Text, Button, Card, Group, useMantineTheme } from '@mantine/core'
import { modal } from '../../utils/modal'

export const ConfirmEmailsModal = ({
  text,
  title,
  onClick,
}: {
  text: React.ReactNode
  title: string
  onClick: () => {}
}) => {
  const theme = useMantineTheme()

  return (
    <Card p={0} bg={'transparent'}>
      <Text fw={600} size='xl'>
        Confirm your email selection
      </Text>
      <Text c={theme.colors.gray[6]} fw={600} size='md' mt='lg'>
        Kindly review the email selections below. Please note that once the
        selection is confirmed, it cannot be reversed.
      </Text>
      <Group mt={'lg'}>
        <Button
          onClick={() => {
            onClick()
            modal.close()
          }}
        >
          Confirm
        </Button>
        <Button onClick={() => modal.close()}>Cancel</Button>
      </Group>
    </Card>
  )
}
