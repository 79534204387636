import { observer } from 'mobx-react'
import {
  Text,
  Space,
  Group,
  Paper,
  TextInput,
  PasswordInput,
  Title,
  Checkbox,
  Anchor,
  Modal,
  useMantineTheme,
} from '@mantine/core'
import { useDisclosure, useViewportSize } from '@mantine/hooks'
import { IconMailOpened } from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import TermsText from '../terms_text'

function InviteStep1({
  email,
  accountId,
  memberId,
  role,
}: {
  email?: string
  accountId?: string
  memberId?: string
  role?: string
}) {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore } = useStores()
  const [tosOpened, { open: openTos, close: closeTos }] = useDisclosure(false)

  const navigate = useNavigate()

  return (
    <>
      <Modal
        centered
        size='55%'
        opened={tosOpened}
        onClose={closeTos}
        title='Terms of Service at 17/11/23'
      >
        <TermsText />
      </Modal>
      <form>
        <Paper ml={'20px'}>
          <Space h={'xs'} />
          <Group>
            <IconMailOpened size={32} />
            <Title order={2}>You are invited to join Garfield</Title>
          </Group>
          <Space h={'lg'} />
          <Space h={'lg'} />
          <TextInput
            value={email}
            id='email-input'
            label="Your company's email address"
            placeholder={email}
            // onChange={(e) => authStore.setAuthEmail(e.target.value)}
            disabled
          />
          <PasswordInput
            value={authStore.authPassword ?? undefined}
            id='password-input'
            label='Your password'
            placeholder='password'
            mt='md'
            onChange={(e) => authStore.setAuthPassword(e.target.value)}
          />
          <PasswordInput
            value={authStore.authConfirmPassword ?? undefined}
            id='confirm-password-input'
            label='Confirm your password'
            placeholder='password'
            mt='md'
            onChange={(e) => authStore.setAuthConfirmPassword(e.target.value)}
          />
          <Space h={'lg'} />
          <Space h={'lg'} />
          <Checkbox
            mt='sm'
            checked={!!authStore.tosAccepted}
            onChange={(event) => {
              authStore.setTOSAccepted(event.currentTarget.checked)
            }}
            label={
              <Text c={theme.colors.gray[7]} size='sm' ta='start'>
                I accept Garfield's{' '}
                <Anchor
                  size='sm'
                  component='button'
                  fw={600}
                  c={theme.colors.black[5]}
                  onClick={(e) => {
                    e.preventDefault()
                    openTos()
                  }}
                >
                  Terms of Service
                </Anchor>
              </Text>
            }
          />
        </Paper>
      </form>
    </>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(InviteStep1)
