// partial_admission_details.ts
import { Instance, types } from 'mobx-state-tree'

const PartialAdmissionDetails = types
  .model('PartialAdmissionDetails', {
    type: types.maybeNull(
      types.enumeration(['defends_balance', 'request_for_time_or_indulgence'])
    ),
    is_paid: types.maybeNull(types.boolean),
  })
  .actions((self) => ({}))

export default PartialAdmissionDetails
export interface IPartialAdmissionDetails
  extends Instance<typeof PartialAdmissionDetails> {}
