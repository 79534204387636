export default {
  ENV: 'production',

  FIREBASE_API_KEY: 'AIzaSyBvJ-MQDKLoHib2oAyDTP5dho3uMVUMzGo',
  FIREBASE_AUTH_DOMAIN: 'garfield-prod.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'garfield-prod',
  FIREBASE_STORAGE_BUCKET: 'garfield-prod.appspot.com',
  FIREBASE_SENDER_ID: '61301392610',
  FIREBASE_APP_ID: '1:61301392610:web:c266ce5dc5fd64fd6e4abb',
  FIREBASE_MEASUREMENT_ID: 'G-EEJMPNB1GV',

  RECAPTCHA_KEY: '6LcTcrQpAAAAAJtCLmOkS8MjhprD-xxaYd9xMaTn',
  APPCHECK_DEBUG_TOKEN: 'A2B9AF27-B91C-44ED-8A1F-6BDC50A95D9B',

  MIXPANEL_PROJECT_TOKEN: '6290481857e24aa268b862e4dfbcbcb7',
  SENTRY_DSN:
    'https://b15a9b17e19892e7b1f2a30d7f67a223@o4507017229434880.ingest.us.sentry.io/4507017230417920',

  SUMSUB_TOKEN_URL: 'https://get-sumsub-token-lw7buift6a-nw.a.run.app',
  SUMSUB_DATA_URL: 'https://get-applicant-data-lw7buift6a-nw.a.run.app',
  SPEECH_URL: 'https://text-to-speech-lw7buift6a-nw.a.run.app',
  REGENERATE_SIGNATURE_URL: 'https://regenerate-signature-lw7buift6a-nw.a.run.app',
  ASSIGN_CUSTOM_CLAIMS_URL: 'https://assign-custom-claims-lw7buift6a-nw.a.run.app',
}
