// Merged admission_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import PaymentOffer from './payment_offer'

const AdmissionDetails = types
  .model('AdmissionDetails', {
    type: types.maybeNull(
      types.enumeration([
        'admission',
        'partial_admission',
        'request_for_time_or_indulgence',
      ])
    ),
    paid: types.maybeNull(types.boolean),
    deadline_date: types.maybeNull(FirestoreTimestamp),
    payment_offer: types.maybeNull(PaymentOffer),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<IAdmissionDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default AdmissionDetails
export interface IAdmissionDetails extends Instance<typeof AdmissionDetails> {}
