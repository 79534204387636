import {
  useMantineTheme,
  AppShell,
  rem,
  Tabs,
  Group,
  Text,
  Badge,
  ScrollArea,
  Container,
  Button,
  Stack,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import SidebarHandle from './sidebar_handle'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import DocumentReader from '../shared/document_reader'
import 'react-resizable/css/styles.css'
import {
  IconDownload,
  IconMailCheck,
  IconTrash,
  IconX,
  IconLoader,
} from '@tabler/icons-react'
import { modal } from 'utils/modal'
import ReviewSendDrawer from 'components/claim_send/review_send_drawer'
import { analytics } from 'services/analytics_service'

function DocBar() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { documentStore, navStore, claimStore } = useStores()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints['sm']})`)
  const { height, width } = useViewportSize()

  const collapsed = navStore.docbarCollapsed
  const selectedDocument = documentStore.selectedSidebarDocument
  const readyToSend = claimStore.currentClaimReadyToSend
  const readyToSendDocument = readyToSend
    ? documentStore.readyToSendDocument
    : null
  
  const documentProcessing = readyToSendDocument && readyToSendDocument?.payment_processing ? true : false
  const currentTab = navStore.docbarTab

  const downloadSelectedDocument = async () => {
    if (!selectedDocument || !selectedDocument.url) return

    try {
      const response = await fetch(selectedDocument.url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = selectedDocument.name || 'download'
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      analytics.error(error)
    }
  }

  const downloadReadyToSendDocument = async () => {
    if (!readyToSendDocument || !readyToSendDocument.url) return

    try {
      const response = await fetch(readyToSendDocument.url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = readyToSendDocument.name || 'download'
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
      analytics.track('Downloaded paid document', {
        document_type: readyToSendDocument.type,
        document_id: readyToSendDocument.document_id,
        claim_id: readyToSendDocument.claim_id,
      })
    } catch (error) {
      analytics.error(error)
    }
  }

  const deleteDocument = async () => {
    if (!selectedDocument) return
    modal.open(
      "Sorry, we can't delete this document",
      'Please contact support to request deletion.',
      'manual_delete',
      async () => {
        console.log('Contact support')
      }
    )
  }

  if (!selectedDocument) {
    if (readyToSend) {
      navStore.setDocbarTab('ready')
    } else {
      console.log('Close docbar from docbar')
      navStore.closeDocbar()
    }
  }

  return (
    <>
      <SidebarHandle direction='right' />

      {collapsed ? null : (
        <>
          {readyToSend ? <ReviewSendDrawer /> : null}

          <AppShell.Aside
            h={height - 27}
            w={navStore.docbarWidth - 30}
            mr={'sm'}
            ml={'md'}
            mt={'sm'}
            style={{ borderRadius: 5 }}
            bg={'transparent'}
            zIndex={98}
            p={0}
          >
            <AppShell.Section p={0}>
              <Tabs
                color={
                  currentTab === 'ready'
                    ? theme.colors.red[5]
                    : theme.colors.black[5]
                }
                bg={'transparent'}
                variant='outline'
                defaultValue={readyToSend ? 'ready' : 'selected'}
                value={currentTab}
                onChange={(value) => {
                  console.log('value', value)
                  navStore.setDocbarTab(
                    value == 'selected' ? 'selected' : 'ready'
                  )
                }}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ScrollArea
                  type='never'
                  scrollbars='x'
                  dir='horizontal'
                  w={navStore.docbarWidth - 30}
                  h={40}
                  p={0}
                  m={0}
                  bg={'transparent'}
                >
                  <Tabs.List
                    style={{ flexWrap: 'nowrap' }}
                    w={'100%'}
                    h={40}
                    justify='flex-start'
                    p={0}
                    m={0}
                    bg={'transparent'}
                  >
                    {readyToSend ? (
                      <Tabs.Tab
                        style={{ flexWrap: 'nowrap' }}
                        py={0}
                        px={'sm'}
                        value={'ready'}
                        bg={
                          currentTab === 'ready'
                            ? theme.colors.white[5]
                            : 'transparent'
                        }
                      >
                        <Group
                          style={{ flexWrap: 'nowrap' }}
                          align='center'
                          justify='start'
                          gap={'xs'}
                        >
                          <Badge
                            color={theme.colors.red[5]}
                            size='xs'
                            radius='sm'
                          >
                            Ready
                          </Badge>
                          <Text fw={500} size='xs'>
                            {readyToSendDocument?.name}
                          </Text>
                        </Group>
                      </Tabs.Tab>
                    ) : null}

                    {selectedDocument ? (
                      <Tabs.Tab
                        style={{ flexWrap: 'nowrap' }}
                        py={0}
                        pl={'xs'}
                        pr={0}
                        m={0}
                        value={'selected'}
                        bg={
                          currentTab == 'selected'
                            ? theme.colors.white[5]
                            : 'transparent'
                        }
                      >
                        <Group
                          style={{ flexWrap: 'nowrap' }}
                          m={0}
                          w={'100%'}
                          align='center'
                          justify='start'
                          gap={'xs'}
                        >
                          <Text fw={500} size='xs'>
                            {selectedDocument.name}
                          </Text>
                          <Container fluid />
                          <Button
                            onClick={() => {
                              documentStore.removeSideBarDocument()
                            }}
                            mr={6}
                            size='xs'
                            p={'xs'}
                            variant='subtle'
                            color={theme.colors.black[5]}
                          >
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                            />
                          </Button>
                        </Group>
                      </Tabs.Tab>
                    ) : null}
                  </Tabs.List>
                </ScrollArea>

                <Tabs.Panel
                  value={currentTab}
                  bg={theme.colors.white[5]}
                  py={'lg'}
                  px={'sm'}
                  style={{
                    flex: 1,
                    overflow: 'auto',
                    borderTop: 'none', // Add this line to remove the top border
                    borderBottom: `1px solid ${theme.colors.gray[4]}`,
                    borderLeft: `1px solid ${theme.colors.gray[4]}`,
                    borderRight: `1px solid ${theme.colors.gray[4]}`,
                    position: 'relative',
                    borderRadius: '0 0 3px 3px',
                  }}
                >
                  {currentTab === 'ready' ? (
                    <Group
                      w={navStore.docbarWidth - 50}
                      justify='center'
                      align='center'
                      style={{
                        position: 'absolute', // Position the button absolutely
                        bottom: 0, // Adjust top as needed
                        zIndex: 10, // Ensure zIndex is higher than that of ScrollArea
                        background: `linear-gradient(to top, ${theme.colors.white[5]}, rgba(255, 255, 255, 0))`,
                      }}
                    >
                      {readyToSend ? (
                        <Button
                          mt={30}
                          mb={40}
                          style={{
                            boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
                          }}
                          onClick={() => {
                            if (
                              readyToSendDocument?.paid
                            ) {
                              downloadReadyToSendDocument()
                            } else {
                              console.log('Open review drawer')
                              navStore.toggleReviewDrawer(true)
                            }
                          }}
                          disabled={documentProcessing}
                          size='md'
                          variant='filled'
                          color={theme.colors.black[5]}
                          leftSection={
                            documentProcessing
                            ? (
                                <IconLoader
                                  style={{ width: rem(22), height: rem(22) }}
                                />
                              )
                            : 
                            readyToSendDocument?.paid ? (
                              <IconDownload
                                style={{ width: rem(22), height: rem(22) }}
                              />
                            ) : (
                              <IconMailCheck
                                style={{ width: rem(22), height: rem(22) }}
                              />
                            )
                          }
                        >
                          {documentProcessing
                            ? 'Processing'
                            : readyToSendDocument?.paid
                              ? 'Download'
                              : 'Review'}
                        </Button>
                      ) : null}
                    </Group>
                  ) : null}

                  <ScrollArea h={height - 112}>
                    {readyToSend || selectedDocument ? (
                      <DocumentReader
                        selectedDocument={
                          currentTab === 'selected'
                            ? selectedDocument
                            : readyToSendDocument
                        }
                        menu={[
                          {
                            label: 'Download',
                            color: theme.colors.black[5],
                            icon: (
                              <IconDownload
                                style={{ width: rem(14), height: rem(14) }}
                              />
                            ),
                            action: downloadSelectedDocument,
                          },
                          {
                            label: 'Delete',
                            color: 'red',
                            icon: (
                              <IconTrash
                                style={{ width: rem(14), height: rem(14) }}
                              />
                            ),
                            action: deleteDocument,
                          },
                        ]}
                        visible={true}
                      />
                    ) : (
                      <Stack h={'100%'} justify='center' align='center'>
                        <Text size='sm' fw={'bold'} c={theme.colors.gray[5]}>
                          Click on a document to read it here
                        </Text>
                      </Stack>
                    )}
                  </ScrollArea>
                </Tabs.Panel>
              </Tabs>
            </AppShell.Section>
          </AppShell.Aside>
        </>
      )}
    </>
  )
}

export default observer(DocBar)
