import { Instance, types } from 'mobx-state-tree'
import Party from './party'
import Contract from './contract'
import ClaimDetails from './claim_details'
import ClaimState from './claim_state'

export interface ClaimRow {
  claimId: string
  displayId: string
  name: string
  dateCreated: string
  stage: string
  debtor: string
  summary: string
  amount: number | string
  action: boolean
}

const Claim = types
  .model('Claim', {
    claim_id: types.identifier,
    account_id: types.string,
    claimants: types.array(Party),
    defendants: types.array(Party),
    contract: types.maybeNull(Contract),
    details: types.maybeNull(ClaimDetails),
    state: types.maybeNull(ClaimState),
  })
  .views((self) => ({
    // Views from the old model
    get displayId() {
      return self.claim_id ? self.claim_id.split('-')[0] : '' // Adjusted to handle possible null id
    },
    get displayName() {
      if (self.defendants.length > 0 && self.defendants[0].name) {
        return self.defendants[0].name
      } else {
        return this.displayId
      }
    },
    get readyToSend() {
      if (!self.state?.documents_to_send) return false
      return self.state?.documents_to_send.length! > 0
    },
  }))
  .actions((self) => ({
    // Actions from the new model (if any were defined, they would be included here)
    // Define actions here
  }))

export default Claim
export interface IClaim extends Instance<typeof Claim> {}
