import { useLocation } from 'react-router-dom'
import { useInitialRootStore, useStores } from './utils/use_stores'
import { observer } from 'mobx-react'
import {
  useHeadroom,
  useMediaQuery,
} from '@mantine/hooks'
import { AppShell } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import { useEffect, useState } from 'react'
import WebHeader from './components/navigation/web_header'
import NavBar from './components/navigation/nav_bar'
// import DocumentBar from './components/navigation/document_bar'
import AppRouter from './components/navigation/app_router'
import WebFooter from './components/navigation/web_footer'
import DocBar from 'components/navigation/doc_bar'

function App() {
  // Set up the root store and rehydrates it with data stored in the browser's local storage
  const { rehydrated, rootStore } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.
    // Good moment to show loading or splash screens if necessary
  })

  // This hook provides access to the data stores
  const {
    authStore: accountStore,
    navStore,
    claimStore,
    billingStore,
  } = useStores()

  useEffect(() => {
    // Initialize stores on render
    accountStore.init()
  }, [])

  const theme = useMantineTheme()
  const location = useLocation()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints['md']})`)
  const [isInitialRender, setIsInitialRender] = useState(true)


  // This calculates the maximum scroll and uncollapses the footer when reached
  const totalHeight = document.documentElement.scrollHeight
  const viewportHeight = window.innerHeight
  const maxScrollY = totalHeight - viewportHeight
  const footerFixPoint = useHeadroom({ fixedAt: maxScrollY })
  const headerFixPoint = useHeadroom({ fixedAt: 50 })

  useEffect(() => {
    if (!isDesktop && !isInitialRender) {
      navStore.closeNavbar()
      navStore.closeDocbar()
    }
    setIsInitialRender(false)
  }, [isDesktop, isInitialRender])

  useEffect(() => {
    const pathName = location.pathname
    // Update the navStore's currentPath with the current location path
    navStore.setCurrentPath(pathName)
    if (!pathName.includes('/claims/')) {
      claimStore.removeCurrentClaim()
    } else {
      console.log('CLAIM STORE:', claimStore.currentClaimId)
      console.log('CLAIM STORE:', claimStore.currentClaimReadyToSend)
      if (!claimStore.currentClaimId) {
        const claimId = pathName.split('/claims/')[1]
        claimStore.setCurrentClaim(claimId)
      }
    }
  }, [location, navStore])

  const shouldCollapseWebHeader = () => {
    if (
      location.pathname === '/' ||
      location.pathname === '/privacy' ||
      location.pathname === '/complaints' ||
      location.pathname === '/legal' ||
      location.pathname === '/accessibility' ||
      location.pathname === '/about' ||
      location.pathname === '/pricing' ||
      location.pathname === '/opportunities' ||
      location.pathname === '/faq' ||
      location.pathname === '/terms'
    ) {
      return false
    }
    return accountStore.isOnboarded || !navStore.headerOpen
  }

  const shouldCollapseWebFooter = () => {
    if (location.pathname === '/') {
      return headerFixPoint
    }
    if (
      location.pathname === '/privacy' ||
      location.pathname === '/complaints' ||
      location.pathname === '/legal' ||
      location.pathname === '/accessibility' ||
      location.pathname === '/about' ||
      location.pathname === '/opportunities' ||
      location.pathname === '/faq' ||
      location.pathname === '/terms'
    ) {
      return false
    }
    if (
      location.pathname === '/signin' ||
      location.pathname === '/signup'
    ) {
      return true
    }
    return !!accountStore.isOnboarded
  }

  return (
    <AppShell
      withBorder={false}
      header={{
        height: 60,
        collapsed: shouldCollapseWebHeader(),
      }}
      navbar={{
        width: navStore.navbarWidth,
        breakpoint: 0,
        collapsed: {
          mobile: !accountStore.isOnboarded,
          desktop: !accountStore.isOnboarded,
        },
      }}
      aside={{
        width: navStore.docbarWidth,
        breakpoint: 0,
        collapsed: {
          mobile: !accountStore.isOnboarded,
          desktop: !accountStore.isOnboarded,
        },
      }}
      footer={{
        height: 60,
        collapsed: shouldCollapseWebFooter(),
      }}
      bg={theme.colors.gray[1]}
    >
      <WebHeader />
      {accountStore.isOnboarded ? <NavBar /> : null}
      <AppRouter />
      {accountStore.isOnboarded ? <DocBar /> : null}
      <WebFooter />
    </AppShell>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(App)
