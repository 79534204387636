import {
  useMantineTheme,
  Stack,
  Tabs,
  rem,
  Paper,
  Text,
  Button,
  Group,
  Container,
  Tooltip,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { print } from '../utils/print'
import NavHeader from '../components/navigation/nav_header'
import { IconTrash } from '@tabler/icons-react'
import { useEffect, useState, createContext, useRef } from 'react'
import { useStores } from '../utils/use_stores'
import { useViewportSize } from '@mantine/hooks'
import DialogueTab from '../components/claim_dialogue/dialogue_tab'
import DetailsTab from '../components/claim_details/details_tab'
import DocumentsTab from '../components/claim_documents/documents_tab'
import DocumentDates from '../models/document/document_dates'
// import SendTab from '../components/claim_send/send_tab'
import SettlementTab from '../components/claim_settlement/settlement_navbar'
import { useNavigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ClaimTabError } from '../components/errors/claim_tab_error'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { IconCloudUpload, IconFile } from '@tabler/icons-react'
import { Attachment } from '../models/document/attachment'
import Document, { DocumentType } from '../models/document/document'
import { modal } from '../utils/modal'

import './claim_page.css'
import { firebase } from '../services/firebase_service'
import { isAlive } from 'mobx-state-tree'
import { analytics } from 'services/analytics_service'

export const FileContext = createContext<{
  attachments: Attachment[]
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>
  addAttachment: (files: File[]) => void
  handleFiles: (files: File[]) => void
}>({
  attachments: [],
  setAttachments: () => {},
  addAttachment: () => {},
  handleFiles: () => {},
})

interface CustomInputAttributes
  extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string
  directory?: string
}

function ClaimPage() {
  const {
    dialogueStore,
    claimStore,
    documentStore,
    memberStore,
    authStore: accountStore,
    navStore
  } = useStores()

  const claim = claimStore.currentClaim

  const theme = useMantineTheme()
  const iconStyle = { width: rem(16), height: rem(16) }

  const { height, width } = useViewportSize()
  const navigate = useNavigate()
  const [isDragging, setIsDragging] = useState(false)
  // const [claim, setClaim] = useState(claimStore.getCurrentClaim()!)

  const [deleteHovered, setDeleteHovered] = useState(false)
  const [currentTab, setCurrentTab] = useState<string | null>('chat')
  // const fees = claimStore.getCurrentClaim()?.state?.fees
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const panelHeight = height - 120

  const dropzoneRef = useRef<HTMLDivElement>(null)

  const draft = dialogueStore.currentDraft
  const draftId = dialogueStore.currentDraftId

  const handleDrop = async (event: any) => {
    console.log('handleDrop 1')
    event.preventDefault()
    setIsDragging(false)
    const items = event.dataTransfer.items
    const files: File[] = []
    console.log('handleDrop 2')
    console.log(items)

    const processFiles = async (item: FileSystemEntry) => {
      if (item.isFile) {
        const filePromise = new Promise<File>((resolve, reject) => {
          ;(item as FileSystemFileEntry).file(resolve, reject)
        })
        return filePromise
          .then((file) => {
            files.push(file)
          })
          .catch((error) => {
            analytics.error(error)
          })
      } else if (item.isDirectory) {
        const directoryReader = (
          item as FileSystemDirectoryEntry
        ).createReader()
        return new Promise<void>((resolve, reject) => {
          directoryReader.readEntries(async (entries) => {
            for (const entry of entries) {
              await processFiles(entry)
            }
            resolve()
          }, reject)
        })
      }
    }

    const filePromises = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry()
      if (item) {
        filePromises.push(processFiles(item))
      }
    }

    await Promise.all(filePromises)
    handleFiles(files)
  }

  const handleFiles = (files: File[]) => {
    console.log('handleFiles 1')
    console.log(files)

    const errors: string[] = []
    const pdfFiles: File[] = []

    if (files.length > 30) {
      modal.open(
        'Could not upload files',
        'Sorry, cannot upload more than 30 files at a time',
        'file_upload',
        () => {
          modal.close()
          return {}
        },
        () => modal.close()
      )
      return
    }

    console.log('handleFiles 2')

    for (const file of files) {
      // check if file is pdf
      if (
        ![
          MIME_TYPES.png as string,
          MIME_TYPES.pdf as string,
          MIME_TYPES.jpeg as string,
        ].includes(file.type)
      ) {
        errors.push(`${file.name} is not a PDF or image file`)
        continue
      }
      // check if file is too large
      if (file.size > 25000000) {
        errors.push(`${file.name} - maximum file size permitted: 25 megabytes.`)
        continue
      }

      pdfFiles.push(file)
    }

    console.log('handleFiles 3')

    if (errors.length) {
      modal.open(
        'Some files could not be uploaded',
        <>
          {errors.map((error, index) => (
            <Text key={index}>{error}</Text>
          ))}
        </>,
        'file_upload',
        () => {
          modal.close()
          return {}
        },
        () => modal.close()
      )
    }

    console.log('handleFiles 4')

    console.log('pdfFiles', pdfFiles)

    if (pdfFiles.length > 0) {
      addAttachment(pdfFiles)
    }
  }

  useEffect(() => {
    const handleDragOver = (event: any) => {
      event.preventDefault()
      setIsDragging(true)
    }

    const handleDragLeave = (event: any) => {
      event.preventDefault()
      setIsDragging(false)
    }

    document.addEventListener('dragover', handleDragOver)
    document.addEventListener('dragleave', handleDragLeave)
    document.addEventListener('drop', handleDrop)

    return () => {
      document.removeEventListener('dragover', handleDragOver)
      document.removeEventListener('dragleave', handleDragLeave)
      document.removeEventListener('drop', handleDrop)
    }
  }, [])

  useEffect(() => {
    // setClaim(claimStore.getCurrentClaim()!)

    // Subscribe when the component mounts
    dialogueStore.subscribeToMessagesForClaim(claimStore.currentClaimId!, 10)
    documentStore.subscribeToDocumentsForClaim(claimStore.currentClaimId!)

    // Unsubscribe when the component unmounts
    return () => {
      documentStore.unsubscribeFromDocuments()
      documentStore.clearDocuments()
      dialogueStore.unsubscribeFromMessages()
      dialogueStore.clearMessages()
    }
  }, [dialogueStore, claimStore.currentClaimId])

  const deleteClaim = async () => {
    modal.loading(true)
    const deleted = await claimStore.deleteClaim(claimStore.currentClaimId!)
    navigate(`/claims`)
    if (deleted) { claimStore.removeCurrentClaim() }
    modal.loading(false)
  }

  const getAttachmentIndex = (file: File) => {
    const existingAttachmentIndex = attachments.findIndex(
      (attachment) => attachment.file.name === file.name
    )
    return existingAttachmentIndex
  }

  useEffect(() => {
    console.log("ClaimStore.currentClaimReadyToSend", claimStore.currentClaimReadyToSend)
    if (claimStore.currentClaimReadyToSend) { navStore.openDocbar() }
  }, [claimStore.currentClaimReadyToSend])

  const addAttachment = async (files: File[]) => {
    console.log('Adding attachment')
    console.log('draftId: ', draftId)
    console.log('dialogueStore.currentDraftId: ', dialogueStore.currentDraftId)
    console.log('draft: ', draft)

    if (!isAlive(dialogueStore.currentDraft)) {
      console.log('Draft is not alive')
      return
    }

    console.log('Adding attachment 1')
    console.log('files: ', files)
    const attachmentsToAdd: Attachment[] = []

    // Generate unique ids for each file
    const newDocumentIds: string[] = []
    while (newDocumentIds.length < files.length) {
      const newDocumentId = await firebase.getUniqueIdForCollection([
        'users',
        accountStore.user?.account_id ?? '',
        'claims',
        claimStore.currentClaimId ?? '',
        'documents',
      ])
      if (!newDocumentIds.includes(newDocumentId)) {
        newDocumentIds.push(newDocumentId)
      }
    }

    console.log('Adding attachment 2')
    console.log('newDocumentIds: ', newDocumentIds)

    // Technically duplicated document ids could appear here if not using usedIds.
    // If I call getUniqueIdForCollection inside the forEach loop everything breaks though ~ please advise J

    files.forEach(async (file, index) => {
      const attachmentIndex = getAttachmentIndex(file)
      if (attachmentIndex === -1) {
        const extension = file.name.split('.').pop()
        const documentId = `${newDocumentIds[index]}`

        const document = Document.create({
          document_id: documentId,
          name: file.name,
          message_id: dialogueStore.currentDraft?.message_id,
          claim_id: dialogueStore.currentDraft?.claim_id,
          account_id: accountStore.user?.account_id,
          dates: DocumentDates.create({
            created_date: new Date(),
            last_modified_date: new Date(),
          }),
          mime_type: file.type,
          prefix: `users/${accountStore.user?.account_id}/claims/${claimStore.currentClaimId}/documents/${documentId}.${extension}`,
          type: null,
          creator: 'user',
          last_updated_date: new Date(),
          uploading: true,
          extension: extension,
        })
        attachmentsToAdd.push({
          file: file,
          document: document,
          claimId: claimStore.currentClaimId!,
        })
        dialogueStore.addAttachmentToDraft(
          document.document_id!,
          dialogueStore.currentDraftId!
        )
        documentStore.updateDocumentFromLocalState(document)
      }
    })

    console.log('Adding attachment 3')
    console.log('attachmentsToAdd', attachmentsToAdd)

    setAttachments((prevAttachments) => [
      ...prevAttachments,
      ...attachmentsToAdd,
    ])
  }

  if (!claim) {
    navigate('/claims')
    return <></>
  }

  // const readyToSend = claim.readyToSend

  return (
    <Stack ml={'xl'} mr={'md'}>
      <Group p={0} justify='center' align='center' gap={'xs'}>
        <NavHeader />
        <Container fluid />
        <Tooltip
          label={'Only Claim Admins can delete claims'}
          disabled={memberStore.canManageClaims}
        >
          <Button
            mt={'xs'}
            size='compact-sm'
            justify='start'
            variant='subtle'
            color={deleteHovered ? theme.colors.red[5] : theme.colors.black[5]}
            opacity={deleteHovered ? 1 : 0.75}
            // leftSection={}
            onClick={deleteClaim}
            onMouseEnter={() => setDeleteHovered(true)}
            onMouseLeave={() => setDeleteHovered(false)}
            disabled={!memberStore.canManageClaims}
          >
            <IconTrash size={18} />
          </Button>
        </Tooltip>
      </Group>

      <Paper
        withBorder
        radius={'sm'}
        h={height - 70}
        p={0}
        style={{ overflow: 'hidden' }}
      >
        <Tabs
          color={
            currentTab === 'send' ? theme.colors.red[5] : theme.colors.black[5]
          }
          variant='default'
          defaultValue={'chat'}
          value={currentTab}
          onChange={setCurrentTab}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Tabs.List h={50} justify='flex-start'>
            <Tabs.Tab value='chat' data-testid='chat-tab'>Dialogue</Tabs.Tab>
            <Tabs.Tab value='details'>Details</Tabs.Tab>
            <Tabs.Tab value='documents'>Documents</Tabs.Tab>
            {process.env.REACT_APP_ENV === 'emulator' ||
            process.env.REACT_APP_ENV === 'development' ? (
              <Tabs.Tab value='settlement'>Settlement</Tabs.Tab>
            ) : null}

            {/* {readyToSend ? (
              <Tabs.Tab value='send'>
                <Card py={4} bg={'transparent'}>
                  <Group>
                    <Indicator
                      inline
                      color='red'
                      position='middle-center'
                      size={8}
                      processing
                    />
                    <Text fw={500} c={theme.colors.black[5]} size='sm'>
                      Ready to send
                    </Text>
                  </Group>
                </Card>
              </Tabs.Tab>
            ) : null} */}
          </Tabs.List>
          <FileContext.Provider
            value={{ attachments, setAttachments, addAttachment, handleFiles }}
          >
            <Tabs.Panel
              value='chat'
              px='xs'
              h={panelHeight}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              <ErrorBoundary
                FallbackComponent={ClaimTabError}
                onError={(error) => print(error)}
              >
                <>
                  {isDragging ? (
                    <Dropzone
                      ref={dropzoneRef}
                      className='dropzone'
                      onDrop={() => {}}
                    >
                      <Group className='dropzone-content'>
                        <Dropzone.Accept>
                          <div className='dropzone-accept' />
                          <IconCloudUpload className='dropzone-icon' />
                          <Text className='dropzone-text' inline>
                            Drop your documents here
                          </Text>
                          <Text
                            className='dropzone-subtext'
                            size='md'
                            c='dimmed'
                            inline
                            mt={10}
                          >
                            Everything you drop will be added to the dialogue
                          </Text>
                        </Dropzone.Accept>
                        <Dropzone.Idle>
                          <IconFile className='dropzone-icon' />
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                  ) : (
                    <DialogueTab claim={claim} />
                  )}
                </>
              </ErrorBoundary>
            </Tabs.Panel>
            <Tabs.Panel
              value='details'
              px='xs'
              h={panelHeight}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              <ErrorBoundary
                FallbackComponent={ClaimTabError}
                onError={(error) => print(error)}
              >
                <DetailsTab claim={claim} />
              </ErrorBoundary>
            </Tabs.Panel>
            <Tabs.Panel
              value='documents'
              p={0}
              h={panelHeight}
              bg={theme.colors.gray[1]}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              <ErrorBoundary
                FallbackComponent={ClaimTabError}
                onError={(error) => print(error)}
              >
                <DocumentsTab claim={claim} />
              </ErrorBoundary>
            </Tabs.Panel>

            <Tabs.Panel
              value='settlement'
              p={0}
              h={panelHeight}
              bg={theme.colors.gray[1]}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              <ErrorBoundary
                FallbackComponent={ClaimTabError}
                onError={(error) => print(error)}
              >
                <SettlementTab claim={claim} />
              </ErrorBoundary>
            </Tabs.Panel>
          </FileContext.Provider>
          {/* {readyToSend ? (
            <Tabs.Panel
              value='send'
              p={0}
              h={panelHeight}
              bg={theme.colors.gray[1]}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              <ErrorBoundary
                FallbackComponent={ClaimTabError}
                onError={(error) => print(error)}
              >
                <SendTab claim={claim} />
              </ErrorBoundary>
            </Tabs.Panel>
          ) : null} */}
        </Tabs>
      </Paper>
    </Stack>
  )
}

export default observer(ClaimPage)
