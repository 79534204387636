import { ReactNode } from 'react'
import {
  Combobox,
  Group,
  useCombobox,
  Text,
  ScrollArea,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { useStores } from '../../utils/use_stores'
import { IDocument } from '../../models/document/document'

interface VersionSelectorBoxProps {
  documentVersions: IDocument[];
  target: (toggleDropdown: () => void) => ReactNode
}

function VersionSelectorBox({
  documentVersions,
  target,
}: VersionSelectorBoxProps) {
  const { documentStore } = useStores()

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

    const data = documentVersions.map((version, index) => ({
        document_id: version.document_id,
        version: documentVersions.length - index,
        date: version.dates?.created_date?.toDate().toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })
    }))

    const options = data
    .map((document) => (
      <Combobox.Option
        value={document.document_id!}
        key={document.document_id}
        active={true}
        onClick={(event) => {
          documentStore.setArchiveDocumentId(document.document_id)
          event.stopPropagation()
        }}
      >
        <Group gap='sm'>
            <div>
                <Text fz='sm' fw={500}>
                {`Version ${document.version}`}
                </Text>
                <Group>
                <Text fz='xs' opacity={0.6}>
                    {document.document_id}
                </Text>
                <Text fz='xs' opacity={0.6}>
                    {document.date}
                </Text>
                </Group>
            </div>
        </Group>
      </Combobox.Option>
    ))

  return (
    <Combobox
      store={combobox}
      withinPortal={true}
      width={350}
      offset={40}
      position='left-start'
    >
      <Combobox.Target>
        {target(() => {
          combobox.toggleDropdown()
        })}
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <ScrollArea.Autosize mah={400} type='scroll'>
            {options}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default observer(VersionSelectorBox)
