// line_item.ts
import { Instance, types } from 'mobx-state-tree'

const LineItem = types
  .model('LineItem', {
    id: types.maybeNull(types.string),
    type: types.maybeNull(types.enumeration(['goods', 'service', 'loan'])),
    title: types.maybeNull(types.string),
    quantity: types.maybeNull(types.string),
    unit_amount: types.maybeNull(types.string),
    tax_amount: types.maybeNull(types.string),
    discount_amount: types.maybeNull(types.string),
  })
  .views((self) => ({
  }))
  .actions((self) => ({
    update(updatedLineItem: Partial<ILineItem>) {
      Object.assign(self, updatedLineItem)
    },
  }))

export default LineItem
export interface ILineItem extends Instance<typeof LineItem> {}
