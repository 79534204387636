import { IKeyValueMap } from 'mobx'
import { Instance, types } from 'mobx-state-tree'

// Model of the user data
const Price = types
  .model('Price', {
    id: types.identifier,
    active: types.boolean,
    billing_scheme: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    interval: types.maybeNull(types.string),
    interval_count: types.maybeNull(types.number),
    product: types.maybeNull(types.string),
    tax_behaviour: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    unit_amount: types.maybeNull(types.number),
    metadata: types.maybeNull(
      types.frozen<IKeyValueMap<string | number | null>>()
    ),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export default Price
export interface IPrice extends Instance<typeof Price> {}
