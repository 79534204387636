import { Instance, SnapshotOut, types, applySnapshot } from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import { IUser } from '../models/auth/user'
import { firebase } from '../services/firebase_service'
import { v4 as uuidv4 } from 'uuid'
import Member, { IMember, ROLES, STATUS } from '../models/auth/member'
import Invite, { IInvite } from '../models/auth/invite'
import { ISimpleType, IMSTArray } from 'mobx-state-tree'
import BankAccount, { IBankAccount } from 'models/billing/bank_account'
import { notification } from 'utils/notification'
import { analytics } from 'services/analytics_service'

// The AuthStore is responsible for the authentication state of the app
export const MemberStoreModel = types
  .model('MemberStore')
  .props({
    currentMemberId: types.maybeNull(types.string),
    members: types.optional(types.array(Member), []),
    invites: types.optional(types.array(Invite), []),
  })
  // Views are read-only derived data of the state tree
  .views((store) => ({
    get currentMember() {
      return store.members.find(
        (member) => member.member_id === store.currentMemberId
      )
    },
    get currentName() {
      return this.currentMember?.name
    },
    get currentRole() {
      return this.currentMember?.role
    },
    get canManageTeam() {
      return (
        this.currentRole === ROLES.team_admin ||
        this.currentRole === ROLES.owner
      )
    },
    get canManageClaims() {
      return (
        this.currentRole === ROLES.claim_admin ||
        this.currentRole === ROLES.owner ||
        this.currentRole === ROLES.team_admin
      )
    },
    get canManageBilling() {
      return this.currentRole === ROLES.owner
    },
    get currentClaimIds() {
      return this.currentMember?.claim_ids || []
    },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    subscribeToMembers() {
      const user = getRootStore(store).user
      // Subscribe to user data changes
      firebase.subscribeToMembers(user.account_id, (snapshot) => {
        try {
          const members: IMember[] = []
          snapshot.forEach((doc) => {
            const member = doc.data() as IMember
            members.push(member)
          })
          this.setMembers(members)
          this.setCurrentMember(user.uid)
        } catch (error) {
          analytics.error(error)
        }
      })
    },
    getInviteIndex(inviteId: string) {
      const inviteIndex = store.invites.findIndex(
        (invite) => invite.member_id === inviteId
      )
      return inviteIndex
    },
    updateInvite(invite: Partial<IInvite>) {
      const inviteIndex = this.getInviteIndex(invite.member_id!)
      if (inviteIndex !== -1) {
        applySnapshot(store.invites[inviteIndex], {
          ...store.invites[inviteIndex],
          ...invite,
        })
      }
    },
    addInvite() {
      const inviteId = uuidv4()
      store.invites.push(
        Invite.create({ member_id: inviteId, role: ROLES.claim_editor })
      )
    },
    removeInvite(inviteId: string) {
      const inviteIndex = this.getInviteIndex(inviteId)
      if (inviteIndex !== -1) {
        store.invites.splice(inviteIndex, 1)
      }
    },
    clearInvites() {
      store.invites.clear()
    },
    invitesReady() {
      const ready = store.invites.every(
        (invite) => invite.validEmail && invite.validRole
      )
      return ready
    },
    async sendInvites() {
      const user = getRootStore(store).user

      const invitePromises = store.invites.map(async (invite) => {
        const newMember = Member.create({
          member_id: invite.member_id,
          email: invite.email!,
          verified: 'pending',
          role: invite.role,
          status: STATUS.pending,
          invite_url: invite.inviteUrl,
          claim_ids: invite.claim_ids || [],
        })
        await firebase.setMember(user.account_id, newMember)
        await firebase.sendInvite(user, invite, newMember)
        return
      })

      await Promise.all(invitePromises)
      this.clearInvites()
    },
    async resendInvite(member: IMember) {
      const user = getRootStore(store).user
      const newInvite = Invite.create({ member_id: uuidv4() })
      await firebase.sendInvite(user, newInvite, member)
    },
    getInvite(inviteId: string) {
      return store.invites.find((invite) => invite.member_id === inviteId)
    },
    getOwner() {
      const user: IUser = getRootStore(store).user
      return store.members.find(
        (member) => member.member_id === user.account_id
      )
    },
    getCurrentName() {
      const currentMemberId = store.currentMemberId
      const currentMember = store.members.find(
        (member) => member.member_id === currentMemberId
      )
      if (currentMember) {
        return currentMember.name
      }
      return ''
    },
    getMember(memberId: string) {
      return store.members.find((member) => member.member_id === memberId)
    },
    setCurrentMember(uid: string) {
      try {
        const currentMember = store.members.find((member) => member.uid === uid)
        if (currentMember) {
          store.currentMemberId = currentMember.member_id
        }
      } catch (error) {
        analytics.error(error)
      }
    },
    setMembers(members: IMember[]) {
      try {
        store.members.replace(members)
      } catch (error) {
        analytics.error(error)
      }
    },
    async updateMember(member: Partial<IMember>) {
      const user: IUser = getRootStore(store).user
      await firebase.updateMember(user.account_id, member)
    },
    async deleteMember(member: IMember) {
      const user: IUser = getRootStore(store).user
      await firebase.deleteMember(user.account_id, member)
    },
    getMemberIdsWithAllClaimIds(claimIds: string[]): string[] {
      return store.members
        .filter((member) =>
          claimIds.every((claimId) => member.claim_ids.includes(claimId))
        )
        .map((member) => member.member_id)
    },

    // Returns member_ids of members that have AT LEAST ONE of the specified claimIds
    getMemberIdsWithAnyClaimIds(claimIds: string[]): string[] {
      return store.members
        .filter((member) =>
          member.claim_ids.some((claimId) => claimIds.includes(claimId))
        )
        .map((member) => member.member_id)
    },
  }))

export interface MemberStore extends Instance<typeof MemberStoreModel> {}
export interface MemberStoreSnapshot
  extends SnapshotOut<typeof MemberStoreModel> {}
