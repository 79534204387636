import {
  Button,
  Card,
  Stack,
  useMantineTheme,
  Container,
  Tabs,
  Group,
  ActionIcon,
  rem,
  Center,
  Text,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import {
  IconCloudUpload,
  IconPaperclip,
  IconRefresh,
  IconTextSize,
  IconWriting,
  IconX,
} from '@tabler/icons-react'
import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone'
import { RichTextEditor } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import { useScreenshot } from 'use-react-screenshot'
import { modal } from '../../utils/modal'

export const SignatureModal = ({
  text,
  title,
  onClick,
}: {
  text: React.ReactNode
  title: string
  onClick: () => {}
}) => {
  const theme = useMantineTheme()
  const { billingStore, authStore: accountStore, memberStore } = useStores()
  const [activeTab, setActiveTab] = useState<string | null>('draw')
  const canvasRef = useRef(null)
  const typeRef = useRef(null)

  const [files, setFiles] = useState<FileWithPath[]>([])
  const [drawReady, setDrawReady] = useState<boolean>(false)
  // const [uploadReady, setUploadReady] = useState<boolean>(false)
  // const [typeReady, setTypeReady] = useState<boolean>(false)

  // useEffect(() => {}, [
  //   canvasRef.current?.
  // ])

  const withSignature = () => {
    switch (activeTab) {
      case 'draw':
        //@ts-ignore
        if (!canvasRef.current?.isEmpty()) {
          return true
        }
        break
      case 'upload':
        if (files.length > 0) {
          return true
        }
        break
      case 'type':
        if (editor?.getText() && editor?.getText() !== 'Your signature') {
          return true
        }
        break
    }
    return false
  }

  function fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
    })
  }

  const [image, takeScreenshot] = useScreenshot({
    type: 'image/png',
    quality: 1.0,
  })
  const getImage = async () => await takeScreenshot(typeRef.current!)

  const saveSignature = async () => {
    let signatureData: string | null = null
    switch (activeTab) {
      case 'draw':
        //@ts-ignore
        if (!canvasRef.current?.isEmpty()) {
          //@ts-ignore
          const trimmedCanvas = canvasRef.current?.getTrimmedCanvas()
          signatureData = trimmedCanvas.toDataURL()
          saveSignatureData(signatureData)
        }
        break
      case 'upload':
        if (files.length > 0) {
          signatureData = await fileToBase64(files[0])
          saveSignatureData(signatureData)
        }
        break
      case 'type':
        if (editor?.getText() && editor?.getText() !== 'Your signature') {
          await getImage()
        }
        break
    }
  }

  useEffect(() => {
    if (image) saveSignatureData(image)
  }, [image])

  const saveSignatureData = async (signatureData: string | null) => {
    if (signatureData) {
      accountStore.saveSignature(signatureData)
      modal.close()
    } else {
      // notification.error(
      //   'Error saving signature',
      //   'Something went wrong saving your signature, please try again or contact support.'
      // )
    }
  }

  const changeTab = (value: string | null) => {
    if (!value) return
    setActiveTab(value)
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: 'This is placeholder' }),
    ],
    content: 'Your signature',
  })

  return (
    <Card p={0} bg={'transparent'} w={400}>
      <Stack justify='center' align='center'>
        <Card p={1} w={'100%'}>
          <Tabs
            w={'100%'}
            variant='default'
            value={activeTab}
            onChange={(value) => changeTab(value)}
          >
            <Tabs.List grow>
              <Tabs.Tab
                value='draw'
                p={'xs'}
                leftSection={<IconWriting size={18} stroke={1.5} />}
              >
                Draw
              </Tabs.Tab>
              <Tabs.Tab
                value='upload'
                p={'xs'}
                leftSection={<IconCloudUpload size={18} stroke={1.5} />}
              >
                Upload
              </Tabs.Tab>
              <Tabs.Tab
                value='type'
                p={'xs'}
                leftSection={<IconTextSize size={18} stroke={1.5} />}
              >
                Type
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='draw'>
              <Card
                w={'100%'}
                withBorder
                mt={'sm'}
                h={150}
                bg={theme.colors.gray[1]}
                p={'xs'}
              >
                <Stack gap={0}>
                  <Group>
                    <Container fluid />
                    <ActionIcon
                      size={22}
                      radius='sm'
                      color={theme.colors.white[2]}
                      variant='subtle'
                      onClick={() => {
                        //@ts-ignore
                        canvasRef.current?.clear()
                        setDrawReady(false)
                      }}
                    >
                      <IconRefresh
                        color={theme.colors.gray[8]}
                        stroke={1.5}
                        scale={'md'}
                      />
                    </ActionIcon>
                  </Group>
                  <SignatureCanvas
                    ref={canvasRef}
                    penColor={theme.colors.black[5]}
                    onEnd={() => setDrawReady(true)}
                    // canvasProps={{
                    //   width: 400,
                    //   height: 150,
                    //   className: 'sigCanvas',
                    // }}
                  />
                </Stack>
              </Card>
            </Tabs.Panel>

            <Tabs.Panel value='upload'>
              <Card w={'100%'} mt={'sm'} h={150} p={0}>
                <Dropzone
                  multiple={false}
                  onDrop={(files) => setFiles(files)}
                  maxSize={6 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                  bg={theme.colors.gray[1]}
                >
                  {files.length > 0 ? (
                    <Group
                      justify='center'
                      gap='xl'
                      mih={116}
                      style={{ pointerEvents: 'none' }}
                    >
                      {files.map((file, index) => {
                        return (
                          <Button
                            key={index}
                            variant='outline'
                            onClick={(e) => {
                              e.preventDefault()
                              setFiles([])
                            }}
                          >
                            {file.name}
                          </Button>
                        )
                      })}
                    </Group>
                  ) : (
                    <Group
                      justify='center'
                      gap='xl'
                      mih={116}
                      style={{ pointerEvents: 'none' }}
                    >
                      <Dropzone.Accept>
                        <IconPaperclip
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: 'var(--mantine-color-blue-6)',
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <IconX
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: 'var(--mantine-color-red-6)',
                          }}
                          stroke={1.5}
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <Stack justify='center' align='center'>
                          <IconCloudUpload
                            style={{
                              width: rem(24),
                              height: rem(24),
                              color: 'var(--mantine-color-dimmed)',
                            }}
                            stroke={1.5}
                          />
                          <Text c={theme.colors.gray[6]}>
                            Drag & Drop or Click to upload
                          </Text>
                        </Stack>
                      </Dropzone.Idle>

                      {/* <Text size='xl' inline>
                        Drag images here or click to select files
                      </Text>
                      <Text size='sm' c='dimmed' inline mt={7}>
                        Attach as many files as you like, each file should not
                        exceed 5mb
                      </Text> */}
                    </Group>
                  )}
                </Dropzone>
              </Card>
            </Tabs.Panel>

            <Tabs.Panel value='type'>
              <Card
                withBorder
                w={'100%'}
                mt={'sm'}
                h={150}
                bg={theme.colors.gray[1]}
                p={'xs'}
              >
                <Center h={150}>
                  <RichTextEditor
                    miw={'80%'}
                    editor={editor}
                    withTypographyStyles={false}
                    style={{
                      fontFamily: "'Satisfy', cursive",
                      fontSize: 32,
                      textAlign: 'center',
                    }}
                  >
                    <div ref={typeRef}>
                      <RichTextEditor.Content />
                    </div>
                  </RichTextEditor>
                </Center>
              </Card>
            </Tabs.Panel>
          </Tabs>
        </Card>

        <Button maw={200} onClick={saveSignature} disabled={!withSignature()}>
          Save signature
        </Button>
      </Stack>
    </Card>
  )
}
