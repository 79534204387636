import { Text, useMantineTheme, Stack, Card, ThemeIcon } from '@mantine/core'
import { observer } from 'mobx-react'
import { IconExclamationCircle } from '@tabler/icons-react'

function ThumbnailError() {
  const theme = useMantineTheme()

  return (
    <Card p={'xs'} bg={theme.colors.red[1]}>
      <Stack maw={80} gap={'xs'} align='center' justify='center'>
        <ThemeIcon variant='light' radius='xl' size='xl' color='red'>
          <IconExclamationCircle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
        <Text
          size='xs'
          fw={500}
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          Error
        </Text>
      </Stack>
    </Card>
  )
}

export default observer(ThumbnailError)
