import { IKeyValueMap } from 'mobx'
import { Instance, types } from 'mobx-state-tree'

const BankAccount = types
  .model('BankAccount', {
    id: types.identifier,
    bank_account_name: types.string,
    bank_account_number: types.string,
    bank_account_sort_code: types.string,
    bank_account_type: types.string,
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export default BankAccount
export interface IBankAccount extends Instance<typeof BankAccount> {}

