import { useMemo, useState } from 'react'
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton, //if using TypeScript (optional, but recommended)
} from 'mantine-react-table'
import { observer } from 'mobx-react'
import { useStores } from '../../utils/use_stores'
import { ClaimRow } from '../../models/claim/claim'
import {
  Button,
  Group,
  Tooltip,
  Text,
  useMantineTheme,
  Container,
  Paper,
  Indicator,
} from '@mantine/core'
import { IconPlus, IconPlaylistAdd, IconTrash } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { analytics } from '../../services/analytics_service'
import { useViewportSize } from '@mantine/hooks'
import { modal } from '../../utils/modal'
import MemberSelectionBox from './member_selection_box'

function ClaimsTable({ data }: { data: any }) {
  const theme = useMantineTheme()
  const { navStore, memberStore, claimStore } = useStores()
  const navigate = useNavigate()
  const { height, width } = useViewportSize()

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})

  const columns = useMemo<MRT_ColumnDef<ClaimRow>[]>(
    () => [
      {
        accessorKey: 'action',
        header: '',
        maxSize: 1,
        size: 1,
        sortUndefined: undefined,
        // grow: true,
        Cell: ({ cell }) => {
          if (cell.getValue()) {
            return (
              <Indicator
                inline
                color='red'
                position='middle-center'
                size={8}
                processing
              />
            )
          } else {
            return null
          }
        },
        Header: () => {
          return null
        },
        columnDefType: 'display',
      },
      {
        accessorKey: 'debtor',
        header: 'Debtor',
        // grow: true,
        Cell: ({ cell }) => {
          if (cell.getValue() === 'Unknown debtor') {
            return (
              <Text size='sm' c={theme.colors.gray[4]}>
                Unknown debtor
              </Text>
            )
          } else {
            return (
              <Text fw={700} size='sm' c={theme.colors.black[5]}>
                {cell.getValue() as string}
              </Text>
            )
          }
        },
      },
      {
        accessorKey: 'summary',
        header: 'Summary',
        maxSize: 500,
        size: 300,
        grow: false,
        Cell: ({ cell }) => {
          if (cell.getValue() === 'No summary') {
            return (
              <Text size='sm' c={theme.colors.gray[4]}>
                No summary
              </Text>
            )
          } else {
            return (
              <Tooltip
                position='left'
                offset={5}
                multiline
                w={300}
                label={cell.getValue() as string}
              >
                <Text size='sm' c={theme.colors.black[5]}>
                  {cell.getValue() as string}
                </Text>
              </Tooltip>
            )
          }
        },
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        maxSize: 120,
        size: 120,
        grow: false,
        Cell: ({ cell }) => {
          if (cell.getValue() === 'N/A') {
            return (
              <Text size='sm' c={theme.colors.gray[4]}>
                N/A
              </Text>
            )
          } else {
            return (
              <Text size='sm' c={theme.colors.black[5]}>
                {cell.getValue() as number}
              </Text>
            )
          }
        },
      },
      {
        accessorKey: 'stage',
        header: 'Stage',
        grow: true,
      },
      {
        accessorKey: 'dateCreated',
        header: 'Created on',
        grow: true,
      },
    ],
    []
  )

  const deleteClaims = () => {
    const selectedClaims = Object.keys(rowSelection)
    selectedClaims.forEach((claimId: string) => {
      claimStore.deleteClaim(claimId)
    })
  }

  //pass table options to useMantineReactTable
  const table = useMantineReactTable({
    columns,
    data,
    localization: {
      noRecordsToDisplay:
        "No claims to show, you can start a new claim by clicking '+ New claim'",
    },
    enableStickyFooter: true,
    // enableStickyHeader: false,
    layoutMode: 'grid',
    initialState: {
      density: 'xs',
      pagination: { pageIndex: 0, pageSize: 100 },
    },

    // defaultColumn: { minSize: 40, maxSize: 1000, size: 180 },
    enableRowSelection: memberStore.canManageClaims,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    getRowId: (originalRow) => originalRow.claimId,
    enableColumnOrdering: false,
    enableGlobalFilter: true,
    enableColumnResizing: true,
    // columnResizeMode: 'onEnd',
    enableRowActions: false,
    mantinePaperProps: {
      withBorder: false,
      shadow: undefined,
      bg: 'transparent',
    },
    mantineSelectAllCheckboxProps: {
      color: theme.colors.black[5],
      variant: 'filled',
      radius: 'xs',
      size: 'sm',
    },
    mantineTableContainerProps: { style: { maxHeight: height - 202 } },
    mantineTableBodyCellProps: {
      style: {
        flex: '0 0 auto',
      },
    },
    mantineTableHeadProps: { style: { position: 'sticky' } },
    mantineTableHeadRowProps: { style: { position: 'sticky' } },
    mantineSelectCheckboxProps: {
      color: theme.colors.black[5],
      variant: 'filled',
      radius: 'xs',
      size: 'sm',
    },
    mantineTableBodyRowProps: ({ row }) => ({
      highlightOnHover: true,
      onClick: async (event) => {
        await claimStore.setCurrentClaim(row.original.claimId!)
        navigate(`/claims/${row.original.claimId!}`)
      },
      style: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: false,
      withTableBorder: true,
      withRowBorders: false,
      bg: theme.colors.white[5],
      p: 'xs',
      styles: {
        table: {
          // tableLayout: 'fixed',
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: `0 0 0 0.2px ${theme.colors.gray[5]}`,
        },
      },
    },
    mantineColumnActionsButtonProps: {
      color: theme.colors.black[5],
      // bg: theme.colors.blue[5],
    },
    // mantineTableHeadProps: {
    //   fw: 500,
    // },
    mantineTableHeadCellProps: {
      fw: 600,
      fz: 'sm',
      c: theme.colors.gray[5],
      style: {
        flex: '0 0 auto',
      },
    },
    renderTopToolbar: ({ table }) => {
      const anySelected = Object.values(rowSelection).some(
        (value) => value === true
      )

      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('deactivating ' + row.getValue('name'))
        })
      }

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('activating ' + row.getValue('name'))
        })
      }

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('contact ' + row.getValue('name'))
        })
      }

      return (
        <Paper
          withBorder
          radius={'sm'}
          p='sm'
          bg={theme.colors.white[5]}
          mb={'md'}
        >
          <Group>
            <Tooltip
              label={'Only Claim Admins can create new claims'}
              disabled={memberStore.canManageClaims}
            >
              <Button
                size='xs'
                justify='start'
                variant='filled'
                color={theme.colors.black[5]}
                leftSection={<IconPlus size={16} />}
                onClick={async () => {
                  modal.loading(true)
                  const claimId = await claimStore.createClaim()
                  if (!claimId) {
                    modal.loading(false)
                    return
                  }
                  await claimStore.setCurrentClaim(claimId)
                  const displayId = claimId
                  navigate(`/claims/${displayId}`)
                  modal.loading(false)
                }}
                disabled={!memberStore.canManageClaims}
              >
                <Text size='sm'>New claim</Text>
              </Button>
            </Tooltip>

            {anySelected && memberStore.canManageClaims ? (
              <MemberSelectionBox
                claimIds={Object.keys(rowSelection)}
                target={(toggleDropdown: any) => (
                  <Button
                    size='xs'
                    justify='start'
                    variant='subtle'
                    color={theme.colors.black[5]}
                    leftSection={<IconPlaylistAdd size={16} />}
                    onClick={() => toggleDropdown()}
                  >
                    <Text size='sm'>Assign</Text>
                  </Button>
                )}
              />
            ) : null}

            {anySelected && memberStore.canManageClaims ? (
              <Button
                size='xs'
                justify='start'
                variant='subtle'
                color={theme.colors.red[5]}
                leftSection={<IconTrash size={16} />}
                onClick={deleteClaims}
              >
                <Text size='sm'>Delete</Text>
              </Button>
            ) : null}

            <Container fluid />
            <MRT_GlobalFilterTextInput table={table} />
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Group>
        </Paper>
      )
    },
  })

  return <MantineReactTable table={table} />
}

export default observer(ClaimsTable)
