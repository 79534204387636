import {
  Button,
  Card,
  useMantineTheme,
  Group,
  Text,
  ScrollArea,
  Container,
  Avatar,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'


import { modal } from '../../utils/modal'
import { observer } from 'mobx-react'
import { useViewportSize } from '@mantine/hooks'
import { ROLES } from '../../models/auth/member'

const DeleteMembersModal = ({
  text,
  title,
  onClick,
  memberIds,
  onGenerate,
}: {
  text: string
  title: string
  onClick: () => {}
  memberIds?: string[]
  onGenerate: (settlementId: string) => void
}) => {
  const theme = useMantineTheme()
  const { memberStore } = useStores()
  const { height, width } = useViewportSize()

  const members = memberStore.members.filter((member) =>
    memberIds!.includes(member.member_id)
  )

  return (
    <Card p={'xs'} bg={'transparent'}>
      <Card.Section withBorder p={'xs'}>
        <Text size='xl' fw={600}>
          {'Are you sure?'}
        </Text>
        <Text size='md'>{'The following users will lose access: '}</Text>
      </Card.Section>

      <ScrollArea py={0} px={0} h={height / 3}>
        {members.map((member) => {
          const name = member.name
          const role = member.role
          let color: string
          switch (role) {
            case ROLES.owner:
              color = theme.colors.gray[9]
              break
            case ROLES.team_admin:
              color = theme.colors.gray[8]
              break
            case ROLES.claim_editor:
              color = theme.colors.gray[7]
              break
            default:
              color = theme.colors.gray[4]
          }
          return (
            <Group p={'sm'}>
              <Avatar
                bg={color}
                color='white'
                size={30}
                //   p={2}
                radius='xl'
              >
                {name
                  ? name
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                      .toUpperCase()
                  : ''}
              </Avatar>
              <Text>{member.email}</Text>
            </Group>
          )
        })}
      </ScrollArea>
      <Card.Section p={'xs'} pt={'lg'}>
        <Group>
          <Container fluid />
          <Button maw={200} variant='subtle' onClick={() => modal.close()}>
            {'Cancel'}
          </Button>
          <Button
            maw={200}
            bg={theme.colors.red[5]}
            onClick={async () => {
              members.map(async (member) => {
                await memberStore.deleteMember(member)
              })
              modal.close()
            }}
          >
            {'Delete users'}
          </Button>
        </Group>
      </Card.Section>
    </Card>
  )
}

export default observer(DeleteMembersModal)
