//@ts-ignore
import { Table } from '@mantine/core'
import { observer } from 'mobx-react'
import { IClaim } from '../../models/claim/claim'
import { useStores } from '../../utils/use_stores'

export interface CostsTableProps {
  claim: IClaim
}

function CostsTable({ claim }: CostsTableProps) {
  const { documentStore, dialogueStore } = useStores()

  const messages = dialogueStore.messages
  const documents = documentStore.currentDocuments

  const costDocuments = documents.filter(
    (document) => document.cost != null && document.cost !== 0
  )
  const costsMessages = messages.filter(
    (message) => message.cost != null && message.cost !== 0
  )

  const totalDocumentAgentCost = costDocuments.reduce(
    (acc, doc) => acc + (doc.cost ?? 0),
    0
  )
  const totalMessagesAgentCost = costsMessages.reduce(
    (acc, message) => acc + (message.cost ?? 0),
    0
  )
  const totalCost = totalDocumentAgentCost + totalMessagesAgentCost

  const costBefore = [
    ['Lba Sent', claim.details?.lba_details?.sent_date],
    ['Claim Form Sent', claim.state?.document_sent_dates?.claim_form_sent_date],
  ]

  const calculateTotalCostBeforeDate = (date: Date | null) => {
    if (!date) return 0

    const documentCostBeforeDate = costDocuments
      .filter((doc) => new Date(doc.dates?.created_date) < date)
      .reduce((acc, doc) => acc + (doc.cost ?? 0), 0)

    const messageCostBeforeDate = costsMessages
      .filter((message) => new Date(message.created_date) < date)
      .reduce((acc, message) => acc + (message.cost ?? 0), 0)

    return documentCostBeforeDate + messageCostBeforeDate
  }

  const costBeforeWithTotals: [string, number][] = costBefore.map(
    ([label, date]) => {
      const totalCost = calculateTotalCostBeforeDate(
        date ? new Date(date) : null
      )
      return [label, totalCost]
    }
  )

  const costData: [string, number][] = [
    ['Total Cost', totalCost],
    ['Document Agent', totalDocumentAgentCost],
    ['Messages Agent', totalMessagesAgentCost],
    ...costBeforeWithTotals,
  ]

  return process.env.REACT_APP_ENV === "emulator" || process.env.REACT_APP_ENV === "development" ? (
    <Table
      style={{ borderCollapse: 'collapse', width: 'auto', marginLeft: '0' }}
    >
      <thead>
        <tr>
          {costData.map(([label], index) => (
            <th
              key={index}
              style={{
                border: '1px solid lightgrey',
                padding: '8px',
                textAlign: 'left',
                fontWeight: 'normal',
              }}
            >
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {costData.map(([, totalCost], index) => (
            <td
              key={index}
              style={{
                border: '1px solid lightgrey',
                padding: '8px',
                textAlign: 'left',
              }}
            >{`$${totalCost.toFixed(2)}`}</td>
          ))}
        </tr>
      </tbody>
    </Table>
  ) : null
}

export default observer(CostsTable)
