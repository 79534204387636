import { Instance, types } from 'mobx-state-tree'

const AcknowledgementOfServiceDetails = types
  .model('AcknowledgementOfServiceDetails', {
    defend_all_claim: types.maybeNull(types.boolean),
    defend_part_of_claim: types.maybeNull(types.boolean),
    contest_jurisdiction: types.maybeNull(types.boolean),
  })
  .actions((self) => ({}))

export default AcknowledgementOfServiceDetails
export interface IAcknowledgementOfServiceDetails
  extends Instance<typeof AcknowledgementOfServiceDetails> {}
