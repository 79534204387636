// court_directions_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const COURT_DECISION = ['won', 'won_in_part', 'lost']

const CourtDirectionsDetails = types
  .model('CourtDirectionsDetails', {
    issue_date: types.maybeNull(FirestoreTimestamp),
    response_date: types.maybeNull(FirestoreTimestamp),
    response_type: types.maybeNull(
      types.enumeration(['won', 'won_in_part', 'lost'])
    ),
  })
  .actions((self) => ({}))

export default CourtDirectionsDetails
export interface ICourtDirectionsDetails
  extends Instance<typeof CourtDirectionsDetails> {}
