// oral_contract_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'


const OralContractDetails = types
  .model('OralContractDetails', {
    debtor_individual_name: types.maybeNull(types.string),
    creditor_individual_name: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    same_as_invoices: types.maybeNull(types.boolean),
    agreement: types.maybeNull(types.string),
    date: types.maybeNull(FirestoreTimestamp),
  })
  .actions((self) => ({}))

export default OralContractDetails
export interface IOralContractDetails extends Instance<typeof OralContractDetails> {}