// counterclaim_details.ts
import { Instance, types } from 'mobx-state-tree'
import CounterclaimItem from './address' // Assuming the correct path is provided

const COUNTERCLAIM_RESPONSE_TYPE = ['defence', 'partial_admission', 'admission']

const CounterclaimDetails = types
  .model('CounterclaimDetails', {
    counterclaim_amount: types.maybeNull(types.number),
    counterclaim_fee_amount: types.maybeNull(types.number),
    counterclaim_response_type: types.maybeNull(
      types.enumeration(COUNTERCLAIM_RESPONSE_TYPE)
    ),
    counterclaim_items: types.optional(types.array(CounterclaimItem), []),
    user_admission_amount: types.maybeNull(types.number),
    user_admission_statement: types.maybeNull(types.string),
    user_admission_statement_sent: types.maybeNull(types.boolean),
    counterclaim_response_sent: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<ICounterclaimDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default CounterclaimDetails
export interface ICounterclaimDetails
  extends Instance<typeof CounterclaimDetails> {}
