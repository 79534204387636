import { observer } from 'mobx-react'
import {
  Text,
  Space,
  Group,
  Paper,
  TextInput,
  Title,
  Checkbox,
  Combobox,
  useCombobox,
  InputBase,
  Accordion,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { useState } from 'react'
import {
  IconMapPin,
} from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import { IUser } from '../../models/auth/user'
import type { ICountry } from 'countries-list'
import { countries } from 'countries-list'
import OnboardingStep4B from './onboarding_step_4B'

function OnboardingStep4() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore} = useStores()
  const navigate = useNavigate()

  const type = authStore?.user?.type
  const country = authStore?.user?.registered_address?.country
  const postalCode = authStore?.user?.registered_address?.postcode
  const firstLine = authStore?.user?.registered_address?.first_line
  const secondLine = authStore?.user?.registered_address?.second_line
  const town = authStore?.user?.registered_address?.town_city
  const county = authStore?.user?.registered_address?.county
  const hasTradingAddress = authStore?.user?.has_trading_address

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const [value, setValue] = useState<string | null>(null)
  const [search, setSearch] = useState('')

  const countriesArray = Object.values(countries)
  const shouldFilterOptions = countriesArray.every(
    (country: ICountry) => country.name !== search
  )
  const filteredOptions = shouldFilterOptions
    ? countriesArray.filter((country: ICountry) =>
        country.name.toLowerCase().includes(search.toLowerCase().trim())
      )
    : countriesArray

  // Sort the filtered options to put United Kingdom first
  const sortedOptions = filteredOptions.sort((a, b) => {
    if (a.name === 'United Kingdom') return -1;
    if (b.name === 'United Kingdom') return 1;
    return a.name.localeCompare(b.name);
  });

  const options = sortedOptions.map((country: ICountry) => (
    <Combobox.Option value={country.name} key={country.name}>
      {country.name}
    </Combobox.Option>
  ))

  return (
    <form>
      <Paper ml={'20px'}>
        <Space h={'xs'} />
        <Group>
          <IconMapPin size={32} />
          <Title order={2}>{`What is your address?`}</Title>
        </Group>
        <Space h={'lg'} />
        <Title
          c={theme.colors.gray[6]}
          order={6}
        >{`Registered address: `}</Title>
        <Space h={'lg'} />
        {/* <TextInput
          mb={'sm'}
          value={country ?? undefined}
          id='country-input'
          label='Country'
          placeholder='United Kingdom'
          onChange={(e) =>
            authStore.updateUser({
              registered_address: { country: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        /> */}
        <Group grow>
          <Combobox
            store={combobox}
            withinPortal={false}
            onOptionSubmit={(val) => {
              setValue(val)
              setSearch(val)
              combobox.closeDropdown()
              authStore.updateUser({
                registered_address: { country: val },
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }}
          >
            <Combobox.Target>
              <InputBase
                mb={'sm'}
                label='Country'
                id='country-input'
                rightSection={<Combobox.Chevron />}
                value={search ? search : country ?? ''}
                onChange={(event: any) => {
                  if (filteredOptions.length == 1) {
                    authStore.updateUser({
                      registered_address: { country: filteredOptions[0].name },
                      uid: authStore.user?.uid,
                    } as Partial<IUser>)
                    combobox.openDropdown()
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  } else {
                    authStore.updateUser({
                      registered_address: { country: null },
                      uid: authStore.user?.uid,
                    } as Partial<IUser>)
                    combobox.openDropdown()
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  }
                  
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && filteredOptions.length === 1) {
                    event.preventDefault(); // Prevent form submission if within a form
                    authStore.updateUser({
                      registered_address: { country: filteredOptions[0].name },
                      uid: authStore.user?.uid,
                    } as Partial<IUser>);
                    combobox.closeDropdown();
                    combobox.updateSelectedOptionIndex();
                    setSearch(filteredOptions[0].name);
                    setValue(filteredOptions[0].name);
                  }
                }}
                onBlur={() => {
                  combobox.closeDropdown()
                  setSearch(value || '')
                }}
                placeholder='Search value'
                rightSectionPointerEvents='none'
              />
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
                {options.length === 0 ? (
                  <Combobox.Empty>Nothing found</Combobox.Empty>
                ) : (
                  options
                )}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
          <TextInput
            mb={'sm'}
            value={postalCode ?? undefined}
            id='postal-code-input'
            label='Postal Code'
            placeholder='CB1 6XJ'
            onChange={(e) =>
              authStore.updateUser({
                registered_address: { postcode: e.target.value },
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
        </Group>

        <TextInput
          mb={'sm'}
          value={firstLine ?? undefined}
          id='first-line-input'
          label='First line'
          placeholder='12 Newton Road'
          onChange={(e) =>
            authStore.updateUser({
              registered_address: { first_line: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
        <TextInput
          mb={'sm'}
          value={secondLine ?? undefined}
          id='seconf-line-input'
          label='Second line (Optional)'
          placeholder='Littleport'
          onChange={(e) =>
            authStore.updateUser({
              registered_address: { second_line: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
        <Group grow>
          <TextInput
            mb={'sm'}
            value={town ?? undefined}
            id='town-input'
            label='Town'
            placeholder='Cambridge'
            onChange={(e) =>
              authStore.updateUser({
                registered_address: { town_city: e.target.value },
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
          <TextInput
            mb={'sm'}
            value={county ?? undefined}
            id='county-input'
            label='County'
            placeholder='Cambridgeshire'
            onChange={(e) =>
              authStore.updateUser({
                registered_address: { county: e.target.value },
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
        </Group>
        <Space h={'xs'} />
        {type === 'organisation' ? (
          <Accordion
            variant='contained'
            radius='xs'
            defaultValue='Trading Address'
            value={hasTradingAddress ? 'Trading Address' : ''}
          >
            <Accordion.Item key={'trading-address'} value={'Trading Address'}>
              <Accordion.Control>
                <Checkbox
                  checked={hasTradingAddress}
                  onChange={(event) => {
                    authStore.updateUser({
                      has_trading_address: event.currentTarget.checked,
                      uid: authStore.user?.uid,
                    } as Partial<IUser>)
                  }}
                  label={
                    <Text c={theme.colors.gray[7]} size='sm' ta='start'>
                      Is the trading address different from the registered
                      address?
                    </Text>
                  }
                />
              </Accordion.Control>
              <Accordion.Panel>
                <OnboardingStep4B />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ) : null}

        {/* {authStore.user?.has_trading_address ? <OnboardingStep4B /> : <></>} */}
      </Paper>
    </form>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(OnboardingStep4)
