import { print } from '../utils/print'
import { firebase } from './firebase_service'
import Config from '../config'
import { analytics } from 'services/analytics_service'

class SumSubService {
  async generateToken(userId: string, levelName: string, ttlInSecs: number) {
    const body = {
      account_id: userId,
      level_name: levelName,
      ttl_in_secs: ttlInSecs,
    }
    try {
      const result = await firebase.callHttpFunction(
        Config.SUMSUB_TOKEN_URL,
        body
      )
      return result.token
    } catch (error) {
      analytics.error(error)
    }
  }

  async getApplicantData(accountId: string, memberId: string) {
    const body = {
      account_id: accountId,
      member_id: memberId,
    }
    try {
      const result = await firebase.callHttpFunction(
        Config.SUMSUB_DATA_URL,
        body
      )
      return result
    } catch (error) {
      analytics.error(error)
      return null
    }
  }
}

export const sumsub = new SumSubService()
