import React from 'react'
import { Container, Group, Paper, Stack, useMantineTheme, Text, Title } from '@mantine/core'
import { observer } from 'mobx-react'
import { useViewportSize } from '@mantine/hooks'
import { useStores } from '../utils/use_stores'
import NavHeader from '../components/navigation/nav_header'
import TeamTable from '../components/teams_page/team_table'
import { MemberRow } from '../models/auth/member'

function TeamPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  const { memberStore, authStore } = useStores()

  const type = authStore.user?.type

  const data: MemberRow[] = memberStore.members.map((user) => {
    return {
      userId: user.member_id,
      name: user.name ?? 'Pending...',
      email: user.email,
      role: user.role ?? 'N/A',
      status: user.status ?? 'N/A',
      dateJoined: user.readableDateJoined ?? 'N/A',
    }
  })

  return (
    <Stack ml={'xl'} mr={'md'}>
      <NavHeader />
      <Paper
        radius={'sm'}
        h={height - 70}
        p={0}
        bg={'transparent'}
        style={{ overflow: 'hidden' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: height - 70,
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            {type === 'individual' ?
              <Paper
                withBorder
                radius={'sm'}
                p='sm'
                bg={theme.colors.white[5]}
                mb={'md'}
              >
                <Title order={4}>Individual account</Title>
                <Text mt={'md'} size='sm' c={theme.colors.gray[6]}>Individual accounts can't manage team members. Get in contact with support to arrange the migration to an organisation account.</Text>
              </Paper>
            : <TeamTable data={data} />}
          </div>
        </div>
      </Paper>
    </Stack>
  )
}

export default observer(TeamPage)
