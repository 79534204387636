

export function redact(str: string, numCharsToShow?: number): string {
    if (numCharsToShow) {
        return '•'.repeat(Math.max(0, str.length - numCharsToShow)) + str.slice(-numCharsToShow);
    } else {
        return '•'.repeat(str.length);
    }
}

