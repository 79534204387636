import { observer } from 'mobx-react'
import {
  Button,
  Flex,
  Container,
  Stack,
  Paper,
  Stepper,
} from '@mantine/core'
import { useDisclosure, useMediaQuery, useViewportSize } from '@mantine/hooks'
import { Group, ScrollArea } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import {
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStores } from '../utils/use_stores'
//@ts-ignore
import background_image from '../assets/images/register.webp'
import { GarfieldLogoText } from '../components/shared/garfield_logo'
import InviteStep1 from '../components/invite/invite_step_1'
import InviteStep2 from '../components/invite/invite_step_2'
import { firebase } from '../services/firebase_service'
import { ROLES, STATUS } from '../models/auth/member'

function InvitePage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  const desktop = useMediaQuery('(min-width: 56.25em)')

  const { navStore, authStore, memberStore } = useStores()

  const navigate = useNavigate()

  const currentStep = authStore.user?.invite_step ?? 0
  const [email, setEmail] = useState(undefined)
  const [accountId, setAccountId] = useState(undefined)
  const [memberId, setMemberId] = useState(undefined)
  const [role, setRole] = useState(undefined)

  const location = useLocation()

  useEffect(() => {
    loadInvite()
  }, [location.search])

  const loadInvite = async () => {
    const searchParams = new URLSearchParams(location.search)
    const inviteId = searchParams.get('id')
    if (!inviteId) {
      navigate('/')
      return
    }
    const invite = await firebase.retrieveInvite(inviteId)
    if (!invite) {
      navigate('/')
      return
    }
    if (!invite.to[0] || !invite.account_id || !invite.member_id) {
      navigate('/')
      return
    }

    setEmail(invite.to[0])
    authStore.setAuthEmail(invite.to[0])
    setAccountId(invite.account_id)
    setMemberId(invite.member_id)
    setRole(invite.role ?? ROLES.claim_editor)
    setSteps([
      <Stepper.Step withIcon={false} key={'step-1'}>
        <InviteStep1
          email={invite.to[0]}
          accountId={invite.account_id}
          memberId={invite.member_id}
          role={invite.role ?? ROLES.claim_editor}
        />
      </Stepper.Step>,
      <Stepper.Step withIcon={false} key={'step-2'}>
        <InviteStep2
          email={invite.to[0]}
          accountId={invite.account_id}
          memberId={invite.member_id}
          role={invite.role ?? ROLES.claim_editor}
        />
      </Stepper.Step>,
    ])
  }

  const validateFields = () => {
    const {
      authEmail,
      authPassword,
      authConfirmPassword,
      tosAccepted,
      user,
    } = authStore
    switch (currentStep) {
      case 0:
        if (authPassword && authConfirmPassword && tosAccepted) return true
        break
      case 1:
        if (user?.type != null) {
          return true
        }
        break
    }
    return false
  }

  const nextStep = async () => {
    let valid = false
    switch (currentStep) {
      case 0:
        if (validateFields()) {
          const id = await authStore.signUpMember(
            role!,
            accountId!,
            memberId!
          )
          if (id) {
            valid = true
            authStore.subscribeToAccount(id)
          }
        }
        break
      case 1:
        if (validateFields()) valid = true
        break
    }

    if (currentStep == 1) {
      if (authStore.user?.verified === 'approved') {
        authStore.updateUser({ uid: authStore.user.uid!, onboarded: true })
        memberStore.updateMember({
          member_id: memberId,
          status: STATUS.active,
        })
      }
    } else {
      if (valid) {
        authStore.updateInviteStep(
          currentStep < 1 ? currentStep + 1 : currentStep
        )
      }
    }
  }

  const prevStep = async () => {
    if (currentStep == 1) {
      openSignOutModal()
    } else {
      authStore.updateOnboardingStep(
        currentStep > 0 ? currentStep - 1 : currentStep
      )
    }
  }

  const [steps, setSteps] = useState([
    <Stepper.Step withIcon={false} key={'step-1'}>
      <InviteStep1
        email={email}
        accountId={accountId}
        memberId={memberId}
        role={role}
      />
    </Stepper.Step>,
    <Stepper.Step withIcon={false} key={'step-2'}>
      <InviteStep2
        email={email}
        accountId={accountId}
        memberId={memberId}
        role={role}
      />
    </Stepper.Step>,
  ])

  const [signOutModal, { open: openSignOutModal, close: closeSignOutModal }] =
    useDisclosure(false)

  return (
    <Flex justify='start' m={0} p={0} gap={'sm'} bg={theme.colors.white[5]}>
      {desktop ? (
        <Paper
          w={'100%'}
          // bg={theme.colors.black[5]}
          m={'sm'}
          radius={'md'}
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          }}
        >
          <Paper
            w={'100%'}
            h={'100%'}
            p={'xl'}
            style={{
              background: `rgba(0, 0, 0, 0.5)`,
            }}
          >
            <Stack justify='space-between' h={'100%'}>
              <GarfieldLogoText
                size={'md'}
                variant={'light'}
                onClick={() => navigate('/')}
                type='full'
              />
            </Stack>
          </Paper>
        </Paper>
      ) : null}

      <Container
        h={height - 25}
        mah={height - 25}
        w={'100%'}
        my={'sm'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          miw={desktop ? 500 : 100}
          maw={desktop ? 600 : 1920}
          h={'100%'}
          radius={'sm'}
          p={'xl'}
        >
          <ScrollArea>
            <Stack justify='space-between' mah={900} mih={300}>
              <Stepper
                size='xs'
                active={currentStep}
                // onStepClick={setCurrentStep}
                allowNextStepsSelect={false}
                iconSize={0}
                style={{ marginLeft: '-20px' }}
              >
                {steps}
                <Stepper.Completed>
                  Completed, click back button to get to previous step
                </Stepper.Completed>
              </Stepper>
            </Stack>
          </ScrollArea>
          <Group justify='start' mt='xl' gap={'xs'}>
            {currentStep > 0 ? (
              <Button
                leftSection={<IconChevronLeft size={18} />}
                variant='subtle'
                onClick={prevStep}
              >
                {currentStep > 1 ? 'Back' : 'Sign out'}
              </Button>
            ) : null}

            <Button
              rightSection={<IconChevronRight size={22} />}
              onClick={nextStep}
              disabled={
                currentStep > 0 && authStore.user?.verified !== 'approved'
              }
            >
              {currentStep > 0 ? 'Launch Garfield' : 'Next step'}
            </Button>
          </Group>
        </Paper>
      </Container>
    </Flex>
  )
}

export default observer(InvitePage)
