// Merged charge.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const Charge = types
  .model('Charge', {
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    date: types.maybeNull(FirestoreTimestamp),
    original_amount: types.maybeNull(types.string),
  })
  .views((self) => ({
    get readableDate() {
      return self.date?.toDate().toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  }))
  .actions((self) => ({}))

export default Charge
export interface ICharge extends Instance<typeof Charge> {}
