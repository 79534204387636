import {
  Container,
  Text,
  Divider,
  useMantineTheme,
  Title,
  Card,
  List,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMediaQuery } from '@mantine/hooks'

const accessibilityText = (
  <Text>
    At Project Garfield Limited ("PGL") we are committed to
    accessibility. In that regard, "Garfield" has a number of
    accessibility and DEI features that authorised users can access.
    <br />
    <br />
    Our website is aimed at being fair and accessible to as wide an
    audience as possible.
    <br />
    <br />
    We would welcome any feedback you can offer us about the
    accessibility of this website. Please let us know if you have any
    comments at the following details:
    <List>
      <List.Item>
        Email:{' '}
        <a href='mailto:enquiries@projectgarfield.co.uk'>
          enquiries@projectgarfield.co.uk
        </a>
      </List.Item>
      <List.Item>
        Postal address: 20-22 Wenlock Road, London, England N1 7GU
      </List.Item>
    </List>
  </Text>
);

function DesktopAccessibilityPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        paddingLeft: '16px',
        backgroundColor: theme.colors.white[5],
      }}
      p={'xl'}
      m={0}
      fluid
    >
      <Card
        shadow='sm'
        withBorder
        p={'xl'}
        radius={'md'}
        maw={width / 2}
        mx={'auto'}
      >
        <Card.Section p={'xl'}>
          <Title py={'md'} order={1} fw={800}>
            Accessibility
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'xl'}>
          {accessibilityText}
          {/* <Text>
            At Project Garfield Limited ("PGL") we are committed to
            accessibility. In that regard, "Garfield" has a number of
            accessibility and DEI features that authorised users can access.
            <br />
            <br />
            Our website is aimed at being fair and accessible to as wide an
            audience as possible.
            <br />
            <br />
            We would welcome any feedback you can offer us about the
            accessibility of this website. Please let us know if you have any
            comments at the following details:
            <List>
              <List.Item>
                Email:{' '}
                <a href='mailto:enquiries@projectgarfield.co.uk'>
                  enquiries@projectgarfield.co.uk
                </a>
              </List.Item>
              <List.Item>
                Postal address: 20-22 Wenlock Road, London, England N1 7GU
              </List.Item>
            </List>
          </Text> */}
        </Card.Section>
      </Card>
    </Container>
  )
}

function MobileAccessibilityPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        backgroundColor: theme.colors.white[5],
      }}
      p={'md'}
      m={0}
      fluid
    >
      <Card shadow='sm' withBorder p={'md'} radius={'md'} mx={'auto'}>
        <Card.Section p={'md'}>
          <Title py={'md'} order={2} fw={700}>
            Accessibility
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'md'}>
          {accessibilityText}
        </Card.Section>
      </Card>
    </Container>
  )
}

function AccessibilityPage() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return isMobile ? <MobileAccessibilityPage /> : <DesktopAccessibilityPage />
}

export default AccessibilityPage
