import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import { firebase } from '../services/firebase_service'
import { print } from '../utils/print'
import Claim, { IClaim } from '../models/claim/claim'


import { analytics } from '../services/analytics_service'
import Config from '../config'
import { modal } from '../utils/modal'
import { Timestamp } from 'firebase/firestore'

// The Claim Store handles all claims' state and tracks the currently selected claim
export const ClaimStoreModel = types
  .model('ClaimStore')
  .props({
    currentClaimId: types.maybeNull(types.string),
    claims: types.optional(types.array(Claim), []),
    emailApprovalTickBoxes: types.optional(
      types.map(types.enumeration(['undecided', 'approved', 'rejected'])),
      {}
    ),
  })

  // Views are read-only derived data of the state tree
  .views((store) => ({
    getEmailApprovalTickBox(
      key: string
    ): 'undecided' | 'approved' | 'rejected' {
      if (!store.emailApprovalTickBoxes.has(key)) {
        return 'undecided'
      }
      return store.emailApprovalTickBoxes.get(key) as
        | 'undecided'
        | 'approved'
        | 'rejected'
    },

    get claimsSortedByCreatedDate() {
      return (ascending: boolean = true) => {
        return store.claims.slice().sort((a, b) => {
          if (a?.state?.pinned_date && b?.state?.pinned_date) {
            return a.state.pinned_date - b.state.pinned_date
          }
          if (a?.state?.pinned_date) {
            return -1
          }
          if (b?.state?.pinned_date) {
            return 1
          }
          const timeA =
            a.state?.created_date instanceof Timestamp
              ? a.state.created_date.toDate().getTime()
              : 0
          const timeB =
            b.state?.created_date instanceof Timestamp
              ? b.state.created_date.toDate().getTime()
              : 0
          return ascending ? timeA - timeB : timeB - timeA
        })
      }
    },
    get claimsSortedByLastMessageDate() {
      return (ascending: boolean = true) => {
        return store.claims.slice().sort((a, b) => {
          if (a?.state?.pinned_date && b?.state?.pinned_date) {
            return a.state.pinned_date - b.state.pinned_date
          }
          if (a?.state?.pinned_date) {
            return -1
          }
          if (b?.state?.pinned_date) {
            return 1
          }
          const timeA =
            a.state?.last_message_date instanceof Timestamp
              ? a.state.last_message_date.toDate().getTime()
              : 0
          const timeB =
            b.state?.last_message_date instanceof Timestamp
              ? b.state.last_message_date.toDate().getTime()
              : 0
          return ascending ? timeB - timeA : timeA - timeB
        })
      }
    },
    get claimsSortedAlphabetically() {
      return (ascending: boolean = true) => {
        return store.claims.slice().sort((a, b) => {
          if (a?.state?.pinned_date && b?.state?.pinned_date) {
            return a.state.pinned_date - b.state.pinned_date
          }
          if (a?.state?.pinned_date) {
            return -1
          }
          if (b?.state?.pinned_date) {
            return 1
          }

          const nameA = a?.displayName.toLowerCase()
          const nameB = b?.displayName.toLowerCase()
          return ascending
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA)
        })
      }
    },
    get currentClaim(): IClaim | null {
      if (!store.currentClaimId) return null
      const claim = store.claims.find(
        (claim) =>
          claim.claim_id === store.currentClaimId ||
          claim.displayId === store.currentClaimId
      )
      if (!claim) return null
      return claim
    },
    get currentClaimReadyToSend() {
      const claim = this.currentClaim
      if (!claim) return false
      return (
        claim.state?.documents_to_send &&
        claim.state?.documents_to_send?.length > 0
      )
    },
  }))

  // Actions are the only way to change the state tree
  .actions((store) => ({
    setEmailApprovalTickBox(
      key: string,
      state: 'undecided' | 'approved' | 'rejected'
    ) {
      if (!store.emailApprovalTickBoxes.has(key)) {
        store.emailApprovalTickBoxes.set(key, state)
      }
      store.emailApprovalTickBoxes.set(key, state)
    },
    clearEmailApprovalTickBoxesForClaim() {
      store.emailApprovalTickBoxes.clear()
    },

    // Listens to changes in claims Firestore collection and sets the claims array
    subscribeToClaims() {
      try {
        const account = getRootStore(store).user
        firebase.subscribeToClaims(account.account_id, (snapshot) => {
          const claims: IClaim[] = []
          snapshot.forEach((doc) => {
            try {
              const data = doc.data() as any

              // if (!Object.values(CLAIMSTAGE).includes(data.state.stage)) {
              //   console.log('Offending claim: ', data.claim_id)
              // }
              // const claim = doc.data() as IClaim
              const claim = Claim.create(data)
              const claimIds: string[] =
                getRootStore(store).memberStore.currentClaimIds
              if (claim.claim_id && claimIds.includes(claim.claim_id)) {
                claims.push(claim)
              }
            } catch (error) {
              console.log('ERROR IN CLAIM: ', doc.data().claim_id)
              console.error(error)
              const uid = getRootStore(store).authStore.user?.account_id
              analytics.error(error, uid, doc.data().claim_id ?? undefined)
            }
          })
          this.setClaims(claims)
        })
      } catch (error) {
        console.log('ERROR IN CLAIMS')
        console.error(error)
        const uid = getRootStore(store).authStore.user?.account_id
        analytics.error(error, uid, undefined)
      }
    },

    // Updates a claim in Firestore
    async updateClaim(claim: Partial<IClaim>) {
      try {
        const user = getRootStore(store).authStore.user
        if (!user) return
        await firebase.updateClaim(user, claim)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        analytics.error(error, uid, claim.claim_id ?? undefined)
      }
    },

    // Updates a claim in Firestore
    async pinClaim(claim: Partial<IClaim>) {
      try {
        const user = getRootStore(store).authStore.user
        if (!user) return
        if (!claim.state) return

        claim.state.pinned_date = new Date()
        await firebase.updateClaim(user, claim)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        analytics.error(error, uid, claim.claim_id ?? undefined)
      }
    },

    async unpinClaim(claim: Partial<IClaim>) {
      try {
        const user = getRootStore(store).authStore.user
        if (!user) return
        if (!claim.state) return
        claim.state.pinned_date = null
        await firebase.updateClaim(user, claim)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        analytics.error(error, uid, claim.claim_id ?? undefined)
      }
    },

    // Returns the currently displayed claim (same as current route in NavStore)
    // getCurrentClaim() {
    //   if (!store.currentClaimId) return null
    //   const claim = store.claims.find(
    //     (claim) =>
    //       claim.claim_id === store.currentClaimId ||
    //       claim.displayId === store.currentClaimId
    //   )
    //   if (!claim) return null
    //   // if (claim.displayId === store.currentClaimId)
    //   //   this.setCurrentClaim(claim.claim_id!)
    //   return claim
    // },

    // Returns the display ID (truncated) for a given claim ID (full)
    getDisplayIdFromClaimId(claimId: string) {
      const claim = store.claims.find((claim) => claim.claim_id === claimId)
      if (!claim) return null
      return claim.displayId
    },

    // Returns the claim ID (full) for a given display ID (truncated)
    getClaimIdFromDisplayId(displayId: string) {
      const claim = store.claims.find((claim) => claim.displayId === displayId)
      if (!claim) return null
      return claim.claim_id
    },

    // Sets the current claim (same as current route in NavStore)
    async setCurrentClaim(claimId: string) {
      const dialogueStore = getRootStore(store).dialogueStore
      const documentStore = getRootStore(store).documentStore
      documentStore.clearDocuments()
      documentStore.unsubscribeFromDocuments()
      dialogueStore.clearMessages()
      dialogueStore.unsubscribeFromMessages()
      store.currentClaimId = claimId
      const newMessageId = await firebase.getUniqueIdForCollection([
        'users',
        getRootStore(store).user!.account_id ?? '',
        'claims',
        claimId,
        'messages',
      ])
      console.log('setCurrentClaim!!!!!!')
      console.log('newMessageId: ', newMessageId)
      console.log(
        'messages in setCurrentClaim: ',
        dialogueStore.messages.length
      )
      dialogueStore.setCurrentDraftFromClaim(newMessageId)

      // open doc bar if in ready to send state
      if (store.currentClaimReadyToSend) {
        getRootStore(store).navStore.openDocbar()
      }
    },

    removeCurrentClaim() {
      store.currentClaimId = null
      getRootStore(store).navStore.setDocbarWidth(0)
    },

    // Replaces the entire claims array
    setClaims(claims: IClaim[]) {
      store.claims.clear()
      claims.forEach((claim) => {
        try {
          store.claims.push(claim)
        } catch (error) {
          console.log('ERROR IN CLAIM: ', claim.claim_id)
          console.error(error)
          const uid = getRootStore(store).authStore.user?.account_id
          analytics.error(error, uid, claim.claim_id ?? undefined)
        }
      })
      const navStore = getRootStore(store).navStore
      for (const claim of store.claims) {
        navStore.addClaimRoute(claim.displayId)
      }
      const authStore = getRootStore(store).authStore
      authStore.toggleAuthLoading(false)
    },

    // Generates new claim doc in Firestore if the user subscription allows it
    async createClaim(): Promise<string | undefined> {
      try {
        // console.log('createClaim 2')
        // const entitlement = getRootStore(store).billingStore.entitlement
        // console.log('entitlement: ', entitlement)
        // if (store.claims.length >= entitlement && Config.ENV !== 'emulator') {
        //   console.log('createClaim 3')
        //   const upgradePriceId = getRootStore(store).billingStore.upgradePriceId
        //   if (upgradePriceId) {
        //     modal.loading(false)
        //     modal.open(
        //       'You reached the plan limits!',
        //       'You have reached your maximum number of claims. Please upgrade your subscription to create more claims.',
        //       'upgrade',
        //       async () => {
        //         modal.loading(true)
        //         const sessionUrl = await getRootStore(
        //           store
        //         ).billingStore.createCheckoutSession(
        //           upgradePriceId,
        //           'subscription'
        //         )
        //         if (sessionUrl) window.location.assign(sessionUrl)
        //       }
        //     )
        //   } else {
        //     modal.loading(false)
        //     modal.open(
        //       'Reached maximum claims allowed',
        //       'You have reached the maximum number of claims allowed for any plan! Please contact support to extend your limit.',
        //       'upgrade',
        //       async () => {
        //         print('Contact support')
        //       }
        //     )
        //   }
        //   return
        // }
        // console.log('createClaim 4')

        const user = getRootStore(store).authStore.user
        const currentMember = getRootStore(store).memberStore.currentMember
        if (!user || !currentMember) return
        const claimId = await firebase.createClaim(user, currentMember)
        analytics.track('Created claim', { claim_id: claimId })
        return claimId
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        analytics.error(error, uid, store.currentClaimId ?? undefined)
      }
    },

    // Removes a claim (this might be disabled in the near future)
    async deleteClaim(claimId: string): Promise<boolean> {
      return new Promise((resolve) => {
        modal.open(
          'Are you sure?',
          'This will delete the claim and it cannot be undone.',
          'delete',
          async () => {
            try {
              modal.loading(true)
              const account = getRootStore(store).user
              await firebase.deleteClaim(account, claimId)
              analytics.track('Deleted claim', { claim_id: claimId })
              modal.loading(false)
              resolve(true)
            } catch (error) {
              const uid = getRootStore(store).authStore.user?.account_id
              analytics.error(error, uid, claimId)
              modal.loading(false)
              resolve(false)
            }
          },
          () => {
            resolve(false)
          }
        )
      })
    },
  }))

export interface ClaimStore extends Instance<typeof ClaimStoreModel> {}
export interface ClaimStoreSnapshot
  extends SnapshotOut<typeof ClaimStoreModel> {}
