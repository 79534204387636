import React, { useState } from 'react'
import {
  Paper,
  Stack,
  Tabs,
  rem,
  useMantineTheme,
  Switch,
  Text,
  Card,
  Title,
  Kbd,
  Group,
  Input,
  Button,
  Tooltip
} from '@mantine/core'
import { observer } from 'mobx-react'
import { useViewportSize } from '@mantine/hooks'
import { useStores } from '../utils/use_stores'
import NavHeader from '../components/navigation/nav_header'
import {
  IconReceipt,
  IconUserCircle,
  IconCheck,
  IconX,
} from '@tabler/icons-react'
import BillingTab from '../components/settings_page/billing_tab'

function SettingsPage() {
  const theme = useMantineTheme()
  const iconStyle = { width: rem(16), height: rem(16) }
  const { height, width } = useViewportSize()
  const panelHeight = height - 130
  const { navStore, memberStore, authStore } = useStores()

  return (
    <Stack ml={'xl'} mr={'md'}>
      <NavHeader />
      <Paper withBorder radius={'sm'} h={height - 70}>

        <Group grow align="flex-start" justify="flex-start">
          <Card p={'xl'}>
            <Card.Section withBorder p={'lg'}>
              <Title order={3}>Preferences</Title>
            </Card.Section>
            <Card.Section p={'lg'}>

              <Switch.Group
                label="Enable term highlighting in dialogue"
                description="Highlighting will help you understand legal terms as you chat with Garfield"
                onChange={(val) => {
                  if (val[0]=="highlighting") {
                    console.log("highlighting")
                    navStore.toggleTermHighlight(true)
                  } else {
                    console.log("not highlighting")
                    navStore.toggleTermHighlight(false)
                  }
                }}
              >
                <Switch
                    value="highlighting"
                    color={theme.colors.black[5]}
                    size='sm'
                    py={'md'}
                  />
              </Switch.Group>


              <Switch.Group
                label="Choose Enter key behaviour"
                description="Use Enter key or Shift + Enter to send a message to Garfield"
                onChange={(val) => {
                  if (val[0]=="enter_sends_message") {
                    console.log("enter_sends_message")
                    navStore.toggleEnterSendsMessage(true)
                  } else {
                    console.log("not enter_sends_message")
                    navStore.toggleEnterSendsMessage(false)
                  }
                }}
              >
                <Switch
                    value="enter_sends_message"
                    color={theme.colors.black[5]}
                    size='sm'
                    py={'md'}
                    label={navStore.enterSendsMessage ? (
                      <div dir="ltr">
                        <Kbd>Enter</Kbd>
                      </div>
                    ) : (
                      <div dir="ltr">
                        <Kbd>Shift</Kbd> + <Kbd>Enter</Kbd>
                      </div>
                    )}
                    labelPosition='right'
                  />
              </Switch.Group>
            </Card.Section>
          </Card>

          <Card p={'xl'}>
            <Card.Section withBorder p={'lg'}>
              <Title order={3}>Account</Title>
            </Card.Section>
            <Card.Section p={'lg'}>

            <Input.Wrapper label="Sign out" description="Temporarily sign out of your account">
              <Button my="md" variant="default" color={theme.colors.black[5]} onClick={() => {
                authStore.signOut()
              }}>Sign out</Button>
            </Input.Wrapper>

            <Input.Wrapper label="Delete account" description="Permanently delete your account and data">
              <Tooltip label="You must be the owner of the account to delete it">
                  <Button disabled={!memberStore.canManageBilling} my="md" variant="outline" color="red" onClick={() => {
                    if (memberStore.canManageBilling) {
                      authStore.deleteAccount()
                    }
                  }}>Delete account</Button>
              </Tooltip>
            </Input.Wrapper>

            </Card.Section>
          </Card>
        </Group>



        {/* <Card p={'xl'}>
          <Card.Section withBorder p={'lg'}>
            <Title order={3}>Billing</Title>
          </Card.Section>
        </Card> */}

        
        {/* <Tabs
          color={theme.colors.black[5]}
          variant='default'
          defaultValue={memberStore.canManageBilling ? 'billing' : 'account'}
          style={{ height: '100%' }}
        >
          <Tabs.List h={50} justify='flex-start'>
            {memberStore.canManageBilling ? (
              <Tabs.Tab
                value='billing'
                leftSection={<IconReceipt style={iconStyle} />}
              >
                Billing
              </Tabs.Tab>
            ) : null}

            <Tabs.Tab
              value='account'
              leftSection={<IconUserCircle style={iconStyle} />}
            >
              Account
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='billing' px='xs' h={panelHeight}>
            <BillingTab />
          </Tabs.Panel>

          <Tabs.Panel value='account' px='xs' h={panelHeight}>
            Preferences
            <div style={{ marginTop: '1rem' }}>
              <Switch
                checked={navStore.termHighlightActive}
                onChange={(event) =>
                  navStore.toggleTermHighlight(event.currentTarget.checked)
                }
                color='teal'
                size='md'
                label='Highlighting'
                thumbIcon={
                  navStore.termHighlightActive ? (
                    <IconCheck
                      style={{ width: rem(12), height: rem(12) }}
                      color={theme.colors.teal[6]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      style={{ width: rem(12), height: rem(12) }}
                      color={theme.colors.red[6]}
                      stroke={3}
                    />
                  )
                }
              />
              <Switch
                checked={navStore.enterSendsMessage}
                onChange={(event) =>
                  navStore.toggleEnterSendsMessage(event.currentTarget.checked)
                }
                color='teal'
                size='md'
                label='Enter sends message'
                thumbIcon={
                  navStore.enterSendsMessage ? (
                    <IconCheck
                      style={{ width: rem(12), height: rem(12) }}
                      color={theme.colors.teal[6]}
                      stroke={3}
                    />
                  ) : (
                    <IconX
                      style={{ width: rem(12), height: rem(12) }}
                      color={theme.colors.red[6]}
                      stroke={3}
                    />
                  )
                }
                style={{ marginTop: '1rem' }}
              />
            </div>
          </Tabs.Panel>
        </Tabs> */}
      </Paper>
    </Stack>
  )
}

export default observer(SettingsPage)
