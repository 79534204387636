import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import Route, { IRoute } from '../models/nav/route'
import { cast } from 'mobx-state-tree'

// The Nav Store is responsible for navigation, routing and foundational UI
export const NavStoreModel = types
  .model('NavigationStore')
  .props({
    currentPath: types.optional(types.string, '/'),
    routes: types.optional(types.map(Route), () => ({
      '/': {
        path: '/',
        name: 'Landing',
        nav: false,
        document: false,
        header: true,
        footer: true,
        claimRoute: false,
      },
      '/claims': {
        path: '/claims',
        name: 'Claims',
        nav: true,
        document: false,
        header: false,
        footer: false,
        claimRoute: false,
      },
      '/team': {
        path: '/team',
        name: 'Team',
        nav: true,
        document: false,
        header: false,
        footer: false,
        claimRoute: false,
      },
      '/settings': {
        path: '/settings',
        name: 'Settings',
        nav: true,
        document: false,
        header: false,
        footer: false,
        claimRoute: false,
      },
      '/signin': {
        path: '/signin',
        name: 'Sign In',
        nav: false,
        document: false,
        header: false,
        footer: false,
        claimRoute: false,
      },
      '/signup': {
        path: '/signup',
        name: 'Sign Up',
        nav: false,
        document: false,
        header: false,
        footer: false,
        claimRoute: false,
      },
    })),
    documentSidebarOpen: types.optional(types.boolean, true),
    customLetterheadRect: types.optional(types.array(types.number), []),
    // navOpenOverride: types.maybeNull(types.boolean),
    // navOpen: types.optional(types.boolean, true),
    headerOpen: types.optional(types.boolean, true),
    footerOpen: types.optional(types.boolean, true),
    termHighlightActive: types.optional(types.boolean, true),
    enterSendsMessage: types.optional(types.boolean, false),
    sidebarClaimSortBy: types.optional(types.string, 'createdDate'),
    sidebarClaimSortOrder: types.optional(types.string, 'asc'),
    navbarWidth: types.optional(types.number, 200),
    docbarWidth: types.optional(types.number, 0),
    docbarTab: types.optional(
      types.enumeration(['selected', 'ready']),
      'ready'
    ),
    reviewDrawerOpen: types.optional(types.boolean, false),
  })
  // Views are read-only derived data of the state tree
  .views((store) => ({
    // Self-explanatory I hope
    get currentRoute(): IRoute | undefined {
      const route = store.routes.get(store.currentPath)
      if (route) {
        return route as IRoute
      } else {
        return undefined
      }
    },
    get docbarCollapsed() {
      return store.docbarWidth < 225
    },
    get navbarCollapsed() {
      return store.navbarWidth < 200
    },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    setNavbarWidth(width: number) {
      store.navbarWidth = width
    },
    setDocbarWidth(width: number) {
      store.docbarWidth = width
    },
    openNavbar() {
      this.setNavbarWidth(200)
    },
    closeNavbar() {
      this.setNavbarWidth(83)
    },
    openDocbar() {
      this.setDocbarWidth(650)
    },
    closeDocbar() {
      const claimStore = getRootStore(store).claimStore
      if (claimStore.currentClaimReadyToSend) {
        this.setDocbarWidth(225)
      } else {
        this.setDocbarWidth(0)
      }
    },
    setDocbarTab(tab: 'selected' | 'ready') {
      store.docbarTab = tab
    },
    // Sets navigation UI items to defaults unless user has modified them
    setDefaultNavItems() {
      this.toggleWebHeader(store.currentRoute?.header ?? false)
      this.toggleWebFooter(store.currentRoute?.footer ?? false)
      // if (store.navOpenOverride === null) {
      //   this.toggleNavBar(store.currentRoute?.nav ?? true)
      // } else {
      //   this.toggleNavBar(store.navOpenOverride)
      // }
      this.setCustomLetterheadRect(store.customLetterheadRect ?? [0.121, 0.13, 0.879, 0.861]) // Garfield coordinates
    },
    setCustomLetterheadRect(rect: number[]) {
      store.customLetterheadRect = cast(rect)
    },
    // Guess what, these toggle things
    // toggleNavBar(state: boolean, override?: boolean) {
    //   if (override != null) store.navOpenOverride = state
    //   store.navOpen = state
    // },
    toggleWebHeader(state: boolean) {
      store.headerOpen = state
    },
    toggleWebFooter(state: boolean) {
      store.footerOpen = state
    },
    toggleTermHighlight(state: boolean) {
      store.termHighlightActive = state
    },
    toggleEnterSendsMessage(state: boolean) {
      store.enterSendsMessage = state
    },
    toggleReviewDrawer(state: boolean) {
      store.reviewDrawerOpen = state
    },

    // Returns a specific route for a given path
    getRoute(path: string) {
      return store.routes.get(path)
    },

    // Sets the current route
    setCurrentPath(path: string) {
      store.currentPath = path
      this.setDefaultNavItems()
    },

    addClaimRoute(claimId: string) {
      const claimPath = `/claims/${claimId}`
      store.routes.set(claimPath, {
        path: claimPath,
        name: `${claimId}`,
        nav: true,
        document: false,
        header: false,
        footer: false,
        claimRoute: true,
      })
      this.setDefaultNavItems()
    },

    setSidebarClaimSortBy(
      sortBy: 'alphabetical' | 'lastMessage' | 'createdDate'
    ) {
      store.sidebarClaimSortBy = sortBy
    },
    setSidebarClaimSortOrder(sortOrder: 'asc' | 'desc') {
      store.sidebarClaimSortOrder = sortOrder
    },
  }))

export interface NavigationStore extends Instance<typeof NavStoreModel> {}
export interface NavigationStoreSnapshot
  extends SnapshotOut<typeof NavStoreModel> {}
