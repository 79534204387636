import { observer } from 'mobx-react'
import {
  Text,
  Space,
  Group,
  Paper,
  Title,
  Stack,
  Checkbox,
  TextInput,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { IconDiscountCheck, IconMapPin } from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import SumsubWebSdk from '@sumsub/websdk-react'
import { sumsub } from '../../services/sumsub_service'
import countries2to3 from 'countries-list/minimal/countries.2to3.min.json'
import { getCountryCode } from 'countries-list'
import { useEffect, useState } from 'react'
import { IUser } from 'models/auth/user'
import { IMember } from 'models/auth/member'

function OnboardingStep5B() {
    const theme = useMantineTheme()
    const { height, width } = useViewportSize()
    const { authStore, memberStore } = useStores()
    const navigate = useNavigate()

    const fullname = memberStore.currentMember?.name

    return (
        <form>
        <Paper ml={'20px'}>
            <Space h={'xs'} />
            <Group>
            <IconMapPin size={32} />
            <Title order={2}>{`Almost done!`}</Title>
            </Group>
            <Space h={'lg'} />

            <TextInput
            mb={'sm'}
            value={fullname ?? undefined}
            id='fullname-input'
            label='Full name'
            placeholder='John Doe'
            onChange={(e) =>
                {
                    memberStore.updateMember({
                        name: e.target.value,
                        member_id: memberStore.currentMember?.member_id,
                    } as Partial<IMember>)
                    authStore.updateUser({
                        uid: authStore.user?.uid,
                        verified: 'approved',
                    } as Partial<IUser>)
                }
            }
            />
            <Space h={'xs'} />
        </Paper>
        </form>
    )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(OnboardingStep5B)
