import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import BankAccount from 'models/billing/bank_account'
import { analytics } from 'services/analytics_service'

export interface MemberRow {
  userId: string
  name: string
  email: string
  role: string
  status: string
  dateJoined: string
}

export enum ROLES {
  owner = 'owner',
  team_admin = 'team_admin',
  claim_admin = 'claim_admin',
  claim_editor = 'claim_editor',
}

export enum STATUS {
  pending = 'pending',
  active = 'active',
  inactive = 'inactive',
}

// Model of the user data
const Member = types
  .model('Member', {
    member_id: types.identifier,
    uid: types.maybeNull(types.string),
    email: types.string,
    invite_url: types.maybeNull(types.string),
    role: types.maybeNull(types.enumeration('role', Object.values(ROLES))),
    status: types.maybeNull(types.enumeration('status', Object.values(STATUS))),
    joined_date: types.maybeNull(FirestoreTimestamp),
    verified: types.enumeration('verification', [
      'pending',
      'approved',
      'rejected',
    ]),
    claim_ids: types.optional(types.array(types.string), []),
    place_of_birth: types.optional(types.maybeNull(types.string), null),
    nationality: types.optional(types.maybeNull(types.string), null),
    profession: types.optional(types.maybeNull(types.string), null),
    mobile_number: types.optional(types.maybeNull(types.string), null),
    landline_number: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    birth_date: types.optional(types.maybeNull(types.string), null),
    title: types.optional(types.maybeNull(types.string), null),
    pep: types.optional(types.maybeNull(types.boolean), null),
    pep_related: types.optional(types.maybeNull(types.boolean), null),
  })
  .views((self) => ({
    get readableDateJoined() {
      console.log('self.joined_date')
      console.log(self.joined_date)
      try {
        return self.joined_date?.toDate().toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      } catch (e) {
        analytics.error(e)
        return null
      }
    },
    get initials() {
      return self.name
        ?.split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase()
    },
  }))
  .actions((self) => ({
    claimIdIsAssigned(claimId: string) {
      return self.claim_ids.includes(claimId)
    },
    addClaim(claimId: string) {
      self.claim_ids.push(claimId)
    },
    removeClaim(claimId: string) {
      self.claim_ids.remove(claimId)
    },
  }))

export default Member
export interface IMember extends Instance<typeof Member> {}
