import {
  Breadcrumbs,
  Button,
  useMantineTheme,
} from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'

function NavHeader() {
  const { navStore } = useStores()
  const navigate = useNavigate()
  const theme = useMantineTheme()

  const [currentRoute, setCurrentRoute] = useState(navStore.currentRoute)

  useEffect(() => {
    setCurrentRoute(navStore.currentRoute)
  }, [navStore.currentPath])

  if (!currentRoute?.path) return <></>

  function splitPath(path: string) {
    const parts = path.split('/').filter((part) => part !== '')
    const result = parts.reduce((acc, part, index) => {
      const fullPath = index === 0 ? `/${part}` : `${acc[index - 1]}/${part}`
      acc.push(fullPath)
      return acc
    }, [] as string[])
    return result
  }

  const splitRoutePaths = splitPath(currentRoute.path)

  const breadcrumbs = splitRoutePaths.map((path, index) => {
    const route = navStore.getRoute(path)
    if (route && route.path) {
      return (
        <Button
          size='compact-sm'
          variant='subtle'
          color={theme.colors.black[5]}
          onClick={() => navigate(path)}
          key={path}
        >
          {route?.name}
        </Button>
      )
    }
    return <></>
  })

  // return <>{currentRoute ? <Text size='xl'>{currentRoute.name}</Text> : null}</>
  return (
    <Breadcrumbs
      separatorMargin={0}
      separator={<IconChevronRight size={18}></IconChevronRight>}
      mt={'xs'}
    >
      {breadcrumbs}
    </Breadcrumbs>
  )
}

export default observer(NavHeader)
