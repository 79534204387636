import { forwardRef } from 'react'
import { IconChevronRight } from '@tabler/icons-react'
import {
  Avatar,
  Text,
  Button,
  ButtonProps,
  Container,
} from '@mantine/core'
import { observer } from 'mobx-react'

interface UserButtonProps extends ButtonProps {
  image?: string
  name: string
  icon?: React.ReactNode
}

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase() // Optional: Convert to uppercase
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, icon, ...others }: UserButtonProps, ref) => (
    <Button
      ref={ref}
      //   style={{
      //     padding: 'var(--mantine-spacing-md)',
      //     color: 'var(--mantine-color-text)',
      //     borderRadius: 'var(--mantine-radius-sm)',
      //   }}
      {...others}
      leftSection={
        <Avatar size={'sm'} radius='xl'>
          {getInitials(name)}
        </Avatar>
      }
      rightSection={icon || <IconChevronRight size='1rem' />}
    >
      <Text size='sm' fw={500} c={'white'} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {name}
      </Text>
      <Container fluid></Container>
    </Button>
  )
)

export default observer(UserButton)
