// Merged document.ts
import { Instance, types } from 'mobx-state-tree'
import DocumentDates from './document_dates'
import BaseDocumentDetails from './base_document_details'
import SettlementDetails from './settlement_details'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import { CLAIMSTAGE } from '../../models/claim/claim_state'

export enum DocumentType {
  
  // Garfield produced. Used in versions so can't overlap with classified documents
  chaser = 'chaser',
  lba = 'lba',
  claim_form = 'claim_form',
  poc = 'poc',
  claim_form_and_poc = 'claim_form_and_poc',
  request_for_judgment = 'request_for_judgment',
  reply_to_defence = 'reply_to_defence',
  reply_to_defence_and_counterclaim = 'reply_to_defence_and_counterclaim',
  calculations = 'calculations',
  claimant_correspondence = 'claimant_correspondence',
  claimant_settlement = 'claimant_settlement',
  n180_claimant_directions_questionnaire = 'n180_claimant_directions_questionnaire',
  claimant_skeleton_arguments = 'claimant_skeleton_arguments',
  claimant_bundle = 'claimant_bundle',

  // All below documents are classified.
  // Forms
  n9a_admission = 'n9a_admission',
  n9b_defence_and_counterclaim = 'n9b_defence_and_counterclaim',
  n9_acknowledgement_of_service = 'n9_acknowledgement_of_service',
  n24_judgment_general = 'n24_judgment_general',
  n30_judgment_default = 'n30_judgment_default',
  n149a_notice_of_proposed_allocation_small_claims = 'n149a_notice_of_proposed_allocation_small_claims',
  n149b_notice_of_proposed_allocation_fast_track = 'n149b_notice_of_proposed_allocation_fast_track',
  n149c_notice_of_proposed_allocation_multi_track = 'n149c_notice_of_proposed_allocation_multi_track',
  n157_notice_of_allocation_hearing = 'n157_notice_of_allocation_hearing',
  n159_notice_of_allocation_no_hearing = 'n159_notice_of_allocation_no_hearing',
  n180_directions_questionnaire_small_claims = 'n180_directions_questionnaire_small_claims',
  n181_directions_questionnaire_fast_intermediate_multi_track = 'n181_directions_questionnaire_fast_intermediate_multi_track',
  n205a_notice_of_issue = 'n205a_notice_of_issue',
  n210_acknowledgment_of_service = 'n210_acknowledgment_of_service',
  n225a_partial_admission = 'n225a_partial_admission',
  n244_application_notice = 'n244_application_notice',
  n279_notice_of_discontinuance = 'n279_notice_of_discontinuance',
  n434_change_of_legal_representative = 'n434_change_of_legal_representative',

  // Prescribed documents
  defence_statement_of_case = 'defence_statement_of_case',
  defendant_bundle = 'defendant_bundle',
  defendant_skeleton_arguments = 'defendant_skeleton_arguments',
  witness_statement = 'witness_statement',
  instructions_to_counsel = 'instructions_to_counsel',
  exhibit = 'exhibit',
 
  // General documents
  settlement = 'settlement',
  invoice = 'invoice',
  contract = 'contract',
  correspondence = 'correspondence',

  irrelevant_or_malicious = 'irrelevant_or_malicious',
  other = 'other',
}

const DocumentDetailsUnion = types.union(
  {
    dispatcher: (snapshot) => {
      if (['offer', 'acceptance', 'rejection']?.includes(snapshot?.type)) {
        return SettlementDetails
      }
      return BaseDocumentDetails
    },
  },
  BaseDocumentDetails,
  SettlementDetails
)

const Document = types
  .model('Document', {
    document_id: types.identifier,
    account_id: types.maybeNull(types.string),
    claim_id: types.maybeNull(types.string),
    message_id: types.maybeNull(types.string),
    associated_document_ids: types.maybeNull(types.array(types.string)),
    signature_prefix_used: types.maybeNull(types.string),
    letterhead_type_used: types.maybeNull(types.string),
    letterhead_prefix_used: types.maybeNull(types.string),
    letterhead_coordinates_used: types.maybeNull(types.array(types.number)),
    bank_account_id_used: types.maybeNull(types.string),
    wanted_bank_account_id: types.maybeNull(types.string),

    signed: types.maybeNull(types.boolean),
    paid: types.maybeNull(types.boolean),
    stage: types.maybeNull(types.enumeration(Object.values(CLAIMSTAGE))),
    name: types.maybeNull(types.string),
    dates: types.maybeNull(DocumentDates),
    url: types.maybeNull(types.string),
    prefix: types.maybeNull(types.string),
    type: types.maybeNull(types.enumeration(Object.values(DocumentType))),
    details: types.maybeNull(DocumentDetailsUnion),
    creator: types.enumeration(['user', 'garfield']),
    last_updated_date: types.maybeNull(FirestoreTimestamp),
    reference_document_id: types.maybeNull(types.string),
    processing: types.optional(types.boolean, false),
    payment_processing: types.optional(types.boolean, false),
    status: types.optional(types.enumeration(['active', 'archived']), 'active'),
    uploading: types.optional(types.boolean, false),
    mime_type: types.maybeNull(types.string),
    extension: types.maybeNull(types.string),
    cost: types.maybeNull(types.number),
  })
  .views((self) => ({
    get readableCreatedDate() {
      if (!self.dates?.created_date) return null
      const date = new Date(self.dates?.created_date * 1000)
      return date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  }))
  .actions((self) => ({
    update(updatedDocument: Partial<IDocument>) {
      Object.assign(self, updatedDocument)
      if (updatedDocument.dates) {
        self.dates?.update(updatedDocument.dates)
      }
    },
    changeUrl(url: string) {
      self.url = url
    },
  }))

export default Document
export interface IDocument extends Instance<typeof Document> {}
