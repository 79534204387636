import { useState, ReactNode } from 'react'
import {
  Checkbox,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
  Text,
  ScrollArea,
  FloatingPosition,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { IClaim } from '../../models/claim/claim'
import { useStores } from '../../utils/use_stores'
import { ISimpleType, IMSTArray } from 'mobx-state-tree'
import { IMember } from '../../models/auth/member'
import { IInvite } from '../../models/auth/invite'

function SelectOption({ claim }: { claim: IClaim }) {
  return (
    <Group>
      <div>
        <Text fz='sm' fw={500}>
          {claim.displayName}
        </Text>
        <Group>
          <Text fz='xs' opacity={0.6}>
            {claim.displayId}
          </Text>
          <Text fz='xs' opacity={0.6}>
            {claim.state?.readableDateCreated}
          </Text>
        </Group>
      </div>
    </Group>
  )
}

interface ClaimSelectionBoxProps {
  memberId: string
  target: (toggleDropdown: () => void) => ReactNode
  offset?: number
  width?: number
  position?: FloatingPosition
  type: 'member' | 'invite'
}

function ClaimSelectionBox({
  memberId,
  target,
  offset,
  width,
  position,
  type,
}: ClaimSelectionBoxProps) {
  const { claimStore, memberStore } = useStores()

  const claims = claimStore.claims
  const member: IMember | IInvite | undefined =
    memberStore.getMember(memberId) ?? memberStore.getInvite(memberId)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const [search, setSearch] = useState('')
  const [assignedClaims, setAssignedClaims] = useState<string[]>(
    member?.claim_ids || []
  )

  // useEffect(() => {
  //   setAssignedClaims(member?.claim_ids || [])
  // }, [member?.claim_ids.slice()])

  const handleValueSelect = async (claimId: string) => {
    setAssignedClaims((current) =>
      current.includes(claimId)
        ? current.filter((v) => v !== claimId)
        : [...current, claimId]
    )
    const updatedClaimIds = assignedClaims.includes(claimId)
      ? assignedClaims.filter((id) => id !== claimId)
      : [...assignedClaims, claimId]
    if (type == 'member') {
      await memberStore.updateMember({
        member_id: member?.member_id,
        claim_ids: updatedClaimIds as IMSTArray<ISimpleType<string>>,
      })
    } else if (type == 'invite') {
      memberStore.updateInvite({
        member_id: member?.member_id,
        claim_ids: updatedClaimIds as IMSTArray<ISimpleType<string>>,
      })
    }
  }

  // const handleValueRemove = (val: string) =>
  //   setAssignedClaims((current) => current.filter((v) => v !== val))

  const values = <Pill>{`${assignedClaims.length}`}</Pill>

  const filter = (claim: IClaim) => {
    return (
      claim.displayName.toLowerCase().includes(search.trim().toLowerCase()) ||
      claim.displayId.toLowerCase().includes(search.trim().toLowerCase()) ||
      claim.details?.base_claim_details?.summary
        ?.toLowerCase()
        .includes(search.trim().toLowerCase())
    )
  }

  const options = claims
    .filter((claim) => filter(claim))
    .map((claim) => (
      <Combobox.Option
        value={claim.claim_id!}
        key={claim.claim_id}
        active={assignedClaims.includes(claim.claim_id!)}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Group gap='sm'>
          <Checkbox
            checked={assignedClaims.includes(claim.claim_id!)}
            onChange={() => {}}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
          />
          <SelectOption claim={claim} />
        </Group>
      </Combobox.Option>
      // <Combobox.Option
      //   value={item}
      //   key={item}
      //   active={value.includes(item)}
      //   onClick={(event) => {
      //     event.stopPropagation()
      //   }}
      // >
      //   <Group
      //     gap='sm'
      //     onClick={(event) => {
      //       event.stopPropagation()
      //     }}
      //   >
      //     {value.includes(item) ? <CheckIcon size={12} /> : null}
      //     <span
      //       onClick={(event) => {
      //         event.stopPropagation()
      //       }}
      //     >
      //       {item}
      //     </span>
      //   </Group>
      // </Combobox.Option>
    ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={true}
      width={width ?? 350}
      offset={offset ?? 40}
      position={position ?? 'left-start'}
    >
      <Combobox.Target>
        {target(() => {
          combobox.toggleDropdown()
        })}
        {/* <Text
          size='sm'
          onClick={(event) => {
            combobox.toggleDropdown()
            event.stopPropagation()
          }}
        >
          Assign claims
        </Text> */}
      </Combobox.Target>
      {/* <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder='Search values'
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(value[value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget> */}

      <Combobox.Dropdown>
        <PillsInput>
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                // onFocus={() => combobox.openDropdown()}
                // onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder='Search claims'
                onChange={(event) => {
                  event.stopPropagation()
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                // onKeyDown={(event) => {
                //   event.stopPropagation()
                //   if (event.key === 'Backspace' && search.length === 0) {
                //     event.preventDefault()
                //     handleValueRemove(value[value.length - 1])
                //   }
                // }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
        <Combobox.Options
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <ScrollArea.Autosize mah={400} type='scroll'>
            {options.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              options
            )}
          </ScrollArea.Autosize>
          {/* {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found...</Combobox.Empty>
          )} */}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default observer(ClaimSelectionBox)
