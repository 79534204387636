import {
    Button,
    Card,
    Group,
    Stack,
    useMantineTheme,
    Box,
    rem,
    Text,
    Tabs,
  } from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { modal } from '../../utils/modal'
import { useEffect, useState } from 'react'
import { ResizableBox } from 'react-resizable'

import { useRef } from 'react'
import { IconUpload, IconRefresh } from '@tabler/icons-react'
import { Rnd } from 'react-rnd'
import { IDocument } from '../../models/document/document'
import { firebase } from '../../services/firebase_service'
import LetterheadReader from '../shared/letterhead_reader'
import { observer } from 'mobx-react'
import { Dropzone,
    FileWithPath,
} from '@mantine/dropzone';
import { IconPaperclip, IconX, IconCloudUpload } from '@tabler/icons-react';

const LetterheadModal = ({
text,
title,
onClick,
onClose
}: {
text: React.ReactNode
title: string
onClick: () => {}
onClose: () => {}
}) => {
    const theme = useMantineTheme()
    const {  documentStore, navStore, memberStore, authStore } = useStores()
    // Change to this enum to also allow for no_letterhead
    const customLetterheadRect = navStore.customLetterheadRect // should Use value from memberStore on load.
    const customLetterheadPrefix = authStore.currentLetterheadPrefix
    const customLetterhead = documentStore.customLetterheadDocument
    const selectedDocument = customLetterhead
    // const [files, setFiles] = useState<FileWithPath[]>([])
    const defaultLetterheadRect = [0.121, 0.13, 0.879, 0.861]

    const [key, setKey] = useState(0)
    const pdfContainerRef = useRef<HTMLDivElement>(null)
    const [pdfDimensions, setPdfDimensions] = useState({
        offsetTop: 0,
        offsetLeft: 0,
        clientWidth: 0,
        clientHeight: 0,
    })

    const custom_x1 = customLetterheadRect[0] * pdfDimensions.clientWidth
    const custom_y1 = customLetterheadRect[1] * pdfDimensions.clientHeight
    const custom_x2 = customLetterheadRect[2] * pdfDimensions.clientWidth
    const custom_y2 = customLetterheadRect[3] * pdfDimensions.clientHeight

    const resetResizableBox = () => {
        navStore.setCustomLetterheadRect(defaultLetterheadRect)
        authStore.saveLetterheadCoordinates(defaultLetterheadRect)
    }

    useEffect(() => {
        if (pdfContainerRef.current) {
        const { offsetTop, offsetLeft, clientWidth, clientHeight } = pdfContainerRef.current
            setPdfDimensions({ offsetTop, offsetLeft, clientWidth, clientHeight })
        }
    }, [selectedDocument, key])

    function fileToBase64(file: File) {
        return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        })
    }

        const uploadLetterhead = async (files: FileWithPath[]) => {
            if (files.length > 0) {
                console.log('uploadLetterhead', files)
                const letterheadData = await fileToBase64(files[0])
                authStore.uploadLetterheadPdf(letterheadData)
                setKey(prevKey => prevKey + 1)
            }
        }

    return (
        <Card p={'xs'} bg={'transparent'} style={{ height: '85vh', overflow: 'hidden', width: '100%'}}>
        <Stack justify='center' align='center' style={{ height: '85vh', overflow: 'hidden'}}>
            <Group justify="left" style={{ height: '5vh', width: '100%' }}>

            <Button 
                color="gray"
                onClick={() => document.getElementById('file-input')?.click()} 
            >
                <IconUpload size={16} />
            </Button>
            <Button color="gray" onClick={resetResizableBox}>
                <IconRefresh size={16} />
            </Button>
            <Button color='gray'onClick={() => setKey(prevKey => prevKey + 1)}>
                Reload
            </Button>

            <input 
                id="file-input" 
                type="file" 
                style={{ display: 'none' }} 
                onChange={(e) => {
                    const files = e.target.files;
                    if (files) uploadLetterhead(Array.from(files));
                }} 
            />

        </Group>
            <div 
                ref={pdfContainerRef} 
                style={{ 
                height: '80vh', 
                overflow: 'hidden',
                cursor: 'default'
                }}>
                <LetterheadReader letterheadPrefix={customLetterheadPrefix ?? ''} />
            </div>

        { (
        <Rnd // This needs improving. Also needs to set the values in the navStore on Resize stop.
            key={key}
            size={{ width: custom_x2-custom_x1, height: custom_y2-custom_y1 }}
            position={{ x: pdfDimensions.offsetLeft + custom_x1, y: pdfDimensions.offsetTop + custom_y1 }}
            minWidth={100}
            minHeight={100}
            disableDragging={true}
            style={{
            background: 'rgba(128, 128, 128, 0.2)',
            boxSizing: 'border-box',
            zIndex: 1000,
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
            
            const scaled_width = ref.offsetWidth / pdfDimensions.clientWidth
            const scaled_height = ref.offsetHeight / pdfDimensions.clientHeight
            const scaled_x1 = (position.x - pdfDimensions.offsetLeft) / pdfDimensions.clientWidth
            const scaled_y1 = (position.y - pdfDimensions.offsetTop) / pdfDimensions.clientHeight
            const scaled_x2 = scaled_x1 + scaled_width
            const scaled_y2 = scaled_y1 + scaled_height
            navStore.setCustomLetterheadRect([scaled_x1, scaled_y1, scaled_x2, scaled_y2])
            authStore.saveLetterheadCoordinates(navStore.customLetterheadRect)
            }}
        >
            <div style={{ width: '100%', height: '100%' }} />
        </Rnd>
        )}
        </Stack>
    </Card>
    )
}

export default observer(LetterheadModal)