import { useState, ReactNode, useEffect } from 'react'
import {
  Checkbox,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
  Text,
  ScrollArea,
  FloatingPosition,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { useStores } from '../../utils/use_stores'
import { ISimpleType, IMSTArray } from 'mobx-state-tree'
import { IMember } from '../../models/auth/member'

function SelectOption({ member }: { member: IMember }) {
  return (
    <Group>
      <div>
        <Text fz='sm' fw={500}>
          {member.name}
        </Text>
        <Group>
          <Text fz='xs' opacity={0.6}>
            {member.email}
          </Text>
          <Text fz='xs' opacity={0.6}>
            {member.role}
          </Text>
        </Group>
      </div>
    </Group>
  )
}

interface MemberSelectionBoxProps {
  claimIds: string[]
  target: (toggleDropdown: () => void) => ReactNode
  offset?: number
  width?: number
  position?: FloatingPosition
}

function MemberSelectionBox({
  claimIds,
  target,
  offset,
  width,
  position,
}: MemberSelectionBoxProps) {
  const { claimStore, memberStore } = useStores()

  const members: IMember[] = memberStore.members

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const [search, setSearch] = useState('')
  const [assignedMemberIds, setAssignedMemberIds] = useState<string[]>(
    memberStore.getMemberIdsWithAllClaimIds(claimIds)
  )

  const [assignedSomeMemberIds, setAssignedSomeMemberIds] = useState<string[]>(
    memberStore.getMemberIdsWithAnyClaimIds(claimIds)
  )

  useEffect(() => {
    setAssignedMemberIds(memberStore.getMemberIdsWithAllClaimIds(claimIds))
    setAssignedSomeMemberIds(memberStore.getMemberIdsWithAnyClaimIds(claimIds))
  }, [claimIds])

  const handleValueSelect = async (memberId: string) => {
    setAssignedMemberIds((current) =>
      current.includes(memberId)
        ? current.filter((v) => v !== memberId)
        : [...current, memberId]
    )

    const member = memberStore.getMember(memberId)

    const updatedClaimIds = assignedMemberIds.includes(memberId)
      ? member?.claim_ids.filter((id) => !claimIds.includes(id))
      : [...(member?.claim_ids ?? []), ...claimIds]

    await memberStore.updateMember({
      member_id: memberId,
      claim_ids: updatedClaimIds as IMSTArray<ISimpleType<string>>,
    })

    setAssignedMemberIds(memberStore.getMemberIdsWithAllClaimIds(claimIds))
    setAssignedSomeMemberIds(memberStore.getMemberIdsWithAnyClaimIds(claimIds))
  }

  const filter = (member: IMember) => {
    return (
      member.name?.toLowerCase().includes(search.trim().toLowerCase()) ||
      member.email.toLowerCase().includes(search.trim().toLowerCase()) ||
      member.role?.toLowerCase().includes(search.trim().toLowerCase())
    )
  }

  const options = members
    .filter((member) => filter(member))
    .map((member) => (
      <Combobox.Option
        value={member.member_id!}
        key={member.member_id}
        active={assignedMemberIds.includes(member.member_id!)}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Group gap='sm'>
          <Checkbox
            checked={assignedMemberIds.includes(member.member_id!)}
            onChange={() => {}}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
            indeterminate={
              !assignedMemberIds.includes(member.member_id!) &&
              assignedSomeMemberIds.includes(member.member_id!)
            }
          />
          <SelectOption member={member} />
        </Group>
      </Combobox.Option>
    ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={true}
      width={width ?? 350}
      offset={offset ?? 40}
      position={position ?? 'left-start'}
    >
      <Combobox.Target>
        {target(() => {
          combobox.toggleDropdown()
        })}
      </Combobox.Target>

      <Combobox.Dropdown>
        <PillsInput>
          <Pill.Group>
            {/* {values} */}
            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                placeholder='Search team members'
                onChange={(event) => {
                  event.stopPropagation()
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
        <Combobox.Options
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <ScrollArea.Autosize mah={400} type='scroll'>
            {options.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              options
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default observer(MemberSelectionBox)
