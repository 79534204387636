import React from 'react'
import {
  useNavigate,
} from 'react-router-dom'

import {
  Container,
  useMantineTheme,
  Title,
  rem,
  Accordion,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
import faqData from '../../assets/json/faq.json'

interface FAQItemProps {
  question: string
  answer: string
}

const placeholder =
  'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.'

function FAQSection() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { navStore } = useStores()
  const navigate = useNavigate()

  return (
    <Container
      size='sm'
      style={{
        minHeight: rem(650),
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          marginBottom: rem(30),
        }}
      >
        Frequently Asked Questions
      </Title>

      <Accordion variant='separated'>
        {faqData.faqs.map((faq, index) => (
          <Accordion.Item
            key={index}
            style={{
              borderRadius: theme.radius.md,
              marginBottom: theme.spacing.lg,
              border: `1px solid ${theme.colors.gray[3]}`,
            }}
            value={index.toString()}
          >
            <Accordion.Control>{faq.question}</Accordion.Control>
            <Accordion.Panel
              style={{
                fontSize: rem(14),
                color: theme.colors.gray[7],
              }}
            >
              {faq.answer}
            </Accordion.Panel>
          </Accordion.Item>
        ))}

        {/* {[
          'reset-password',
          'another-account',
          'newsletter',
          'credit-card',
          'payment',
        ].map((value) => (
          <Accordion.Item
            key={value}
            style={{
              borderRadius: theme.radius.md,
              marginBottom: theme.spacing.lg,
              border: `1px solid ${theme.colors.gray[3]}`,
            }}
            value={value}
          >
            <Accordion.Control>
              {value === 'reset-password' && 'How can I reset my password?'}
              {value === 'another-account' &&
                'Can I create more than one account?'}
              {value === 'newsletter' &&
                'How can I subscribe to monthly newsletter?'}
              {value === 'credit-card' &&
                'Do you store credit card information securely?'}
              {value === 'payment' && 'What payment systems do you work with?'}
            </Accordion.Control>
            <Accordion.Panel>{placeholder}</Accordion.Panel>
          </Accordion.Item>
        ))} */}
      </Accordion>
    </Container>
  )
}

export default FAQSection
