import {
  ReactNode,
} from 'react'
import {
  useMantineTheme,
  Badge,
  rem,
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import {
  IconCircleCheck,
  IconCircleDashed,
  IconCircleDot,
  IconCircleMinus,
} from '@tabler/icons-react'
import { observer } from 'mobx-react'


import { STATUS } from '../../models/auth/member'

function StatusBadge({ status }: { status: string }) {
  const { hovered, ref } = useHover()
  const theme = useMantineTheme()

  let icon: ReactNode
  let color: string
  let statusString: string

  switch (status) {
    case STATUS.pending:
      icon = <IconCircleDot style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.orange[4]
      statusString = 'Pending invite'
      break
    case STATUS.active:
      icon = <IconCircleCheck style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.green[4]
      statusString = 'Active'
      break
    case STATUS.inactive:
      icon = <IconCircleMinus style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[5]
      statusString = 'Inactive'
      break
    default:
      icon = <IconCircleDashed style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[5]
      statusString = 'N/A'
  }

  return (
    <Badge leftSection={icon} color={color} size='sm' pl={6}>
      {statusString}
    </Badge>
  )
}

export default observer(StatusBadge)
