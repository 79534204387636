// post_action_base_details.ts
import { Instance, types } from 'mobx-state-tree'

const PostActionBaseDetails = types
  .model('PostActionBaseDetails', {
    court_names: types.maybeNull(types.array(types.string)),
    track: types.maybeNull(
      types.enumeration(['unallocated', 'small', 'fast', 'multi'])
    ),
    numbers: types.maybeNull(types.array(types.string)),
    experts_required: types.maybeNull(types.boolean),
    hearing_result: types.maybeNull(
      types.enumeration(['won_in_full', 'won_in_part', 'lost'])
    ),
    request_mediation: types.maybeNull(types.boolean),
    papers_only: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<IPostActionBaseDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default PostActionBaseDetails
export interface IPostActionBaseDetails
  extends Instance<typeof PostActionBaseDetails> {}
