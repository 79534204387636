import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import { print } from '../../utils/print'
import { Timestamp } from 'firebase/firestore'
import * as changeCase from 'change-case'
import StageNode from './stage_node'
import DocumentSentDates, { IDocumentSentDates } from './document_sent_dates'
import { analytics } from 'services/analytics_service'

export enum CLAIMSTAGE {
  triage = 'triage',
  invalid_claim = 'invalid_claim', // New one
  correspondence_info_needed = 'correspondence_info_needed', // New one
  correspondence_ready = 'correspondence_ready', // New one
  correspondence_await_response = 'correspondence_await_response', // New one
  initial_approach_await_decision = 'initial_approach_await_decision',
  chaser_info_needed = 'chaser_info_needed',
  chaser_ready = 'chaser_ready',
  chaser_await_sending = 'chaser_await_sending',
  chaser_await_response = 'chaser_await_response',
  lba_info_needed = 'lba_info_needed',
  lba_ready = 'lba_ready',
  lba_await_sending = 'lba_await_sending',
  lba_await_response = 'lba_await_response',
  lba_response_await_decision = 'lba_response_await_decision',
  negotiate_lba_settlement_offer = 'negotiate_lba_settlement_offer',
  stalling_response_await_decision = 'stalling_response_await_decision',
  lba_settlement_accepted = 'lba_settlement_accepted',
  negotiate_lba_request_for_time_or_indulgence = 'negotiate_lba_request_for_time_or_indulgence',
  accept_lba_request_for_time_or_indulgence = 'accept_lba_request_for_time_or_indulgence',
  claim_form_info_needed = 'claim_form_info_needed',
  claim_form_ready = 'claim_form_ready',
  claim_form_await_response = 'claim_form_await_response',
  request_for_judgment_info_needed = 'request_for_judgment_info_needed',
  request_for_judgment_ready = 'request_for_judgment_ready',
  request_for_judgment_await_response = 'request_for_judgment_await_response',
  request_for_judgment_response_decision = 'request_for_judgment_response_decision',
  defence_response_info_needed = 'defence_response_info_needed',
  counterclaim_response_info_needed = 'counterclaim_response_info_needed', // New one
  admission_info_needed = 'admission_info_needed',
  notice_of_proposed_allocation_info_needed = 'notice_of_proposed_allocation_info_needed',
  reply_to_defence_ready = 'reply_to_defence_ready', // New one
  reply_to_defence_and_counterclaim_ready = 'reply_to_defence_and_counterclaim_ready',
  directions_questionnaire_info_needed = 'directions_questionnaire_info_needed',
  directions_questionnaire_ready = 'directions_questionnaire_ready',
  await_court_standard_directions = 'await_court_standard_directions',
  court_bundle_info_needed = 'court_bundle_info_needed',
  court_bundle_ready = 'court_bundle_ready',
  await_court_decision = 'await_court_decision',
  case_resolved = 'case_resolved',
  broken = 'broken',
  claim_form_blocker = 'claim_form_blocker',
}

const PastClaimStage = types.model({
  date: types.maybeNull(FirestoreTimestamp),
  stage: types.maybeNull(types.enumeration(Object.values(CLAIMSTAGE))),
})

const ClaimState = types
  .model('ClaimState', {
    created_date: types.maybeNull(FirestoreTimestamp),
    last_updated_date: types.maybeNull(FirestoreTimestamp),
    last_message_date: types.maybeNull(FirestoreTimestamp),
    pinned_date: types.maybeNull(FirestoreTimestamp),
    stage: types.maybeNull(
      types.enumeration('ClaimStageType', Object.values(CLAIMSTAGE))
    ),
    past_stages: types.optional(types.array(PastClaimStage), []),
    documents_to_send: types.optional(types.array(types.string), []),
    is_resolved: types.maybeNull(types.boolean),
    chaser_paid: types.maybeNull(types.boolean),
    lba_paid: types.maybeNull(types.boolean),
    totals: types.maybeNull(
      types.model({
        outstanding: types.maybeNull(types.string),
        principal_amount: types.maybeNull(types.string),
        interest_accrued: types.maybeNull(types.string),
        last_updated_date: types.maybeNull(FirestoreTimestamp),
      })
    ),
    document_sent_dates: types.maybeNull(DocumentSentDates),
    schema_version: types.maybeNull(types.string),
  })
  .views((self) => ({
    getStageDisplay(stage: CLAIMSTAGE) {
      const stagesDict = {
        [CLAIMSTAGE.triage]: 'Initial claim analysis',
        [CLAIMSTAGE.invalid_claim]: 'Invalid Claim',
        [CLAIMSTAGE.initial_approach_await_decision]: 'First Steps',
        [CLAIMSTAGE.chaser_info_needed]: 'Chaser information required',
        [CLAIMSTAGE.chaser_ready]: 'Review chaser',
        [CLAIMSTAGE.chaser_await_sending]: 'Send chaser',
        [CLAIMSTAGE.chaser_await_response]: 'Wait for response to chaser',
        [CLAIMSTAGE.lba_info_needed]: 'Letter Before Action information required',
        [CLAIMSTAGE.lba_ready]: 'Review Letter Before Action',
        [CLAIMSTAGE.lba_await_sending]: 'Send Letter Before Action',
        [CLAIMSTAGE.lba_await_response]: 'Wait for response to Letter Before Action',
        [CLAIMSTAGE.claim_form_blocker]: 'Wait for Garfield launch',

        [CLAIMSTAGE.correspondence_info_needed]: 'Correspondence',
        [CLAIMSTAGE.correspondence_ready]: 'Correspondence',
        [CLAIMSTAGE.correspondence_await_response]: 'Correspondence',
        [CLAIMSTAGE.lba_response_await_decision]: 'Wait for defendant',
        [CLAIMSTAGE.negotiate_lba_settlement_offer]: 'LBA Negotiation',
        [CLAIMSTAGE.stalling_response_await_decision]: 'Wait for defendant',
        [CLAIMSTAGE.case_resolved]: 'Settlement',
        [CLAIMSTAGE.lba_settlement_accepted]: 'Settlement',
        [CLAIMSTAGE.negotiate_lba_request_for_time_or_indulgence]: 'LBA Negotiation',
        [CLAIMSTAGE.accept_lba_request_for_time_or_indulgence]: 'LBA Negotiation',
        [CLAIMSTAGE.claim_form_info_needed]: 'Claim Form',
        [CLAIMSTAGE.claim_form_ready]: 'Claim Form',
        [CLAIMSTAGE.claim_form_await_response]: 'Wait for defendant',
        [CLAIMSTAGE.request_for_judgment_info_needed]: 'Judgment',
        [CLAIMSTAGE.request_for_judgment_ready]: 'Judgment',
        [CLAIMSTAGE.request_for_judgment_await_response]: 'Judgment',
        [CLAIMSTAGE.request_for_judgment_response_decision]: 'Judgment',
        [CLAIMSTAGE.defence_response_info_needed]: 'Defence',
        [CLAIMSTAGE.counterclaim_response_info_needed]: 'Defence & Counterclaim',
        [CLAIMSTAGE.admission_info_needed]: 'Defence & Counterclaim',
        [CLAIMSTAGE.reply_to_defence_ready]: 'Defence',
        [CLAIMSTAGE.reply_to_defence_and_counterclaim_ready]: 'Defence & Counterclaim',
        [CLAIMSTAGE.notice_of_proposed_allocation_info_needed]: 'Defence & Counterclaim',
        [CLAIMSTAGE.directions_questionnaire_info_needed]: 'Directions',
        [CLAIMSTAGE.directions_questionnaire_ready]: 'Directions',
        [CLAIMSTAGE.await_court_standard_directions]: 'Directions',
        [CLAIMSTAGE.court_bundle_info_needed]: 'Bundle',
        [CLAIMSTAGE.court_bundle_ready]: 'Bundle',
        [CLAIMSTAGE.await_court_decision]: 'Defence',
        [CLAIMSTAGE.broken]: '?',
      }
      return stagesDict.hasOwnProperty(stage) ? stagesDict[stage] : 'Claim created'
    },
    get fees() {
      if (
        self.stage === CLAIMSTAGE.claim_form_ready ||
        self.stage === CLAIMSTAGE.request_for_judgment_ready ||
        self.stage === CLAIMSTAGE.reply_to_defence_and_counterclaim_ready
      ) {
        return 'variable'
      } else if (self.stage === CLAIMSTAGE.lba_ready) {
        return 'fixed'
      } else {
        return 'none'
      }
    },
    get isPastClaimForm() {
      return (
        self.past_stages.filter(
          (pastStage) =>
            pastStage.stage === CLAIMSTAGE.claim_form_ready
        ).length > 0
      )
    },
    get readableDateCreated() {
      try {
        return self.created_date?.toDate().toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      } catch (e) {
        analytics.error(e)
        return self.created_date
      }
    },
    get pastStagesInOrder() {
      return self.past_stages.slice().sort((a, b) => a.date - b.date)
    },
    get pastStage(): string {
      if (this.pastStagesInOrder.length === 0) return ''
      const stage = this.pastStagesInOrder[this.pastStagesInOrder.length - 1]?.stage
      return stage as CLAIMSTAGE
    },
    get currentStageDisplay() {
      return this.getStageDisplay(self.stage as CLAIMSTAGE)
    },
  }))
  .actions((self) => ({
    update(updatedState: Partial<IClaimState>) {
      Object.assign(self, updatedState)
    },
  }))

export default ClaimState
export interface IClaimState extends Instance<typeof ClaimState> {}
