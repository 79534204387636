import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import Instalment from './instalment'
import LineItem from './line_item'

const Invoice = types
  .model('Invoice', {
    id: types.maybeNull(types.string),
    number: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    summary: types.maybeNull(types.string),
    created_date: types.maybeNull(FirestoreTimestamp),
    due_date: types.maybeNull(FirestoreTimestamp),
    instalments: types.optional(types.array(Instalment), []),
    line_items: types.optional(types.array(LineItem), []),
    interest_rate_percentage: types.maybeNull(types.string),
    interest_rate_not_known: types.maybeNull(types.boolean),
    compound: types.maybeNull(types.boolean),
    compound_period: types.maybeNull(types.integer),
  })
  .views((self) => ({
    get readableDateCreated() {
      return self.created_date?.toDate().toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    get readableDateDue() {
      return self.due_date?.toDate().toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  }))

export default Invoice
export interface IInvoice extends Instance<typeof Invoice> {}
