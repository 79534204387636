import { Instance, types } from 'mobx-state-tree'
import ContractDetails from './contract_details'
import Invoice from './invoice'
import Charge from './charge'

const Contract = types
  .model('Contract', {
    // Merging the details field from the new model as it is more detailed
    details: types.optional(ContractDetails, {
      is_written: null,
      is_deed: null,
      no_vulnerable_persons: null,
      contract_date: null,
      oral_contract_details: null,
    }),
    invoices: types.optional(types.array(Invoice), []),
    charges: types.maybeNull(types.optional(types.array(Charge), [])),
  })
  .views((self) => ({
    // Adding views from the old model as they provide useful computed values
  }))
  .actions((self) => ({
    // Keeping actions empty as in the new model, but ready for future additions
  }))

export default Contract
export interface IContract extends Instance<typeof Contract> {}
