import { observer } from 'mobx-react'
import {
  Space,
  Group,
  TextInput,
  Title,
  Combobox,
  useCombobox,
  InputBase,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { useState } from 'react'


import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import { IUser } from '../../models/auth/user'
import type { ICountry } from 'countries-list'
import { countries } from 'countries-list'

function OnboardingStep4B() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore } = useStores()
  const navigate = useNavigate()

  const type = authStore?.user?.type
  const country = authStore?.user?.trading_address?.country
  const postalCode = authStore?.user?.trading_address?.postcode
  const firstLine = authStore?.user?.trading_address?.first_line
  const secondLine = authStore?.user?.trading_address?.second_line
  const town = authStore?.user?.trading_address?.town_city
  const county = authStore?.user?.trading_address?.county

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const [value, setValue] = useState<string | null>(null)
  const [search, setSearch] = useState('')

  const countriesArray = Object.values(countries)
  const shouldFilterOptions = countriesArray.every(
    (country: ICountry) => country.name !== search
  )
  const filteredOptions = shouldFilterOptions
    ? countriesArray.filter((country: ICountry) =>
        country.name.toLowerCase().includes(search.toLowerCase().trim())
      )
    : countriesArray

  const options = filteredOptions.map((country: ICountry) => (
    <Combobox.Option value={country.name} key={country.name}>
      {country.name}
    </Combobox.Option>
  ))

  return (
    <>
      <Space h={'lg'} />
      <Title c={theme.colors.gray[6]} order={6}>{`Trading address: `}</Title>

      <Space h={'lg'} />
      <Group grow>
        <Combobox
          store={combobox}
          withinPortal={false}
          onOptionSubmit={(val) => {
            setValue(val)
            setSearch(val)
            combobox.closeDropdown()
            authStore.updateUser({
              trading_address: { country: val },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }}
        >
          <Combobox.Target>
            <InputBase
              mb={'sm'}
              label='Country'
              id='country-input'
              rightSection={<Combobox.Chevron />}
              value={search ? search : country ?? ''}
              onChange={(event: any) => {
                authStore.updateUser({
                  trading_address: { country: null },
                  uid: authStore.user?.uid,
                } as Partial<IUser>)
                combobox.openDropdown()
                combobox.updateSelectedOptionIndex()
                setSearch(event.currentTarget.value)
              }}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => {
                combobox.closeDropdown()
                setSearch(value || '')
              }}
              placeholder='Search value'
              rightSectionPointerEvents='none'
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
              {options.length === 0 ? (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              ) : (
                options
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
        <TextInput
          mb={'sm'}
          value={postalCode ?? undefined}
          id='postal-code-input'
          label='Postal Code'
          placeholder='CB1 6XJ'
          onChange={(e) =>
            authStore.updateUser({
              trading_address: { postcode: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
      </Group>

      <TextInput
        mb={'sm'}
        value={firstLine ?? undefined}
        id='first-line-input'
        label='First line'
        placeholder='12 Newton Road'
        onChange={(e) =>
          authStore.updateUser({
            trading_address: { first_line: e.target.value },
            uid: authStore.user?.uid,
          } as Partial<IUser>)
        }
      />
      <TextInput
        mb={'sm'}
        value={secondLine ?? undefined}
        id='seconf-line-input'
        label='Second line (Optional)'
        placeholder='Littleport'
        onChange={(e) =>
          authStore.updateUser({
            trading_address: { second_line: e.target.value },
            uid: authStore.user?.uid,
          } as Partial<IUser>)
        }
      />
      <Group grow>
        <TextInput
          mb={'sm'}
          value={town ?? undefined}
          id='town-input'
          label='Town'
          placeholder='Cambridge'
          onChange={(e) =>
            authStore.updateUser({
              trading_address: { town_city: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
        <TextInput
          mb={'sm'}
          value={county ?? undefined}
          id='county-input'
          label='County'
          placeholder='Cambridgeshire'
          onChange={(e) =>
            authStore.updateUser({
              trading_address: { county: e.target.value },
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
      </Group>
      <Space h={'lg'} />
    </>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(OnboardingStep4B)
