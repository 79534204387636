import { observer } from 'mobx-react'
import {
  Text,
  Space,
  Group,
  Paper,
  Title,
  Stack,
  Checkbox,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { IconDiscountCheck } from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import SumsubWebSdk from '@sumsub/websdk-react'
import { sumsub } from '../../services/sumsub_service'
import countries2to3 from 'countries-list/minimal/countries.2to3.min.json'
import { getCountryCode } from 'countries-list'
import { useEffect, useState } from 'react'
import { analytics } from 'services/analytics_service'

function InviteStep2({
  email,
  accountId,
  memberId,
  role,
}: {
  email?: string
  accountId?: string
  memberId?: string
  role?: string
}) {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore: accountStore, memberStore } = useStores()
  const navigate = useNavigate()

  const type = accountStore?.user?.type
  const countryCode = getCountryCode(
    accountStore.user?.registered_address?.country ?? ''
  )
  const countryCodeAlpha3 =
    countryCode !== false ? countries2to3[countryCode] : null

  const [verificationToken, setVerificationToken] = useState<string | null>(
    null
  )

  useEffect(() => {
    const fetchVerificationToken = async () => {
      const token = await accountStore.getVerificationToken()
      if (token) setVerificationToken(token)
    }

    // Run once immediately when the component is mounted
    fetchVerificationToken()

    // Set up the interval to run the function repeatedly
    const intervalId: ReturnType<typeof setInterval> = setInterval(
      fetchVerificationToken,
      accountStore.sumsubVerificationTtl * 1000 - 6000
    )

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, [])

  function sumsubPortal() {
    if (!verificationToken) return <></>

    return (
      <SumsubWebSdk
        accessToken={verificationToken}
        expirationHandler={async () => {
          const token = await accountStore.getVerificationToken()
          if (token) setVerificationToken(token)
        }}
        config={{
          lang: 'en',
          country: countryCodeAlpha3,
          email: accountStore.user?.email,
        }}
        // options={options}
        onMessage={(type: any, payload: any) => {
          try {
            if (payload.reviewStatus === 'completed') {
              if (payload.reviewResult.reviewAnswer == 'GREEN') {
                accountStore.updateUser({
                  uid: accountStore.user?.uid,
                  verified: 'approved',
                })
                memberStore.updateMember({ member_id: memberId, verified: 'approved' })
                sumsub.getApplicantData(accountId!, memberId!)
              } else {
                accountStore.updateUser({ uid: accountStore.user?.uid, verified: 'rejected' })
                memberStore.updateMember({ member_id: memberId, verified: 'rejected' })
              }
            }
          } catch (e) {
            analytics.error(e)
          }
          console.log('WebSDK onMessage', type, payload)
        }}
        onError={async (error: any) => {
          // if (error.code === 'initialization-error') {
          //   await authStore.getVerificationToken()
          // }
          console.error('WebSDK onError', error)
        }}
      />
    )
  }

  const [isOwner, setIsOwner] = useState(type === 'organisation' ? true : false)

  return (
    <Paper ml={'20px'}>
      <Space h={'xs'} />
      <Group>
        <IconDiscountCheck size={32} />
        <Title order={2}>{`Verify your identity`}</Title>
      </Group>
      {isOwner ? (
        <>
          {accountStore.user?.verified === 'approved' ? (
            <Paper radius={'md'} withBorder p={'lg'} mt={'xl'}>
              <Stack align='center'>
                <IconDiscountCheck color={theme.colors.green[4]} size={40} />
                <Title c={theme.colors.green[4]} order={4}>
                  {' '}
                  You've been verified{' '}
                </Title>
                <Text c={'dimmed'}>
                  {' '}
                  Click on Launch Garfield to finish the onboarding{' '}
                </Text>
              </Stack>
            </Paper>
          ) : (
            sumsubPortal()
          )}
        </>
      ) : (
        <>
          <Paper radius={'md'} withBorder p={'lg'} mt={'xl'}>
            <Stack align='center'>
              <Title c={theme.colors.black[4]} order={4}>
                Only an owner of the business can be verified
              </Title>
              <Checkbox
                checked={isOwner}
                onChange={(event) => {
                  setIsOwner(event.currentTarget.checked)
                }}
                label={
                  <Text c={theme.colors.gray[7]} size='sm' ta='start'>
                    I am an owner
                  </Text>
                }
              />
            </Stack>
          </Paper>
        </>
      )}
    </Paper>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(InviteStep2)
