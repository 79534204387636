// settlement.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const BaseDocumentDetails = types
  .model('BaseDocumentDetails', {
    received_date: types.maybeNull(FirestoreTimestamp),
    sent_date: types.maybeNull(FirestoreTimestamp),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export default BaseDocumentDetails
export interface IBaseDocumentDetails
  extends Instance<typeof BaseDocumentDetails> {}
