// document_sent_dates.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'


const DocumentSentDates = types
  .model('DocumentSentDates', {
    lba_sent_date: types.maybeNull(FirestoreTimestamp),
    claim_form_sent_date: types.maybeNull(FirestoreTimestamp),
    directions_questionnaire_sent_date: types.maybeNull(FirestoreTimestamp),
    court_bundle_sent_date: types.maybeNull(FirestoreTimestamp),
    skeleton_arguments_sent_date: types.maybeNull(FirestoreTimestamp),
    request_for_judgment_sent_date: types.maybeNull(FirestoreTimestamp),
    notice_of_issue_sent_date: types.maybeNull(FirestoreTimestamp),
  })
  .actions((self) => ({
    update(updatedDocument: Partial<IDocumentSentDates>) {
      Object.assign(self, updatedDocument)
    },
  }))

export default DocumentSentDates
export interface IDocumentSentDates extends Instance<typeof DocumentSentDates> {}
