import { useNavigate } from 'react-router-dom'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import {
  Button,
  Text,
  AppShell,
  Group,
  useMantineTheme,
  Burger,
  Drawer,
  Stack,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { MobileFooterContent } from '../navigation/web_footer'
import { GarfieldLogoText } from '../shared/garfield_logo'
import { useDisclosure } from '@mantine/hooks'

const MobileHeader = () => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false)

  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { authStore } = useStores()

  return (
    <AppShell.Header
      hidden={authStore.isAuthenticated}
      style={{ borderBottom: `1px solid ${theme.colors.gray[2]}` }}
    >
      <Group h='100%' px='md' py={0} justify='space-between'>
        <GarfieldLogoText
          size={'sm'}
          variant={'dark'}
          onClick={() => navigate('/')}
          type='full'
        />
        <Burger opened={drawerOpened} onClick={toggleDrawer} size='sm' />
      </Group>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size='100%'
        padding='md'
        title={
          <GarfieldLogoText
            size={'sm'}
            variant={'dark'}
            onClick={() => {
              navigate('/')
              closeDrawer()
            }}
            type='full'
          />
        }
        zIndex={1000000}
      >
        <Stack>
          <Button
            variant='filled'
            color={theme.colors.black[5]}
            onClick={() => {
              navigate('/signup')
              closeDrawer()
            }}
          >
            <Text size='sm'>Sign up</Text>
          </Button>
          <Button
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => {
              navigate('/#about')
              closeDrawer()
            }}
          >
            <Text size='sm'>About</Text>
          </Button>
          <Button
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => {
              navigate('/#faq')
              closeDrawer()
            }}
          >
            <Text size='sm'>Frequently Asked Questions</Text>
          </Button>
          <Button
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => {
              navigate('/#pricing')
              closeDrawer()
            }}
          >
            <Text size='sm'>Pricing</Text>
          </Button>
          {/* <Button
          variant='default'
          color={theme.colors.black[5]}
          onClick={() => { navigate('/signin'); closeDrawer(); }}
        >
          <Text size='sm'>Sign in</Text>
        </Button> */}
          {/* <Button
          variant='filled'
          color={theme.colors.black[5]}
          onClick={() => { navigate('/signup'); closeDrawer(); }}
        >
          <Text size='sm'>Sign up</Text>
        </Button> */}
          <MobileFooterContent closeDrawer={closeDrawer} />
        </Stack>
      </Drawer>
    </AppShell.Header>
  )
}

function WebHeader() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { authStore } = useStores()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}`)

  if (isMobile) {
    return <MobileHeader />
  }

  return (
    <AppShell.Header
      hidden={authStore.isOnboarded}
      style={{ borderBottom: `1px solid ${theme.colors.gray[2]}` }}
    >
      <Group h='100%' px='lg' py={0}>
        <GarfieldLogoText
          size={'md'}
          variant={'dark'}
          onClick={() => navigate('/')}
          type='full'
        />
        <AppShell.Section grow></AppShell.Section>
        <Button
          m={0}
          px='xl'
          radius={0}
          size='sm'
          h='100%'
          justify='start'
          variant='subtle'
          color={theme.colors.gray[7]}
          onClick={() => navigate('/#about')}
        >
          <Text size='sm'>About Us</Text>
        </Button>
        <Button
          m={0}
          px='xl'
          radius={0}
          size='sm'
          h='100%'
          justify='start'
          variant='subtle'
          color={theme.colors.gray[7]}
          onClick={() => navigate('/#pricing')}
        >
          <Text size='sm'>Pricing</Text>
        </Button>
        <Button
          m={0}
          px='xl'
          radius={0}
          size='sm'
          h='100%'
          justify='start'
          variant='subtle'
          color={theme.colors.gray[7]}
          onClick={() => navigate('/#faq')}
        >
          <Text size='sm'>FAQs</Text>
        </Button>

        <AppShell.Section grow></AppShell.Section>

        <Group h='100%'>
          <Button
            size='sm'
            justify='start'
            variant='default'
            color={theme.colors.black[5]}
            onClick={() => navigate('/signin')}
            data-testid="signin-button"
          >
            <Text size='sm'>Sign in</Text>
          </Button>

          <Button
            size='sm'
            justify='start'
            variant='filled'
            color={theme.colors.black[5]}
            onClick={() => navigate('/signup')}
          >
            <Text size='sm'>Sign up</Text>
          </Button>
        </Group>
      </Group>
    </AppShell.Header>
  )
}

export default observer(WebHeader)

