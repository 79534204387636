import {
  Instance,
  SnapshotOut,
  isAlive,
  types,
} from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import { IUser } from '../models/auth/user'
import { firebase } from '../services/firebase_service'
import { print } from '../utils/print'
import Document, { IDocument, DocumentType } from '../models/document/document'
import { analytics } from '../services/analytics_service'
import { Timestamp } from 'firebase/firestore'
import SettlementDetails, {
  ISettlementDetails,
} from '../models/document/settlement_details'
import { Attachment } from '../models/document/attachment'

// The Document Store handles documents for all claims (though it's mostly used for one claim at a time)
export const DocumentStoreModel = types
  .model('DocumentStore')
  .props({
    documents: types.optional(types.array(Document), []),
    archiveDocumentId: types.maybeNull(types.string),
    settlementDocumentId: types.maybeNull(types.string),
    sidebarDocumentId: types.maybeNull(types.string),
  })
  .volatile((store) => ({
    unsubscribeFromDocumentsFunction: () => {},
  }))
  // Views are read-only derived data of the state tree
  .views((store) => ({
    // Documents for the current claim
    get currentDocuments() {
      const currentClaimId: string =
        getRootStore(store).claimStore.currentClaimId!
      return store.documents.filter((document) =>
        document.claim_id?.includes(currentClaimId)
      )
    },

    get garfieldLetterheadDocument() {
      return (
        store.documents.find(
          (document) => document.document_id === 'garfield_letterhead'
        ) ?? null
      )
    },
    get customLetterheadDocument() {
      return (
        store.documents.find(
          (document) => document.document_id === 'custom_letterhead'
        ) ?? null
      )
    },

    // Settlement documents for the current claim
    get currentSettlements() {
      const currentClaimId: string =
        getRootStore(store).claimStore.currentClaimId!

      return store.documents
        .filter(
          (document) =>
            document.claim_id?.includes(currentClaimId) &&
            document.type === 'settlement'
        )
        .sort((a, b) => b.last_updated_date - a.last_updated_date)
    },

    get selectedSidebarDocument() {
      return (
        store.documents.find(
          (document) => document.document_id === store.sidebarDocumentId
        ) ?? null
      )
    },

    // Returns document for the selected settlement
    get selectedSettlementDocument() {
      return (
        store.documents.find(
          (document) => document.document_id === store.settlementDocumentId
        ) ?? null
      )
    },

    // Returns document for the selected document in the Documents Tab
    get selectedArchiveDocument() {
      return (
        store.documents.find(
          (document) => document.document_id === store.archiveDocumentId
        ) ?? null
      )
    },

    get calculationsDocument(): IDocument | null {
      const currentClaim = getRootStore(store).claimStore.currentClaim
      if (!currentClaim) return null

      const calculationsDocuments = store.documents
        .filter(
          (document) =>
            document.claim_id?.includes(currentClaim.claim_id!) &&
            document.type === 'calculations'
        )
        .sort((a, b) => b.last_updated_date - a.last_updated_date)

      if (calculationsDocuments.length > 0) {
        return calculationsDocuments[0]
      } else {
        return null
      }
    },

    get readyToSendDocument() {
      const currentClaim = getRootStore(store).claimStore.currentClaim
      const documentsToSend: string[] = currentClaim?.state?.documents_to_send!
      if (!documentsToSend || documentsToSend.length === 0) return null
      const readyToSendDocument = store.documents.find(
        (document) => document.document_id === documentsToSend[0]
      )
      return readyToSendDocument || null
    },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    // Generates or uploads a settlement document
    async createSettlementDocument(document: Partial<IDocument>, file?: File) {
      if (!document.document_id) return
      const currentClaimId: string =
        getRootStore(store).claimStore.currentClaimId!
      const account: IUser = getRootStore(store).user
      const extension = file ? file.name.split('.').pop() : 'pdf'
      const settlementDetails = document.details as ISettlementDetails
      const settlementDocument = Document.create({
        document_id: document.document_id,
        claim_id: currentClaimId,
        account_id: account.account_id,
        name: `settlement_${document.document_id}`,
        type: DocumentType.claimant_settlement,
        creator: document.creator!,
        last_updated_date: Timestamp.fromDate(new Date()),
        prefix: `users/${account?.account_id}/claims/${currentClaimId}/documents/${document.document_id}.${extension}`,
        details: SettlementDetails.create({
          type: settlementDetails.type,
          amount: settlementDetails?.amount,
          deadline_date: settlementDetails.deadline_date,
          reference_document_id: settlementDetails.reference_document_id,
        }),
        mime_type: document.mime_type,
        extension: document.extension,
      })
      this.updateDocumentFromLocalState(settlementDocument)
      if (document.creator === 'user') {
        if (file)
          await this.uploadDocument({
            claimId: currentClaimId!,
            file: file,
            document: settlementDocument,
          })
        analytics.track('Uploaded settlement letter')
      } else {
        analytics.track('Generated settlement letter')
      }
      await firebase.createDocument(account, settlementDocument)
      return settlementDocument.document_id
    },

    // Sets the selected document ID for the Documents Tab or the Settelement Tab
    selectDocument(tab: string, documentId: string | null) {
      switch (tab) {
        case 'archive':
          store.archiveDocumentId = documentId
          break
        case 'settlement':
          store.settlementDocumentId = documentId
          break
      }
    },

    setArchiveDocumentId(documentId: string) {
      store.archiveDocumentId = documentId
    },

    selectSidebarDocument(documentId: string) {
      const navStore = getRootStore(store).navStore
      const docbarCollapsed = navStore.docbarCollapsed
      if (docbarCollapsed) {
        navStore.openDocbar()
      }
      if (documentId !== store.readyToSendDocument?.document_id) {
        store.sidebarDocumentId = documentId
        navStore.setDocbarTab('selected')
      } else {
        navStore.setDocbarTab('ready')
      }
    },

    removeSideBarDocument() {
      const navStore = getRootStore(store).navStore
      if (store.readyToSendDocument) {
        navStore.setDocbarTab('ready')
      } else {
        console.log('CLOSE DOCBAR FROM DOCUMENT STORE')
        navStore.closeDocbar()
      }
      store.sidebarDocumentId = null
    },

    // Returns the Firebase Storage public URL for a document
    async getDocumentUrl(documentId: string): Promise<string | null> {
      try {
        const documentIndex = this.documentIndexForId(documentId)
        const currentClaimId: string | null =
          getRootStore(store).claimStore.currentClaimId
        const accountId: string = getRootStore(store).user.account_id
        if (
          documentIndex === null ||
          documentIndex === undefined ||
          !currentClaimId ||
          !accountId
        ) {
          return null
        }
        const document = store.documents[documentIndex]
        if (!document || !isAlive(document)) {
          return null
        }
        if (document.prefix && document.document_id) {
          const url = await firebase.getDocumentUrl(
            document.document_id,
            accountId,
            currentClaimId
          )
          if (url.length > 1 && isAlive(document)) {
            document.changeUrl(url)
            return url
          }
        } else if (document.url) {
          return document.url
        }
        return null
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
        return null
      }
    },

    // Uploads one attached document
    async uploadDocument(attachment: Attachment) {
      const account = getRootStore(store).user
      await firebase.uploadDocument(
        account,
        attachment.claimId,
        attachment.file,
        attachment.document
      )
    },

    // Uploads multiple attached documents
    async uploadDocuments(attachments: Attachment[]) {
      print('UPLOADING DOCUMENTS:', attachments)
      const account = getRootStore(store).user
      await firebase.uploadDocuments(account, attachments)
    },

    // Returns the documents attached to a specific message
    // documentsForMessage(attachmentIds: string[]) {
    //   return attachmentIds.map((attachmentId) =>
    //     store.documents.find((document) => document.document_id === attachmentId)
    //   );
    // },
    documentsForMessage(messageId: string) {
      return store.documents.filter(
        (document) => document.message_id === messageId
      )
    },

    // Returns all documents attached to all currently loaded messages
    documentsForLoadedMessages(): IDocument[] {
      const messageIds = getRootStore(store).dialogueStore.messages.map(
        (message) => message.message_id
      )
      return store.documents.filter(
        (document) =>
          document.message_id && messageIds.includes(document.message_id)
      )
    },

    // Returns document for specific claim ID
    documentsForClaim(claimId: string) {
      return store.documents.filter((document) => document.claim_id === claimId)
    },

    // Returns document for specific document ID
    documentForId(documentId: string): IDocument | null {
      const document =
        store.documents.find(
          (document) => document.document_id === documentId
        ) ?? null
      return document
    },

    // Returns the index of a specific document in the documents array
    documentIndexForId(documentId: string): number | null {
      const index = store.documents.findIndex(
        (document) => document.document_id === documentId
      )
      return index !== -1 ? index : null
    },

    // Returns False if document does not exist or its index if it does exist
    getDocIndex(documentId: string) {
      const documentIndex = store.documents.findIndex(
        (document) => document.document_id === documentId
      )
      return documentIndex
    },

    // Calls the document update method if it exists, adds new document if it doesn't
    updateDocumentFromLocalState(document: IDocument) {
      const documentExists = this.getDocIndex(document.document_id!)
      if (documentExists > -1) {
        store.documents[documentExists].update(document)
      } else {
        store.documents.push(document)
      }
    },

    // Deletes document from Firestore
    deleteDocument(document: IDocument) {
      const documentExists = this.getDocIndex(document.document_id!)
      if (documentExists > -1) {
        const account = getRootStore(store).user
        firebase.deleteDocument(account, document)
      }
    },

    async updateDocument(document: IDocument) {
      const documentIndex = this.getDocIndex(document.document_id!)
      if (documentIndex > -1) {
        const account = getRootStore(store).user
        await firebase.updateDocument(account, document)
      }
    },

    async setDocumentPaymentProcessing(document: IDocument) {
      document.payment_processing = true
      await this.updateDocument(document)
    },

    async handleDocumentUpdateAndSelect(
      document: IDocument,
      tab: 'archive' | 'settlement'
    ) {
      try {
        // Attempt to update the document
        await this.updateDocument(document)
        // If updateDocument succeeds, then hide the document
        this.selectDocument(tab, null)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Removes the document only from the documents array, not from Firestore
    removeDocumentFromLocalState(documentId: string) {
      const documentExists = this.getDocIndex(documentId)
      if (documentExists > -1) {
        store.documents.splice(documentExists, 1)
      }
    },

    // Replaces documents in the documents array
    setDocuments(documents: IDocument[]) {
      store.documents.clear()
      documents.forEach((document) => {
        store.documents.push(document)
      })
    },

    // Clears the documents array
    clearDocuments() {
      store.documents.clear()
    },

    // Triggers with any chang`es to the documents collection in Firestore and updates local array
    async subscribeToDocumentsForClaim(claimId: string) {
      const account = getRootStore(store).user
      const receivedDocumentIds = new Set()
      store.unsubscribeFromDocumentsFunction = firebase.subscribeToDocuments(
        account.account_id,
        claimId,
        (snapshot) => {
          try {
            snapshot.forEach((doc) => {
              const document = doc.data() as IDocument
              receivedDocumentIds.add(document.document_id!)
              if (document.document_id)
                this.updateDocumentFromLocalState(document)
            })
          } catch (error) {
            analytics.error(error)
          }
        }
      )
    },

    // Unsubscribes from documents collection in Firestore (called when changing claims)
    unsubscribeFromDocuments() {
      if (store.unsubscribeFromDocumentsFunction) {
        store.unsubscribeFromDocumentsFunction()
        // Reset the unsubscribe function to a no-op after calling it
        store.unsubscribeFromDocumentsFunction = () => {}
      }
    },
  }))

export interface DocumentStore extends Instance<typeof DocumentStoreModel> {}
export interface DocumentStoreSnapshot
  extends SnapshotOut<typeof DocumentStoreModel> {}
