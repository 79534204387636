import { IClaim } from '../../models/claim/claim'
import {
  useMantineTheme,
  Group,
  Text,
  Stack,
  Button,
  List,
  FileButton,
  UnstyledButton,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import { useRef } from 'react'
import Document, { IDocument } from '../../models/document/document'


import SettlementDetails from '../../models/document/settlement_details'
import { useContext } from 'react'
import { PageContext } from './settlement_navbar'
import SettlementCard from './settlement_card'
import { firebase } from '../../services/firebase_service'

export interface SettlementProps {
  claim: IClaim
}

function SettlementLetters({ claim }: SettlementProps) {
  const { documentStore, authStore: accountStore, claimStore } = useStores()
  const theme = useMantineTheme()
  const resetRef = useRef<() => void>(null)
  const { setPage, setIsEditing } = useContext(PageContext)

  const settlements: IDocument[] = documentStore.currentSettlements
  const usedIds = new Set<string>()
  const uploadDebtorLetter = async (files: File[]) => {
    for (const file of files) {
      const documentId = await firebase.getUniqueIdForCollection(
        [
          'users',
          accountStore.user?.account_id ?? '',
          'claims',
          claimStore.currentClaimId ?? '',
          'documents',
        ],
        '.pdf',
        usedIds
      )
      usedIds.add(documentId)

      const partialDocument = Document.create({
        document_id: documentId,
        creator: 'user',
        details: SettlementDetails.create({
          type: null,
          amount: null,
          deadline_date: null,
          reference_document_id: null,
        }),
        mime_type: 'application/pdf',
        extension: 'pdf',
      })
      await documentStore.createSettlementDocument(partialDocument, file)
    }
  }

  return (
    <Stack gap={0}>
      <Text size='lg' fw={500}>
        Settlement Letters
      </Text>
      <Text mt={'sm'} size='sm' fw={300} c={theme.colors.gray[6]}>
        Every dispute, no matter how apparently straightforward, inherently
        carries risk. It is therefore always advisable to attempt settlement. If
        you can settle your claim, you will avoid risk, save time and also you
        should get paid faster.
      </Text>
      <Group mt={'xl'} preventGrowOverflow={false} align='center' gap={'sm'}>
        <Button
          variant='filled'
          color={theme.colors.black[5]}
          size='sm'
          onClick={() => {
            setPage('letterEdit')
            setIsEditing(false)
          }}
          maw={170}
        >
          Create new letter
        </Button>
        <Text size='sm' fw={400} c={theme.colors.gray[6]}>
          or
        </Text>
        <FileButton
          resetRef={resetRef}
          onChange={uploadDebtorLetter}
          accept='application/pdf'
          multiple
        >
          {(props) => (
            <UnstyledButton
              variant='outline'
              color={theme.colors.gray[3]}
              size='sm'
              maw={170}
              fw={500}
              {...props}
            >
              <Text fw={700} size='sm' c={theme.colors.gray[7]}>
                <Text span fw={600}>
                  {' '}
                  upload debtor letters
                </Text>
              </Text>
            </UnstyledButton>
          )}
        </FileButton>
      </Group>

      <Text mt={'xl'} size='sm' fw={600}>
        History
      </Text>
      <List mt={'xs'}>
        {settlements
          .sort((a, b) => b.last_updated_date - a.last_updated_date)
          .map((settlement) => {
            return <SettlementCard settlement={settlement} />
          })}
      </List>
    </Stack>
  )
}

export default observer(SettlementLetters)
