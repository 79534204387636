import { useState } from 'react'
import {
  type MRT_Row,
  type MRT_TableInstance, //if using TypeScript (optional, but recommended)
} from 'mantine-react-table'
import { observer } from 'mobx-react'
import { useStores } from '../../utils/use_stores'
import {
  Group,
  Menu,
  Text,
  ActionIcon,
  useMantineTheme,
  rem,
  useCombobox,
  Combobox,
  CheckIcon,
} from '@mantine/core'
import {
  IconPlayerPause,
  IconPlayerPlay,
  IconRefresh,
  IconUserEdit,
  IconDotsVertical,
  IconX,
  IconPlaylistAdd,
} from '@tabler/icons-react'
import { ROLES, STATUS, MemberRow } from '../../models/auth/member'
import { modal } from '../../utils/modal'
import * as changeCase from 'change-case'
import ClaimSelectionBox from './claim_selection_box'

function TeamMemberOptions({
  row,
  table,
}: {
  row: MRT_Row<MemberRow>
  table: MRT_TableInstance<MemberRow>
}) {
  const theme = useMantineTheme()
  const [rowActionsOpened, setRowActionsOpened] = useState(false)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const { memberStore } = useStores()

  const member = memberStore.getMember(row.id)

  const options = Object.values(ROLES).map((role) => {
    if (role !== 'owner') {
      return (
        <Combobox.Option
          value={role}
          key={role}
          active={role === member?.role!}
        >
          <Group preventGrowOverflow gap='xs'>
            {role === member?.role! && (
              <CheckIcon color={theme.colors.gray[4]} size={12} />
            )}
            <span>{changeCase.capitalCase(role)}</span>
          </Group>
        </Combobox.Option>
      )
    }
  })

  return (
    <Menu
      opened={rowActionsOpened}
      //   onChange={setRowActionsOpened}
      closeOnClickOutside
      shadow='md'
      width={200}
    >
      <Menu.Target>
        <ActionIcon
          onClick={() => setRowActionsOpened(!rowActionsOpened)}
          variant='subtle'
        >
          <IconDotsVertical
            style={{ width: '70%', height: '70%' }}
            stroke={1.5}
          />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Member options</Menu.Label>
        <Menu.Item
          leftSection={
            <IconPlaylistAdd style={{ width: rem(14), height: rem(14) }} />
          }
        >
          <ClaimSelectionBox
            memberId={row.id}
            type='member'
            target={(toggleDropdown) => (
              <Text
                size='sm'
                onClick={(event) => {
                  toggleDropdown()
                  event.stopPropagation()
                }}
              >
                Assign claims
              </Text>
            )}
          />
          {/* <Combobox
            store={combobox}
            width={150}
            offset={40}
            position='left-start'
            onOptionSubmit={async (val) => {
              await memberStore.updateMember({
                member_id: member?.member_id,
                role: ROLES[val as keyof typeof ROLES],
              })
              combobox.closeDropdown()
            }}
          >
            <Combobox.Target>
              <Text
                size='sm'
                onClick={(event) => {
                  combobox.toggleDropdown()
                  event.stopPropagation()
                }}
              >
                Change role
              </Text>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>{options}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox> */}
        </Menu.Item>
        <Menu.Item
          disabled={member?.role === ROLES.owner}
          leftSection={
            <IconUserEdit style={{ width: rem(14), height: rem(14) }} />
          }
        >
          <Combobox
            store={combobox}
            width={150}
            offset={40}
            position='left-start'
            disabled={member?.role === ROLES.owner}
            onOptionSubmit={async (val) => {
              await memberStore.updateMember({
                member_id: member?.member_id,
                role: ROLES[val as keyof typeof ROLES],
              })
              combobox.closeDropdown()
            }}
          >
            <Combobox.Target>
              <Text
                size='sm'
                c={member?.role === ROLES.owner ? 'dimmed' : 'inherit'}
                onClick={(event) => {
                  combobox.toggleDropdown()
                  event.stopPropagation()
                }}
              >
                Change role
              </Text>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>{options}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </Menu.Item>
        {member?.status === STATUS.pending ? (
          <Menu.Item
            leftSection={
              <IconRefresh style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => memberStore.resendInvite(member)}
          >
            Resend invite
          </Menu.Item>
        ) : null}
        <Menu.Divider></Menu.Divider>
        <Menu.Item
          disabled={member?.status === STATUS.pending}
          color={member?.status === STATUS.active ? 'orange' : 'green'}
          leftSection={
            member?.status === STATUS.active ? (
              <IconPlayerPause style={{ width: rem(14), height: rem(14) }} />
            ) : (
              <IconPlayerPlay style={{ width: rem(14), height: rem(14) }} />
            )
          }
          onClick={async () => {
            await memberStore.updateMember({
              member_id: member?.member_id,
              status:
                member?.status === STATUS.active
                  ? STATUS.inactive
                  : STATUS.active,
            })
          }}
        >
          {member?.status === STATUS.active ? 'Disable' : 'Enable'}
        </Menu.Item>
        <Menu.Item
          disabled={member?.role === ROLES.owner}
          color={'red'}
          leftSection={<IconX style={{ width: rem(14), height: rem(14) }} />}
          onClick={() =>
            modal.open(
              '',
              '',
              'delete_members',
              async () => {},
              () => {},
              { memberIds: [member?.member_id] }
            )
          }
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default observer(TeamMemberOptions)
