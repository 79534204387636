//@ts-ignore
import { observer } from 'mobx-react'
import DetailsGrid from './details_grid'
import { IClaim } from '../../models/claim/claim'

export interface DetailsTabProps {
  claim: IClaim
}

function DetailsTab({ claim }: DetailsTabProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <DetailsGrid claim={claim} />
      </div>
    </div>
  )
}

export default observer(DetailsTab)
