import ProdConfig from './config.production'
import DevConfig from './config.development'
import EmulatorConfig from './config.emulator'
import TestConfig from './config.test'

const env = process.env.REACT_APP_ENV || 'development'
let EnvConfig = DevConfig

if (env === 'production') {
  EnvConfig = ProdConfig
} else if (env === 'emulator') {
  EnvConfig = EmulatorConfig
} else if (env === 'test') {
  EnvConfig = TestConfig
}

const Config = { ...EnvConfig }

export default Config
