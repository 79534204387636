import { createContext, useContext, useEffect, useState } from 'react'
import { RootStore, RootStoreModel } from '../stores/root_store'
import { setupRootStore } from './setup_root_store'

// Creates an empty root store to be hydrated by the useInitialRootStore function, called from app.tsx.
// If needed, you can pass initial state to this function
const _rootStore = RootStoreModel.create({})

// The actual context of the root store
const RootStoreContext = createContext<RootStore>(_rootStore)

// Can be used to create a different instance of the root store. Just for testing purposes (aka, don't touch this)
export const RootStoreProvider = RootStoreContext.Provider

// Hook for getting access to the stores from other components
export const useStores = () => useContext(RootStoreContext)

// Call this from app.tsx (or the root of the app) to initialize the root store
export const useInitialRootStore = (callback: () => void | Promise<void>) => {
  const rootStore = useStores()
  const [rehydrated, setRehydrated] = useState(false)

  // Kick off initial async loading actions, like loading fonts and rehydrating RootStore
  useEffect(() => {
    let _unsubscribe: undefined | (() => void)
    ;(async () => {
      // set up the RootStore (returns the state restored from AsyncStorage)
      const {  unsubscribe } = await setupRootStore(rootStore)
      _unsubscribe = unsubscribe

      // let the app know we've finished rehydrating
      setRehydrated(true)

      // invoke the callback, if provided
      if (callback) callback()
    })()

    return () => {
      // cleanup
      if (_unsubscribe) _unsubscribe()
      _unsubscribe = undefined
    }
  }, [])

  return { rootStore, rehydrated }
}
