import { Text, Group, useMantineTheme, Card } from '@mantine/core'
import { observer } from 'mobx-react'
import { IconId, IconTimeline } from '@tabler/icons-react'
import { IClaim } from '../../models/claim/claim'

export interface ClaimIdSectionProps {
  claim?: IClaim
}

function ClaimIdSection({ claim }: ClaimIdSectionProps) {
  const theme = useMantineTheme()

  return process.env.REACT_APP_ENV === "emulator" || process.env.REACT_APP_ENV === "development" ? (
    <Card withBorder={false} radius='md' miw={150}>
      <Card.Section withBorder={false} inheritPadding py='xs'>
        <Group justify='flex-start' gap={'xs'}>
          <IconId color={theme.colors.black[6]} size={18} stroke={2}></IconId>
          <Text c={theme.colors.black[8]} fw={500} size='sm'>
            CLAIM ID:
          </Text>
          <Text c={theme.colors.gray[5]} size='md'>
            #{claim?.claim_id}
          </Text>
        </Group>
        <Group justify='flex-start' gap={'xs'} pt={'xs'}>
          <IconTimeline
            color={theme.colors.black[6]}
            size={18}
            stroke={2}
          ></IconTimeline>
          <Text c={theme.colors.black[8]} fw={500} size='sm'>
            STATE:
          </Text>
          <Text c={theme.colors.gray[5]} size='sm'>
            {claim?.state?.past_stages[claim.state.past_stages.length - 2]
              ?.stage || '...'}
          </Text>
          <Text c={theme.colors.gray[5]} size='xs'>
            {'-->'}
          </Text>
          <Text c={theme.colors.gray[5]} size='sm'>
            {claim?.state?.past_stages[claim.state.past_stages.length - 1]
              ?.stage || '...'}
          </Text>
          <Text c={theme.colors.gray[5]} size='xs'>
            {'-->'}
          </Text>
          <Text c={theme.colors.gray[5]} size='sm' fw={'bold'}>
            {claim?.state?.stage}
          </Text>
        </Group>
      </Card.Section>
    </Card>
  ) : null
}

export default observer(ClaimIdSection)
