import React from 'react'
import { Container, Center, Text, Divider, Anchor } from '@mantine/core'

function OpportunitiesPage() {
  return (
    <Container fluid p={0} m={0} style={{ height: '100%', padding: '0 16px' }}>
      <Center style={{ height: '100%' }}>
        <div style={{ maxWidth: '800px', width: '100%' }}>
          {' '}
          {/* Sets a max-width for content */}
          <h1 style={{ textAlign: 'center' }}>Commercial Opportunities</h1>
          <Divider style={{ margin: '20px 0' }} />
          <Text style={{ margin: '10px 0', textAlign: 'justify' }}>
            If you have a commercial opportunity for us, and this may include:
            (a) you are a potential substantial bulk user of Garfield and would
            like to discuss bulk user discounts; (b) you want to partner with,
            or invest in, Garfield; or (c) other, please email us at:
            <Anchor
              href='mailto:commercial_opportunities@garfield.co.uk'
              style={{ fontWeight: 'bold' }}
            >
              commercial_opportunities@garfield.co.uk
            </Anchor>
          </Text>
        </div>
      </Center>
    </Container>
  )
}

export default OpportunitiesPage
