import {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Card,
  Flex,
  useMantineTheme,
  Tooltip,
  Center,
  Loader,
  Text,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { IDocument } from '../../models/document/document'
import { observer } from 'mobx-react'
import { analytics } from 'services/analytics_service'


import { Document, Thumbnail } from 'react-pdf'
import { isAlive } from 'mobx-state-tree'

function AttachmentThumbnail({
  onClick,
  documentId,
  index,
}: {
  onClick: (document: IDocument) => {}
  documentId: string
  index: number
}) {
  const theme = useMantineTheme()
  const { documentStore } = useStores()
  const documentIndex = documentStore.documentIndexForId(documentId)
  const document =
    documentIndex !== null && documentIndex !== undefined
      ? documentStore.documents[documentIndex]
      : null

  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState<string | null>(document?.url || null)

  const loadAttachment = useCallback(async () => {
    setLoading(true)
    if (document && isAlive(document)) {
      if (document && document.document_id) {
        try {
          const documentUrl = await documentStore.getDocumentUrl(
            document.document_id
          )
          if (!documentUrl) return
          setUrl(documentUrl)
        } catch (error) {
          analytics.error(error)
        }
      }
    }
    setLoading(false)
  }, [document, documentStore])

  useEffect(() => {
    loadAttachment()
  }, [document?.document_id, loadAttachment])

  const getAttachment = (
    document: IDocument,
    url: string,
    mimeType: string | null
  ) => {
    if (!url) return null
    if (mimeType === 'application/pdf' || /\.pdf$/i.test(document.name!)) {
      return (
        <Document
          file={url}
          noData={<Flex h={100} align={'center'} justify={'center'}></Flex>}
          loading={
            <Flex h={100} align={'center'} justify={'center'}>
              <Center>
                <Loader color={theme.colors.gray[6]} size={'sm'} />
              </Center>
            </Flex>
          }
        >
          <Thumbnail pageNumber={1} height={100} />
        </Document>
      )
    } else if (
      (mimeType === 'image/jpeg' || mimeType === 'image/png') &&
      !/\.pdf$/i.test(document.name!)
    ) {
      return (
        <img
          src={url}
          alt={document?.name || 'image'}
          style={{
            height: '100px',
            width: '100px',
            objectFit: 'cover',
          }}
        />
      )
    }
    return null
  }

  if (!document) {
    return null
  }

  if (!document) {
    return null
  }

  return (
    <Tooltip key={index} label={document.name}>
      <Card
        onClick={() => onClick(document!)}
        withBorder
        bg={theme.colors.gray[2]}
        p={2}
        mih={120}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '120px',
          width:
            document.mime_type === 'application/pdf'
              ? '80px'
              : document.mime_type === 'image/jpeg' ||
                  document.mime_type === 'image/png'
                ? '100px'
                : '80px',
        }}
      >
        {getAttachment(document, url || '', document.mime_type)}
        <Text
          size='xs'
          fw={600}
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        >
          {document.name}
        </Text>
      </Card>
    </Tooltip>
  )
}

export default observer(AttachmentThumbnail)
