

import {
  useMantineTheme,
  Badge,
} from '@mantine/core'
import { useHover } from '@mantine/hooks'


import { observer } from 'mobx-react'


import { ROLES } from '../../models/auth/member'

function RoleBadge({ role }: { role: string }) {
  const { hovered, ref } = useHover()
  const theme = useMantineTheme()

  // let icon: ReactNode
  let color: string
  let roleString: string

  switch (role) {
    case ROLES.owner:
      // icon = <IconUserHexagon style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[9]
      roleString = 'Owner'
      break
    case ROLES.team_admin:
      // icon = <IconUserPentagon style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[8]
      roleString = 'Team Admin'
      break
    case ROLES.claim_admin:
      // icon = <IconUserPentagon style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[8]
      roleString = 'Claim Admin'
      break
    case ROLES.claim_editor:
      // icon = <IconUserSquare style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[7]
      roleString = 'Claim Editor'
      break
    default:
      // icon = <IconHelpHexagon style={{ width: rem(15), height: rem(15) }} />
      color = theme.colors.gray[4]
      roleString = 'N/A'
  }

  return (
    <Badge color={color} size='sm'>
      {roleString}
    </Badge>
  )
}

export default observer(RoleBadge)
