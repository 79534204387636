import { observer } from 'mobx-react'
import {
  Center,
  Flex,
  Box,
  Text,
  Space,
  Group,
  Paper,
  Title,
  UnstyledButton,
  Card,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import {
  IconBriefcase2,
  IconBuildingSkyscraper,
  IconUsers,
} from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'

function OnboardingStep2() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { claimStore, authStore } = useStores()

  const navigate = useNavigate()

  const accountType = authStore?.user?.type

  return (
    <Paper ml={'20px'}>
      <Space h={'xs'} />
      <Group>
        <IconUsers size={32}></IconUsers>
        <Title order={2}>Choose your account type</Title>
        <Space h={'sm'} />
        <Text>Are you an individual or part of a team?</Text>
      </Group>
      <Space h={'lg'} />
      <UnstyledButton
        onClick={() =>
          authStore.updateUser({
            type: 'individual',
            has_trading_address: false,
            uid: authStore.user?.uid,
          })
        }
      >
        <Card
          withBorder={accountType === 'individual'}
          style={{ borderWidth: 1, borderColor: theme.colors.gray[5] }}
        >
          <Flex>
            <Box h={'100%'}>
              <Center>
                <IconBriefcase2
                  size={28}
                  color={
                    accountType === 'individual'
                      ? theme.colors.black[5]
                      : theme.colors.gray[5]
                  }
                />
              </Center>
            </Box>
            <Space w={'lg'}></Space>
            <Box>
              <Title
                order={4}
                c={
                  accountType === 'individual'
                    ? theme.colors.black[5]
                    : theme.colors.gray[5]
                }
              >
                Create a single user account
              </Title>
              <Text
                fz='sm'
                c={
                  accountType === 'individual'
                    ? theme.colors.black[5]
                    : theme.colors.gray[5]
                }
              >
                Choose this option if you are a sole trader or a small business
                owner. Only one user per account.
              </Text>
            </Box>
          </Flex>
        </Card>
      </UnstyledButton>
      <Space h={'lg'} />
      <UnstyledButton
        onClick={() =>
          authStore.updateUser({
            type: 'organisation',
            has_trading_address: true,
            uid: authStore.user?.uid,
          })
        }
      >
        <Card
          withBorder={accountType === 'organisation'}
          style={{ borderWidth: 1, borderColor: theme.colors.gray[5] }}
        >
          <Flex>
            <Box h={'100%'}>
              <Center>
                <IconBuildingSkyscraper
                  size={28}
                  color={
                    accountType === 'organisation'
                      ? theme.colors.black[5]
                      : theme.colors.gray[5]
                  }
                />
              </Center>
            </Box>
            <Space w={'lg'}></Space>
            <Box>
              <Title
                c={
                  accountType === 'organisation'
                    ? theme.colors.black[5]
                    : theme.colors.gray[5]
                }
                order={4}
              >
                Create an organisation account
              </Title>
              <Text
                c={
                  accountType === 'organisation'
                    ? theme.colors.black[5]
                    : theme.colors.gray[5]
                }
                fz='sm'
              >
                Choose this option if you are representing a larger company or
                team. You will be able to invite your team members later.
              </Text>
            </Box>
          </Flex>
        </Card>
      </UnstyledButton>
    </Paper>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(OnboardingStep2)
