import React, { useState } from 'react'
import {
  Grid,
  Box,
  Collapse,
  Text,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import faqData from '../assets/json/faq.json'

interface FAQItemProps {
  question: string
  answer: string
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false)

  return (
    <Grid justify='center' style={{ padding: '0 15%' }}>
      <Grid.Col span={4}>
        <Box
          style={{
            background: 'black',
            borderRadius: '10px',
            padding: '10px',
            height: '60px',
          }}
        >
          <Text c='white'>{question}</Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={1}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60px',
            width: '50px',
            borderRadius: '10px',
            background: 'black',
          }}
        >
          <IconPlus
            color='white'
            onClick={() => setOpen((o) => !o)}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      </Grid.Col>
      <Grid.Col span={6}>
        <Collapse in={open}>
          <Box
            style={{
              background: 'white',
              borderRadius: '10px',
              height: '120px',
              padding: '10px',
            }}
          >
            <Text c='black'>{answer}</Text>
          </Box>
        </Collapse>
      </Grid.Col>
    </Grid>
  )
}

const FaqPage: React.FC = () => {
  // I have saved the raw Q&A for the FAQ in a json in the frontend for the time being
  // I am not sure if this is the correct approach or whether we should put it in the backend.
  // I will let Pablo be the judge of this!

  return (
    <>
      <br></br>
      <Text size='xl' ta='center' style={{ margin: '20px 0' }}>
        You have questions. We have answers
      </Text>
      <br></br>
      <div>
        {faqData.faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </>
  )
}

export default FaqPage
