import {
  getStripePayments,
  StripePayments,
  createCheckoutSession,
} from '@invertase/firestore-stripe-payments'
import Product, { IProduct } from '../models/billing/product'
import Price from '../models/billing/price'
import { FirebaseApp } from 'firebase/app'
import { firebaseApp, functions, db, firebase } from './firebase_service'
import { httpsCallable } from 'firebase/functions'
import {
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import Config from '../config'
import { analytics } from 'services/analytics_service'

// const app = getApp()
// const payments = getStripePayments(app, {
//   productsCollection: '/products',
//   customersCollection: '/users',
// })

const stripePayments = (firebaseApp: FirebaseApp): StripePayments =>
  getStripePayments(firebaseApp, {
    productsCollection: 'products',
    customersCollection: 'users',
  })

class StripeService {
  async getProducts(): Promise<IProduct[]> {
    if (Config.ENV === 'emulator') return []

    let products: IProduct[] = []
    try {
      const productsRef = collection(db, 'products')
      const productsSnapshot = await getDocs(productsRef)
      products = await Promise.all(
        productsSnapshot.docs
        .filter(doc => doc.data().active)
        .map(async (doc) => {
          const pricesRef = collection(doc.ref, 'prices')
          const pricesSnapshot = await getDocs(pricesRef)
          const prices = pricesSnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }))
          const product = {
            id: doc.id,
            active: true,
            name: doc.data().name,
            prices: prices.map((price: any) => Price.create(price)),
            ...doc.data(),
          }
          return Product.create(product)
        })
      )
    } catch (e) {
      analytics.error(e)
    }

    return products
  }

  async subscribeToSubscriptions(
    accountId: string,
    callback: (snapshot: QuerySnapshot) => void
  ) {
    if (Config.ENV === 'emulator') return

    // try {
    //   const payments = stripePayments(firebaseApp)
    //   console.log('Subscribing to subscriptions 3')
    //   onCurrentUserSubscriptionUpdate(payments, (snapshot) => {
    //     console.log('Subscribing to subscriptions 4')
    //     callback(snapshot)
    //   })
    // } catch (e) {
    //   analytics.error(e)
    // }

    try {
      const subscriptionsRef = collection(
        db,
        'users',
        accountId,
        'subscriptions'
      )
      onSnapshot(subscriptionsRef, (snapshot) => {
        callback(snapshot)
      })
    } catch (e) {
      analytics.error(e)
    }
  }

  subscribeToInvoices(
    accountId: string,
    subscriptionId: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    const documentsRef = collection(
      db,
      'users',
      accountId,
      'subscriptions',
      subscriptionId,
      'invoices'
    )
    const unsubscribe = onSnapshot(documentsRef, (snapshot) => {
      callback(snapshot)
    })

    return unsubscribe
  }

  subscribeToMultipleInvoices(
    accountId: string,
    subscriptionIds: string[],
    callback: (
      subscriptionId: string,
      snapshot: QuerySnapshot<DocumentData>
    ) => void
  ): () => void {
    const unsubscribes = subscriptionIds.map((subscriptionId) => {
      return this.subscribeToInvoices(accountId, subscriptionId, (snapshot) => {
        callback(subscriptionId, snapshot)
      })
    })

    // Return a function that unsubscribes from all
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe())
  }

  // async createCheckoutSession(
  //   priceId: string,
  //   mode: 'subscription' | 'payment',
  //   metadata?: any
  // ): Promise<string> {
  //   if (Config.ENV === 'emulator') return ''
  //   console.log('Creating checkout session')
  //   const payments = stripePayments(firebaseApp)
  //   const session = await createCheckoutSession(payments, {
  //     price: priceId,
  //     mode: mode,
  //     metadata: metadata
  //   })
  //   return session.url
  // }
  

  async createCheckoutSession(accountId: string, priceId: string, mode: 'subscription' | 'payment', metadata?: any): Promise<string> {
    if (Config.ENV === 'emulator') return ''
      
    const checkoutSessionsRef = collection(db, 'users', accountId, 'checkout_sessions')

    console.log('Creating checkout session')
    console.log('metadata:', metadata)
    
    const sessionData = {
      client: "web",
      price: priceId,
      mode: mode,
      success_url: window.location.href,
      ...(metadata && { metadata })
    }
  
    const docRef = await addDoc(checkoutSessionsRef, sessionData)
  
  
    // Wait for the 'url' field to be added
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        const data = docSnapshot.data();
        if (data && data.url && data.sessionId) {
          unsubscribe(); // Stop listening for changes
          resolve(data.url);
        }
      }, (error) => {
        unsubscribe(); // Stop listening on error
        reject(error);
      });
  
      // Set a timeout in case the 'url' field never appears
      setTimeout(() => {
        unsubscribe();
        reject(new Error('Timeout waiting for checkout session URL'));
      }, 30000); // 30 seconds timeout
    });
  }

  async createPortalSession(): Promise<string> {
    if (Config.ENV === 'emulator') return ''
    const functionRef = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink'
    )
    const { data } = await functionRef({
      returnUrl: window.location.origin + '/settings',
      locale: 'auto', // Optional, defaults to "auto"
    })
    //@ts-ignore
    return data.url
  }
}



// async function addSessionDoc(
//   uid: string,
//   params: PriceIdSessionCreateParams,
// ): Promise<DocumentReference> {
//   console.log('UID:', uid)
//   const sessions: CollectionReference = collection(
//       firestore,
//       stripePayments.customersCollection,
//       uid,
//       'checkout_sessions',
//   )
//   console.log('Sessions:', sessions)
//   try {
//       return await addDoc(sessions, params)
//   } catch (err) {
//       throw new StripePaymentsError(
//           'internal',
//           'Error while querying Firestore.',
//           err,
//       )
//   }
// }

// async function createCheckoutSessionDb(
//   uid: string,
//   params: PriceIdSessionCreateParams,
//   timeoutMillis: number,
// ): Promise<Session> {
//   const doc: DocumentReference = await addSessionDoc(uid, params)
//   return waitForSessionId(doc, timeoutMillis)
// }


// export async function createCheckoutSession(
//   uid: string,
//   params: PriceIdSessionCreateParams,
//   options?: CreateCheckoutSessionOptions,
// ): Promise<Session> {
//   params = {...params}
//   checkAndUpdateCommonParams(params)
//   if (hasLineItems(params)) {
//       checkLineItemParams(params)
//   } else {
//       checkPriceIdParams(params)
//   }

//   const timeoutMillis: number = getTimeoutMillis(options?.timeoutMillis)
//   return createCheckoutSessionDb(uid, params, timeoutMillis)
// }



// Export a singleton instance in the global namespace
export const stripe = new StripeService()