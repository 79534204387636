
class DuplicateBankAccountError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'DuplicateBankAccountError';
    }
  }

export { DuplicateBankAccountError }

