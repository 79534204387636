import { Instance, types } from 'mobx-state-tree'
import { ROLES } from './member'

// Model of the user data
const Invite = types
  .model('Invite', {
    member_id: types.identifier,
    email: types.maybeNull(types.string),
    role: types.optional(
      types.enumeration('role', Object.values(ROLES)),
      ROLES.claim_editor
    ),
    claim_ids: types.maybeNull(types.array(types.string)),
  })
  .views((self) => ({
    get validEmail() {
      return self.email && self.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    },
    get validRole() {
      return self.role && Object.values(ROLES).includes(self.role)
    },
    get inviteUrl() {
      const url = new URL(window.location.origin)
      url.pathname = '/invite'
      url.searchParams.set('id', self.member_id)
      return url.toString()
    },
  }))
  .actions((self) => ({}))

export default Invite
export interface IInvite extends Instance<typeof Invite> {}
