// Merged notice_of_issue_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import Address from './address'

const NoticeOfIssueDetails = types
  .model('NoticeOfIssueDetails', {
    issue_date: types.maybeNull(FirestoreTimestamp),
    served_date: types.maybeNull(FirestoreTimestamp),
    defendant_reply_deadline_date: types.maybeNull(FirestoreTimestamp),
    court_address: types.maybeNull(Address),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<INoticeOfIssueDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default NoticeOfIssueDetails
export interface INoticeOfIssueDetails
  extends Instance<typeof NoticeOfIssueDetails> {}
