import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const ClaimFormDetails = types
  .model('ClaimFormDetails', {
    response_date: types.maybeNull(FirestoreTimestamp),
    response_type: types.maybeNull(
      types.enumeration([
        'no_response',
        'payment',
        'admission',
        'admission_and_defence',
        'defence',
        'defence_and_counterclaim',
        'admission_and_defence_and_counterclaim',
      ])
    ),
  })
  .actions((self) => ({
    // Actions from the old model can be added here if necessary, ensuring field names match the merged model.
  }))

export default ClaimFormDetails
export interface IClaimFormDetails extends Instance<typeof ClaimFormDetails> {}
