import { useViewportSize } from '@mantine/hooks'
import {
  Drawer,
  Button,
  Text,
  Group,
  useMantineTheme,
  ScrollArea,
  Card,
  Stack,
  Flex,
  rem,
  Avatar,
  Image,
  TextInput,
  SegmentedControl,
  Select,
  Combobox,
  InputBase,
  Container,
  Checkbox,
  Tooltip,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { useStores } from 'utils/use_stores'
import DocumentReader from '../shared/document_reader'
import {
  IconBuildingBank,
  IconCurrencyPound,
  IconFileText,
  IconSignature,
  IconTextCaption,
} from '@tabler/icons-react'
import { firebase } from '../../services/firebase_service'
import { useEffect, useState } from 'react'
import { modal } from '../../utils/modal'
import { IDocument, DocumentType } from '../../models/document/document'
import BankAccountSelectionBox from './bank_account_selection_box'
import LetterheadSelectionBox from './letterhead_selection_box'
import Config from 'config'
import { analytics } from 'services/analytics_service'


function ReviewSendDrawer() {
  const theme = useMantineTheme()
  const { navStore, documentStore, memberStore, billingStore, authStore } = useStores()
  const { height, width } = useViewportSize()
  const readyToSendDocument = documentStore.readyToSendDocument
  const drawerWidth = width >= 750 ? 750 : width * 0.96
  const readerWidth = drawerWidth / 2
  const ownerName = memberStore.getOwner()?.name

  const [consentToSign, setConsentToSign] = useState(false);
  const [signature, setSignature] = useState<string | null>(null)
  const [signatureLoading, setSignatureLoading] = useState<boolean>(true)
  const [reviewAndSendButtonDisabled, setReviewAndSendButtonDisabled] =
    useState<boolean>(false)
  const [regeneratingSignature, setRegeneratingSignature] =
    useState<boolean>(false)

  const signaturePrefix = authStore.currentSignature

  const regeneratingCriteraMet = (
    !readyToSendDocument?.signature_prefix_used ||
    readyToSendDocument?.signature_prefix_used !== signaturePrefix ||
    readyToSendDocument?.bank_account_id_used !== readyToSendDocument?.wanted_bank_account_id ||
    readyToSendDocument?.letterhead_type_used !== authStore.currentLetterheadType ||
    readyToSendDocument?.letterhead_prefix_used !== authStore.currentLetterheadPrefix ||
    readyToSendDocument?.letterhead_coordinates_used?.join(',') !== authStore.currentLetterheadCoordinates?.join(',')
  )

  const documentFee = readyToSendDocument?.type ? (billingStore.getPriceForDocumentType(readyToSendDocument?.type)?.unit_amount! / 100).toFixed(2) : "NAN"

  const openLetterheadModal = () => {
    modal.open(
      'Letterhead Selector',
      '',
      'letterhead',
      async () => {},
      () => {},
      {
        closeModal: () => modal.close(),
      }
    )
  }

  useEffect(() => {
    if (readyToSendDocument) {
      loadSignature()
    }
  }, [authStore.currentSignature])

  useEffect(() => {
    setConsentToSign(false)
    if (navStore.reviewDrawerOpen) {
      if (regeneratingCriteraMet) {
        loadSignature()
      }
    }
    }, [navStore.reviewDrawerOpen, readyToSendDocument?.wanted_bank_account_id,
      authStore.currentLetterheadType, authStore.currentLetterheadPrefix,
    authStore.currentLetterheadCoordinates?.join(',')])

  const loadSignature = async () => {
    if (!signaturePrefix) return
    setSignatureLoading(true)
    const url = await firebase.getStorageUrl(signaturePrefix)
    setSignatureLoading(false)
    if (!url) return
    setSignature(url)

    console.log('loadSignature')

    if (
      !regeneratingSignature &&
      !readyToSendDocument?.paid &&
      regeneratingCriteraMet
    ) {
      console.log('Regenerating signature or payment url conditions met')
      regenerateDocumentSignature(readyToSendDocument!, false)
    }
  }

  const openSignatureModal = () => {
    modal.open(
      'You reached the plan limits!',
      'You have reached your maximum number of claims. Please upgrade your subscription to create more claims.',
      'signature',
      async () => {
        // Upload signature
      }
    )
  }

  const regenerateDocumentSignature = async (
    document: IDocument,
    warmup: boolean = false
  ) => {
    if (!document) return
    if (warmup) {
      console.log('regenerateDocumentSignature: warmup=True')
    } else {
      setRegeneratingSignature(true)
      console.log('regenerateDocumentSignature: regenerating signature')
    }
    const body = {
      account_id: document.account_id,
      claim_id: document.claim_id,
      document_id: document.document_id,
      warmup: warmup,
    }

    const firebaseCall = firebase.callHttpFunction(
      Config.REGENERATE_SIGNATURE_URL,
      body
    )
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('Request timed out')), 20000)
    })

    try {
      console.log('regenerate_signature: waiting for response')
      await Promise.race([firebaseCall, timeoutPromise])
      console.log('regenerate_signature: response received')
    } catch (error) {
      analytics.error(error)
    } finally {
      console.log('Finally!')
      if (!warmup) {
        setRegeneratingSignature(false)
      }
    }
  }

  
  const payAndSendDocument = async (document: IDocument) => {
    console.log('payAndSendDocument')
    console.log(document)
    if (!document || !document.type) return    

    console.log('payAndSendDocument: getting price')
    const price = billingStore.getPriceForDocumentType(document.type)
    if (!price) return
    console.log('payAndSendDocument: price', price)

    const metadata = {
      account_id: document.account_id,
      claim_id: document.claim_id,
      document_id: document.document_id,
      send_document: true,
    }
    await documentStore.setDocumentPaymentProcessing(document)
    modal.loading(true)
    const sessionUrl = await billingStore.createCheckoutSession(price.id, 'payment', metadata)
    if (sessionUrl) window.location.assign(sessionUrl)
  }

  let confirmationText = 'Before signing this document, please satisfy yourself that the facts stated in it are true and accurate. If you do not think they are, or are uncertain or unhappy about any part, please ask Garfield to make appropriate changes before signing. You should only sign documents that you are comfortable are true and correct as otherwise you risk exposing yourself and/or your organisation to considerable risk.'

  if (readyToSendDocument?.type !== 'chaser' && readyToSendDocument?.type !== 'lba') {
    confirmationText += 'In the case of a document verified by a Statement of Truth, if the Court was later to determine that the facts within it are untrue, the Court could impose penalties on you and/or your organisation including a fine and possibly imprisonment.'
  }

  return (
    <Drawer
      size={drawerWidth}
      offset={12}
      radius='md'
      opened={navStore.reviewDrawerOpen}
      onClose={() => navStore.toggleReviewDrawer(false)}
      position='right'
      withCloseButton={false}
      px={0}
      mx={0}
      styles={{
        body: {
          padding: '0 0 0 0',
        },
      }}
    >
      <Group w={drawerWidth} preventGrowOverflow>
        <Card w={readerWidth}>
          <ScrollArea h={height - 112}>
            <DocumentReader
              selectedDocument={readyToSendDocument}
              menu={[]}
              visible={true}
            />
          </ScrollArea>
        </Card>

        <Card w={readerWidth - 40} pt={'xl'}>
          <Card.Section withBorder inheritPadding pt={'sm'}>
            <Group justify='space-between'>
              <Text fw={'bold'}>Review</Text>
            </Group>
          </Card.Section>

          <Card.Section withBorder>
            <ScrollArea py='xl' h={height - 190} pr={'md'}>
              <Group
                mb={'xl'}
                gap={'sm'}
                justify='flex-start'
                align='start'
                preventGrowOverflow
                style={{
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Avatar size={ width >= 750 ? 'lg' : 'sm'}>
                  <IconFileText
                    color={theme.colors.black[5]}
                    style={{ width: rem(26), height: rem(26) }}
                  />
                </Avatar>

                <Stack
                  gap={0}
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    overflow: 'hidden',
                  }}
                >
                  <Text size='sm' c={theme.colors.gray[6]}>
                    Document name
                  </Text>
                  <Text
                    maw={200}
                    size='md'
                    fw={600}
                    c={theme.colors.black[5]}
                    style={{
                      multiline: 'truncate',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {readyToSendDocument?.name}
                  </Text>
                </Stack>
              </Group>


              <Group
                mb={'xl'}
                gap={'sm'}
                justify='flex-start'
                align='start'
                preventGrowOverflow
                style={{
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Avatar size={ width >= 750 ? 'lg' : 'sm'}>
                  <IconTextCaption
                    color={theme.colors.black[5]}
                    style={{ width: rem(26), height: rem(26) }}
                  />
                </Avatar>
                <Stack
                  gap={0}
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    overflow: 'hidden',
                  }}
                >
                  <Text size='sm' c={theme.colors.gray[6]}>
                    Customize your document
                  </Text>
                  <Text
                    maw={200}
                    size='md'
                    fw={600}
                    c={theme.colors.black[5]}
                    style={{
                      multiline: 'truncate',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Letterhead
                  </Text>
                </Stack>
                <Flex gap={0} align='center' direction='row' w={"100%"}>
                  <Container w={70} />
                  <Card
                    shadow={'none'}
                    p={0}
                    w={"100%"}
                  >
                  <LetterheadSelectionBox 
                    document={readyToSendDocument}
                    offset={0}
                    width={250}
                    position='bottom'
                    onClick={ async (value: string) => {
                      if (!value) return
                      authStore.saveLetterheadType(value)
                    }}
                  />
                  </Card>
                </Flex>
                {authStore.currentLetterheadType === 'custom' && 
                <Button color={theme.colors.gray[6]} onClick={openLetterheadModal}>Letterhead Selector</Button>}
                
              </Group>

              
              <Group
                mb={'xl'}
                gap={'sm'}
                justify='flex-start'
                align='start'
                preventGrowOverflow
                style={{
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Avatar size={ width >= 750 ? 'lg' : 'sm'}>
                  <IconBuildingBank
                    color={theme.colors.black[5]}
                    style={{ width: rem(26), height: rem(26) }}
                  />
                </Avatar>

                <Stack
                  gap={0}
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    overflow: 'hidden',
                  }}
                >
                  <Text size='sm' c={theme.colors.gray[6]}>
                    Where should the debtor pay
                  </Text>
                  <Text
                    maw={200}
                    size='md'
                    fw={600}
                    c={theme.colors.black[5]}
                    style={{
                      multiline: 'truncate',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Bank account
                  </Text>
                </Stack>
                <Flex gap={0} align='center' direction='row' w={"100%"}>
                  <Container w={70} />
                  <Card
                    shadow={'none'}
                    p={0}
                    w={"100%"}
                  >

                    <BankAccountSelectionBox
                      document={readyToSendDocument}
                      offset={0}
                      width={250}
                      position='bottom'
                      onClick={ async (bankAccountId: string) => {
                        if (!bankAccountId || !readyToSendDocument) return
                        const updatedDocument: IDocument = {
                          ...readyToSendDocument,
                          wanted_bank_account_id: bankAccountId,
                        }
                        await documentStore.updateDocument(updatedDocument)
                        await authStore.updateUser({ uid: authStore.user?.uid, last_bank_account_used: bankAccountId })
                      }}
                    />
                  </Card>
                </Flex>
              </Group>


              <Group
                mb={'xl'}
                gap={'sm'}
                justify='flex-start'
                align='start'
                preventGrowOverflow
                style={{
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Avatar size={ width >= 750 ? 'lg' : 'sm'}>
                  <IconSignature
                    color={theme.colors.black[5]}
                    style={{ width: rem(26), height: rem(26) }}
                  />
                </Avatar>

                <Stack
                  gap={0}
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    overflow: 'hidden',
                  }}
                >
                  <Text size='sm' c={theme.colors.gray[6]}>
                    Add your signature
                  </Text>
                  <Text
                    maw={200}
                    size='md'
                    fw={600}
                    c={theme.colors.black[5]}
                    style={{
                      multiline: 'truncate',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ownerName}
                  </Text>
                </Stack>
                <Flex gap='md' align='center' direction='column'>
                  <Card
                    shadow={'none'}
                    ml={70}
                    p={0}
                    withBorder
                    mah={120}
                    style={{ minWidth: '20vh', maxWidth: '30vh' }}
                  >
                    {signature ? (
                      <Image
                        p={'xs'}
                        radius='md'
                        src={signature}
                        fit='contain'
                        mah={120}
                        maw={width / 2}
                        onClick={openSignatureModal}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Button
                        variant='subtle'
                        color={theme.colors.black[5]}
                        onClick={openSignatureModal}
                      >
                        Click to sign
                      </Button>
                    )}
                  </Card>
                </Flex>
              </Group>


              <Group
                mb={'xl'}
                gap={'sm'}
                justify='flex-start'
                align='start'
                preventGrowOverflow
                style={{
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Avatar size={ width >= 750 ? 'lg' : 'sm'}>
                  <IconCurrencyPound
                    color={theme.colors.black[5]}
                    style={{ width: rem(26), height: rem(26) }}
                  />
                </Avatar>

                <Stack
                  gap={0}
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    overflow: 'hidden',
                  }}
                >
                  <Text size='sm' c={theme.colors.gray[6]}>
                    Price to remove watermark
                  </Text>
                  <Text
                    maw={200}
                    size='xl'
                    fw={600}
                    c={theme.colors.black[5]}
                    style={{
                      multiline: 'truncate',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    £{documentFee}
                  </Text>
                </Stack>
              </Group>

              <Card withBorder mt={'xl'}>
              <Text size='xs' c={theme.colors.gray[6]} mb={'lg'}>
              {confirmationText}
              </Text>
              <Checkbox
                  size='xs'
                  defaultChecked
                  label="I consent to signing this document"
                  checked={consentToSign}
                  onChange={(event) => setConsentToSign(event.currentTarget.checked)}
                  disabled={regeneratingSignature || !readyToSendDocument?.signed}
                />
              </Card>

            </ScrollArea>

          </Card.Section>

          <Card.Section inheritPadding py='xs'>
            
            <Group justify='flex-start' px={0} preventGrowOverflow gap={'xs'}>
              <Button
                onClick={() => {
                  navStore.toggleReviewDrawer(false)
                }}
                size='sm'
                variant='subtle'
                color={theme.colors.black[5]}
              >
                Cancel
              </Button>

              <Button
                onClick={ async () => {
                  await payAndSendDocument(readyToSendDocument!)
                  navStore.toggleReviewDrawer(false)
                }}
                disabled={!consentToSign || reviewAndSendButtonDisabled || regeneratingSignature || !readyToSendDocument?.signed}
                size='sm'
                variant='filled'
                color={theme.colors.black[5]}
                // leftSection={
                //   <IconFileCertificate style={{ width: rem(22), height: rem(22) }} />
                // }
              >
                {regeneratingSignature ? 'Regenerating...' : 'Remove watermark'}
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </Group>
    </Drawer>
  )
}

export default observer(ReviewSendDrawer)
