import {
  Text,
  Button,
  useMantineTheme,
  AppShell,
  Group,
  Flex,
  Space,
  ScrollArea,
  Divider,
  Menu,
  rem,
  Tooltip,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconHelpOctagon,
  IconLayoutDashboard,
  IconLogin,
  IconPlus,
  IconSettings2,
  IconUsers,
  IconSortAscendingLetters,
  IconMessageSearch,
  IconCalendarSearch,
  IconArrowsDownUp,
  IconArrowsUpDown,
} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import { GarfieldLogoText } from '../shared/garfield_logo'
import ClaimButton from './claim_button'
import SidebarHandle from './sidebar_handle'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { sumsub } from '../../services/sumsub_service'
import UserButton from './user_button'
import { analytics } from '../../services/analytics_service'
import { modal } from '../../utils/modal'
import { useEffect, useState } from 'react'
import 'react-resizable/css/styles.css'

function NavBar() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const {
    authStore: accountStore,
    navStore,
    claimStore,
    memberStore,
  } = useStores()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints['sm']})`)
  const { height, width } = useViewportSize()

  const collapsed = navStore.navbarCollapsed

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(
    navStore.sidebarClaimSortOrder as 'asc' | 'desc'
  )
  const [sortBy, setSortBy] = useState<
    'alphabetical' | 'lastMessage' | 'createdDate'
  >(
    navStore.sidebarClaimSortBy as
      | 'alphabetical'
      | 'lastMessage'
      | 'createdDate'
  )

  useEffect(() => {
    navStore.setSidebarClaimSortOrder(sortOrder)
    navStore.setSidebarClaimSortBy(sortBy)
  }, [sortOrder, sortBy, navStore])

  const getSortedClaims = () => {
    if (!claimStore.claims) return []
    switch (sortBy) {
      case 'alphabetical':
        return claimStore.claimsSortedAlphabetically(sortOrder === 'asc')
      case 'lastMessage':
        return claimStore.claimsSortedByLastMessageDate(sortOrder === 'asc')
      case 'createdDate':
        return claimStore.claimsSortedByCreatedDate(sortOrder === 'asc')
      default:
        return claimStore.claims
    }
  }

  const handleSortBy = (
    newSortBy: 'alphabetical' | 'lastMessage' | 'createdDate'
  ) => {
    if (newSortBy !== sortBy) {
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
    setSortBy(newSortBy)
  }

  const sortedClaims = getSortedClaims()
  const numPinnedClaims = sortedClaims.filter(
    (claim) => claim.state?.pinned_date
  ).length
  const canPinClaims = numPinnedClaims < 3

  return (
    <>
      <SidebarHandle direction='left' />
      <AppShell.Navbar
        mr={'0'}
        h={height - 27}
        w={navStore.navbarWidth - 15}
        ml={'sm'}
        mt={'sm'}
        style={{
          transition: 'width 0.3s ease',
          borderRadius: 5,
        }}
        bg={theme.colors.black[5]}
        zIndex={98}
      >
        <AppShell.Section py={'md'} px={'md'}>
          <Group justify='space-between' gap='xl'>
            <GarfieldLogoText
              size={'sm'}
              variant={'light'}
              onClick={() => navigate('/')}
              type={collapsed ? 'mark' : 'full'}
            />
          </Group>
        </AppShell.Section>

        <AppShell.Section p='sm'>
          <Flex direction={'column'}>
            <Tooltip label={'Claims'} disabled={!collapsed} position='right' offset={20}>
              <Button
                size='sm'
                justify='start'
                variant={navStore.currentPath === '/claims' ? 'light' : 'subtle'}
                color={
                  navStore.currentPath === '/claims'
                    ? theme.colors.white[5]
                    : theme.colors.gray[5]
                }
                leftSection={<IconLayoutDashboard size={18} />}
                onClick={() => navigate('/claims')}
              >
                <Text size='sm'>Claims</Text>
              </Button>
            </Tooltip>

            
            <Space h='xs' />

            <Tooltip label={'Team'} disabled={!collapsed} position='right' offset={20}>
              <Button
                size='sm'
                justify='start'
                variant={navStore.currentPath === '/team' ? 'light' : 'subtle'}
                color={
                  navStore.currentPath === '/team'
                    ? theme.colors.white[5]
                    : theme.colors.gray[5]
                }
                leftSection={<IconUsers size={18} />}
                onClick={() => navigate('/team')}
              >
                <Text size='sm'>Team</Text>
              </Button>
            </Tooltip>


            
            <Space h='xs' />

            <Tooltip label={'Settings'} disabled={!collapsed} position='right' offset={20}>
            <Button
              size='sm'
              justify='start'
              variant={
                navStore.currentPath === '/settings' ? 'light' : 'subtle'
              }
              color={
                navStore.currentPath === '/settings'
                  ? theme.colors.white[5]
                  : theme.colors.gray[5]
              }
              leftSection={<IconSettings2 size={18} />}
              onClick={() => navigate('/settings')}
            >
              <Text size='sm'>Settings</Text>
            </Button>
            </Tooltip>


            
          </Flex>
        </AppShell.Section>

        <Divider mx={'sm'} color={theme.colors.gray[9]} />

        <AppShell.Section px='sm' pt={'sm'} pb={5}>
          {collapsed ? (
            <Flex direction={'column'}>
              <Tooltip label={'Add new claim'} disabled={!collapsed} position='right' offset={20}>
                <Button
                  size='sm'
                  justify='start'
                  variant='subtle'
                  color={theme.colors.white[5]}
                  leftSection={<IconPlus size={18} />}
                  onClick={async () => {
                    modal.loading(true)
                    const claimId = await claimStore.createClaim()
                    if (!claimId) {
                      return
                    }
                    claimStore.setCurrentClaim(claimId)
                    // await new Promise((resolve) => setTimeout(resolve, 2000))
                    const displayId = claimId.split('-')[0]
                    navigate(`/claims/${displayId}`)
                    modal.loading(false)
                  }}
                  disabled={!memberStore.canManageClaims}
                >
                  <Text size='sm'>New claim</Text>
                </Button>
            </Tooltip>
            </Flex>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ maxWidth: '120px' }}>
                <Tooltip
                  label={'Only Claim Admins can create new claims'}
                  disabled={memberStore.canManageClaims}
                >
                  <Button
                    size='sm'
                    justify='start'
                    variant='subtle'
                    // mih={30}
                    color={theme.colors.white[5]}
                    bg={
                      !memberStore.canManageClaims
                        ? theme.colors.black[5]
                        : undefined
                    }
                    leftSection={<IconPlus size={18} />}
                    onClick={async () => {
                      modal.loading(true)
                      const claimId = await claimStore.createClaim()
                      if (!claimId) {
                        return
                      }
                      claimStore.setCurrentClaim(claimId)
                      const displayId = claimId
                      navigate(`/claims/${displayId}`)
                      modal.loading(false)
                    }}
                    disabled={!memberStore.canManageClaims}
                  >
                    <Text size='sm'>New claim</Text>
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Menu>
                  <Menu.Target>
                    <Button
                      variant='subtle'
                      style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      {sortOrder === 'asc' ? (
                        <IconArrowsDownUp
                          size='1rem'
                          color={theme.colors.gray[6]}
                        />
                      ) : (
                        <IconArrowsUpDown
                          size='1rem'
                          color={theme.colors.gray[6]}
                        />
                      )}
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => handleSortBy('alphabetical')}
                      leftSection={
                        <IconSortAscendingLetters
                          size='1rem'
                          color={theme.colors.gray[6]}
                        />
                      }
                    >
                      Sort Alphabetically
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleSortBy('lastMessage')}
                      leftSection={
                        <IconMessageSearch
                          size='1rem'
                          color={theme.colors.gray[6]}
                        />
                      }
                    >
                      Sort by Last Message
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleSortBy('createdDate')}
                      leftSection={
                        <IconCalendarSearch
                          size='1rem'
                          color={theme.colors.gray[6]}
                        />
                      }
                    >
                      Sort by Created Date
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
          )}
        </AppShell.Section>

        <AppShell.Section
          maw={navStore.navbarWidth - 16}
          grow
          px='sm'
          pb='sm'
          component={ScrollArea}
          scrollbars={'y'}
          type='never'
        >
          {sortedClaims.map((claim, index) => {
            // if (collapsed) {
            //   return null
            // }
            return (
              <ClaimButton
                width={navStore.navbarWidth - 40}
                key={'claim-button-' + claim.claim_id}
                claim={claim}
                onClick={async () => {
                  await claimStore.setCurrentClaim(claim.claim_id!)
                  navigate(`/claims/${claim.claim_id!}`)
                }}
                canPin={canPinClaims}
                collapsed={collapsed}
              />
            )
          })}
        </AppShell.Section>

        <Divider mx={'sm'} color={theme.colors.gray[9]} />

        <AppShell.Section p={'xs'}>
          <Flex direction={'column'}>
            <Menu position='top-start' offset={15}>
              <Menu.Target>
                <UserButton
                  color={theme.colors.white[5]}
                  size='sm'
                  justify='space-between'
                  variant='subtle'
                  icon={
                    <IconDotsVertical
                      size='1rem'
                      color={theme.colors.gray[6]}
                    />
                  }
                  name={memberStore.currentName ?? ''}
                />
              </Menu.Target>
              <Menu.Dropdown variant='subtle'>
                <Menu.Item
                  onClick={() =>
                    sumsub.getApplicantData(
                      accountStore.user?.account_id!,
                      memberStore.currentMemberId!
                    )
                  }
                  leftSection={
                    <IconHelpOctagon
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Support
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    accountStore.signOut()
                  }}
                  leftSection={
                    <IconLogin style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Sign out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </AppShell.Section>
      </AppShell.Navbar>
    </>
  )
}

export default observer(NavBar)
