export default {
  ENV: 'development',

  FIREBASE_API_KEY: 'AIzaSyBY4H-hzgJdrh0HteQOXi6dOjTtVwtsuYg',
  FIREBASE_AUTH_DOMAIN: 'garfield-7e41d.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'garfield-7e41d',
  FIREBASE_STORAGE_BUCKET: 'garfield-7e41d.appspot.com',
  FIREBASE_SENDER_ID: '775589318270',
  FIREBASE_APP_ID: '1:775589318270:web:7c902bdf1cf39c94b8522f',
  FIREBASE_MEASUREMENT_ID: 'G-X1D6F21Q87',

  RECAPTCHA_KEY: '6Lf4slQnAAAAAIhL7l4laEcSlm1dkJnHgtK-oQiZ',
  APPCHECK_DEBUG_TOKEN: '0C16CB71-5FBF-414B-A2C1-93F6ABB93966',

  MIXPANEL_PROJECT_TOKEN: '6290481857e24aa268b862e4dfbcbcb7',
  SENTRY_DSN:
    'https://b15a9b17e19892e7b1f2a30d7f67a223@o4507017229434880.ingest.us.sentry.io/4507017230417920',

  SUMSUB_TOKEN_URL: 'https://get-sumsub-token-ne67pvdjga-nw.a.run.app',
  SUMSUB_DATA_URL: 'https://get-applicant-data-ne67pvdjga-nw.a.run.app',
  SPEECH_URL: 'https://text-to-speech-ne67pvdjga-nw.a.run.app',
  REGENERATE_SIGNATURE_URL:
    'https://regenerate-signature-ne67pvdjga-nw.a.run.app',
  ASSIGN_CUSTOM_CLAIMS_URL:
    'https://assign-custom-claims-ne67pvdjga-nw.a.run.app',
}
