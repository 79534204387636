import { useMemo, useState } from 'react'
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton, //if using TypeScript (optional, but recommended)
} from 'mantine-react-table'
import { observer } from 'mobx-react'
import { useStores } from '../../utils/use_stores'
import {
  Button,
  Group,
  Tooltip,
  Text,
  useMantineTheme,
  Container,
  Paper,
  Avatar,
  useCombobox,
} from '@mantine/core'
import {
  IconUserPlus,
} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import RoleBadge from './role_badge'
import StatusBadge from './status_badge'
import { ROLES, MemberRow } from '../../models/auth/member'
import { print } from '../../utils/print'
import { modal } from '../../utils/modal'
import TeamMemberOptions from './team_member_options'

function TeamTable({ data }: { data: any }) {
  const theme = useMantineTheme()
  const { memberStore } = useStores()
  const navigate = useNavigate()
  const { height, width } = useViewportSize()

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [rowActionsOpened, setRowActionsOpened] = useState(false)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const columns = useMemo<MRT_ColumnDef<MemberRow>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'User',
        enableSorting: false,
        enableColumnActions: false,
        // grow: true,
        Cell: ({ cell, row }) => {
          const name = cell.getValue() as string
          const role = memberStore.getMember(row.id)!.role ?? ROLES.claim_editor
          let color: string
          switch (role) {
            case ROLES.owner:
              color = theme.colors.gray[9]
              break
            case ROLES.team_admin:
              color = theme.colors.gray[8]
              break
            case ROLES.claim_editor:
              color = theme.colors.gray[7]
              break
            default:
              color = theme.colors.gray[4]
          }
          const initials = name
            .split(' ')
            .map((word) => word[0])
            .join('')
            .toUpperCase()
          return (
            <Group>
              <Avatar
                bg={color}
                color='white'
                size={30}
                //   p={2}
                radius='xl'
              >
                {name === 'Pending...' ? '' : initials}
              </Avatar>
              <Text
                fw={name === 'Pending...' ? 500 : 700}
                size='sm'
                c={
                  name === 'Pending...'
                    ? theme.colors.gray[5]
                    : theme.colors.black[5]
                }
              >
                {name}
              </Text>
            </Group>
          )
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false,
        enableColumnActions: false,
        grow: true,
        Cell: ({ cell }) => {
          return (
            <Text size='sm' c={theme.colors.black[5]}>
              {cell.getValue() as string}
            </Text>
          )
        },
      },
      {
        accessorKey: 'role',
        header: 'Role',
        enableSorting: false,
        enableColumnActions: false,
        grow: false,
        Cell: ({ cell }) => {
          return <RoleBadge role={cell.getValue() as string} />
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        enableColumnActions: false,
        grow: true,
        Cell: ({ cell }) => {
          return <StatusBadge status={cell.getValue() as string} />
        },
      },
      {
        accessorKey: 'dateJoined',
        header: 'Date Joined',
        enableSorting: false,
        enableColumnActions: false,
        grow: true,
        Cell: ({ cell }) => {
          if (cell.getValue() === 'N/A') {
            return (
              <Text size='sm' fw={600} c={theme.colors.gray[4]}>
                N/A
              </Text>
            )
          } else {
            return (
              <Text size='sm' c={theme.colors.black[5]}>
                {cell.getValue() as string}
              </Text>
            )
          }
        },
      },
    ],
    []
  )

  //pass table options to useMantineReactTable
  const table = useMantineReactTable({
    columns,
    data,
    enableStickyFooter: true,
    // enableStickyHeader: false,
    layoutMode: 'grid',
    initialState: {
      density: 'xs',
      pagination: { pageIndex: 0, pageSize: 100 },
    },

    // defaultColumn: { minSize: 40, maxSize: 1000, size: 180 },
    enableRowSelection: memberStore.canManageTeam,
    enableRowActions: memberStore.canManageTeam,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 1, //make actions column wider
        Cell: ({ row, table }) => {
          if (!memberStore.canManageTeam) {
            return
          }
          return <TeamMemberOptions row={row} table={table} />
        },
      },
    },
    positionActionsColumn: 'last',
    // renderRowActionMenuItems: ({ row }) => {
    //   if (!memberStore.canManageTeam) {
    //     return
    //   }

    //   const member = memberStore.getMember(row.id)

    //   const options = Object.values(ROLES).map((role) => {
    //     if (role !== 'owner') {
    //       return (
    //         <Combobox.Option
    //           value={role}
    //           key={role}
    //           active={role === member?.role!}
    //         >
    //           <Group preventGrowOverflow gap='xs'>
    //             {role === member?.role! && (
    //               <CheckIcon color={theme.colors.gray[4]} size={12} />
    //             )}
    //             <span>{changeCase.capitalCase(role)}</span>
    //           </Group>
    //         </Combobox.Option>
    //       )
    //     }
    //   })

    //   return [
    //     <Menu.Label>Member options</Menu.Label>,
    //     <Menu.Item
    //       leftSection={
    //         <IconUserEdit style={{ width: rem(14), height: rem(14) }} />
    //       }
    //     >
    //       <ClaimSelectionBox></ClaimSelectionBox>
    //       {/* <Combobox
    //           store={combobox}
    //           width={150}
    //           offset={40}
    //           position='left-start'
    //           onOptionSubmit={async (val) => {
    //             await memberStore.updateMember({
    //               member_id: member?.member_id,
    //               role: ROLES[val as keyof typeof ROLES],
    //             })
    //             combobox.closeDropdown()
    //           }}
    //         >
    //           <Combobox.Target>
    //             <Text
    //               size='sm'
    //               onClick={(event) => {
    //                 combobox.toggleDropdown()
    //                 event.stopPropagation()
    //               }}
    //             >
    //               Change role
    //             </Text>
    //           </Combobox.Target>

    //           <Combobox.Dropdown>
    //             <Combobox.Options>{options}</Combobox.Options>
    //           </Combobox.Dropdown>
    //         </Combobox> */}
    //     </Menu.Item>,
    //     <Menu.Item
    //       leftSection={
    //         <IconUserEdit style={{ width: rem(14), height: rem(14) }} />
    //       }
    //     >
    //       <Combobox
    //         store={combobox}
    //         width={150}
    //         offset={40}
    //         position='left-start'
    //         onOptionSubmit={async (val) => {
    //           await memberStore.updateMember({
    //             member_id: member?.member_id,
    //             role: ROLES[val as keyof typeof ROLES],
    //           })
    //           combobox.closeDropdown()
    //         }}
    //       >
    //         <Combobox.Target>
    //           <Text
    //             size='sm'
    //             onClick={(event) => {
    //               combobox.toggleDropdown()
    //               event.stopPropagation()
    //             }}
    //           >
    //             Change role
    //           </Text>
    //         </Combobox.Target>

    //         <Combobox.Dropdown>
    //           <Combobox.Options>{options}</Combobox.Options>
    //         </Combobox.Dropdown>
    //       </Combobox>
    //     </Menu.Item>,
    //     member?.status === STATUS.pending ? (
    //       <Menu.Item
    //         leftSection={
    //           <IconRefresh style={{ width: rem(14), height: rem(14) }} />
    //         }
    //         onClick={() => memberStore.resendInvite(member)}
    //       >
    //         Resend invite
    //       </Menu.Item>
    //     ) : null,
    //     <Menu.Divider></Menu.Divider>,
    //     <Menu.Item
    //       disabled={member?.status === STATUS.pending}
    //       color={member?.status === STATUS.active ? 'orange' : 'green'}
    //       leftSection={
    //         member?.status === STATUS.active ? (
    //           <IconPlayerPause style={{ width: rem(14), height: rem(14) }} />
    //         ) : (
    //           <IconPlayerPlay style={{ width: rem(14), height: rem(14) }} />
    //         )
    //       }
    //       onClick={async () => {
    //         await memberStore.updateMember({
    //           member_id: member?.member_id,
    //           status:
    //             member?.status === STATUS.active
    //               ? STATUS.inactive
    //               : STATUS.active,
    //         })
    //       }}
    //     >
    //       {member?.status === STATUS.active ? 'Disable' : 'Enable'}
    //     </Menu.Item>,
    //     <Menu.Item
    //       disabled={member?.role === ROLES.owner}
    //       color={'red'}
    //       leftSection={<IconX style={{ width: rem(14), height: rem(14) }} />}
    //       onClick={() =>
    //         modal.open(
    //           '',
    //           '',
    //           'delete_members',
    //           async () => {},
    //           () => {},
    //           { memberIds: [member?.member_id] }
    //         )
    //       }
    //     >
    //       Delete
    //     </Menu.Item>,
    //   ]
    // },
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    getRowId: (originalRow) => originalRow.userId,
    enableColumnOrdering: false,
    enableGlobalFilter: true,
    enableColumnResizing: false,
    mantinePaperProps: {
      withBorder: false,
      shadow: undefined,
      bg: 'transparent',
    },
    mantineSelectAllCheckboxProps: {
      color: theme.colors.blue[5],
      variant: 'outline',
      radius: 'xs',
      size: 'sm',
    },
    mantineTableContainerProps: { style: { maxHeight: height - 202 } },
    mantineTableBodyCellProps: {
      style: {
        flex: '0 0 auto',
      },
    },
    mantineTableHeadProps: { style: { position: 'sticky' } },
    mantineTableHeadRowProps: { style: { position: 'sticky' } },
    mantineSelectCheckboxProps: {
      color: theme.colors.blue[5],
      variant: 'outline',
      radius: 'xs',
      size: 'sm',
    },
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: false,
      withTableBorder: true,
      withRowBorders: false,
      bg: theme.colors.white[5],
      p: 'xs',
      styles: {
        table: {
          // tableLayout: 'fixed',
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: `0 0 0 0.2px ${theme.colors.gray[5]}`,
        },
      },
    },
    mantineColumnActionsButtonProps: {
      color: theme.colors.red[5],
      // bg: theme.colors.blue[5],
    },
    // mantineTableHeadProps: {
    //   fw: 500,
    // },
    mantineTableHeadCellProps: {
      fw: 600,
      fz: 'sm',
      c: theme.colors.gray[5],
      style: {
        flex: '0 0 auto',
      },
    },
    renderTopToolbar: ({ table }) => {
      const anySelected = Object.values(rowSelection).some(
        (value) => value === true
      )

      const memberIds = Object.keys(rowSelection)

      const deletableMemberIds = memberIds.filter(
        (memberId) => memberStore.getMember(memberId)?.role !== ROLES.owner
      )

      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('deactivating ' + row.getValue('name'))
        })
      }

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('activating ' + row.getValue('name'))
        })
      }

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('contact ' + row.getValue('name'))
        })
      }

      return (
        <Paper
          withBorder
          radius={'sm'}
          p='sm'
          bg={theme.colors.white[5]}
          mb={'md'}
        >
          <Group>
            <Tooltip
              label={'Only Team Admins can invite new users'}
              disabled={memberStore.canManageTeam}
            >
              <Button
                size='xs'
                justify='start'
                variant='filled'
                bg={
                  memberStore.canManageTeam
                    ? theme.colors.black[5]
                    : theme.colors.gray[2]
                }
                color={theme.colors.black[5]}
                leftSection={<IconUserPlus size={16} />}
                onClick={async () => {
                  modal.open('', '', 'invite_members', async () => {
                    print('Invite users')
                  })
                }}
                disabled={!memberStore.canManageTeam}
              >
                <Text size='sm'>Add new users</Text>
              </Button>
            </Tooltip>

            {/* <Button
              size='xs'
              justify='start'
              variant='filled'
              bg={theme.colors.gray[2]}
              color={theme.colors.black[5]}
              leftSection={<IconUserPlus size={16} />}
              onClick={async () => {
                await firebase.updateUserDocs()
              }}
            >
              <Text size='sm'>Update users</Text>
            </Button> */}

            {anySelected && deletableMemberIds.length > 0 ? (
              <Button
                size='xs'
                justify='start'
                variant='subtle'
                color={theme.colors.red[5]}
                onClick={() => {
                  if (deletableMemberIds.length > 0) {
                    modal.open(
                      '',
                      '',
                      'delete_members',
                      async () => {},
                      () => {},
                      { memberIds: deletableMemberIds }
                    )
                  }
                }}
              >
                <Text size='sm'>Delete users</Text>
              </Button>
            ) : null}
            <Container fluid />
            <MRT_GlobalFilterTextInput table={table} />
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Group>
        </Paper>
      )
    },
  })

  return <MantineReactTable table={table} />
}

export default observer(TeamTable)
