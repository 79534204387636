import { useMantineTheme, Stack, Paper } from '@mantine/core'
import { observer } from 'mobx-react'
import { useViewportSize } from '@mantine/hooks'
import { useStores } from '../utils/use_stores'
import NavHeader from '../components/navigation/nav_header'
import { ClaimRow } from '../models/claim/claim'
import ClaimsTable from '../components/claims_page/claims_table'

function ClaimsPage() {
  const theme = useMantineTheme()
  const { claimStore } = useStores()
  const { height, width } = useViewportSize()

  const data: ClaimRow[] = claimStore.claims.map((claim) => {
    return {
      name: claim.displayName,
      claimId: claim.claim_id!,
      displayId: claim.displayId,
      dateCreated: claim.state ? claim.state?.readableDateCreated : null,
      stage: claim.state?.currentStageDisplay ?? '',
      debtor:
        claim.defendants.length > 0
          ? claim.defendants[0].name ?? 'Unknown debtor'
          : 'Unknown debtor',
      summary: claim.details?.base_claim_details?.summary ?? 'No summary',
      amount: claim.state?.totals?.outstanding
        ? '£' + claim.state?.totals?.outstanding
        : 'N/A',
      action: claim.state?.documents_to_send
        ? claim.state.documents_to_send.length! > 0
        : false,
    }
  })

  return (
    <Stack ml={'xl'} mr={'md'}>
      <NavHeader />

      <Paper
        radius={'sm'}
        h={height - 70}
        p={0}
        bg={'transparent'}
        style={{ overflow: 'hidden' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: height - 70,
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            <ClaimsTable data={data} />
          </div>
        </div>
      </Paper>
    </Stack>
  )
}

export default observer(ClaimsPage)
