import React from 'react'
import {
  Group,
  Text,
  useMantineTheme,
} from '@mantine/core'

export interface LogoProps {
  type?: 'mark' | 'full'
  variant?: 'light' | 'dark'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  bg?: any
  style?: any
  onClick: () => void
}

export function GarfieldLogoText({
  type,
  size,
  variant,
  bg,
  style,
  onClick,
}: LogoProps) {
  const theme = useMantineTheme()

  const bgColor =
    variant === 'light' ? theme.colors.white[5] : theme.colors.black[5]
  const color =
    variant === 'light' ? theme.colors.black[5] : theme.colors.white[5]

  const sizeMap = {
    xs: { bgSize: '24px', iconSize: '12px', textSize: 'xs' },
    sm: { bgSize: '32px', iconSize: '16px', textSize: 'sm' },
    md: { bgSize: '40px', iconSize: '20px', textSize: 'md' },
    lg: { bgSize: '48px', iconSize: '24px', textSize: 'lg' },
    xl: { bgSize: '56px', iconSize: '28px', textSize: 'xl' },
  }

  const { bgSize, iconSize, textSize } = sizeMap[size || 'md']

  return (
    <Group onClick={onClick} style={{ cursor: 'pointer' }}>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          borderRadius: 8,
          background: bgColor,
          width: bgSize,
          height: bgSize,
        }}
      >
        <svg
          // width={size}
          // height={size}
          viewBox='0 0 1469 1469'
          xmlns='http://www.w3.org/2000/svg'
          style={{ height: iconSize, width: iconSize }}
        >
          <path
            d='M1469 734.5C1469 895.925 1415.82 1052.85 1317.69 1181.02C1219.55 1309.19 1081.93 1401.47 926.09 1443.57C770.254 1485.68 604.886 1475.28 455.556 1413.97C306.225 1352.67 181.249 1243.87 99.9506 1104.42C18.6525 964.957 -14.4396 802.601 5.79101 642.449C26.0216 482.297 98.448 333.269 211.872 218.409C325.296 103.548 473.4 29.2518 633.285 7.00723C793.17 -15.2374 955.93 15.8087 1096.4 95.3454L982.731 296.097C886.381 241.542 774.742 220.248 665.075 235.505C555.409 250.763 453.823 301.724 376.024 380.508C298.226 459.292 248.548 561.511 234.671 671.361C220.795 781.211 243.493 892.573 299.256 988.229C355.019 1083.88 440.742 1158.51 543.169 1200.56C645.597 1242.61 759.024 1249.74 865.914 1220.86C972.804 1191.98 1067.2 1128.69 1134.51 1040.78C1201.83 952.862 1238.3 845.223 1238.3 734.5H1469Z'
            fill={color}
          />
          <path
            d='M1058.62 743.265C1058.98 785.943 1050.93 828.274 1034.93 867.842C1018.93 907.409 995.292 943.437 965.368 973.869C935.445 1004.3 899.82 1028.54 860.529 1045.21C821.237 1061.87 779.048 1070.63 736.37 1070.99C693.691 1071.35 651.36 1063.3 611.793 1047.3C572.226 1031.3 536.198 1007.67 505.765 977.745C475.333 947.821 451.092 912.197 434.428 872.905C417.763 833.614 409.001 791.424 408.641 748.746L733.629 746.006L1058.62 743.265Z'
            fill={color}
          />
        </svg>
      </div>

      {type === 'full' ? (
        <Text size={textSize} c={bgColor} style={{ fontWeight: 'bold' }}>
          Garfield
        </Text>
      ) : null}
    </Group>
  )
}
