// contract_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import OralContractDetails from './oral_contract_details'

const ContractDetails = types
  .model('ContractDetails', {
    is_written: types.maybeNull(types.boolean),
    is_deed: types.maybeNull(types.boolean),
    no_vulnerable_persons: types.maybeNull(types.boolean),
    contract_date: types.maybeNull(FirestoreTimestamp),
    oral_contract_details: types.maybeNull(OralContractDetails),
  })
  .actions((self) => ({}))

export default ContractDetails
export interface IContractDetails extends Instance<typeof ContractDetails> {}
