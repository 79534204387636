import {
    Button,
    Card,
    useMantineTheme,
    Group,
    Text,
    ScrollArea,
    Select,
    Container,
    TextInput,
    rem,
    ActionIcon,
    SegmentedControl,
  } from '@mantine/core'
  import { useStores } from '../../utils/use_stores'
  import { IconPlus, IconX } from '@tabler/icons-react'
  import { useState } from 'react'
  import { modal } from '../../utils/modal'
  import { observer } from 'mobx-react'
  import { useViewportSize } from '@mantine/hooks'
  import { ROLES } from '../../models/auth/member'
  import * as changeCase from 'change-case'
  import ClaimSelectionBox from '../teams_page/claim_selection_box'
import BankAccount from 'models/billing/bank_account'
  
  const BankAccountModal = ({
    text,
    title,
  }: {
    text: string
    title: string
  }) => {
    const theme = useMantineTheme()
    const { billingStore } = useStores()
  
    const [accountType, setAccountType] = useState('business');
    const [sortCode, setSortCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [fullName, setFullName] = useState('');
  
    return (
      <Card miw={350} maw={350} p={'xs'} bg={'transparent'}>
        <Card.Section withBorder p={'xs'}>
          <Text size='xl' fw={600}>
            {'Add new bank account'}
          </Text>
        </Card.Section>
        <ScrollArea py={'md'} px={0} h={250}>
        <SegmentedControl
          value={accountType}
          onChange={setAccountType}
          data={[
            { label: 'Personal', value: 'personal' },
            { label: 'Business', value: 'business' }
          ]}
        />
        <TextInput
          placeholder="Sort code"
          type="number"
          value={sortCode}
          onChange={(event) => setSortCode(event.currentTarget.value)}
          mt="md"
          autoComplete="nope"
        />
        <TextInput
          placeholder="Account number"
          type="number"
          value={accountNumber}
          onChange={(event) => setAccountNumber(event.currentTarget.value)}
          mt="md"
          autoComplete="nope"
        />
        <TextInput
          placeholder="Full name on account"
          type="text"
          value={fullName}
          onChange={(event) => setFullName(event.currentTarget.value)}
          mt="md"
          autoComplete="nope"
        />
        </ScrollArea>
        <Card.Section p={'xs'} pt={'lg'}>
          <Group>
            <Container fluid />
            <Button maw={200} variant='subtle' onClick={() => modal.close()}>
              {'Cancel'}
            </Button>
            <Button
              maw={200}
              bg={theme.colors.black[5]}
              onClick={async () => {
                if (await billingStore.createBankAccount(fullName, accountType, sortCode, accountNumber)) {
                  modal.close()
                }
              }}
            >
              {'Add bank account'}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    )
  }
  
  export default observer(BankAccountModal)
