// counterclaim_details.ts
import { Instance, types } from 'mobx-state-tree'

const InvalidArgumentsDetails = types
  .model('InvalidArgumentsDetails', {
    is_damages_claim: types.maybeNull(types.boolean),
    is_fraud_claim: types.maybeNull(types.boolean),
    is_construction_contract: types.maybeNull(types.boolean),
    outside_england_and_wales: types.maybeNull(types.boolean),
    illegal_claim: types.maybeNull(types.boolean),
    is_not_debt_claim: types.maybeNull(types.boolean),
    arbitration_clause_present: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<IInvalidArgumentsDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default InvalidArgumentsDetails
export interface IInvalidArgumentsDetails
  extends Instance<typeof InvalidArgumentsDetails> {}
