import { Instance, types } from 'mobx-state-tree'
import Price from './price'
import { IKeyValueMap } from 'mobx'

// Model of the user data
const Product = types
  .model('Product', {
    id: types.identifier,
    active: types.boolean,
    description: types.maybeNull(types.string),
    images: types.array(types.string),
    name: types.string,
    role: types.maybeNull(types.string),
    prices: types.array(Price),
    metadata: types.maybeNull(
      types.frozen<IKeyValueMap<string | number | null>>()
    ),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export default Product
export interface IProduct extends Instance<typeof Product> {}
