import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const ChaserDetails = types.model('ChaserDetails', {
  sent_date: types.maybeNull(FirestoreTimestamp),
  response_date: types.maybeNull(FirestoreTimestamp),
  response_type: types.maybeNull(
    types.enumeration([
      'no_response',
      'non_meaningful',
      'stalling',
      'legitimate',
      'settlement_offer',
      'request_for_time_or_indulgence',
    ])
  ),
  response_decision: types.maybeNull(
    types.enumeration(['accept', 'accept_part', 'reject'])
  ),
})

export default ChaserDetails
export interface IChaserDetails extends Instance<typeof ChaserDetails> {}
