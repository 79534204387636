import { useNavigate } from 'react-router-dom'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import { Button, Text } from '@mantine/core'
import { AppShell, Group } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import { GarfieldLogoText } from '../shared/garfield_logo'
import { useMediaQuery } from '@mantine/hooks'
import { Stack } from '@mantine/core'

const footerLinks = [
  { label: 'Legal', path: '/legal' },
  { label: 'Privacy', path: '/privacy' },
  { label: 'Accessibility', path: '/accessibility' },
  { label: 'Terms', path: '/terms' },
  { label: 'Complaints', path: '/complaints' },
]

export const MobileFooterContent = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const navigate = useNavigate()
  const theme = useMantineTheme()

  return (
    <Stack gap="xs">
      {footerLinks.map((link) => (
        <Button
          key={link.path}
          variant="subtle"
          color={theme.colors.gray[7]}
          onClick={() => {
            navigate(link.path)
            closeDrawer()
          }}
        >
          <Text size="sm">{link.label}</Text>
        </Button>
      ))}
    </Stack>
  )
}

function WebFooter() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { authStore } = useStores()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  if (authStore.isAuthenticated) {
    return null
  }

  if (isMobile) {
    return <></>
  }

  return (
    <AppShell.Footer
      hidden={authStore.isOnboarded}
      style={{ borderTop: `1px solid ${theme.colors.gray[2]}` }}
    >
      <Group h='100%' px='lg' py={0}>
        <GarfieldLogoText
          size={'md'}
          variant={'dark'}
          onClick={() => navigate('/')}
          type='full'
        />
        <AppShell.Section grow></AppShell.Section>

        <AppShell.Section grow></AppShell.Section>

        <Group h='100%' gap={0}>
          <Button
            m={0}
            px='md'
            radius={0}
            size='sm'
            h='100%'
            justify='start'
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => navigate('/legal')}
          >
            <Text size='sm'>Legal</Text>
          </Button>

          <Button
            m={0}
            px='md'
            radius={0}
            size='sm'
            h='100%'
            justify='start'
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => navigate('/privacy')}
          >
            <Text size='sm'>Privacy</Text>
          </Button>

          <Button
            m={0}
            px='md'
            radius={0}
            size='sm'
            h='100%'
            justify='start'
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => navigate('/accessibility')}
          >
            <Text size='sm'>Accessibility</Text>
          </Button>

          <Button
            m={0}
            px='md'
            radius={0}
            size='sm'
            h='100%'
            justify='start'
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => navigate('/terms')}
          >
            <Text size='sm'>Terms</Text>
          </Button>

          <Button
            m={0}
            px='md'
            radius={0}
            size='sm'
            h='100%'
            justify='start'
            variant='subtle'
            color={theme.colors.gray[7]}
            onClick={() => navigate('/complaints')}
          >
            <Text size='sm'>Complaints</Text>
          </Button>
        </Group>
      </Group>
    </AppShell.Footer>
  )
}

export default observer(WebFooter)
