// address.ts
import { Instance, types } from 'mobx-state-tree'

const Address = types
  .model('Address', {
    first_line: types.maybeNull(types.string),
    second_line: types.maybeNull(types.string),
    town_city: types.maybeNull(types.string),
    county: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
  })
  .actions((self) => ({}))

export default Address
export interface IAddress extends Instance<typeof Address> {}
