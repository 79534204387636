// Merged message.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const Message = types
  .model('Message', {
    message_id: types.identifier,
    account_id: types.maybeNull(types.string),
    claim_id: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    highlighted_text: types.maybeNull(types.string),
    creator: types.maybeNull(types.enumeration(['user', 'garfield', 'email'])),
    created_date: types.maybeNull(FirestoreTimestamp),
    attachment_ids: types.optional(types.array(types.string), []),
    ignore: types.maybeNull(types.boolean),
    audio: types.maybeNull(types.string),
    timepoints: types.maybeNull(types.optional(types.array(types.number), [])),
    visible: types.maybeNull(types.boolean),
    email_decision: types.maybeNull(
      types.enumeration(['approved', 'rejected', 'undecided'])
    ),
    cost: types.maybeNull(types.number),
  })
  .views((self) => ({
    // View from the old model
    get createdDate(): Date | null {
      return self.created_date ? new Date(self.created_date) : null
    },
    get readableCreatedDate() {
      if (!self.created_date) return null
      const date = new Date(self.created_date * 1000)
      return date.toLocaleDateString('en-GB', {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  }))
  .actions((self) => ({
    // Action from the new model with a simpler update logic
    update(updatedMessage: Partial<IMessage>) {
      Object.keys(updatedMessage).forEach((key) => {
        if (key === 'attachment_ids' && Array.isArray(updatedMessage[key])) {
          self.attachment_ids.replace([
            ...self.attachment_ids,
            ...updatedMessage.attachment_ids!,
          ])
        } else if (key === 'timepoints' && Array.isArray(updatedMessage[key])) {
          if (self.timepoints) {
            self.timepoints.replace([
              ...self.timepoints,
              ...updatedMessage.timepoints!,
            ])
          } else {
            self.timepoints = updatedMessage.timepoints!
          }
        } else if (Object.hasOwnProperty.call(self, key)) {
          // @ts-ignore
          self[key] = updatedMessage[key]
        }
      })
    },

    getAttachmentIdx(documentId: string) {
      const existingAttachmentIndex = self.attachment_ids.findIndex(
        (attachmentId) => attachmentId === documentId
      )
      return existingAttachmentIndex
    },

    addAttachment(documentId: string) {
      const attachmentIdx = this.getAttachmentIdx(documentId)
      if (attachmentIdx === -1) {
        self.attachment_ids.replace([...self.attachment_ids, documentId])
      }
    },

    removeAttachment(documentId: string) {
      const attachmentIdx = this.getAttachmentIdx(documentId)
      if (attachmentIdx !== -1) {
        self.attachment_ids.splice(attachmentIdx, 1)
      }
    },
  }))

export default Message
export interface IMessage extends Instance<typeof Message> {}
