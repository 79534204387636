import { types } from 'mobx-state-tree'
import { Timestamp } from 'firebase/firestore' // Import Firestore Timestamp

// Custom type for Firestore timestamp
export const FirestoreTimestamp = types.custom({
  name: 'FirestoreTimestamp',
  fromSnapshot(value: any): Date | null {
    if (value instanceof Timestamp) {
      // Convert Firestore Timestamp to JavaScript Date object
      return value.toDate()
    } else if (
      value &&
      typeof value === 'object' &&
      'seconds' in value &&
      'nanoseconds' in value
    ) {
      // Convert plain object with seconds and nanoseconds to Firestore Timestamp, then to Date
      return new Timestamp(value.seconds, value.nanoseconds).toDate()
    } else if (value === null) {
      // Handle null values
      return null
    } else {
      // Pass through Date objects
      return value
    }
  },
  toSnapshot(value: any): any {
    if (value instanceof Date) {
      // Convert JavaScript Date object to Firestore Timestamp
      return Timestamp.fromDate(value)
    } else if (
      value &&
      typeof value === 'object' &&
      'seconds' in value &&
      'nanoseconds' in value
    ) {
      // Convert plain object with seconds and nanoseconds to Firestore Timestamp, then to Date
      return new Timestamp(value.seconds, value.nanoseconds)
    } else if (value === null) {
      // Handle null values
      return null
    } else {
      // Pass through Firestore Timestamps
      return value
    }
  },
  isTargetType(value: any): boolean {
    // Check if the value is a Date, a Firestore Timestamp, or null
    return value instanceof Date || value instanceof Timestamp || value === null
  },
  getValidationMessage(value: any): string {
    if (
      !(typeof value === 'object') &&
      !(value instanceof Date) &&
      !(value instanceof Timestamp) &&
      value !== null
    ) {
      return 'Value is neither a Date nor a Firestore Timestamp nor null'
    }
    return ''
  },
})
