import {
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import { firebase } from '../services/firebase_service'
import Message, { IMessage } from '../models/dialogue/message'
import Document from '../models/document/document'
import { analytics } from '../services/analytics_service'
import { speech } from '../services/speech_service'
import { Timestamp } from 'firebase/firestore'
import { notification } from '../utils/notification'

// The Dialogue Store handles messages and drafts for all claims (though it's mostly used for one claim at a time)
export const DialogueStoreModel = types
  .model('DialogueStore')
  .props({
    currentDraftId: types.maybeNull(types.string),
    drafts: types.optional(types.array(Message), []),
    messages: types.optional(types.array(Message), []),
    documents: types.optional(types.array(Document), []),
    input_lines: types.optional(types.number, 1),
  })
  .volatile((store) => ({
    unsubscribeFromMessagesFunction: () => {},
  }))
  // Views are read-only derived data of the state tree
  .views((store) => ({
    // Returns the draft currently being edited
    get currentDraft() {
      return store.drafts.find(
        (message) => message.message_id === store.currentDraftId
      )
    },

    // Returns only the most recent message
    get lastMessage() {
      if (store.messages.length === 0) return null
      const lastMessage = store.messages
        .slice()
        .sort(
          (a, b) =>
            (b.created_date instanceof Timestamp
              ? b.created_date?.toDate().getTime()
              : 0) -
            (a.created_date instanceof Timestamp
              ? a.created_date?.toDate().getTime()
              : 0)
        )[0]
      return lastMessage
    },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    // Listens to changes in the messages collection for a given claim
    async subscribeToMessagesForClaim(claimId: string, limit: number) {
      console.log('Subscribing to messages')
      console.log('claimId: ', claimId)
      console.log('messages: ', store.messages.length)
      const account = getRootStore(store).user
      store.unsubscribeFromMessagesFunction = firebase.subscribeToMessages(
        account.account_id,
        claimId,
        limit,
        (snapshot) => {
          console.log('Received messages')
          snapshot.forEach((doc) => {
            try {
              const message = doc.data() as IMessage
              console.log('message: ', message.message_id)
              this.updateMessage(message)
            } catch (error) {
              console.error('Error in subscribeToMessages:', error)
              const uid = getRootStore(store).authStore.user?.account_id
              analytics.error(error, uid, claimId ?? undefined)
            }
          })
        }
      )
      console.log('Subscribed to messages!')
    },

    // Keeps track of how many lines the user is typing
    updateInputLines(lines: number) {
      store.input_lines = lines
    },

    // Generate and downloads audio for a given message text
    async getAudio(message: IMessage) {
      try {
        const accountId = getRootStore(store).user.account_id
        let audioUrl = ''
        let timepoints: number[] = []
        if (!message) return null
        if (message.audio && message.timepoints) {
          audioUrl = await firebase.getStorageUrl(message.audio)
          timepoints = message.timepoints
        } else {
          // Call the function to generate audio file
          const response = await speech.textToSpeech(
            accountId,
            message.claim_id!,
            message.message_id!
          )
          audioUrl = await firebase.getStorageUrl(response!.audio)
          timepoints = response!.timepoints
        }
        return { url: audioUrl, timepoints: timepoints }
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Starts a new draft
    setNewCurrentDraft(messageId: string) {
      console.log('Setting new current draft')
      console.log('messageId: ', messageId)
      try {
        const currentClaimId = getRootStore(store).claimStore.currentClaimId
        store.currentDraftId = messageId
        store.drafts.push(
          Message.create({
            message_id: messageId,
            account_id: getRootStore(store).user.account_id,
            text: '',
            creator: 'user',
            created_date: new Date(),
            claim_id: currentClaimId,
            visible: true,
          })
        )
        console.log('store.currentDraftId: ', store.currentDraftId)
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Sets the current draft when changing current claim
    setCurrentDraftFromClaim(newMessageId: string) {
      console.log('setCurrentDraftFromClaim: ', newMessageId)
      const currentClaimId = getRootStore(store).claimStore.currentClaimId
      try {
        const message = store.drafts.find(
          (message) => message.claim_id === currentClaimId
        )
        if (message) {
          store.currentDraftId = message.message_id
        } else {
          this.setNewCurrentDraft(newMessageId)
        }
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
        this.setNewCurrentDraft(newMessageId)
      }
    },

    // Self-explanatory. Means I'm a good coder
    removeDraft(messageId: string) {
      try {
        const draftIdx = this.getDraftIdx(messageId)
        if (draftIdx !== -1) {
          store.drafts.splice(draftIdx, 1)
        }
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Attaches a document ID to a draft with a given ID
    addAttachmentToDraft(documentId: string, messageId: string) {
      console.log('Adding attachment to draft')
      const draftIdx = this.getDraftIdx(messageId)
      if (draftIdx !== -1) {
        store.drafts[draftIdx].addAttachment(documentId)
      }
    },

    // Same but in reverse
    removeAttachmentFromDraft(documentId: string, messageId: string) {
      try {
        const draftIdx = this.getDraftIdx(messageId)
        if (draftIdx !== -1) {
          store.drafts[draftIdx].removeAttachment(documentId)
        }
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Updates any draft with a new message object
    updateDraft(message: IMessage) {
      console.log('Updating draft: ', message.message_id)
      try {
        const draftIdx = this.getDraftIdx(message.message_id!)
        if (draftIdx !== -1) {
          store.drafts[draftIdx].update(message)
        } else {
          store.drafts.push(message)
        }
        store.currentDraftId = message.message_id
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Saves draft as a message in Firestore and removes the draft
    async sendDraft(messageId: string) {
      const draftIdx = this.getDraftIdx(messageId)

      if (draftIdx !== -1) {
        try {
          const account = getRootStore(store).user
          // Set created date to now
          store.drafts[draftIdx].created_date = new Date()
          await firebase.sendMessage(account, store.drafts[draftIdx])
          this.removeDraft(messageId)
          analytics.track('Sent message')
          return true
        } catch (error) {
          const uid = getRootStore(store).authStore.user?.account_id
          const claimId = getRootStore(store).claimStore.currentClaimId
          analytics.error(error, uid, claimId ?? undefined)
          return false
        }
      } else {
        notification.warning(
          'Message Not Sent',
          'Unable to send message. Please try again later.'
        )
        return false
      }
    },

    // Returns the draft index in it exists, false if it doesn't
    getDraftIdx(draftId: string) {
      const existingDraftIndex = store.drafts.findIndex(
        (draft) => draft.message_id === draftId
      )
      return existingDraftIndex
    },

    // Returns the message index in it exists, false if it doesn't
    messageExists(messageId: string) {
      const existingMessageIndex = store.messages.findIndex(
        (message) => message.message_id === messageId
      )
      return existingMessageIndex === -1 ? false : existingMessageIndex
    },

    // Updates a message with a new message object
    updateMessage(message: IMessage) {
      try {
        const messageExists = this.messageExists(message.message_id!)
        if (messageExists !== false) {
          store.messages[messageExists].update(message)
        } else {
          store.messages.push(message)
        }
      } catch (error) {
        const uid = getRootStore(store).authStore.user?.account_id
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, uid, claimId ?? undefined)
      }
    },

    // Replaces the messages array
    setMessages(messages: IMessage[]) {
      store.messages.clear()
      messages.forEach((message) => {
        store.messages.push(message)
      })
    },

    // I'd say this clears the messages array, but don't quote me on that
    clearMessages() {
      store.messages.clear()
    },

    // Important, unsubscribe from the messages collection when switching between claims
    unsubscribeFromMessages() {
      if (store.unsubscribeFromMessagesFunction) {
        store.unsubscribeFromMessagesFunction()
        // Reset the unsubscribe function to a no-op after calling it
        store.unsubscribeFromMessagesFunction = () => {}
      }
    },
  }))

export interface DialogueStore extends Instance<typeof DialogueStoreModel> {}
export interface DialogueStoreSnapshot
  extends SnapshotOut<typeof DialogueStoreModel> {}
