import { observer } from 'mobx-react'
import {
  Button,
  Flex,
  Container,
  Space,
  Title,
  Stack,
  Paper,
  Loader,
  NumberInput,
  Select,
  TextInput,
  Group,
  Text,
  Modal,
  Checkbox,
} from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { IconChevronRight, IconCalendarUser } from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from '@mantine/form'
import background_image from '../../assets/images/register.webp'
import { GarfieldLogoText } from '../../components/shared/garfield_logo'
import { firebase } from 'services/firebase_service'
import { analytics } from 'services/analytics_service'
import TermsAndConditions from './terms_and_conditions'

function WaitListPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()
  const navigate = useNavigate()
  const desktop = useMediaQuery('(min-width: 56.25em)')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [opened, setOpened] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      businessName: '',
      websiteAddress: '',
      claimsPerYear: 10,
      averageClaimAmount: '',
      pricingPlan: '',
      referralSource: '',
    },
    validate: {
      email: (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email',
      businessName: (value) => (value ? null : 'Business name is required'),
      websiteAddress: (value) =>
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)
          ? null
          : 'Invalid website address',
      claimsPerYear: (value) =>
        value > 0 ? null : 'Claims per year must be greater than 0',
      averageClaimAmount: (value) =>
        value ? null : 'Average claim amount is required',
      pricingPlan: (value) => (value ? null : 'Pricing plan is required'),
      referralSource: (value) => (value ? null : 'Referral source is required'),
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
  })

  const onSubmit = async (values: typeof form.values) => {
    try {
      setIsSubmitting(true)
      await firebase.addToWaitList(values)
      form.reset()
      setIsSubmitted(true)
    } catch (error) {
      analytics.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size='xl'
        title={
          <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
            WAITLIST TERMS & CONDITIONS
          </Text>
        }
        styles={{
          title: {
            textAlign: 'center',
            width: '100%',
          },
          header: {
            display: 'flex',
            justifyContent: 'center',
          },
          content: {
            borderRadius: '1rem',
          },
        }}
      >
        <TermsAndConditions />
      </Modal>
      <Flex justify='start' m={0} p={0} gap={'sm'} bg={theme.colors.white[5]}>
        {desktop ? (
          <Paper
            w={'100%'}
            m={'sm'}
            radius={'md'}
            style={{
              backgroundImage: `url(${background_image})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
            }}
          >
            <Paper
              w={'100%'}
              h={'100%'}
              p={'xl'}
              style={{
                background: `rgba(0, 0, 0, 0.5)`,
              }}
            >
              <Stack justify='space-between' h={'100%'}>
                <GarfieldLogoText
                  size={'md'}
                  variant={'light'}
                  onClick={() => navigate('/')}
                  type='full'
                />
              </Stack>
            </Paper>
          </Paper>
        ) : null}

        <Container
          h={height - 25}
          mah={height - 25}
          w={'100%'}
          my={'sm'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            miw={desktop ? 500 : 100}
            maw={desktop ? 600 : 1920}
            h={'100%'}
            radius={'sm'}
            p={'xl'}
          >
            <Group justify='start' mt='xl' gap={'xs'}>
              {isSubmitted ? (
                <>
                  <Title order={2}>Thank you for joining the wait list!</Title>
                  <Text>
                    Please check your email account for our welcome email.
                  </Text>
                </>
              ) : (
                <form onSubmit={form.onSubmit(onSubmit)}>
                  <Paper ml={'20px'}>
                    <Space h={'xs'} />
                    <Group>
                      <IconCalendarUser size={32} />
                      <Title order={2}>Join Garfield's Wait List</Title>
                    </Group>
                    <Space h={'lg'} />
                    <Space h={'lg'} />
                    <TextInput
                      {...form.getInputProps('email')}
                      id='email-input'
                      label='Your email address'
                      placeholder={`example@example.com`}
                      type='email'
                      error={form.errors.email}
                    />
                    <Space h={'lg'} />
                    <TextInput
                      {...form.getInputProps('businessName')}
                      id='business-name-input'
                      label='Your business name'
                      placeholder='ACME Ltd.'
                      error={form.errors.businessName}
                    />
                    <Space h={'lg'} />
                    <TextInput
                      {...form.getInputProps('websiteAddress')}
                      id='website-address-input'
                      label='Your website address'
                      placeholder='www.example.com'
                      error={form.errors.websiteAddress}
                    />
                    <Space h={'lg'} />
                    <NumberInput
                      {...form.getInputProps('claimsPerYear')}
                      mb={'sm'}
                      suffix=' claims'
                      label='Estimated number of claims per year'
                      min={1}
                      max={10000}
                      error={form.errors.claimsPerYear}
                    />
                    <Space h={'lg'} />
                    <Select
                      {...form.getInputProps('averageClaimAmount')}
                      mb={'sm'}
                      label='Estimated average amount per claim'
                      data={[
                        { value: '1-500', label: '£1 - £500' },
                        { value: '500-1000', label: '£500 - £1,000' },
                        { value: '1000-3000', label: '£1,000 - £3,000' },
                        { value: '3000-5000', label: '£3,000 - £5,000' },
                        { value: '5000-10000', label: '£5,000 - £10,000' },
                      ]}
                      placeholder='Select an amount range'
                      error={form.errors.averageClaimAmount}
                    />
                    <Space h={'lg'} />
                    <Select
                      {...form.getInputProps('pricingPlan')}
                      mb={'sm'}
                      label='Anticipated pricing plan'
                      data={[
                        { value: 'pay-as-you-go', label: 'Pay as you go' },
                        { value: 'fixed-fee', label: 'Fixed fee' },
                        { value: 'subscription', label: 'Subscription' },
                        { value: 'enterprise', label: 'Enterprise' },
                      ]}
                      placeholder='Select a pricing plan'
                      error={form.errors.pricingPlan}
                    />
                    <Space h={'lg'} />
                    <Select
                      {...form.getInputProps('referralSource')}
                      label='How did you hear about us?'
                      placeholder='Select an option'
                      data={[
                        {
                          value: 'Search Engine',
                          label: 'Search Engine (e.g., Google, Bing)',
                        },
                        {
                          value: 'Social Media',
                          label:
                            'Social Media (e.g., Facebook, Twitter, Instagram)',
                        },
                        {
                          value: 'Online Ads',
                          label: 'Online Ads (e.g., Google Ads, Facebook Ads)',
                        },
                        { value: 'Email Campaign', label: 'Email Campaign' },
                        {
                          value: 'Word of Mouth / Referral',
                          label: 'Word of Mouth / Referral',
                        },
                        {
                          value: 'Blog or Website Article',
                          label: 'Blog or Website Article',
                        },
                        {
                          value: 'Online Forum or Community',
                          label: 'Online Forum or Community',
                        },
                        {
                          value: 'Event or Webinar',
                          label: 'Event or Webinar',
                        },
                        { value: 'Podcast', label: 'Podcast' },
                        {
                          value: 'Direct Visit',
                          label: 'Direct Visit (Typed URL)',
                        },
                        { value: 'Other', label: 'Other' },
                      ]}
                      error={form.errors.referralSource}
                    />
                    <Space h={'lg'} />
                    <Group align='center'>
                      <Checkbox
                        mt='xs'
                        label={
                          <>
                            <Text style={{ fontSize: 'inherit' }}>
                              By signing up to our wait list you are also
                              agreeing to these{' '}
                              <Text
                                component='a'
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault()
                                  setOpened(true)
                                }}
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  fontSize: 'inherit',
                                }}
                              >
                                Terms and Conditions
                              </Text>
                              .
                            </Text>
                          </>
                        }
                        checked={agreed}
                        onChange={(event) =>
                          setAgreed(event.currentTarget.checked)
                        }
                      />
                    </Group>
                    <Space h={'lg'} />
                    <Button
                      type='submit'
                      disabled={!form.isValid() || !agreed}
                      rightSection={<IconChevronRight size={22} />}
                      loading={isSubmitting}
                    >
                      {isSubmitting ? <Loader size='xs' /> : 'Join Us'}
                    </Button>
                  </Paper>
                </form>
              )}
            </Group>
          </Paper>
        </Container>
      </Flex>
    </>
  )
}

export default observer(WaitListPage)
