import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp' // Assuming the new model's path is the correct one

const DocumentDates = types
  .model('DocumentDates', {
    internal_date: types.maybeNull(FirestoreTimestamp),
    created_date: types.maybeNull(FirestoreTimestamp),
    last_modified_date: types.maybeNull(FirestoreTimestamp),
    received_date: types.maybeNull(FirestoreTimestamp),
    sent_date: types.maybeNull(FirestoreTimestamp),
    response_deadline_date: types.maybeNull(FirestoreTimestamp),
    served_date: types.maybeNull(FirestoreTimestamp),
    sot_date: types.maybeNull(FirestoreTimestamp),
  })
  .actions((self) => ({
    update(updatedDates: Partial<IDocumentDates>) {
      Object.assign(self, updatedDates)
    },
  }))

export default DocumentDates
export interface IDocumentDates extends Instance<typeof DocumentDates> {}
