import {
  Button,
  Card,
  useMantineTheme,
  Group,
  Text,
  ScrollArea,
  Select,
  Container,
  TextInput,
  rem,
  ActionIcon,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { IconPlus, IconX } from '@tabler/icons-react'
import { useState } from 'react'
import { modal } from '../../utils/modal'
import { observer } from 'mobx-react'
import { useViewportSize } from '@mantine/hooks'
import { ROLES } from '../../models/auth/member'
import * as changeCase from 'change-case'
import ClaimSelectionBox from '../teams_page/claim_selection_box'

const InviteMembersModal = ({
  text,
  title,
}: {
  text: string
  title: string
}) => {
  const theme = useMantineTheme()
  const { memberStore } = useStores()
  const { height, width } = useViewportSize()

  const invites = memberStore.invites

  const data = Array(100)
    .fill(0)
    .map((_, index) => `Option ${index}`)

  const [displayErrors, setDisplayErrors] = useState(false)

  return (
    <Card miw={700} maw={700} p={'xs'} bg={'transparent'}>
      <Card.Section withBorder p={'xs'}>
        <Text size='xl' fw={600}>
          {'Invite new team members'}
        </Text>
      </Card.Section>
      <ScrollArea py={0} px={0} h={height / 2}>
        {invites.map((invite) => {
          return (
            <Group
              key={invite.member_id}
              preventGrowOverflow
              pt={'xs'}
              gap={'xs'}
              px={0}
            >
              <TextInput
                defaultValue={invite.email ?? undefined}
                placeholder='Enter email address'
                error={displayErrors && !invite.validEmail}
                maw={280}
                miw={280}
                onChange={(event) => {
                  if (event) {
                    memberStore.updateInvite({
                      member_id: invite.member_id,
                      email: event.target.value,
                    })
                  }
                }}
              />
              <Select
                placeholder='Choose role'
                error={displayErrors && !invite.validRole}
                defaultValue={
                  invite.role
                    ? changeCase.capitalCase(invite.role)
                    : changeCase.capitalCase(ROLES.claim_editor)
                }
                data={Object.values(ROLES).map((role) =>
                  changeCase.capitalCase(role)
                )}
                maw={140}
                miw={140}
                onChange={(value) => {
                  if (value) {
                    memberStore.updateInvite({
                      member_id: invite.member_id,
                      role: ROLES[value.toLowerCase() as keyof typeof ROLES],
                    })
                  }
                }}
              />
              {/* <Tooltip label={'This feature is currently in development'}>
                <MultiSelect
                  placeholder='Assign claims'
                  data={data}
                  maxDropdownHeight={250}
                  maw={150}
                  miw={150}
                  disabled
                />
              </Tooltip> */}

              <ClaimSelectionBox
                memberId={invite.member_id}
                type='invite'
                offset={10}
                position='bottom'
                target={(toggleDropdown) => (
                  <Select
                    onClick={(event) => {
                      toggleDropdown()
                      event.stopPropagation()
                    }}
                    placeholder={
                      invite.claim_ids
                        ? `${invite.claim_ids.length} claims assigned`
                        : 'Assign claims'
                    }
                    // error={displayErrors && !invite.validRole}
                    // value={`${invite.claim_ids?.length ?? 0} claims assigned`}
                    // data={Object.values(ROLES).map((role) =>
                    //   changeCase.capitalCase(role)
                    // )}
                    maw={190}
                    miw={190}
                    // onChange={(value) => {
                    //   print(value)
                    //   if (value) {
                    //     print(ROLES[value.toLowerCase() as keyof typeof ROLES])
                    //     memberStore.updateInvite({
                    //       member_id: invite.member_id,
                    //       role: ROLES[value.toLowerCase() as keyof typeof ROLES],
                    //     })
                    //   }
                    // }}
                  />
                )}
              />

              <ActionIcon
                p={0}
                size={'lg'}
                radius='sm'
                // bg={theme.colors.gray[2]}
                variant='subtle'
                onClick={() => memberStore.removeInvite(invite.member_id)}
              >
                <IconX
                  color={theme.colors.gray[7]}
                  style={{
                    width: rem(18),
                    height: rem(18),
                    color: theme.colors.red[6],
                  }}
                  stroke={2}
                  //   scale={'md'}
                />
              </ActionIcon>
            </Group>
          )
        })}

        <Group pt={'xs'}>
          <Button
            maw={200}
            bg={theme.colors.gray[2]}
            c={theme.colors.gray[7]}
            leftSection={
              <IconPlus style={{ width: rem(15), height: rem(15) }} />
            }
            size='xs'
            onClick={() => memberStore.addInvite()}
          >
            {'Add invite'}
          </Button>
          <Container fluid />
        </Group>
      </ScrollArea>
      <Card.Section p={'xs'} pt={'lg'}>
        <Group>
          <Container fluid />
          <Button maw={200} variant='subtle' onClick={() => modal.close()}>
            {'Cancel'}
          </Button>
          <Button
            maw={200}
            bg={theme.colors.black[5]}
            onClick={async () => {
              if (memberStore.invitesReady()) {
                await memberStore.sendInvites()
                modal.close()
              } else {
                setDisplayErrors(true)
              }
            }}
          >
            {'Send invites'}
          </Button>
        </Group>
      </Card.Section>
    </Card>
  )
}

export default observer(InviteMembersModal)
