import {
  Text,
  Button,
  Card,
  Stack,
  useMantineTheme,
} from '@mantine/core'



export const ManualDeleteModal = ({
  text,
  title,
  onClick,
}: {
  text: React.ReactNode
  title: string
  onClick: () => {}
}) => {
  const theme = useMantineTheme()

  return (
    <Card p={0} bg={'transparent'}>
      <Stack justify='center' align='center'>
        <Text fw={600} size='xl'>
          {title}
        </Text>
        <Text size='sm'>{text}</Text>
        <Button maw={200} bg={theme.colors.red[6]} onClick={onClick}>
          Contact support
        </Button>
      </Stack>
    </Card>
  )
}
