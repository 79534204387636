// instalment.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const Instalment = types
  .model('Instalment', {
    date: types.maybeNull(FirestoreTimestamp),
    amount: types.maybeNull(types.string),
  })
  .actions((self) => ({}))

export default Instalment
export interface IInstalment extends Instance<typeof Instalment> {}
