// court_bundle_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const CourtBundleDetails = types
  .model('CourtBundleDetails', {
    issue_date: types.maybeNull(FirestoreTimestamp),
    response_date: types.maybeNull(FirestoreTimestamp),
  })
  .actions((self) => ({}))

export default CourtBundleDetails
export interface ICourtBundleDetails
  extends Instance<typeof CourtBundleDetails> {}
