import {
  applySnapshot,
  onSnapshot,
} from 'mobx-state-tree'
import {
  RootStore,
} from '../stores/root_store'
import { analytics } from 'services/analytics_service'

// Random key
const ROOT_STATE_STORAGE_KEY = 'root-v6'

let _disposer: undefined | (() => void)

// This function hydrates the root store from local storage, so the state is preserved between sessions
export function setupRootStore(rootStore: RootStore) {
  let restoredState

  try {
    const data = localStorage.getItem(ROOT_STATE_STORAGE_KEY)
    if (data) {
      restoredState = JSON.parse(data)
      applySnapshot(rootStore, restoredState)
    }
  } catch (e) {
    analytics.error(e)
  }

  if (_disposer) _disposer()

  _disposer = onSnapshot(rootStore, (snapshot) => {
    localStorage.setItem(ROOT_STATE_STORAGE_KEY, JSON.stringify(snapshot))
  })

  const unsubscribe = () => {
    if (_disposer) _disposer()
    _disposer = undefined
  }

  return { rootStore, restoredState, unsubscribe }
}
