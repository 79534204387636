import { useEffect, useRef, useState } from 'react'
import {
  Text,
  Group,
  useMantineTheme,
  Stack,
  Button,
  Badge,
  SegmentedControl,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
//@ts-ignore
import { observer } from 'mobx-react'
import {
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconFile,
  IconPhoto,
} from '@tabler/icons-react'
import { NodeRendererProps, Tree } from 'react-arborist'
import { DocumentType, IDocument } from '../../models/document/document'
import * as changeCase from 'change-case'
import React from 'react'

const calculateChildren = (node: any) => {
  node.childCount = 0

  for (const child of node.children) {
    if (!child.children) {
      node.childCount += 1
    } else {
      calculateChildren(child)
      node.childCount += child.childCount
    }
  }

  return node
}

const getStageData = (documents: IDocument[]) => {
  const documentsToShow: [boolean, string, string, DocumentType][] = [
    [true, 'chaser', 'Chaser', DocumentType.chaser],
    [true, 'lba', 'Letter before action', DocumentType.lba],
    [false, 'invoice', 'Invoice', DocumentType.invoice],
    [false, 'contract', 'Contract', DocumentType.contract],
    [true, 'claim_form', 'Claim Form', DocumentType.claim_form_and_poc],
    [false, 'correspondence', 'Correspondence', DocumentType.correspondence],
    [
      false,
      'instructions',
      'Instructions to Counsel',
      DocumentType.instructions_to_counsel,
    ],
    // [false, 'calculations', 'Calculations', DocumentType.calculations],
    [false, 'settlement', 'Settlement', DocumentType.settlement],
    [false, 'request_for_judgment', 'Request for Judgment', DocumentType.request_for_judgment],
    [false, 'reply_to_defence', 'Reply to Defence', DocumentType.reply_to_defence],
    [false, 'reply_to_defence_and_counterclaim', 'Reply to Defence and Counterclaim', DocumentType.reply_to_defence_and_counterclaim],
  
    [false, 'claimant_correspondence', 'Claimant Correspondence', DocumentType.claimant_correspondence],
    [false, 'claimant_settlement', 'Claimant Settlement', DocumentType.claimant_settlement],
    [false, 'n180_claimant_directions_questionnaire', 'Claimant Directions Questionnaire', DocumentType.n180_claimant_directions_questionnaire],
    [false, 'claimant_skeleton_arguments', 'Claimant Skeleton Arguments', DocumentType.claimant_skeleton_arguments],
    [false, 'claimant_bundle', 'Claimant Bundle', DocumentType.claimant_bundle],
    [false, 'n9a_admission', 'N9A Admission', DocumentType.n9a_admission],
    [false, 'n9b_defence_and_counterclaim', 'N9B Defence and Counterclaim', DocumentType.n9b_defence_and_counterclaim],
    [false, 'n9_acknowledgement_of_service', 'N9 Acknowledgement of Service', DocumentType.n9_acknowledgement_of_service],
    [false, 'n24_judgment_general', 'N24 Judgment General', DocumentType.n24_judgment_general],
    [false, 'n30_judgment_default', 'N30 Judgment Default', DocumentType.n30_judgment_default],
    [false, 'n149a_notice_of_proposed_allocation_small_claims', 'N149A Notice of Proposed Allocation Small Claims', DocumentType.n149a_notice_of_proposed_allocation_small_claims],
    [false, 'n149b_notice_of_proposed_allocation_fast_track', 'N149B Notice of Proposed Allocation Fast Track', DocumentType.n149b_notice_of_proposed_allocation_fast_track],
    [false, 'n149c_notice_of_proposed_allocation_multi_track', 'N149C Notice of Proposed Allocation Multi Track', DocumentType.n149c_notice_of_proposed_allocation_multi_track],
    [false, 'n157_notice_of_allocation_hearing', 'N157 Notice of Allocation Hearing', DocumentType.n157_notice_of_allocation_hearing],
    [false, 'n159_notice_of_allocation_no_hearing', 'N159 Notice of Allocation No Hearing', DocumentType.n159_notice_of_allocation_no_hearing],
    [false, 'n180_directions_questionnaire_small_claims', 'N180 Directions Questionnaire Small Claims', DocumentType.n180_directions_questionnaire_small_claims],
    [false, 'n181_directions_questionnaire_fast_intermediate_multi_track', 'N181 Directions Questionnaire Fast Intermediate Multi Track', DocumentType.n181_directions_questionnaire_fast_intermediate_multi_track],
    [false, 'n205a_notice_of_issue', 'N205A Notice of Issue', DocumentType.n205a_notice_of_issue],
    [false, 'n210_acknowledgment_of_service', 'N210 Acknowledgment of Service', DocumentType.n210_acknowledgment_of_service],
    [false, 'n225a_partial_admission', 'N225A Partial Admission', DocumentType.n225a_partial_admission],
    [false, 'n244_application_notice', 'N244 Application Notice', DocumentType.n244_application_notice],
    [false, 'n279_notice_of_discontinuance', 'N279 Notice of Discontinuance', DocumentType.n279_notice_of_discontinuance],
    [false, 'n434_change_of_legal_representative', 'N434 Change of Legal Representative', DocumentType.n434_change_of_legal_representative],
    [false, 'defence_statement_of_case', 'Defence Statement of Case', DocumentType.defence_statement_of_case],
    [false, 'defendant_bundle', 'Defendant Bundle', DocumentType.defendant_bundle],
    [false, 'defendant_skeleton_arguments', 'Defendant Skeleton Arguments', DocumentType.defendant_skeleton_arguments],
    [false, 'witness_statement', 'Witness Statement', DocumentType.witness_statement],
    [false, 'instructions_to_counsel', 'Instructions to Counsel', DocumentType.instructions_to_counsel],
    [false, 'exhibit', 'Exhibit', DocumentType.exhibit],
    [false, 'irrelevant_or_malicious', 'Irrelevant or Malicious', DocumentType.irrelevant_or_malicious],
    [false, 'other', 'Other', DocumentType.other],
  ]

  const userDocumentNode = (id: string, name: string, type: DocumentType) => {
    const children = documents
      .filter(
        (document) => document.type === type && document.status !== 'archived'
      )
      .map((document) => ({
        id: document.document_id,
        name: document.name,
        mime_type: document.mime_type,
      }))

    if (children.length === 0) {
      return null
    }

    return {
      id,
      name,
      children,
    };
  };
  
  const garfieldProducedNode = (id: string, name: string, type: DocumentType) => {
    const filteredDocuments = documents
      .filter((document) => document.type === type && document.status !== 'archived')
      .sort((a, b) => b.dates?.created_date - a.dates?.created_date).slice(0, 1);


    const nodes = filteredDocuments
      .slice()
      .reverse()
      .map((document, index) => {
        const associatedDocuments: {
          id: string
          name: string
          mime_type: string
        }[] = []
        if (document.associated_document_ids) {
          for (const docId of document.associated_document_ids) {
            const associatedDoc = documents.find(
              (doc) => doc.document_id === docId
            )
            if (associatedDoc) {
              if (
                associatedDoc.document_id &&
                associatedDoc.name &&
                associatedDoc.mime_type
              ) {
                associatedDocuments.push({
                  id: associatedDoc.document_id,
                  name: associatedDoc.name,
                  mime_type: associatedDoc.mime_type,
                })
              }
            }
          }
        }

          return {
            id: `${id}-${index + 1}`,
            name: `${name}`,
            children: [
              {
                id: document.document_id,
                name: document.name,
                mime_type: document.mime_type,
              },
              ...associatedDocuments, // If this is commented out then the parent title works.
            ],
          };
      });
  
  
    return nodes.length > 0 ? nodes : null;
  };
  
  const nodes: Array<{ id: string; name: string; children: any[] }> = documentsToShow.flatMap(([garfieldProduced, id, name, type]) => {
    const node = garfieldProduced ? garfieldProducedNode(id, name, type) : userDocumentNode(id, name, type);
    return node ? (Array.isArray(node) ? node : [node]) : [];
  });
  return nodes;
}

function DocumentsTree({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>
}) {
  const { documentStore } = useStores()
  const treeHeaderRef = useRef<HTMLDivElement>(null)
  const [parentContainerHeight, setParentContainerHeight] = useState(500)
  const [treeHeaderHeight, setTreeHeaderHeight] = useState(500)

  useEffect(() => {
    const containerElement = containerRef.current
    if (!containerElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const rect = entry.target.getBoundingClientRect()
        const visibleArea = Math.min(rect.bottom, window.innerHeight) - rect.top
        setParentContainerHeight(visibleArea)
      }
    })

    resizeObserver.observe(containerElement)

    return () => {
      resizeObserver.unobserve(containerElement)
    }
  }, [containerRef])

  useEffect(() => {
    const treeHeaderElement = treeHeaderRef.current
    if (!treeHeaderElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const treeHeaderHeight = entry.target.getBoundingClientRect().height
        setTreeHeaderHeight(treeHeaderHeight)
      }
    })

    resizeObserver.observe(treeHeaderElement)
    return () => {
      resizeObserver.unobserve(treeHeaderElement)
    }
  }, [treeHeaderRef])

  const documents = documentStore.currentDocuments
  const theme = useMantineTheme()
  const [view, setView] = useState('stage')

  const getTreeByStatus = (documentStatus: string) => [
    ...Object.values(DocumentType)
      .map((type, typeIndex) => {
        const filteredDocuments = documents.filter(
          (document) => document.type === type && document.status === documentStatus
        ).sort((a, b) => b.dates?.created_date - a.dates?.created_date);
  
        if (filteredDocuments.length === 0) {
          return null
        }

        return {
          id: `archive-t-${typeIndex.toString()}`,
          name: changeCase.capitalCase(type),
          children: filteredDocuments.map((document, docIndex) => ({
            id: document.document_id,
            name: document.name,
            mime_type: document.mime_type,
          })),
        }
      })
      .filter(Boolean), // Remove null entries
  ]

  const selectDocument = (nodeId: string) => {
    documentStore.selectDocument('archive', nodeId)
  }

  const getTreeData = () => {
    switch (view) {
      case 'stage':
        return getStageData(documents).map((doc) => calculateChildren(doc))
      case 'category':
        return getTreeByStatus('active').map((doc) => calculateChildren(doc))
      case 'archive':
        return getTreeByStatus('archived').map((doc) => calculateChildren(doc))
      default:
        return []
    }
  }
  const responsiveTreeHeight = parentContainerHeight - treeHeaderHeight - 100

  return (
    <Stack gap='xs' p={0}>
      <div ref={treeHeaderRef}>
        <Text size='lg' fw={500}>
          Document Library
        </Text>
        <Group gap={'xs'}>
          <Text fw={500} size='xs' c={theme.colors.gray[6]}>
            View by{' '}
          </Text>
          <SegmentedControl
            size='xs'
            value={view}
            onChange={setView}
            data={[
              { label: 'Claim Stage', value: 'stage' },
              { label: 'Category', value: 'category' },
              { label: 'Archive', value: 'archive' },
            ]}
          />
        </Group>
      </div>

      <Tree
        width='100%'
        height={responsiveTreeHeight}
        data={getTreeData()}
        openByDefault={true}
        onActivate={(node: any) => {
          if (node.isLeaf) {
            selectDocument(node.id)
          }
        }}
      >
        {Node}
      </Tree>
    </Stack>
  )
}

function Node({ node, style, dragHandle }: NodeRendererProps<any>) {
  function truncatedFilename(filename: string) {
    if (filename) {
      const maxLength = 50
      const extension = filename.slice(
        ((filename.lastIndexOf('.') - 1) >>> 0) + 2
      )
      const nameWithoutExtension = filename.replace(/\.[^/.]+$/, '')

      if (nameWithoutExtension.length <= maxLength) {
        return filename
      }

      return `${nameWithoutExtension.substring(
        0,
        maxLength - 3
      )}...${extension}`
    } else {
      return 'untitled.pdf'
    }
  }

  const theme = useMantineTheme()
  /* This node instance can do many things. See the API reference. */
  return (
    <div style={style} ref={dragHandle} onClick={() => node.toggle()}>
      {node.isLeaf ? (
        <Button
          size='compact-xs'
          variant='subtle'
          c={theme.colors.red[5]}
          mt={'sm'}
          onClick={() => node.activate()}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            {node.data.mime_type === 'image/jpeg' ||
            node.data.mime_type === 'image/png' ? (
              <IconPhoto size={16} stroke={2} color={theme.colors.gray[8]} />
            ) : (
              <IconFile size={16} stroke={2} color={theme.colors.gray[8]} />
            )}
            <Text size='sm' c={theme.colors.gray[8]}>
              {truncatedFilename(node.data.name)}
            </Text>
          </div>
        </Button>
      ) : (
        <Button size='compact-xs' variant={'subtle'} my={'sm'}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            {node.isClosed ? (
              <IconCaretRightFilled
                style={{ color: theme.colors.gray[8] }}
                stroke={1.5}
                size={12}
              />
            ) : (
              <IconCaretDownFilled
                style={{ color: theme.colors.gray[8] }}
                stroke={2}
                size={12}
              />
            )}
            <Text c={theme.colors.gray[8]} size='sm'>
              {node.data.name}
            </Text>
            {node.data.childCount! > 0 && (
              <Badge
                size='xs'
                bg={theme.colors.gray[7]}
              >{`${node.data.childCount}`}</Badge>
            )}
          </div>
        </Button>
      )}
    </div>
  )
}

export default observer(DocumentsTree)
export { getStageData }