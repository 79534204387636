import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { MantineProvider, Text } from '@mantine/core'
import { theme } from './utils/theme'
import { Notifications } from '@mantine/notifications'
import '@mantine/core/styles.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import './layout.css'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { ModalsProvider } from '@mantine/modals'
import Config from './config'
import * as changeCase from 'change-case'
import 'mantine-react-table/styles.css'
import { ErrorBoundary } from 'react-error-boundary'
import { analytics } from './services/analytics_service'
import { injectNewRelic } from './services/newrelic'
import { CrashFallback } from './components/errors/crash_fallback'

analytics.init()


injectNewRelic()


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={CrashFallback}
        onError={(error) => console.log(error)}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <ModalsProvider>
          <Notifications />
          <Router>
            <div style={{ position: 'relative' }}>
              {Config.ENV !== 'production' ? (
                <Text
                  onClick={() => analytics.error(Error('Oh no'))}
                  style={{
                    position: 'absolute',
                    zIndex: 100000,
                    top: 0,
                    left: '45%',
                  }}
                  size='xs'
                  color='gray'
                >
                  {changeCase.capitalCase(Config.ENV)} environment
                </Text>
              ) : null}

              <App />
            </div>
          </Router>
        </ModalsProvider>
      </ErrorBoundary>
    </MantineProvider>
  </React.StrictMode>
)
