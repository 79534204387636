import { useState, useMemo } from 'react'
import { Container, Center, Text, Select } from '@mantine/core'

// Obvs we need to decide pricing but it seems sensible for the time being to knock up a prototype
// I suspect pricing at the higher claim values (i.e. £3k and above) will be much more than just Court permitted recoveries. After all, our competition is a many x multiple of our likely pricing so we win even with a higher fee that the permitted recovery.
// This is something that I should model properly and then we can work it out.

function PricingPage() {
  const [claimsCount, setClaimsCount] = useState<string | null>(null)
  const [claimAmount, setClaimAmount] = useState<string | null>(null)

  // Need to deal with pre-action stage

  const claimsCountOptions = [
    { value: '1', label: '1' },
    { value: '2-5', label: '2-5' },
    { value: '6-10', label: '6-10' },
    { value: '10-30', label: '10-30' },
    { value: 'more-30', label: 'More than 30' },
  ]

  const claimAmountOptions = [
    { value: '25-500', label: '£25 to £500' },
    { value: '500.01-1000', label: '£500.01 to £1000' },
    { value: '1000.01-3000', label: '£1000.01 to £3000' },
    { value: '3000.01-5000', label: '£3000.01 to £5000' },
    { value: '5000.01-7000', label: '£5000.01 to £7000' },
    { value: '7000.01-10000', label: '£7000.01 to £10k' },
  ]

  // What about VAT?
  const claimIssuePricing = [
    { value: '0-500', label: '50' },
    { value: '500.01-1000', label: '70' },
    { value: '1000.01-3000', label: '80' },
    { value: '3000.01-5000', label: '100' },
    { value: '5000.01-7000', label: '300' },
    { value: '7000.01-10000', label: '500' },
  ]

  const handleClaimsCountChange = (value: string | null) => {
    setClaimsCount(value)
  }

  const handleClaimAmountChange = (value: string | null) => {
    setClaimAmount(value)
  }

  const priceMessage = useMemo(() => {
    if (claimsCount === '1' && claimAmount) {
      const lowerBound = parseFloat(claimAmount.split('-')[0])

      const pricing = claimIssuePricing.find((p) => {
        const [min, max] = p.value.split('-').map(parseFloat)
        return lowerBound >= min && lowerBound <= max
      })

      if (pricing) {
        return `In that scenario, "Garfield's" fee will be £${pricing.label}`
      }
    }
    return ''
  }, [claimsCount, claimAmount])

  return (
    <Container
      fluid
      p={0}
      m={0}
      style={{ height: '100%', paddingLeft: '20px' }}
    >
      <Center style={{ height: '100%' }}>
        <div>
          <br></br>
          <Text size='lg'>
            On average, how many claims do you expect to bring in the next 12
            months?
          </Text>
          <Select
            data={claimsCountOptions}
            value={claimsCount}
            onChange={handleClaimsCountChange}
            placeholder='Select option'
          />
          <br></br>

          {claimsCount && claimsCount !== 'more-30' && (
            <>
              <Text size='lg' mt='md'>
                How much do you expect to be claiming, on average, per claim?
              </Text>
              <Select
                data={claimAmountOptions}
                value={claimAmount}
                onChange={handleClaimAmountChange}
                placeholder='Select option'
              />
            </>
          )}

          {claimsCount === 'more-30' && (
            <Text>
              Please email bulk_enquiries@projectgarfield.co.uk for bespoke
              pricing.
            </Text>
          )}
          <br></br>

          {priceMessage && <Text>{priceMessage}</Text>}
        </div>
      </Center>
    </Container>
  )
}

export default PricingPage
