import { Text } from '@mantine/core'

export default function TermsAndConditions() {
  return (
    <div style={{ width: '100%', padding: '0 20px' }}>
      <br></br>
      <Text>
        <strong>WELCOME TO OUR WAITLIST</strong>
        <br />
        <br></br>
        <strong>1. Joining our waitlist.</strong> We are releasing access to
        Garfield in limited batches of accounts. At this time, we are inviting
        all prospective users to join our waitlist by giving us their email
        address and certain other details. By completing this form, you are
        representing and warranting to us that the details you give us are
        accurate and you are a bonafide business with a bonafide reason to use
        Garfield.
        <br />
        <strong>2. Our waitlist.</strong> Joining the waitlist does not
        automatically commit you to signing up for an account with Garfield and
        it does not guarantee we will provide access to Garfield to you, though
        of course we hope to do so.
        <br />
        <strong>3. Providing updates.</strong> While you are on our waitlist, we
        may occasionally send you emails with updates about Garfield.
        <br />
        <strong>4. Confirming your order.</strong> If you are on our waitlist
        when we release a limited batch of accounts and are selected for one of
        these accounts, we will contact you via email to give you the green
        light and ask you to sign up for the account. To make sure our waitlist
        is fair, we reserve the right to give you a specific window of time in
        which to sign up for an account before we offer accounts to other
        applicants on the waitlist. Please don't miss it!
        <br />
        <strong>5. Our right to cancel.</strong> We reserve our right not to
        permit access to, or grant any account with, Garfield to any applicant
        who is on our waitlist, in our absolute discretion. We may let you know
        by email if your waitlist position has been canceled.
        <br />
        <strong>6. Ending the waitlist.</strong> We may at a future time
        discontinue use of the waitlist and permit all prospective users to sign
        up for an account with Garfield. If you are still on the waitlist at
        that time, we will email you to let you know that you can do this.
      </Text>
    </div>
  )
}
