import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const DECISION = ['accept', 'accept_part', 'reject']

const LBA_RESPONSE_TYPE = [
  'no_response',
  'non_meaningful',
  'stalling',
  'legitimate',
  'settlement_offer',
  'request_for_time_or_indulgence',
]

const LbaDetails = types
  .model('LbaDetails', {
    sent_date: types.maybeNull(FirestoreTimestamp),
    response_date: types.maybeNull(FirestoreTimestamp),
    response_type: types.maybeNull(
      types.enumeration('LbaResponseType', LBA_RESPONSE_TYPE)
    ),
    settlement_offer_decision: types.maybeNull(
      types.enumeration('Decision', DECISION)
    ),
    response_decision: types.maybeNull(types.enumeration('Decision', DECISION)),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<ILbaDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default LbaDetails
export interface ILbaDetails extends Instance<typeof LbaDetails> {}
