import { Instance, types } from 'mobx-state-tree'
import IAddress from '../claim/address'
import IUser from './member'
import Address from '../claim/address'

// Model of the user data
const User = types
  .model('User', {
    uid: types.string,
    account_id: types.string,
    email: types.string,
    onboarded: types.boolean,
    member: types.optional(types.boolean, false),
    verified: types.enumeration('verification', [
      'pending',
      'approved',
      'rejected',
    ]),
    tos_accepted: types.optional(types.maybeNull(types.string), null),
    // claim_ids: types.optional(types.array(types.string), []),
    entity_description: types.optional(types.maybeNull(types.string), null),
    entity_name: types.optional(types.maybeNull(types.string), null),
    first_name: types.optional(types.maybeNull(types.string), null),
    last_name: types.optional(types.maybeNull(types.string), null),
    inc_date: types.optional(types.maybeNull(types.string), null),
    inc_number: types.optional(types.maybeNull(types.string), null),
    onboarding_step: types.optional(types.maybeNull(types.number), null),
    invite_step: types.optional(types.maybeNull(types.number), null),
    registered_address: types.optional(types.maybeNull(IAddress), {
      first_line: null,
      second_line: null,
      town_city: null,
      county: null,
      postcode: null,
      country: 'United Kingdom',
    }),
    trading_address: types.optional(types.maybeNull(IAddress), null),
    has_trading_address: types.optional(types.boolean, true),
    type: types.optional(types.maybeNull(types.string), null),
    vat: types.optional(types.maybeNull(types.string), null),
    verification_token: types.maybeNull(types.string),
    token_expiration: types.maybeNull(types.string),
    claim_number_estimate: types.maybeNull(types.number),
    claim_value_estimate: types.maybeNull(types.number),
    last_bank_account_used: types.maybeNull(types.string),
    signature_prefix: types.optional(types.maybeNull(types.string), null),
    letterhead_prefix: types.optional(types.maybeNull(types.string), null),
    letterhead_type: types.optional(types.maybeNull(types.enumeration('letterhead_type', 
      ['garfield', 'custom', 'simple'])), null),
    letterhead_coordinates: types.optional(types.maybeNull(types.array(types.number)), []),
    launch_version: types.maybeNull(types.string),
  })
  .actions((self) => ({}))

export default User
export interface IUser extends Instance<typeof User> {}
