// base_claim_details.ts
import { Instance, types } from 'mobx-state-tree'

const BaseClaimDetails = types
  .model('BaseClaimDetails', {
    initial_approach_decision: types.maybeNull(
      types.enumeration(['chaser', 'letter_before_action', 'claim_form'])
    ),
    summary: types.maybeNull(types.string),
    witness_required: types.maybeNull(types.boolean),
    preferred_court: types.maybeNull(types.string),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<IBaseClaimDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default BaseClaimDetails
export interface IBaseClaimDetails extends Instance<typeof BaseClaimDetails> {}
