import { print } from '../utils/print'
import { firebase } from './firebase_service'
import Config from '../config'
import { analytics } from 'services/analytics_service'

class SpeechService {
  async textToSpeech(accountId: string, claimId: string, messageId: string) {
    const body = {
      account_id: accountId,
      claim_id: claimId,
      message_id: messageId,
    }
    try {
      const result = await firebase.callHttpFunction(Config.SPEECH_URL, body)
      const audio = result.audio
      const timepoints = result.timepoints
      return { audio, timepoints }
    } catch (error) {
      analytics.error(error)
    }
  }
}

export const speech = new SpeechService()
