// Merged claim_details.ts
import { Instance, types } from 'mobx-state-tree'
import BaseClaimDetails from './base_claim_details'
import InvalidArgumentsDetails from './invalid_arguments_details'
import PostActionBaseDetails from './post_action_base_details'
import LbaDetails from './lba_details'
import ClaimFormDetails from './claim_form_details'
import NoticeOfIssueDetails from './notice_of_issue_details'
import AcknowledgementOfServiceDetails from './acknowledgement_of_service_details'
import AdmissionDetails from './admission_details'
import PartialAdmissionDetails from './partial_admission_details'
import CounterclaimDetails from './counterclaim_details'
import DefenceDetails from './defence_details'
import DirectionsQuestionnaireDetails from './directions_questionnaire_details'
import CourtDirectionsDetails from './court_directions_details'
import RequestForJudgmentDetails from './request_for_judgment_details'
import CourtBundleDetails from './court_bundle_details'
import ChaserDetails from './chaser_details'
// import CorrespondenceDetails from './correspondence_details'

const ClaimDetails = types
  .model('ClaimDetails', {
    base_claim_details: types.maybeNull(BaseClaimDetails),
    // correspondence_details: types.maybeNull(CorrespondenceDetails),
    invalid_arguments_details: types.maybeNull(InvalidArgumentsDetails),
    post_action_base_details: types.maybeNull(PostActionBaseDetails),
    lba_details: types.maybeNull(LbaDetails),
    chaser_details: types.maybeNull(ChaserDetails),
    claim_form_details: types.maybeNull(ClaimFormDetails),
    notice_of_issue_details: types.maybeNull(NoticeOfIssueDetails),
    acknowledgement_of_service_details: types.maybeNull(
      AcknowledgementOfServiceDetails
    ),
    admission_details: types.maybeNull(AdmissionDetails),
    partial_admission_details: types.maybeNull(PartialAdmissionDetails),
    counterclaim_details: types.maybeNull(CounterclaimDetails),
    defence_details: types.maybeNull(DefenceDetails),
    directions_questionnaire_details: types.maybeNull(
      DirectionsQuestionnaireDetails
    ),
    court_directions_details: types.maybeNull(CourtDirectionsDetails),
    request_for_judgment_details: types.maybeNull(RequestForJudgmentDetails),
    court_bundle_details: types.maybeNull(CourtBundleDetails),
  })
  .actions((self) => ({
    update(updatedDetails: Partial<IClaimDetails>) {
      Object.assign(self, updatedDetails)
    },
  }))

export default ClaimDetails
export interface IClaimDetails extends Instance<typeof ClaimDetails> {}
