import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import LandingPage from '../../pages/landing_page'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import ClaimsPage from '../../pages/claims_page'
import { Container, Skeleton, Stack } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { AppShell } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import SignInPage from '../../pages/signin_page'
import SignUpPage from '../../pages/signup_page'
import TeamPage from '../../pages/team_page'
import SettingsPage from '../../pages/settings_page'
import ClaimPage from '../../pages/claim_page'
import AboutPage from '../../pages/about_page'
import LegalPage from '../../pages/legal_page'
import ComplaintsPage from '../../pages/complaints_page'
import PrivacyPage from '../../pages/privacy_page'
import AccessibilityPage from '../../pages/accessibility_page'
import PricingPage from '../../pages/pricing_page'
import FaqPage from '../../pages/faq_page'
import { useEffect } from 'react'
import { modal } from '../../utils/modal'
import InvitePage from '../../pages/invite_page'
import WaitListPage from '../../pages/wait_list_page'
import { ClaimTabError } from '../errors/claim_tab_error'
import OpportunitiesPage from '../../pages/opportunities_page'
import TermsPage from 'pages/terms_page'

function AppRouter() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const { authStore: accountStore, navStore, claimStore } = useStores()
  const { height, width } = useViewportSize()

  // const [currentClaim, setCurrentClaim] = useState(claimStore.getCurrentClaim())

  const currentClaim = claimStore.currentClaim

  // useEffect(() => {
  //   setCurrentClaim(claimStore.currentClaim)
  // }, [claimStore.claims.length, claimStore.currentClaimId])

  

  useEffect(() => {
    if (accountStore.isLoading) {
      modal.loading(true)
    } else {
      modal.loading(false)
    }
  }, [accountStore.isLoading])

  return (
    <AppShell.Main>
      <Container w={width} m={0} px={'0'} fluid>
        {!accountStore.isLoading ? (
          <Routes>
            <Route
              path='/'
              element={
                accountStore.isOnboarded ? (
                  <Navigate to='/claims' replace />
                ) : (
                  <LandingPage />
                )
              }
            />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/pricing' element={<PricingPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/legal' element={<LegalPage />} />
            <Route path='/complaints' element={<ComplaintsPage />} />
            <Route path='/privacy' element={<PrivacyPage />} />
            <Route path='/accessibility' element={<AccessibilityPage />} />
            <Route path='/opportunities' element={<OpportunitiesPage />} />
            <Route path='/terms' element={<TermsPage />} />
            <Route
              path='/claims'
              element={
                accountStore.isOnboarded ? (
                  <ClaimsPage />
                ) : (
                  <Navigate to='/' replace />
                )
              }
            />
            <Route
              path='/claims/:claimId'
              element={
                accountStore.isOnboarded ? (
                  claimStore.currentClaimId != null ? (
                    currentClaim ? (
                      <ClaimPage />
                    ) : (
                      <ClaimTabError
                        error={Error('No current claim')}
                        resetErrorBoundary={null}
                      />
                    )
                  ) : (
                    <ClaimTabError
                      error={Error('No current claim ID')}
                      resetErrorBoundary={null}
                    />
                  )
                ) : (
                  <Navigate to='/' replace />
                )
              }
            />
            <Route
              path='/team'
              element={
                accountStore.isOnboarded ? (
                  <TeamPage />
                ) : (
                  <Navigate to='/' replace />
                )
              }
            ></Route>
            <Route
              path='/settings'
              element={
                accountStore.isOnboarded ? (
                  <SettingsPage />
                ) : (
                  <Navigate to='/' replace />
                )
              }
            ></Route>
            <Route
              path='/signup'
              element={
                accountStore.isOnboarded ? (
                  <Navigate to='/claims' replace />
                ) : (
                  <SignUpPage />
                )
              }
            ></Route>
            <Route
              path='/signin'
              element={
                accountStore.isOnboarded
                ? ( <Navigate to='/claims' replace /> )
                : accountStore.isAuthenticated ? <Navigate to='/signup' replace />
                : (<SignInPage />)
              }
            ></Route>
            <Route
              path='/invite'
              element={
                accountStore.isOnboarded ? (
                  <Navigate to='/claims' replace />
                ) : (
                  <InvitePage />
                )
              }
            ></Route>
            <Route path='/waitlist' element={<WaitListPage />}></Route>
          </Routes>
        ) : (
          <Stack p={'lg'} gap={'xs'}>
            <Skeleton
              py={'md'}
              height={5}
              width={170}
              radius='lg'
              color='white'
              animate
            />
            <Skeleton
              py={'md'}
              height={height - 80}
              radius='sm'
              color='white'
              animate
            />
          </Stack>
        )}
      </Container>
    </AppShell.Main>
  )
}

export default observer(AppRouter)
