import {
  Timeline,
  Text,
  Group,
  Stack,
  Flex,
  Space,
  ThemeIcon,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { IconExclamationCircle } from '@tabler/icons-react'

function MessageError() {
  return (
    <Timeline.Item mt={'xl'}>
      <Flex justify='start' align='start'>
        <ThemeIcon variant='light' radius='xl' size='xl' color='red'>
          <IconExclamationCircle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
        <Space w={'md'}></Space>
        <Stack gap={'xs'}>
          <Group>
            <Text size='md' mt={0} fw={700}>
              Error
            </Text>
          </Group>
          <Group>
            <Text span c='dimmed' size='md'>
              This message couldn't be displayed
            </Text>
          </Group>
        </Stack>
      </Flex>
    </Timeline.Item>
  )
}

export default observer(MessageError)
