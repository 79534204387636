import { useEffect, useState, useRef, useCallback } from 'react'
import {
  Text,
  Group,
  useMantineTheme,
  Loader,
  Stack,
  Card,
  Center,
  Container,
  Menu,
  ActionIcon,
  SimpleGrid,
  Button,
  Slider,
  rem,
  Badge
} from '@mantine/core'
import { IconHistory } from '@tabler/icons-react'
import { observer } from 'mobx-react'
import { IDocument } from '../../models/document/document'
import { Document as PDF, Page } from 'react-pdf'
import React from 'react'
import { IconDots, IconMinus, IconPlus } from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { isAlive } from 'mobx-state-tree'
import { MIME_TYPES } from '@mantine/dropzone'
// import ClaimSelectionBox from '../teams_page/claim_selection_box'
import VersionSelectorBox from '../claim_documents/version_selector'

type DocumentReaderProps = {
  selectedDocument: IDocument | null | undefined
  menu: {
    label: string
    icon: JSX.Element | null
    color: string
    action: () => void
  }[]
  visible: boolean
  documentVersions?: IDocument[]
}

function DocumentReader({
  selectedDocument,
  menu,
  visible,
  documentVersions
}: DocumentReaderProps) {
  const theme = useMantineTheme()
  const [scale, setScale] = useState(1)
  const [gridCols, setGridCols] = useState(1) // Changing this to default to most zoomed in
  const [currentPage, setCurrentPage] = useState(0)
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { documentStore } = useStores()
  const [numPages, setNumPages] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)

  const calculateScale = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth
      const pageWidth = 595 // Width of an A4 page at scale 1 in points (1 point = 1/72 of an inch)
      const scaleFactor = containerWidth / pageWidth

      // Clear the previous timeout if it exists
      if (debounceTimeoutRef.current !== null) {
        clearTimeout(debounceTimeoutRef.current)
      }

      // Set a new timeout
      debounceTimeoutRef.current = setTimeout(() => {
        setScale(scaleFactor)
      }, 300)
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateScale)
    const currentContainer = containerRef.current // Capture the current value of containerRef.current

    if (currentContainer) {
      resizeObserver.observe(currentContainer)
    }

    return () => {
      if (currentContainer) {
        // Use the captured value in the cleanup function
        resizeObserver.unobserve(currentContainer)
      }
    }
  }, [selectedDocument?.last_updated_date])

  useEffect(() => {
    loadDocument()
  }, [selectedDocument?.last_updated_date])

  const loadDocument = async () => {
    setLoading(true)
    if (selectedDocument && selectedDocument?.document_id && isAlive(selectedDocument)) {
      const url = await documentStore.getDocumentUrl(
        selectedDocument.document_id
      )
    }
    if (!selectedDocument?.url) return

    setLoading(false)
  }

  const scrollToPage = useCallback((node: HTMLDivElement | null) => {
    if (!node) return
    setTimeout(() => {
      node.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }, [])

  const handlePageClick = (pageIndex: number) => {
    setGridCols(1)
    setCurrentPage(pageIndex)
  }

  //@ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  if (!selectedDocument) {
    return (
      <>
        <Group
          grow
          preventGrowOverflow={false}
          w={'100%'}
          // h={height - 170}
          align='center'
          justify='flex-start'
        >
          <Center>
            <Text size='lg' fw={500} c={theme.colors.gray[4]}>
              No file selected
            </Text>
          </Center>
        </Group>
      </>
    )
  }

  let versionUsed = -1
  if (documentVersions && selectedDocument && documentVersions.length > 0 && 
    documentVersions[0].document_id !== selectedDocument.document_id) {
    versionUsed = documentVersions.length - documentVersions.findIndex(
      document => document.document_id === selectedDocument.document_id)
  }

  const getHeader = () => {
    return (
      <Group
        grow
        preventGrowOverflow
        gap={'sm'}
        align='center'
        justify='flex-start'
      >
        <Group preventGrowOverflow grow>
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            size='md'
            fw={600}
            w={containerRef.current?.clientWidth! / 1.85}
          >
            {selectedDocument?.name}
          </Text>
          {documentVersions && versionUsed !== -1 && (
             <Badge size="sm" variant="light">Version {versionUsed}/{documentVersions.length}</Badge>
          )}
        </Group>
        <Group justify='flex-end'>
          <Menu
            shadow='md'
            width={150}
            position='bottom-end'
            offset={8}
            transitionProps={{
              transition: 'fade',
              duration: 250,
            }}
          >
            <Group gap={'xs'} justify='center' align='center'>
              {containerRef.current?.clientWidth! > 300 ? (
                <Button
                w={30}
                p={0}
                disabled={gridCols === 10}
                onClick={() => setGridCols(Math.min(gridCols + 1, 10))}
                radius='xl'
                size='xs'
                color={theme.colors.gray[2]}
              >
                <IconMinus
                  stroke={5}
                  color={theme.colors.black[5]}
                  style={{ width: rem(10), height: rem(10) }}
                />
              </Button>
              ) : null}
              

              {containerRef.current?.clientWidth! > 550 ? (
                <Slider
                  label={null}
                  min={-10}
                  max={-1}
                  value={-gridCols}
                  onChange={(value) => setGridCols(-value)}
                  w={containerRef.current?.clientWidth! / 5}
                  color={theme.colors.black[5]}
                  styles={{
                    thumb: {
                      backgroundColor: theme.colors.black[5],
                      height: '8px',
                      width: '8px',
                    },
                    track: {
                      height: '3px',
                      // backgroundColor: theme.colors.black[5],
                    },
                  }}
                />
              ) : null}


              {containerRef.current?.clientWidth! > 300 ? (
                <Button
                w={30}
                p={0}
                disabled={gridCols === 1}
                onClick={() => setGridCols(Math.max(1, gridCols - 1))}
                radius='xl'
                size='xs'
                color={theme.colors.gray[2]}
              >
                <IconPlus
                  stroke={5}
                  color={theme.colors.black[5]}
                  style={{ width: rem(10), height: rem(10) }}
                />
              </Button>
              ) : null}

              
            </Group>
            {menu.length === 1 ? (
              <Button
                onClick={menu[0].action}
                color={theme.colors.gray[6]}
                radius='xl'
                size='xs'
              >
                {menu[0].icon}
              </Button>
            ) : (
              <>
                <Menu.Target>
                  <ActionIcon
                    variant='subtle'
                    color={theme.colors.black[5]}
                    // size='lg'
                    radius='xl'
                    aria-label='Settings'
                    w={20}
                  >
                    <IconDots
                      style={{ width: '20px', height: '20px' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>Document options</Menu.Label>
                  {menu.map((option) => {
                    return (
                      <Menu.Item
                        color={option.color}
                        leftSection={option.icon}
                        onClick={option.action}
                      >
                        {option.label}
                      </Menu.Item>
                    )
                  })}
                  {documentVersions && (
                  <Menu.Item
                  leftSection={<IconHistory style={{ width: rem(14), height: rem(14) }} />}
                  >
                    <VersionSelectorBox
                        documentVersions={documentVersions}
                        target={(toggleDropdown) => (
                          <Text
                            size='sm'
                            onClick={(event) => {
                              toggleDropdown()
                              event.stopPropagation()
                            }}
                          >
                            Versions
                          </Text>
                        )}
                      />
                  </Menu.Item>
                )}
                </Menu.Dropdown>
              </>
            )}
          </Menu>
        </Group>
      </Group>
    )
  }
  if (
    selectedDocument?.url &&
    (selectedDocument?.mime_type === MIME_TYPES.jpeg ||
      selectedDocument?.mime_type === MIME_TYPES.png)
  ) {
    return (
      <Card withBorder p={0} style={{ borderRadius: theme.radius.sm }}>
        <img
          src={selectedDocument?.url}
          alt='document'
          style={{ maxWidth: '100%' }}
        />
      </Card>
    )
  }

  return (
    <div ref={containerRef}>
      <Stack>
        {getHeader()}
        {loading ? (
          <Loader color={theme.colors.gray[5]} size={'xs'} />
        ) : visible ? (
          <PDF
            file={selectedDocument?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Container>
                <Center>
                  <Loader color={theme.colors.gray[5]} size={'xs'} />
                </Center>
              </Container>
            }
          >
            <SimpleGrid cols={gridCols} spacing='md'>
              {Array.from(new Array(numPages), (el, index) => (
                <Card
                  withBorder
                  shadow='md'
                  p={0}
                  style={{ borderRadius: theme.radius.sm }} //  radius='6px'
                  key={index}
                  onClick={() => handlePageClick(index)}
                  ref={
                    index === currentPage && gridCols === 1
                      ? scrollToPage
                      : null
                  }
                  {...(gridCols > 1 && { style: { cursor: 'pointer' } })} // making cards clickable when grid view is enabled
                >
                  <Page pageIndex={index} scale={scale / gridCols} />
                </Card>
              ))}
            </SimpleGrid>
          </PDF>
        ) : (
          <Card bg={theme.colors.gray[2]} radius={0} />
        )}
      </Stack>
    </div>
  )
}

export default observer(DocumentReader)
