import { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import {
  Paper,
  Group,
  rem,
  useMantineTheme,
  ActionIcon,
  FileButton,
  Textarea,
  Stack,
} from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
import {
  IconArrowUp,
  IconMicrophone,
  IconPaperclip,
  IconPlayerStopFilled,
} from '@tabler/icons-react'
import Message from '../../models/dialogue/message'


import { print } from '../../utils/print'
import { observer } from 'mobx-react'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import FileThumbnail from './file_thumbnail'
import { Attachment } from '../../models/document/attachment'
import { ErrorBoundary } from 'react-error-boundary'
import ThumbnailError from '../errors/thumbnail_error'
import { FileContext } from '../../pages/claim_page'
import { firebase } from '../../services/firebase_service'

function ChatInput() {
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const { height, width } = useViewportSize()
  const {
    dialogueStore,
    claimStore,
    authStore: accountStore,
    documentStore,
    navStore,
  } = useStores()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints['sm']})`)
  const resetRef = useRef<() => void>(null)
  const { attachments, setAttachments, addAttachment, handleFiles } =
    useContext(FileContext)
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition()
  const textareaRef = useRef(null)

  const [audioText, setAudioText] = useState('')
  const draftId = dialogueStore.currentDraftId
  const draft = dialogueStore.currentDraft
  const draftAttachments = dialogueStore.currentDraft?.attachment_ids

  useEffect(() => {
    if (transcript || audioText) {
      console.log('transcript', transcript)
      updateDraftWithText(audioText + transcript)
    }
  }, [transcript])

  useEffect(() => {
    const textareaElement = textareaRef.current

    if (textareaElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          // print(entry.target.clientHeight)
        }
      })

      resizeObserver.observe(textareaElement)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const startListening = async () => {
    setAudioText(draft?.text! + ' ')
    SpeechRecognition.startListening()
  }

  const sendDraft = async () => {
    if (!draftId) return
    if (draftAttachments) {
      console.log('draftAttachments', draftAttachments)
      const attachmentSets: Attachment[] = []
      console.log('currentDocuments', documentStore.currentDocuments)
      documentStore.currentDocuments.forEach(async (document) => {
        if (draftAttachments?.includes(document.document_id!)) {
          console.log('YUP ATTACHMENT')
          const attachment = attachments.find(
            (f) => f.file.name === document.name
          )!
          attachmentSets.push({
            claimId: claimStore.currentClaimId!,
            file: attachment.file,
            document: document,
          })
        }
      })

      await documentStore.uploadDocuments(attachmentSets)
      setAttachments([])
    }

    if (await dialogueStore.sendDraft(draftId)) {
      const newMessageId = await firebase.getUniqueIdForCollection([
        'users',
        accountStore.user?.account_id ?? '',
        'claims',
        claimStore.currentClaimId ?? '',
        'messages',
      ])
      dialogueStore.setNewCurrentDraft(newMessageId)
    }
  }

  const updateDraftWithText = async (messageText: string) => {
    if (!draftId) return
    dialogueStore.updateDraft(
      Message.create({
        claim_id: claimStore.currentClaimId,
        account_id: accountStore.user?.account_id,
        message_id: draftId,
        text: messageText,
        creator: 'user',
        visible: true,
      })
    )
    console.log('draft: ', draft)
  }

  const removeAttachment = (attachment: Attachment, index: number) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter((_, i) => i !== index)
    )
    dialogueStore.removeAttachmentFromDraft(
      attachment.document.document_id!,
      draft?.message_id!
    )
    documentStore.removeDocumentFromLocalState(attachment.document.document_id!)
    resetRef.current?.()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!!listening || dialogueStore.lastMessage?.creator === 'user') {
      return
    }

    if (
      navStore.enterSendsMessage &&
      event.key === 'Enter' &&
      !event.shiftKey
    ) {
      event.preventDefault()
      sendDraft()
    } else if (
      !navStore.enterSendsMessage &&
      event.key === 'Enter' &&
      event.shiftKey
    ) {
      event.preventDefault()
      sendDraft()
    }
  }

  return (
    <Paper mt={'xs'} mb={'xs'} w={'100%'} bg={'transparent'}>
      {attachments.length > 0 ? (
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <Group mx={'xs'} mb={'sm'} gap={'xs'}>
            {attachments.map((attachment, index) => {
              return (
                <ErrorBoundary
                  key={index}
                  FallbackComponent={ThumbnailError}
                  onError={(error) => print(error)}
                  onReset={(details) => {
                    // Reset the state of your app so the error doesn't happen again
                  }}
                >
                  <FileThumbnail
                    file={attachment.file}
                    index={index}
                    onClick={() => {
                      if (
                        !listening &&
                        dialogueStore.lastMessage?.creator !== 'user'
                      ) {
                        removeAttachment(attachment, index)
                      }
                    }}
                  />
                </ErrorBoundary>
              )
            })}
          </Group>
        </div>
      ) : null}
      <Textarea
        ref={textareaRef}
        minRows={1}
        maxRows={4}
        autosize
        radius='sm'
        error={listening}
        size='lg'
        variant='default'
        placeholder={
          listening
            ? 'Recording...'
            : 'Message Garfield, drag and drop files ...'
        }
        rightSectionWidth={60}
        leftSectionWidth={80}
        onChange={(e) => updateDraftWithText(e.target.value)}
        onKeyDown={handleKeyDown}
        value={draft?.text ?? ''}
        data-testid='message-input-area'
        leftSection={
          <Stack justify='flex-end' align='flex-end' h={'100%'} pb={13}>
            <Group gap={'xs'} justify='flex-start' align='flex-start'>
              <FileButton
                resetRef={resetRef}
                onChange={(files) => {
                  handleFiles(files)
                  resetRef.current?.()
                }}
                multiple
                disabled={!!listening}
              >
                {(props) => (
                  <ActionIcon
                    size={22}
                    radius='sm'
                    color={theme.colors.white[2]}
                    variant='subtle'
                    {...props}
                  >
                    <IconPaperclip
                      color={
                        listening ? theme.colors.gray[5] : theme.colors.gray[8]
                      }
                      style={{ width: rem(22), height: rem(22) }}
                      stroke={1.5}
                      scale={'md'}
                    />
                  </ActionIcon>
                )}
              </FileButton>
              {browserSupportsSpeechRecognition ? (
                listening ? (
                  <ActionIcon
                    size={22}
                    radius='xl'
                    variant='subtle'
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IconPlayerStopFilled
                      color={theme.colors.red[6]}
                      style={{
                        width: rem(18),
                        height: rem(18),
                        color: theme.colors.red[6],
                      }}
                      stroke={1.5}
                      scale={'md'}
                    />
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    size={22}
                    radius='sm'
                    color={theme.colors.white[2]}
                    variant='subtle'
                    onClick={() => startListening()}
                  >
                    <IconMicrophone
                      color={theme.colors.gray[8]}
                      style={{ width: rem(22), height: rem(22) }}
                      stroke={1.5}
                      scale={'md'}
                    />
                  </ActionIcon>
                )
              ) : null}
            </Group>
          </Stack>
        }
        rightSection={
          <ActionIcon
            size={32}
            radius='sm'
            color={theme.colors.black[2]}
            variant='filled'
            onClick={() => sendDraft()}
            disabled={
              // !!listening || dialogueStore.lastMessage?.sender === 'user'
              !!listening || dialogueStore.lastMessage?.creator === 'user'
            }
          >
            <IconArrowUp
              color={theme.colors.white[7]}
              style={{ width: rem(18), height: rem(18) }}
              stroke={3}
              data-testid='send-message-button'
            />
          </ActionIcon>
        }
      />
    </Paper>
  )
}

export default observer(ChatInput)
