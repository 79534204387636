// payment_offer.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const PAYMENT_TYPE = ['one_time', 'monthly']

const PaymentOffer = types
  .model('PaymentOffer', {
    // amount: types.maybeNull(types.number),
    // date: types.maybeNull(FirestoreTimestamp),
    // method: types.maybeNull(types.enumeration('PaymentType', PAYMENT_TYPE)),
    amount: types.maybeNull(types.number),
    date: types.maybeNull(FirestoreTimestamp),
    method: types.maybeNull(types.enumeration(['one_time', 'monthly'])),
  })
  .actions((self) => ({}))

export default PaymentOffer
export interface IPaymentOffer extends Instance<typeof PaymentOffer> {}
