

import { useViewportSize } from '@mantine/hooks'
import { CrashFallback } from '../errors/crash_fallback'

export const CrashModal = ({ error }: { error: Error }) => {
  const { height, width } = useViewportSize()

  return <CrashFallback error={error} resetErrorBoundary={null} />
}
