import {
  Button,
  useMantineTheme,
  Text,
} from '@mantine/core'
import {
  IconChevronLeft,
} from '@tabler/icons-react'
import { useState } from 'react'


import { useContext } from 'react'
import { PageContext } from './settlement_navbar'
import { Loader } from '@mantine/core'
import { Center } from '@mantine/core'

export const SettlementLetterReadingLetter = ({
  onGenerate,
}: {
  onGenerate?: (settlementId: string) => void
}) => {
  const theme = useMantineTheme()
  const { setPage, isEditing } = useContext(PageContext)
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
        <Button
          style={{
            borderRadius: '50%',
            width: '25px',
            height: '25px',
            padding: 0,
            backgroundColor: theme.colors.gray[2],
          }}
          onClick={() => setPage('letterList')}
        >
          <IconChevronLeft size={16} color={theme.colors.dark[7]} />
        </Button>
        <Text style={{ fontSize: theme.fontSizes.lg, fontWeight: 500 }}>
          {isEditing ? 'Offer from debtor' : 'New settlement letter'}
        </Text>
      </div>
      <Center style={{ height: '100%', flexDirection: 'column' }}>
        {isLoading && (
          <Loader size='lg' style={{ color: theme.colors.gray[6] }} />
        )}
        <Text
          style={{
            marginTop: theme.spacing.sm,
            color: theme.colors.gray[6],
            fontSize: theme.fontSizes.lg,
            fontWeight: 600,
          }}
        >
          Reading letter...
        </Text>
      </Center>
    </>
  )
}
