

//@ts-ignore
import { observer } from 'mobx-react'
import ChatTimeline from './dialogue_timeline'
import ChatInput from './dialogue_input'
import { IClaim } from '../../models/claim/claim'

export interface ChatTabProps {
  claim: IClaim
}

function DialogueTab({ claim }: ChatTabProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <ChatTimeline claim={claim} />
      </div>
      <ChatInput />
    </div>
  )
}

export default observer(DialogueTab)
