import {
  Container,
  Text,
  Divider,
  useMantineTheme,
  Title,
  Card,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMediaQuery } from '@mantine/hooks'

function DesktopLegalPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        paddingLeft: '16px',
        backgroundColor: theme.colors.white[5],
      }}
      p={'xl'}
      m={0}
      fluid
    >
      <Card
        shadow='sm'
        withBorder
        p={'xl'}
        radius={'md'}
        maw={width / 2}
        mx={'auto'}
      >
        <Card.Section p={'xl'}>
          <Title py={'md'} order={1} fw={800}>
            Legal and Regulatory Notice
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'xl'}>
          <Text>
            Project Garfield Limited ("PGL") is a limited company registered in
            England and Wales under number 14848790. Its registered office is at
            20-22 Wenlock Road, London, England N1 7GU.
            <br />
          </Text>
        </Card.Section>
      </Card>
    </Container>
  )
}

function MobileLegalPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        backgroundColor: theme.colors.white[5],
      }}
      p={'md'}
      m={0}
      fluid
    >
      <Card shadow='sm' withBorder p={'md'} radius={'md'} mx={'auto'}>
        <Card.Section p={'md'}>
          <Title py={'md'} order={2} fw={700}>
            Legal and Regulatory Notice
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'md'}>
          <Text size="sm">
            Project Garfield Limited ("PGL") is a limited company registered in
            England and Wales under number 14848790. Its registered office is at
            20-22 Wenlock Road, London, England N1 7GU.
          </Text>
        </Card.Section>
      </Card>
    </Container>
  )
}

function LegalPage() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  return isMobile ? <MobileLegalPage /> : <DesktopLegalPage />
}

export default LegalPage
