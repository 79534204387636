import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { getRootStore } from '../utils/get_root_store'
import User, { IUser } from '../models/auth/user'
import { firebase } from '../services/firebase_service'
import { sumsub } from '../services/sumsub_service'
import { analytics } from '../services/analytics_service'
import { ROLES } from '../models/auth/member'
import { v4 as uuidv4 } from 'uuid'
import { ISimpleType, IMSTArray } from 'mobx-state-tree'

// The Account Store is responsible for the authentication state and account data
export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybe(User),
    authEmail: types.maybeNull(types.string),
    authPassword: types.maybeNull(types.string),
    authConfirmPassword: types.maybeNull(types.string),
    tosAccepted: types.maybeNull(types.string),
    sumsubVerificationTtl: types.optional(types.number, 3600),
    isLoadingAuthState: types.optional(types.boolean, true),
  })
  // Views are read-only derived data of the state tree
  .views((store) => ({
    get isOnboarded() {
      return !!store.user && store.user.onboarded
    },
    get isAuthenticated() {
      return !!store.user
    },
    get isLoading() {
      return store.isLoadingAuthState
    },
    get currentSignature() {
      return store.user?.signature_prefix
    },
    get currentLetterheadType() {
      return store.user?.letterhead_type
    },
    get currentLetterheadPrefix() {
      return store.user?.letterhead_prefix
    },
    get currentLetterheadCoordinates() {
      return store.user?.letterhead_coordinates
    },
  }))
  // Actions are the only way to change the state tree
  .actions((store) => ({
    // Subscribes to authentication changes and controls what UI we show the user
    init() {
      try {
        this.toggleAuthLoading(true)
        firebase.subscribeToAuth((user) => {
          if (user) {
            const { uid } = user
            const isNewUser = Date.now() - new Date(user.metadata.creationTime).getTime() < 10000; // 10 seconds threshold
            if (!isNewUser) {
              this.subscribeToAccount(uid)
            }
            analytics.identify(uid, user.email)
          } else {
            this.removeAccount()
            this.toggleAuthLoading(false)
          }
        })
      } catch (error: any) {
        console.log('Error in auth store init:', error)
        analytics.crash(error)
      }
    },

    // Controls the auth loading state to avoid weird UI flashes while waiting for auth data
    toggleAuthLoading(value: boolean) {
      store.isLoadingAuthState = value
    },

    // Listens for changes in account data, triggers subscription other collection when retrieved
    subscribeToAccount(uid: string) {
      try {
        const { claimStore, memberStore, billingStore } = getRootStore(store)
        firebase.subscribeToAccount(uid, (snapshot) => {
          const accountData = snapshot.data()
          if (accountData) {
            console.log('Account data:', accountData)
            this.setAccount({ ...accountData })
            memberStore.subscribeToMembers()
            claimStore.subscribeToClaims()
            billingStore.init(accountData.member)
          } else {
            this.signOut()
          }
        })
      } catch (error: any) {
        console.log('Error in subscribeToAccount:', error)
        this.signOut()
        analytics.crash(error)
      }
    },

    // Sets account, believe or not
    setAccount(account: IUser) {
      store.user = account
    },

    // Only used for Auth screens to store inputs
    setAuthEmail(email: string) {
      store.authEmail = email
    },
    setAuthPassword(password: string) {
      store.authPassword = password
    },
    setAuthConfirmPassword(password: string) {
      store.authConfirmPassword = password
    },
    setTOSAccepted(accepted: boolean) {
      store.tosAccepted = accepted ? new Date().toISOString() : null
    },

    // Removes account, only called when unable to retrieve auth data
    removeAccount() {
      store.user = undefined
    },

    // Keeps track of onboarding progress
    updateOnboardingStep(step: number) {
      try {
        analytics.track('Progressed onboarding', { step: step })
        firebase.updateOnboardingStep(step)
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    // Keeps track of invite progress
    updateInviteStep(step: number) {
      try {
        analytics.track('Progressed invite', { step: step })
        firebase.updateInviteStep(step)
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    // Updates account doc in Firestore
    async updateUser(user: Partial<IUser>) {
      try {
        await firebase.updateUser(user)
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    async signIn(authEmail: string, authPassword: string) {
      try {
        if (await firebase.signIn(authEmail, authPassword)) {
          analytics.track('Signed in', { email: authEmail })
          return true
        }
      } catch (error) {
        console.log('Error in signIn:', error)
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
        return false
      }
    },

    // Triggers sign up flow
    async signUp() {
      try {
        const accountId = await firebase.signUp(
          store.authEmail!,
          store.authPassword!,
          store.authConfirmPassword!,
          store.tosAccepted!
        )
        if (accountId) {
          analytics.track('Signed up', { account_id: accountId, email: store.authEmail })
          return accountId
        }
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    async signUpMember(role: ROLES, accountId: string, memberId: string) {
      try {
        const id = await firebase.signUpMember(
          store.authEmail!,
          store.authPassword!,
          store.authConfirmPassword!,
          store.tosAccepted!,
          role,
          accountId,
          memberId
        )
        if (id) {
          analytics.track('Signed up as member', { member_id: memberId, account_id: accountId, email: store.authEmail })
          return id
        }
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    // Resets all stores local state and then signs out
    async signOut() {
      try {
        analytics.track('Signed out')
        const rootStore = getRootStore(store)
        rootStore.reset()
        return await firebase.signOut()
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    async deleteAccount() {
      try {
        analytics.track('Deleted account')
        return await firebase.deleteAccount()
      } catch (error) {
        analytics.error(error, store.user?.uid)
      }
    },

    // Calls SumSub to get a verification token for SumSub's portal to work
    async getVerificationToken() {
      try {
        let level =
          store.user?.type === 'organisation' ? 'organisations' : 'individuals'
        if (store.user?.member) level = 'members'
        const token = await sumsub.generateToken(
          store.user?.uid!,
          level,
          store.sumsubVerificationTtl
        )
        if (token) {
          return token
        }
      } catch (error) {
        const claimId = getRootStore(store).claimStore.currentClaimId
        analytics.error(error, store.user?.uid, claimId ?? undefined)
      }
    },

    async saveSignature(signatureData: string) {
      const user = store.user
      if (!user) return
      const uuid = uuidv4()
      const prefix = `users/${user.account_id}/signatures/signature-${uuid}.png`
      const uploaded = await firebase.uploadSignature(signatureData, prefix)
      if (!uploaded) return
      await firebase.updateUser({uid: user.uid, signature_prefix: prefix})
      analytics.track('Saved signature', { signature_prefix: prefix })
    },
    async uploadLetterheadPdf(letterheadData: string) {
      const user = getRootStore(store).user
      const prefix = `users/${user.account_id}/letterheads/letterhead-${uuidv4()}.pdf`
      const uploaded = await firebase.uploadSignature(letterheadData, prefix)
      if (!uploaded) return
      await firebase.updateUser({uid: user.uid, letterhead_prefix: prefix})
    },
    async saveLetterheadType(letterhead_type: string) {
      const user = getRootStore(store).user
      await firebase.updateUser({uid: user.uid, letterhead_type: letterhead_type})
    },
    async saveLetterheadCoordinates(letterhead_coordinates: number[]) {
      const user = getRootStore(store).user
      console.log('letterhead_coordinates', letterhead_coordinates)
      await firebase.updateUser({uid: user.uid, letterhead_coordinates: letterhead_coordinates as IMSTArray<ISimpleType<number>>})
    },
  }))

export interface AuthStore extends Instance<typeof AuthStoreModel> {}
export interface AuthStoreSnapshot extends SnapshotOut<typeof AuthStoreModel> {}
