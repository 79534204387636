import { observer } from 'mobx-react'
import {
  Button,
  Text,
  Indicator,
  Tooltip,
} from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import { MouseEventHandler, useState } from 'react'
import { IClaim } from '../../models/claim/claim'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import { IconFile, IconPin, IconPinFilled } from '@tabler/icons-react'

function ClaimButton({
  width,
  onClick,
  claim,
  canPin,
  collapsed,
}: {
  width: number
  onClick: MouseEventHandler<HTMLButtonElement>
  claim: IClaim
  canPin: boolean
  collapsed: boolean
}) {
  const theme = useMantineTheme()
  const { claimStore, navStore } = useStores()
  const [hovered, setHovered] = useState(false)
  const [deleteHovered, setDeleteHovered] = useState(false)
  const navigate = useNavigate()

  const selected = navStore.currentPath.includes(claim.displayId)
  const readyToSend = claim.readyToSend

  const handlePin = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()
    claimStore.pinClaim(claim)
  }
  const handleUnpin = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()
    claimStore.unpinClaim(claim)
  }

  return (
    <Tooltip label={`Claim: ${claim.displayName}`} disabled={!collapsed} position='right' offset={20}>
      <Button
      w={width}
      mah={35}
      mb={5}
      size='sm'
      variant={selected ? 'light' : 'subtle'}
      justify='left'
      leftSection={
        claim?.state?.pinned_date ? (
          <IconPinFilled
            onClick={handleUnpin}
            size={18}
            color={theme.colors.white[5]}
          />
        ) : canPin && hovered ? (
          <IconPin
            onClick={handlePin}
            size={18}
            color={theme.colors.white[5]}
          />
        ) : collapsed ? <IconFile size={18} color={theme.colors.gray[6]} /> : null
      }
      color={theme.colors.white[5]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      rightSection={
        readyToSend ? (
          <Indicator
            inline
            color='red'
            position='middle-center'
            size={8}
            processing
          />
        ) : null
      }
      style={{
        overflow: 'hidden',
      }}
    >
      {collapsed ? null : (
        <Text
          size={'xs'}
          c={hovered ? theme.colors.white[5] : theme.colors.gray[6]}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
            {claim.displayName}
          </Text>
      )}
    </Button>
    </Tooltip>
    
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(ClaimButton)

// hovered ? (
//   <IconTrash
//     size={18}
//     color={deleteHovered ? theme.colors.red[5] : theme.colors.white[5]}
//     opacity={deleteHovered ? 1 : 0.25}
//     onClick={async (e) => {
//       e.stopPropagation()
//       const deleted = await claimStore.deleteClaim(claim.claim_id)
//       if (deleted) {
//         if (navStore.currentRoute?.path?.includes(claim.displayId)) {
//           navigate('/')
//         }
//       }
//     }}
//     onMouseEnter={() => setDeleteHovered(true)}
//     onMouseLeave={() => setDeleteHovered(false)}
//   />
// ) : null
