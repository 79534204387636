import {
  Button,
  Card,
  useMantineTheme,
  Group,
  Text,
  ScrollArea,
  Select,
  Radio,
  NumberInput,
  NumberInputHandlers,
  Alert,
} from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import {
  IconCalendar,
  IconChevronLeft,
  IconAlertCircle,
} from '@tabler/icons-react'
import { useRef, useState } from 'react'
import { DatePickerInput } from '@mantine/dates'
import SettlementDetails, {
  ISettlementDetails,
} from '../../models/document/settlement_details'
import Document, { IDocument } from '../../models/document/document'
import { useContext } from 'react'
import { PageContext } from './settlement_navbar'
import { firebase } from '../../services/firebase_service'

const placeholderDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 14) // Add 14 days to current date

  const day = date.getDay()
  if (day === 0) {
    // If it's Sunday
    date.setDate(date.getDate() + 1) // Add one day to make it Monday
  } else if (day === 6) {
    // If it's Saturday
    date.setDate(date.getDate() + 2) // Add two days to make it Monday
  }

  return date
}

export const SettlementLetterEditor = ({
  onGenerate,
}: {
  onGenerate?: (settlementId: string) => void
}) => {
  const theme = useMantineTheme()
  const { documentStore, authStore: accountStore, claimStore } = useStores()
  const settlements = documentStore.currentSettlements ?? []
  const selectedSettlement: IDocument | null =
    documentStore.selectedSettlementDocument
  const settlementDetails = selectedSettlement?.details as ISettlementDetails
  const { setPage, isEditing } = useContext(PageContext)

  // Setting the state
  // If in edit mode, use the existing values
  // If in create mode, use the placeholder values
  const [deadline, setDeadline] = useState<Date>(
    isEditing
      ? settlementDetails?.deadline_date?.toDate() ?? placeholderDate()
      : placeholderDate()
  )
  const [type, setType] = useState<string>(
    isEditing ? settlementDetails?.type ?? 'offer' : 'offer'
  )
  const [referenceId, setReferenceId] = useState<string | null>(
    isEditing ? settlementDetails?.reference_document_id ?? null : null
  )
  const [amount, setAmount] = useState<number>(
    isEditing ? settlementDetails?.amount ?? 25 : 25
  )
  const handlersRef = useRef<NumberInputHandlers>(null)

  const handleSubmit = async () => {
    if (!selectedSettlement) {
      // throw new Error('No selected settlement')
      return
    }
    const generatedDocumentId = await firebase.getUniqueIdForCollection(
      [
        'users',
        accountStore.user?.account_id ?? '',
        'claims',
        claimStore.currentClaimId ?? '',
        'documents',
      ],
      '.pdf'
    )
    const documentId = isEditing
      ? selectedSettlement?.document_id
      : generatedDocumentId

    if (isEditing) {
      const updatedDocument: IDocument = {
        ...selectedSettlement,
        details: {
          type: type,
          amount: amount,
          deadline_date: deadline,
          reference_document_id: referenceId,
          received_date: selectedSettlement?.details?.received_date,
          sent_date: selectedSettlement?.details?.sent_date,
        },
      }
      await documentStore.updateDocument(updatedDocument)
    } else {
      const partialDocument = Document.create({
        document_id: documentId,
        creator: 'garfield',
        details: SettlementDetails.create({
          type: type,
          amount: amount,
          deadline_date: deadline,
          reference_document_id: referenceId,
        }),
        mime_type: 'application/pdf',
        extension: 'pdf',
      })
      await documentStore.createSettlementDocument(partialDocument)
    }
    setPage('letterList')
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
        <Button
          style={{
            borderRadius: '50%',
            width: '25px',
            height: '25px',
            padding: 0,
            backgroundColor: theme.colors.gray[2],
          }}
          onClick={() => setPage('letterList')}
        >
          <IconChevronLeft size={16} color={theme.colors.dark[7]} />
        </Button>
        <Text size='lg' fw={500}>
          {isEditing ? 'Offer from debtor' : 'New settlement letter'}
        </Text>
      </div>
      {isEditing && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title='Did Garfield miss something?'
          color='gray'
        >
          Please, correct any information that doesn't match the text in the
          letter.
        </Alert>
      )}
      <ScrollArea py={0} px={0}>
        <Card py={'sm'} px={0}>
          <Text size='sm' fw={700} c={theme.colors.gray[7]}>
            In response to
          </Text>
          <Text size='xs' fw={400} c={theme.colors.gray[5]}>
            Optional field. Select a letter from the debtor if you want to reply
            to it.
          </Text>
          <Select
            mt={'xs'}
            value={referenceId}
            placeholder='Letter to reply to'
            data={settlements.map((settlement) => settlement.document_id!)}
            onChange={setReferenceId}
          />
        </Card>
        <>
          <Card py={'sm'} px={0}>
            <Radio.Group
              value={type}
              onChange={setType}
              // name='favoriteFramework'
              label={
                <Text size='sm' fw={700} c={theme.colors.gray[7]}>
                  Action
                </Text>
              }
              description={
                <Text size='xs' fw={400} c={theme.colors.gray[5]}>
                  Whether you'd like to reject, accept or propose a new offer.
                </Text>
              }
              // withAsterisk
            >
              <Group mt='xs'>
                <Radio checked={type === 'offer'} value='offer' label='Offer' />
                <Radio
                  checked={type === 'acceptance'}
                  value='acceptance'
                  label='Accept'
                />
                <Radio
                  checked={type === 'rejection'}
                  value='rejection'
                  label='Reject'
                />
              </Group>
            </Radio.Group>
          </Card>
        </>
        <Card py={'sm'} px={0}>
          <Text size='sm' fw={700} c={theme.colors.gray[7]}>
            Deadline for debtor to reply
          </Text>
          <Text size='xs' fw={400} c={theme.colors.gray[5]}>
            How long before the offer expires
          </Text>
          <DatePickerInput
            //@ts-ignore
            placeholder={deadline}
            dropdownType='popover'
            mt={'xs'}
            value={deadline}
            onChange={(date) => {
              if (date) {
                setDeadline(date)
              }
            }}
            rightSection={
              <IconCalendar size={18} color={theme.colors.gray[5]} />
            }
          />
        </Card>

        <Card py={'sm'} px={0}>
          <Text size='sm' fw={700} c={theme.colors.gray[7]}>
            Amount
          </Text>
          <Text size='xs' fw={400} c={theme.colors.gray[5]}>
            The amount you'd be willing to accept
          </Text>
          <NumberInput
            mt={'xs'}
            handlersRef={handlersRef}
            prefix='£'
            step={25}
            min={25}
            max={100000}
            defaultValue={25}
            value={amount}
            onChange={(value) =>
              setAmount(typeof value === 'string' ? parseInt(value) : value)
            }
          />
        </Card>
      </ScrollArea>
      <Card.Section p={'xs'} pt={'lg'}>
        <Button maw={200} onClick={handleSubmit}>
          {isEditing ? 'Update letter' : 'Generate letter'}
        </Button>
      </Card.Section>
    </>
  )
}
