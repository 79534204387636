// settlement.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'
import * as changeCase from 'change-case'
import BaseDocumentDetails from './base_document_details'

const SettlementDetails = types
  .compose(
    BaseDocumentDetails,
    types.model('SettlementDetails', {
      type: types.maybeNull(
        types.enumeration([
          'offer',
          'received_offer',
          'counter_offer',
          'received_counter_offer',
          'acceptance',
          'received_acceptance',
          'rejection',
          'received_rejection',
        ])
      ),
      amount: types.maybeNull(types.number),
      deadline_date: types.maybeNull(FirestoreTimestamp),
      reference_document_id: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get readableType() {
      return changeCase.capitalCase(self.type ?? '')
    },
  }))
  .actions((self) => ({}))

export default SettlementDetails
export interface ISettlementDetails
  extends Instance<typeof SettlementDetails> {}
