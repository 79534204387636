import { IClaim } from '../../models/claim/claim'
import { useState, createContext } from 'react'
import { rem } from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import { IDocument } from '../../models/document/document'
import ReaderTabLayout from '../shared/reader_tab_layout'
import { IconArrowBackUp, IconCheck, IconX } from '@tabler/icons-react'
import SettlementCard from './settlement_card'
import { SettlementLetterEditor } from './settlement_letter_editor'
import SettlementLetters from './settlement_letters'
import { useRef, useEffect } from 'react'
import { RefObject } from 'react'
import { SettlementLetterReadingLetter } from './settlement_letter_reading_letter'

export interface SettlementProps {
  claim: IClaim
}

interface PageContextType {
  page: string
  setPage: (page: string) => void
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  tabLayoutRef: RefObject<HTMLDivElement> | null
}

export const PageContext = createContext<PageContextType>({
  page: 'letterList',
  setPage: (page: string) => {},
  isEditing: false,
  setIsEditing: (isEditing: boolean) => {},
  tabLayoutRef: null,
})

function SettlementTabDashboard({ claim }: SettlementProps) {
  const { documentStore } = useStores()
  const settlements: IDocument[] = documentStore.currentSettlements
  const selectedSettlement = documentStore.selectedSettlementDocument
  const [page, setPage] = useState('letterList')
  const [isEditing, setIsEditing] = useState(false)
  const tabLayoutRef = useRef<HTMLDivElement>(null)
  const [parentContainerHeight, setParentContainerHeight] = useState(500)

  const onGenerate = () => {}

  useEffect(() => {
    const containerElement = tabLayoutRef.current
    if (!containerElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const rect = entry.target.getBoundingClientRect()
        const visibleArea = Math.min(rect.bottom, window.innerHeight) - rect.top
        setParentContainerHeight(visibleArea)
      }
    })

    resizeObserver.observe(containerElement)

    return () => {
      resizeObserver.unobserve(containerElement)
    }
  }, [tabLayoutRef])

  const getControlPanel = () => {
    return (
      <div style={{ height: parentContainerHeight - 88, overflow: 'auto' }}>
        {page === 'settlement' ? (
          <SettlementCard settlement={settlements[0]} />
        ) : null}
        {page === 'letterList' ? <SettlementLetters claim={claim} /> : null}
        {/* If the selected settlement document is_processing, show a loading indicator */}
        {page === 'letterEdit' ? (
          selectedSettlement?.processing ? (
            <SettlementLetterReadingLetter />
          ) : (
            <SettlementLetterEditor onGenerate={onGenerate} />
          )
        ) : null}
      </div>
    )
  }

  return (
    <>
      <div style={{ minHeight: '90vh' }} ref={tabLayoutRef}>
        <PageContext.Provider
          value={{ page, setPage, isEditing, setIsEditing, tabLayoutRef }}
        >
          <ReaderTabLayout
            selectedDocument={
              selectedSettlement?.processing ? null : selectedSettlement
            }
            position='left'
            menu={[
              {
                label: 'Accept',
                color: 'green',
                icon: <IconCheck style={{ width: rem(14), height: rem(14) }} />,
                action: () => {},
              },
              {
                label: 'Reject',
                color: 'red',
                icon: <IconX style={{ width: rem(14), height: rem(14) }} />,
                action: () => {},
              },
              {
                label: 'Counter-offer',
                color: 'orange',
                icon: (
                  <IconArrowBackUp
                    style={{ width: rem(14), height: rem(14) }}
                  />
                ),
                action: () => {},
              },
            ]}
            controlPanel={getControlPanel()}
            width={370}
          />
        </PageContext.Provider>
      </div>
    </>
  )
}

export default observer(SettlementTabDashboard)
