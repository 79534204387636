import Config from '../config'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { IMSTArray, ISimpleType } from 'mobx-state-tree'
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
  collection,
  onSnapshot,
  writeBatch,
  getDocs,
  query,
  where,
  DocumentData,
  QuerySnapshot,
  orderBy,
  limit,
  connectFirestoreEmulator,
  Timestamp,
} from 'firebase/firestore'

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  connectStorageEmulator,
} from 'firebase/storage'

import {
  initializeAuth,
  browserLocalPersistence,
  onAuthStateChanged,
  createUserWithEmailAndPassword as createAccountWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  deleteUser,
  fetchSignInMethodsForEmail,
  connectAuthEmulator,
} from 'firebase/auth'
import User, { IUser } from '../models/auth/user'
import Claim, { IClaim } from '../models/claim/claim'
import Message, { IMessage } from '../models/dialogue/message'
import ClaimState, { CLAIMSTAGE } from '../models/claim/claim_state'
import ClaimDetails from '../models/claim/claim_details'
import Contract from '../models/claim/contract'
import ContractDetails from '../models/claim/contract_details'
import Party from '../models/claim/party'
import Address from '../models/claim/address'
import { analytics } from 'services/analytics_service'
// import CorrespondenceDetails from '../models/claim/correspondence_details'

import { v4 as uuidv4 } from 'uuid'
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions'
import Member, { IMember, ROLES, STATUS } from '../models/auth/member'
import LbaDetails from '../models/claim/lba_details'
import ClaimFormDetails from '../models/claim/claim_form_details'
import NoticeOfIssueDetails from '../models/claim/notice_of_issue_details'
import AcknowledgementOfServiceDetails from '../models/claim/acknowledgement_of_service_details'
import AdmissionDetails from '../models/claim/admission_details'
import PartialAdmissionDetails from '../models/claim/partial_admission_details'
import CounterclaimDetails from '../models/claim/counterclaim_details'
import DirectionsQuestionnaireDetails from '../models/claim/directions_questionnaire_details'
import RequestForJudgmentDetails from '../models/claim/request_for_judgment_details'
import mime from 'mime'
import { IDocument } from '../models/document/document'
import { Attachment } from '../models/document/attachment'
import { notification } from '../utils/notification'
import { IInvite } from '../models/auth/invite'
import BankAccount, { IBankAccount } from 'models/billing/bank_account'
import { DuplicateBankAccountError } from 'utils/errors'

// Sets up firebase configuration based on the environment
const firebaseConfig = {
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
}

// Initialize firebase app
export const firebaseApp = initializeApp(firebaseConfig)
// Initialize Firebase App Check
if (Config.ENV !== 'production') {
  // @ts-ignore: Unreachable code error
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = Config.APPCHECK_DEBUG_TOKEN
}
// @ts-ignore: Unreachable code error
window.FIREBASE_APPCHECK_DEBUG_TOKEN = Config.APPCHECK_DEBUG_TOKEN

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(Config.RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: Config.ENV !== 'production',
})
// Initialize firestore
export const db = getFirestore(firebaseApp)
// Initialize auth
const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
})
export const functions = getFunctions(firebaseApp, 'europe-west2')
const storage = getStorage(firebaseApp)

// Firestore and Functions emulator setup
if (Config.ENV === 'emulator') {
  // Connect to Functions emulator
  connectFunctionsEmulator(functions, 'localhost', 5001)
  // Connect to Functions emulator
  connectFirestoreEmulator(db, 'localhost', 8080)
  // Connect to Auth emulator
  connectAuthEmulator(auth, 'http://localhost:9099')
  // Connect to Storage emulator
  connectStorageEmulator(storage, 'localhost', 9199)
}

// This is a service class that handles all the firebase calls
class FirebaseService {
  // Returns a callback to the auth store when auth state changes
  async subscribeToAuth(callback: (account: any) => void) {
    console.log('Subscribing to auth')
    onAuthStateChanged(auth, (snapshot: any) => {
      callback(snapshot)
    })
  }

  // Returns a callback to the auth store when user data changes
  subscribeToAccount(
    uid: string,
    callback: (snapshot: any) => void
  ): () => void {
    console.log('Subscribing to account:', uid)
    const accountRef = doc(db, 'users', uid)
    const unsubscribe = onSnapshot(accountRef, (snapshot) => {
      callback(snapshot)
    })
    return unsubscribe
  }

  // Returns a callback to the claims store when the claims change
  subscribeToClaims(
    accountId: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    console.log('Subscribing to claims:', accountId)
    const claimsRef = collection(db, 'users', accountId, 'claims')
    const unsubscribe = onSnapshot(claimsRef, (snapshot) => {
      callback(snapshot)
    })
    return unsubscribe
  }

  async setEmailDecision(
    accountId: string,
    claimId: string,
    messageId: string,
    decision: 'approved' | 'rejected' | 'undecided'
  ) {
    const messageRef = doc(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'messages',
      messageId
    )
    await updateDoc(messageRef, {
      email_decision: decision,
    })
  }

  // subscribeToClaims(
  //   accountId: string,
  //   claimIds: string[], // Array of claim IDs to listen to
  //   callback: (snapshot: QuerySnapshot<DocumentData>) => void
  // ): () => void {
  //   const claimsRef = collection(db, 'users', accountId, 'claims')
  //   const q = query(claimsRef, where('__name__', 'in', claimIds))
  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     callback(snapshot)
  //   })
  //   return unsubscribe
  // }

  // Returns a callback to the chat store with the messages for a given claim
  subscribeToMessages(
    accountId: string,
    claimId: string,
    limitCount: number,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    console.log('Subscribing to messages:', accountId, claimId)
    const messagesRef = collection(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'messages'
    )
    const q = query(
      messagesRef,
      orderBy('created_date', 'desc'),
      limit(limitCount)
    )
    const unsubscribe = onSnapshot(q, (snapshot) => {
      callback(snapshot)
    })

    return unsubscribe
  }

  // Returns a callback to the dialogue store with the documents for a given claim
  subscribeToDocuments(
    accountId: string,
    claimId: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    console.log('Subscribing to documents:', accountId, claimId)
    const documentsRef = collection(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'documents'
    )
    const unsubscribe = onSnapshot(documentsRef, (snapshot) => {
      callback(snapshot)
    })

    return unsubscribe
  }

  // Returns a callback to the account store when the users of an account change
  subscribeToMembers(
    accountId: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    console.log('Subscribing to members:', accountId)
    const claimsRef = collection(db, 'users', accountId, 'members')
    const unsubscribe = onSnapshot(claimsRef, (snapshot) => {
      callback(snapshot)
    })
    return unsubscribe
  }

  subscribeToBankAccounts(
    accountId: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void
  ): () => void {
    console.log('Subscribing to bank accounts:', accountId)
    const claimsRef = collection(db, 'users', accountId, 'bank_accounts')
    const unsubscribe = onSnapshot(claimsRef, (snapshot) => {
      callback(snapshot)
    })
    return unsubscribe
  }

  async createBankAccount(accountId: string, bankAccount: IBankAccount) {
    // Check for existing bank accounts with the same number
    const bankAccountsRef = collection(db, 'users', accountId, 'bank_accounts')
    const q = query(
      bankAccountsRef,
      where('bank_account_number', '==', bankAccount.bank_account_number)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
      throw new DuplicateBankAccountError(
        'A bank account with this number already exists'
      )
    }

    // If no existing account found, proceed with creating the new account
    const bankAccountRef = doc(
      db,
      'users',
      accountId,
      'bank_accounts',
      bankAccount.id
    )
    await setDoc(bankAccountRef, bankAccount)
  }

  async deleteBankAccount(accountId: string, bankAccount: IBankAccount) {
    const bankAccountRef = doc(
      db,
      'users',
      accountId,
      'bank_accounts',
      bankAccount.id
    )
    await deleteDoc(bankAccountRef)
  }

  async getMember(accountId: string, memberId: string): Promise<IMember> {
    const memberRef = doc(db, 'users', accountId, 'members', memberId)
    const memberSnapshot = await getDoc(memberRef)
    return memberSnapshot.data() as IMember
  }

    async updateUserDocs() {
    const body = {}
    try {
      await this.callHttpFunction(
        // 'https://update-users-ne67pvdjga-nw.a.run.app',
        'http://localhost:5001/garfield-7e41d/europe-west2/update_users', // For local testing
        body
      )
    } catch (error) {
      analytics.error(error)
    }
  }

  async callFunction(name: string, data: any) {
    const randomTestFunction = httpsCallable(functions, name)
    const result = await randomTestFunction(data)
    return result
  }

  async assignClaims(
    uid: string,
    account_id: string,
    role: ROLES,
    member_id?: string
  ) {
    const body = {
      uid: uid,
      account_id: account_id,
      member_id: member_id,
      role: role,
    }
    try {
      await this.callHttpFunction(
        // 'https://assign-custom-claims-ne67pvdjga-nw.a.run.app',
        // 'http://localhost:5001/garfield-7e41d/europe-west2/assign_custom_claims', // For local testing
        Config.ASSIGN_CUSTOM_CLAIMS_URL,
        body
      )
      await auth.currentUser?.getIdToken(true)
    } catch (error) {
      analytics.error(error)
    }
  }

  async callHttpFunction(url: string, data: any) {
    console.log('Calling function:', url)
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.status.toString().startsWith('2')) {
        throw new Error(`HTTP error! status: ${response.status}`)
      } else {
        if (!response.ok) {
          console.log('FOR SOME REASON THE RESPONSE IS NOT OK')
        }
      }
      const result = await response.json()
      console.log('Function result:', result)
      return result
    } catch (error) {
      console.log('Error calling function:', error)
      return null
    }
  }

  async signIn(email: string, password: string) {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
    return true
  }

  async signUp(
    email: string,
    password: string,
    confirmPassword: string,
    tosAccepted: string
  ) {
    if (password !== confirmPassword) {
      notification.warning(
        'The passwords do not match',
        'The password and the confirmation password are not the same.'
      )
      return null
    }

    if (await this.isNewAccount(email)) {
      await createAccountWithEmailAndPassword(auth, email, password)
      const account: IUser = User.create({
        account_id: auth.currentUser?.uid!,
        uid: auth.currentUser?.uid!,
        email: auth.currentUser?.email!,
        onboarded: false,
        member: false,
        verified: 'pending',
        onboarding_step: 0,
        tos_accepted: tosAccepted,
        launch_version: 'miniG'
      })
      const user: IMember = Member.create({
        uid: account.account_id,
        member_id: account.account_id,
        email: account.email,
        role: ROLES.owner,
        verified: 'pending',
        joined_date: Timestamp.now(),
      })
      await this.assignClaims(
        account.account_id,
        account.account_id,
        ROLES.owner,
        account.account_id
      )
      await this.setAccount(account)
      await this.setMember(account.account_id, user)

      return account.uid
    } else {
      notification.warning(
        'This account already exists',
        'Sign in or create a new account with a different email address'
      )
      return null
    }
  }

  async signUpMember(
    email: string,
    password: string,
    confirmPassword: string,
    tosAccepted: string,
    role: ROLES,
    accountId: string,
    memberId: string
  ) {
    if (password !== confirmPassword) {
      notification.warning(
        'The passwords do not match',
        'The password and the confirmation password are not the same.'
      )
      return null
    }

    if (await this.isNewAccount(email)) {
      await createAccountWithEmailAndPassword(auth, email, password)

      const account: IUser = User.create({
        account_id: accountId,
        uid: auth.currentUser?.uid!,
        email: auth.currentUser?.email!,
        member: true,
        onboarded: false,
        verified: 'pending',
        invite_step: 0,
        tos_accepted: tosAccepted,
      })
      await this.setAccount(account)
      // uid: string, account_id: string, role: ROLES, member_id?: string
      await this.assignClaims(account.uid, account.account_id, role, memberId)

      const member: IMember = Member.create({
        member_id: memberId,
        name: 'Random Name',
        uid: account.uid,
        role: role,
        email: auth.currentUser?.email!,
        verified: 'pending',
        status: STATUS.pending,
        joined_date: Timestamp.now(),
      })
      await this.updateMember(account.account_id, member)

      return accountId
    } else {
      notification.warning(
        'This account already exists',
        'Sign in or create a new account with a different email address'
      )
      return null
    }
  }

  async signOut() {
    await signOut(auth)
  }

  async isNewAccount(email: string): Promise<boolean> {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email)
    return signInMethods.length < 1
  }

  async deleteAccount() {
    const account = auth.currentUser
    if (account) {
      await deleteUser(account)
    }
  }

  async updateOnboardingStep(stepNumber: number): Promise<void> {
    const currentUser = auth.currentUser
    if (!currentUser) throw new Error('No account')
    const accountRef = doc(db, 'users', currentUser.uid)
    await updateDoc(accountRef, { onboarding_step: stepNumber })
  }

  async updateInviteStep(stepNumber: number): Promise<void> {
    const currentUser = auth.currentUser
    if (!currentUser) throw new Error('No account')
    const accountRef = doc(db, 'users', currentUser.uid)
    await updateDoc(accountRef, { invite_step: stepNumber })
  }

  convertToDotNotation<T extends object>(
    data: Partial<T>
  ): { [key: string]: any } {
    const updates: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(data)) {
      if (
        value !== null &&
        typeof value === 'object' &&
        !Array.isArray(value)
      ) {
        // Iterate over nested object properties
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          updates[`${key}.${nestedKey}`] = nestedValue
        }
      } else {
        updates[key] = value
      }
    }
    return updates
  }

  async updateUser(account: Partial<IUser>): Promise<void> {
    console.log({account})
    const accountRef = doc(db, 'users', account.uid!)
    console.log({accountRef})
    const updates = this.convertToDotNotation(account)
    console.log({updates})
    await updateDoc(accountRef, updates)
  }

  async updateMember(
    accountId: string,
    member: Partial<IMember>
  ): Promise<void> {
    const memberRef = doc(db, 'users', accountId, 'members', member.member_id!)
    const updates = this.convertToDotNotation(member)
    await updateDoc(memberRef, updates)
  }

  async setAccount(account: IUser): Promise<void> {
    const accountRef = doc(db, 'users', account.uid)
    await setDoc(accountRef, account)
  }

  async setMember(accountId: string, member: IMember): Promise<void> {
    const memberRef = doc(db, 'users', accountId, 'members', member.member_id)
    await setDoc(memberRef, member)
  }

  async deleteMember(accountId: string, member: IMember) {
    const memberRef = doc(db, 'users', accountId, 'members', member.member_id)
    await deleteDoc(memberRef)
  }

  async sendInvite(account: IUser, invite: IInvite, member: IMember) {
    const inviteTemplateDoc = await getDoc(doc(db, 'mail_templates', 'invite'))
    let html = inviteTemplateDoc.data()?.html
    // Replace the substrings with the desired values
    html = html.replace('ACCOUNT_EMAIL', account.email)
    html = html.replace('BUTTON_LINK', invite.inviteUrl)
    html = html.replace('SUPPORT_EMAIL', 'support@garfield.law')
    const inviteRef = doc(db, 'mail', invite.member_id)
    const email = {
      to: [member.email],
      message: {
        subject: 'Invitation to join Project Garfield',
        html: html,
      },
      account_id: account.account_id,
      member_id: member.member_id,
      role: member.role,
      invite_id: invite.member_id,
    }
    await setDoc(inviteRef, email)
  }

  async sendBugReport(
    error: Error,
    errorId: string,
    uid?: string,
    claimId?: string
  ) {
    const mailRef = doc(db, 'mail', errorId)
    const email = {
      to: ['pablo@projectgarfield.co.uk'],
      message: {
        subject:
          'Garfield Bug Report ' +
          errorId +
          ' - UID: ' +
          uid +
          ' - Claim ID: ' +
          (claimId ?? 'No Claim ID'),
        html: error?.message ?? '',
      },
    }
    await setDoc(mailRef, email)
  }

  async retrieveInvite(inviteId: string) {
    const inviteRef = doc(db, 'mail', inviteId)
    const inviteSnapshot = await getDoc(inviteRef)
    return inviteSnapshot.data()
  }

  async createDocument(account: IUser, document: IDocument) {
    if (!account) throw new Error('No account')
    await setDoc(
      doc(
        db,
        'users',
        account.account_id!,
        'claims',
        document.claim_id!,
        'documents',
        document.document_id!
      ),
      document
    )
  }

  async createTestPaymentDocument(
    accountId: string,
    paymentData: any
  ): Promise<void> {
    const paymentId = uuidv4()
    const paymentRef = doc(db, 'users', accountId, 'payments', paymentId)
    await setDoc(paymentRef, paymentData)
  }

  async createClaim(account: IUser, member: IMember): Promise<string> {
    console.log('firebase.createClaim 1')
    if (!account) throw new Error('No account')
    console.log('firebase.createClaim 2')
    // Assuming Claim, Contract, ContractDetails, ClaimDetails, ClaimState, and Message are classes with a toJson() method

    const newClaimId = await firebase.getUniqueIdForCollection([
      'users',
      account.account_id ?? '',
      'claims',
    ])
    console.log('firebase.createClaim 3')
    console.log('newClaimId: ', newClaimId)
    const claim = Claim.create({
      claim_id: newClaimId,
      account_id: account.account_id,
      claimants: [
        Party.create({
          name: account.entity_name,
          address: Address.create({
            first_line: account.registered_address?.first_line,
            second_line: account.registered_address?.second_line,
            town_city: account.registered_address?.town_city,
            county: account.registered_address?.county,
            postcode: account.registered_address?.postcode,
            country: account.registered_address?.country,
          }),
        }),
      ],
      contract: Contract.create({ details: ContractDetails.create({}) }),
      details: ClaimDetails.create({
        // correspondence_details: CorrespondenceDetails.create({
        //   ongoing: false,
        //   correspondence_items: [],
        // }),
        lba_details: LbaDetails.create({}),
        claim_form_details: ClaimFormDetails.create({}),
        notice_of_issue_details: NoticeOfIssueDetails.create({}),
        acknowledgement_of_service_details:
          AcknowledgementOfServiceDetails.create({}),
        admission_details: AdmissionDetails.create({}),
        partial_admission_details: PartialAdmissionDetails.create({}),
        counterclaim_details: CounterclaimDetails.create({}),
        directions_questionnaire_details: DirectionsQuestionnaireDetails.create(
          {}
        ),
        request_for_judgment_details: RequestForJudgmentDetails.create({}),
      }),
      state: ClaimState.create({
        stage: CLAIMSTAGE.triage,
        created_date: new Date(),
        last_updated_date: new Date(),
      }),
    })
    console.log('firebase.createClaim 4')
    console.log('claim: ', claim)

    await this.updateMember(account.account_id, {
      member_id: member.member_id,
      claim_ids: [...member.claim_ids, claim.claim_id!] as IMSTArray<
        ISimpleType<string>
      >,
    })
    console.log('firebase.createClaim 5')
    const initialMessageId = await firebase.getUniqueIdForCollection([
      'users',
      account.account_id ?? '',
      'claims',
      claim.claim_id ?? '',
      'messages',
    ])
    console.log('firebase.createClaim 6')
    console.log('initialMessageId: ', initialMessageId)
    const initialMessage = Message.create({
      text: "Hi! I'm Garfield, your legal assistant. I can help you collect debts of less than £10k.\n\nTo start, either describe your claim in the message bar below, or drag and drop your invoice (and contract, if any) onto this window, or attach those documents using the paperclip.",
      creator: 'garfield',
      created_date: new Date(),
      claim_id: claim.claim_id,
      account_id: account.account_id,
      message_id: initialMessageId,
      visible: true,
    })
    console.log('firebase.createClaim 7')
    console.log('initialMessage: ', initialMessage)
    // Add claim to account's claims and update account
    // await updateDoc(doc(db, 'users', account.account_id), {
    //   claim_ids: arrayUnion(claim.claim_id),
    // })
    console.log('firebase.createClaim 8')
    // Set the new claim
    await setDoc(
      doc(db, 'users', account.account_id!, 'claims', claim.claim_id!),
      claim
    )
    console.log('firebase.createClaim 9')
    // Add the initial message to the claim's messages
    const initialMessageRef = doc(
      db,
      'users',
      account.account_id!,
      'claims',
      claim.claim_id!,
      'messages',
      initialMessageId
    )
    console.log('firebase.createClaim 10')
    await setDoc(initialMessageRef, initialMessage)
    console.log('firebase.createClaim 11')
    console.log('claim.claim_id!: ', claim.claim_id!)
    return claim.claim_id!
  }

  async updateClaim(account: IUser, claim: Partial<IClaim>) {
    if (!account) throw new Error('No account')
    const updates = this.convertToDotNotation(claim)
    await updateDoc(
      doc(db, 'users', account.account_id, 'claims', claim.claim_id!),
      updates
    )
  }

  async getUniqueIdForCollection(
    pathSegments: string[],
    extension?: string,
    usedIds?: Set<string>
  ): Promise<string> {
    const db = getFirestore()
    const collectionRef = collection(
      db,
      ...(pathSegments as [string, ...string[]])
    )
    let id: string
    let idExistsInCollection: boolean
    let idExistsInUsedIds: boolean

    let attempts = 0
    do {
      if (attempts >= 4) {
        throw new Error('Failed to generate a unique ID after 4 attempts')
      }
      id = uuidv4().substring(0, 5)
      if (extension) {
        id += extension
      }
      const messageRef = doc(collectionRef, id)
      const messageSnapshot = await getDoc(messageRef)
      idExistsInCollection = messageSnapshot.exists()
      idExistsInUsedIds = usedIds?.has(id) ?? false
      attempts++
    } while (idExistsInCollection || idExistsInUsedIds)
    return id
  }

  async getMessages(
    accountId: string,
    claimId: string,
    limitCount: number
  ): Promise<IMessage[]> {
    if (!accountId) throw new Error('No account')
    const messagesRef = collection(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'messages'
    )
    const q = query(
      messagesRef,
      orderBy('created_date', 'desc'),
      limit(limitCount)
    )

    const messagesSnapshot = await getDocs(q)
    const messages: IMessage[] = messagesSnapshot.docs.map((doc) => {
      const message = doc.data() as IMessage
      return message
    })
    return messages
  }

  async uploadDocument(
    account: IUser,
    claimId: string,
    file: File,
    document: IDocument
  ) {
    if (!account) throw new Error('No account')
    const documentRef = doc(
      db,
      'users',
      account.account_id,
      'claims',
      claimId,
      'documents',
      document.document_id!
    )
    await setDoc(documentRef, document)
    const metadata = {
      contentType: mime.getType(file.name!) || 'application/octet-stream', // Fallback to a generic binary stream type
    }
    const fileRef = ref(storage, document.prefix!)
    const uploadResult = await uploadBytes(fileRef, file, metadata)
  }

  async uploadDocuments(account: IUser, documentSets: Attachment[]) {
    if (!account) throw new Error('No account')
    const uploadPromises = documentSets.map(
      async ({ claimId, file, document }) => {
        const documentRef = doc(
          db,
          'users',
          account.account_id,
          'claims',
          claimId,
          'documents',
          document.document_id!
        )
        await setDoc(documentRef, document)
        const metadata = {
          contentType: mime.getType(file.name!) || 'application/octet-stream', // Fallback to a generic binary stream type
        }
        const fileRef = ref(storage, document.prefix!)
        return uploadBytes(fileRef, file, metadata)
      }
    )

    await Promise.all(uploadPromises)
  }

  async getUniqueMessageId(
    account_id: string,
    claim_id: string
  ): Promise<string> {
    let messageId: string
    let messageExists: boolean

    let attempts = 0
    do {
      if (attempts >= 4) {
        throw new Error('Failed to generate a unique claim ID after 4 attempts')
      }
      messageId = uuidv4().split('-')[0]
      const messageRef = doc(
        db,
        'users',
        account_id,
        'claims',
        claim_id,
        'messages',
        messageId
      )
      const messageSnapshot = await getDoc(messageRef)
      messageExists = messageSnapshot.exists()
      attempts++
    } while (messageExists)
    return messageId
  }

  async sendMessageDirect(message: IMessage): Promise<void> {
    if (!message.account_id) throw new Error('No account')

    const messagesRef = doc(
      db,
      'users',
      message.account_id!,
      'claims',
      message.claim_id!,
      'messages',
      message.message_id!
    )
    await setDoc(messagesRef, message)
  }

  async sendMessage(account: IUser, message: IMessage): Promise<void> {
    if (!account) throw new Error('No account')

    const messagesRef = doc(
      db,
      'users',
      account.account_id,
      'claims',
      message.claim_id!,
      'messages',
      message.message_id!
    )

    await setDoc(messagesRef, message)
  }

  async getMessagesSnapshot(
    accountId: string,
    claimId: string
  ): Promise<QuerySnapshot<DocumentData>> {
    const messagesRef = collection(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'messages'
    )
    const messagesSnapshot = await getDocs(messagesRef)
    return messagesSnapshot
  }

  async deleteClaim(account: IUser, claimIdToRemove: string): Promise<void> {
    if (!account) throw new Error('No account')

    const batch = writeBatch(db)

    // Assuming you have a function to get all message documents
    const messagesSnapshot = await this.getMessagesSnapshot(
      account.account_id,
      claimIdToRemove
    )
    messagesSnapshot.docs.forEach((messageDoc) => {
      batch.delete(messageDoc.ref)
    })

    // Delete claim
    const claimRef = doc(
      db,
      'users',
      account.account_id,
      'claims',
      claimIdToRemove
    )
    batch.delete(claimRef)

    // Remove claim id from account and update
    // const accountRef = doc(db, 'users', account.account_id)
    // batch.update(accountRef, {
    //   claim_ids: account.claim_ids,
    // })

    await batch.commit()
  }

  async deleteData(account: IUser): Promise<void> {
    if (!account) throw new Error('No account')

    // // Delete all claims
    // for (const claimId of account.claim_ids) {
    //   // await this.deleteClaim(user, claimId);
    // }

    // Delete user
    const accountRef = doc(db, 'users', account.account_id)
    await deleteDoc(accountRef)

    // Delete auth account
    if (auth.currentUser) {
      await deleteUser(auth.currentUser)
    }
  }

  async deleteDocument(account: IUser, document: IDocument): Promise<void> {
    if (!account) throw new Error('No account')

    // Delete claim
    const docRef = doc(
      db,
      'users',
      account.account_id,
      'claims',
      document.claim_id!,
      'documents',
      document.document_id!
    )

    await deleteDoc(docRef)

    // TODO: Delete file from storage
  }

  async updateDocument(
    account: IUser,
    document: Partial<IDocument>
  ): Promise<void> {
    const documentRef = doc(
      db,
      'users',
      account.account_id,
      'claims',
      document.claim_id!,
      'documents',
      document.document_id!
    )
    const updates = this.convertToDotNotation(document)
    await updateDoc(documentRef, updates)
  }

  async getDocumentUrl(documentId: string, accountId: string, claimId: string) {
    const documentRef = doc(
      db,
      'users',
      accountId,
      'claims',
      claimId,
      'documents',
      documentId
    )
    const documentSnapshot = await getDoc(documentRef)
    const document = documentSnapshot.data() as IDocument
    // Create a reference to the file
    const storageRef = ref(storage, document.prefix!)
    // Get the download URL
    const url = await getDownloadURL(storageRef)
    return url
  }

  async getStorageUrl(path: string) {
    // Create a reference to the file
    const storageRef = ref(storage, path)
    // Get the download URL
    return await getDownloadURL(storageRef)
  }

  async uploadSignature(base64String: string, path: string): Promise<boolean> {
    // Convert base64 string to a Blob
    const fetchRes = await fetch(base64String)
    const blob = await fetchRes.blob()

    const storageRef = ref(storage, path)
    await uploadBytes(storageRef, blob)

    return true
  }

  async addToWaitList(waitListData: {
    email: string
    businessName: string
    websiteAddress: string
    claimsPerYear: number
    averageClaimAmount: string
    pricingPlan: string
  }): Promise<void> {
    const waitListRef = collection(db, 'wait_list')
    const q = query(waitListRef, where('email', '==', waitListData.email))
    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) {
      await addDoc(waitListRef, waitListData)
    } else {
      throw new Error('email already exists in the wait list')
    }
  }
}

// Export a singleton instance in the global namespace
export const firebase = new FirebaseService()
