import {
  Button,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core'
import { IconExclamationCircle } from '@tabler/icons-react'

export function ClaimTabError({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: any | null
}) {
  const theme = useMantineTheme()

  return (
    <Paper h={'100%'} my={0} bg={theme.colors.white[2]} pt={'xl'}>
      <Stack h={'100%'} justify='center' align='center'>
        <ThemeIcon variant='light' radius='xl' size='xl' color='red'>
          <IconExclamationCircle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
        <Text size='xl' fw={500}>
          {'Where did Garfield go?'}
        </Text>
        <Text c={theme.colors.gray[6]} maw={500}>
          {`There was an error fetching your claim. Please reload the
          page or contact support if the issue persists.`}
        </Text>
        <Text c={theme.colors.gray[6]}></Text>
        <Group>
          <Button
            onClick={() => {
              const url = window.location.origin
              window.location.assign(url)
            }}
          >
            Reload
          </Button>
          <Button
            variant='subtle'
            onClick={() => {
              const url = window.location.origin
              window.location.assign(url)
            }}
          >
            Contact support
          </Button>
        </Group>
      </Stack>
    </Paper>
  )
}
