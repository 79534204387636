import { notifications } from '@mantine/notifications'
import { FirebaseError } from '@firebase/util'
import { AuthError } from '@firebase/auth-types'
import { FirestoreError } from 'firebase/firestore'
import { StorageError } from '@firebase/storage'
import { firebase } from '../services/firebase_service'
import { DuplicateBankAccountError } from './errors'

class Notification {
  warning(title: string, message: string) {
    notifications.show({
      id: 'warning-notification',
      withCloseButton: true,
      autoClose: 6500,
      title: title,
      message: message,
      color: 'orange',
      loading: false,
      withBorder: true,
    })
  }

  dismiss(id: string) {
    notifications.hide(id)
  }

  error(error: Error, errorId: string, uid?: string, claimId?: string) {
    const { title, message } = this.parseError(error)
    notifications.show({
      id: 'error-notification',
      withCloseButton: true,
      autoClose: 6500,
      title: title + ' (Click to report)',
      message: message,
      color: 'red',
      loading: false,
      withBorder: true,
      onClick: async () => {
        try {
          await firebase.sendBugReport(error, errorId, uid, claimId)
        } catch (error) {
          console.error(error)
        }
        this.dismiss('error-notification')
      },
      style: { cursor: 'pointer' },
    })
  }

  private parseAuthError(error: AuthError): { title: string; message: string } {
    const errorMapping: {
      [key: string]: { title: string; message: string }
    } = {
      // Add more mappings based on the provided error codes
      'auth/admin-restricted-operation': {
        title: 'Admin Restricted Operation',
        message: 'This operation is restricted to administrators only.',
      },
      'auth/argument-error': {
        title: 'Argument Error',
        message:
          'There was an error with the arguments provided to the operation.',
      },
      'auth/app-not-authorized': {
        title: 'App Not Authorized',
        message:
          'This app is not authorized to use Firebase Authentication. Please check your configuration and permissions.',
      },
      'auth/weak-password': {
        title: 'Weak Password',
        message: 'The password is too weak. Please choose a stronger password.',
      },
      'auth/user-disabled': {
        title: 'User Disabled',
        message:
          'This user account has been disabled. Please contact support if you believe this is an error.',
      },
      'auth/user-not-found': {
        title: 'User Not Found',
        message: 'No user found with the provided credentials.',
      },
      'auth/wrong-password': {
        title: 'Wrong Password',
        message: 'The password is incorrect. Please try again.',
      },
      'auth/too-many-requests': {
        title: 'Too Many Requests',
        message:
          'Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.',
      },
      'auth/network-request-failed': {
        title: 'Network Error',
        message:
          'A network error has occurred. Please check your connection and try again.',
      },
      'auth/email-already-in-use': {
        title: 'This account already exists',
        message:
          'Please choose a different email address or sign in with the existing account.',
      },
      'auth/invalid-email': {
        title: 'The email is invalid',
        message:
          'Please change or re-enter the email address in a valid format.',
      },
    }

    let title = 'Unexpected Authentication Error'
    let message = 'An error occurred during authentication. Please try again.'

    if (errorMapping[error.code]) {
      title = errorMapping[error.code].title
      message = errorMapping[error.code].message
    }

    return { title, message }
  }

  private parseFirestoreError(error: FirestoreError): {
    title: string
    message: string
  } {
    const errorMapping: {
      [key: string]: { title: string; message: string }
    } = {
      'permission-denied': {
        title: 'Permission Denied',
        message: 'You do not have permission to access the requested data.',
      },
      unauthenticated: {
        title: 'Not Authenticated',
        message: 'You must be logged in to view this data.',
      },
      'not-found': {
        title: 'Data Not Found',
        message: 'The requested data could not be found.',
      },
      'resource-exhausted': {
        title: 'Quota Exceeded',
        message: 'You have exceeded your quota for this resource.',
      },
      'failed-precondition': {
        title: 'Invalid Operation',
        message:
          'The operation was rejected due to a failed precondition, such as querying across multiple collections.',
      },
      aborted: {
        title: 'Operation Aborted',
        message:
          'The operation was aborted, typically due to a concurrency issue like transaction aborts, etc.',
      },
      'out-of-range': {
        title: 'Out of Range',
        message: 'The operation was attempted past the valid range.',
      },
      unavailable: {
        title: 'Service Unavailable',
        message:
          'The service is currently unavailable. Please try again later.',
      },
      'data-loss': {
        title: 'Data Loss',
        message:
          'Unrecoverable data loss or corruption. Please contact support.',
      },
      // Add more Firestore error mappings as needed
    }

    let title = 'Unexpected Database Error'
    let message =
      'An error occurred while accessing the database. Please try again or contact support if the issue persists.'

    if (errorMapping[error.code]) {
      title = errorMapping[error.code].title
      message = errorMapping[error.code].message
    }

    return { title, message }
  }

  private parseStorageError(error: StorageError): {
    title: string
    message: string
  } {
    const errorMapping: {
      [key: string]: { title: string; message: string }
    } = {
      'storage/unauthorized': {
        title: 'Unauthorized Access',
        message: 'You do not have permission to access the requested resource.',
      },
      'storage/canceled': {
        title: 'Operation Canceled',
        message: 'The operation was canceled by the user.',
      },
      'storage/unknown': {
        title: 'Unknown Stroage Error',
        message:
          'An unknown error occurred. Please try again or contact support if the issue persists.',
      },
      'storage/object-not-found': {
        title: 'File Not Found',
        message: 'No file found at the specified path.',
      },
      'storage/bucket-not-found': {
        title: 'Bucket Not Found',
        message: 'No bucket configured for Firebase Storage.',
      },
      'storage/project-not-found': {
        title: 'Project Not Found',
        message: 'No project configured for Firebase Storage.',
      },
      'storage/quota-exceeded': {
        title: 'Quota Exceeded',
        message:
          'Your storage quota has been exceeded. Please consider upgrading your plan.',
      },
      'storage/retry-limit-exceeded': {
        title: 'Retry Limit Exceeded',
        message: 'Upload retry limit exceeded. Please try again later.',
      },
      // Add more Firebase Storage error mappings as needed
    }

    let title = 'Unexpected Storage Error'
    let message =
      'An error occurred with Firebase Storage. Please try again or contact support if the issue persists.'

    if (errorMapping[error.code]) {
      title = errorMapping[error.code].title
      message = errorMapping[error.code].message
    }

    return { title, message }
  }

  private parseError(error: Error): { title: string; message: string } {
    // Default error title and message
    let title = 'Unexpected Error'
    let message =
      'Something went wrong. Please try again or contact support if the problem persists.'

    if (error instanceof DuplicateBankAccountError) {
      return {
        title: 'Duplicate Bank Account',
        message: error.message
      };
    }

    //Handling Firebase Auth errors
    if (this.isFirebaseError(error)) {
      if (this.isFirebaseAuthError(error)) {
        return this.parseAuthError(error)
      } else if (this.isFirestoreError(error)) {
        return this.parseFirestoreError(error)
      } else if (this.isStorageError(error)) {
        return this.parseStorageError(error)
      }
    }

    // Handling common JavaScript errors
    if (error instanceof TypeError) {
      title = 'Operation Error'
      message =
        'An operation was performed incorrectly. Please check your inputs or actions.'
    } else if (error instanceof RangeError) {
      title = 'Range Error'
      message =
        'A number is out of its allowed range. Please enter a valid range value.'
    } else if (error instanceof ReferenceError) {
      title = 'Reference Error'
      message =
        'There was a reference to something that does not exist. This might be a bug.'
    } else if (error instanceof SyntaxError) {
      title = 'Syntax Error'
      message =
        'There’s an issue with the application’s code. This is likely a bug.'
    } else if (error instanceof URIError) {
      title = 'Link Error'
      message =
        'There was an error with a link operation. Please ensure URLs are correct.'
    } else if (error instanceof EvalError) {
      title = 'Evaluation Error'
      message = 'An error occurred in an eval() function. This is likely a bug.'
    }

    // Custom error handling based on error message content
    if (error.message.includes('timeout')) {
      title = 'Timeout Error'
      message =
        'The operation timed out. Please check your internet connection and try again.'
    } else if (error.message.includes('network')) {
      title = 'Network Error'
      message =
        'A network error occurred. Please ensure you are connected to the internet.'
    } else if (error.message.toLowerCase().includes('not found')) {
      title = 'Not Found'
      message =
        'The requested resource could not be found. It may have been removed or relocated.'
    } else if (error.message.toLowerCase().includes('permission denied')) {
      title = 'Permission Denied'
      message =
        'You do not have permission to perform this action. Please check your rights or contact support.'
    }

    if (error.message.includes('No account')) {
      title = 'Authentication Error'
      message =
        'No account is currently signed in! Please reload the page or sign in again.'
    }
    if (error.message.includes('email already exists in the wait list')) {
      title = 'Email already exists in the wait list'
      message = 'Please wait for the next email from us.'
    }

    return { title, message }
  }

  private isFirebaseError(error: Error | FirebaseError): error is FirebaseError {
    return (error as FirebaseError).code !== undefined;
  }

  private isFirebaseAuthError(error: FirebaseError): error is AuthError {
    return error.code.startsWith('auth/')
  }

  private isFirestoreError(error: FirebaseError): error is FirestoreError {
    return error.code.startsWith('firestore/')
  }

  private isStorageError(error: FirebaseError): error is StorageError {
    return error.code.startsWith('storage/')
  }
}

export const notification = new Notification()
