import { Button, Combobox, FloatingPosition, Group, Input, InputBase, Text, useCombobox } from '@mantine/core';
import { observer } from 'mobx-react';
import { IBankAccount } from 'models/billing/bank_account';
import { useStores } from 'utils/use_stores';
import { IconPlus } from '@tabler/icons-react'
import * as changeCase from 'change-case'
import { redact } from 'utils/strings';
import { IDocument } from 'models/document/document';
import { modal } from 'utils/modal';


function SelectOption({ label, value }: { label: string, value: string }) {

  return (
    <Group >
      <div>
        <Text size='md' opacity={0.8}>
            {label}
        </Text>
      </div>
    </Group>
  );
}

function NonSelectedOption() {
  return (
    <Group>
      <div>
      <Text fz="h6" fw={500} c="dimmed">
          No letterhead selected
        </Text>
      </div>
    </Group>
  );
}

function AddNewOption() {
  return (
    <Group py="xs">
        <IconPlus size={18} color='gray' />
        <Text fz="h6" fw={500} c="dimmed">
          Add new one
        </Text>
    </Group>
  );
}

interface LetterheadSelectionBoxProps {
  // target: (toggleDropdown: () => void) => ReactNode
  document: IDocument | null
  onClick?: (bankAccountId: string) => Promise<void>
  offset?: number
  width?: number
  position?: FloatingPosition
}

function LetterheadSelectionBox({
  document,
  onClick,
  offset,
  width,
  position,
}: LetterheadSelectionBoxProps) {
  const { authStore, memberStore } = useStores()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const openLetterheadModal = () => {
    modal.open(
      'Letterhead Selector',
      '',
      'letterhead',
      async () => {},
      () => {},
      {
        closeModal: () => modal.close(),
      }
    )
  }

  const letterheadOptions: any[] = [
    ...(process.env.REACT_APP_ENV === "emulator" || process.env.REACT_APP_ENV === "development" 
      ? [{ label: 'Garfield', value: 'garfield' }] 
      : []),
    { label: 'Custom', value: 'custom' },
    { label: 'Simple', value: 'simple' },
  ]
  const selectedType = authStore.currentLetterheadType

  // const [value, setValue] = useState<string | null>(null);
  const selectedOption = selectedType ? letterheadOptions.find((option) => option.value === selectedType) : null

  const options = [...letterheadOptions.map((option) => (
        <Combobox.Option value={option.value} key={option.value}>
        <SelectOption {...option} />
        </Combobox.Option>
    ))
  ]



  return (
    <Combobox
      store={combobox}
      // withinPortal={false}
      onOptionSubmit={async (val) => {
        // if (val === 'custom') {
        //   combobox.closeDropdown();
        //   openLetterheadModal()
        //   return
        // }
        await onClick?.(val)
        combobox.closeDropdown();
      }}
      withinPortal={true}
      // width={width ?? 350}
      offset={offset ?? 40}
      position={position ?? 'left-start'}
    >
      <Combobox.Target >
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          multiline
          w={"100%"}
        >
          {selectedOption ? (
            <SelectOption {...selectedOption} />
          ) : (
            <NonSelectedOption/>
          )}
        </InputBase>
      </Combobox.Target>

       {/* <Combobox.Target>
         {target(() => {
           combobox.toggleDropdown()
         })}
       </Combobox.Target> */}

      <Combobox.Dropdown>
        <Combobox.Options>{ options }</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default observer(LetterheadSelectionBox)

