import {
  Container,
  Text,
  Divider,
  useMantineTheme,
  Title,
  Card,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMediaQuery } from '@mantine/hooks'


const complaintText = (
  <Text>
    If at any point an authorised user becomes unhappy or concerned
    about the service "Garfield" has provided, it should inform Project Garfield Limited
    immediately so that PGL can do its best to resolve the problem.
    <p>
      In the first instance, authorised users may please feel free to
      send an email to{' '}
      <a href='mailto:complaints@projectgarfield.co.uk'>
        complaints@projectgarfield.co.uk
      </a>
      . If the authorised user would like to make a formal complaint,
      then it can upon request read PGL's full complaints procedure.
      Making a complaint will not affect how "Garfield" operates.
    </p>
  </Text>
);

function DesktopComplaintsPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        paddingLeft: '16px',
        backgroundColor: theme.colors.white[5],
      }}
      p={'xl'}
      m={0}
      fluid
    >
      <Card
        shadow='sm'
        withBorder
        p={'xl'}
        radius={'md'}
        maw={width / 2}
        mx={'auto'}
      >
        <Card.Section p={'xl'}>
          <Title py={'md'} order={1} fw={800}>
            Complaints Procedure
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'xl'}>
          {complaintText}
            {/* <p>
              If you have any concerns about PGL's behaviour, for example, if
              you believe that PGL has treated you unfairly because of your age,
              disability or other characteristic, you can raise your concerns
              with The Solicitors Regulation Authority who will be able to
              assist you.
            </p>
            <p>What to do if PGL cannot resolve your complaint:</p>
            <p>
              The Legal Ombudsman can help you if PGL is unable to resolve your
              complaint. They will look at your complaint independently and it
              will not affect how "Garfield" handles your case.
            </p>
            <p>
              Before accepting a complaint for investigation, the Legal
              Ombudsman will check that you have tried to resolve your complaint
              with PGL first. If you have, then you must take your complaint to
              the Legal Ombudsman:
            </p>
            Within six months of receiving a final response to your complaint
            and<br></br>
            Within one year of the date of the act/omission about which you are
            concerned or within one year of you realising there was cause for
            complaint.
            <p>
              If you would like more information about the Legal Ombudsman, you
              may contact them at:
            </p>
            website:{' '}
            <a href='www.legalombudsman.org.uk'>www.legalombudsman.org.uk</a>
            <p>Call: 0300 555 0333 between 9am to 5pm.</p>
            <p>
              Email:{' '}
              <a href='mailto:enquiries@legalombudsman.org.uk'>
                enquiries@legalombudsman.org.uk
              </a>
            </p>
            <p>By post: Legal Ombudsman PO Box 6806, Wolverhampton, WV1 9WJ</p> */}
        </Card.Section>
      </Card>
    </Container>
  )
}

function MobileComplaintsPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        backgroundColor: theme.colors.white[5],
      }}
      p={'md'}
      m={0}
      fluid
    >
      <Card shadow='sm' withBorder p={'md'} radius={'md'} mx={'auto'}>
        <Card.Section p={'md'}>
          <Title py={'md'} order={2} fw={700}>
            Complaints Procedure
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'md'}>
          {complaintText}
        </Card.Section>
      </Card>
    </Container>
  )
}

function ComplaintsPage() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return isMobile ? <MobileComplaintsPage /> : <DesktopComplaintsPage />
}

export default ComplaintsPage
