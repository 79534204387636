import {
  Progress,
} from '@mantine/core'
import { useEffect, useState } from 'react'

export const LoadingModal = () => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((oldValue) => {
        const newValue = oldValue + 10
        if (newValue >= 100) {
          clearInterval(interval)
          return 100
        }
        return newValue
      })
    }, 100) // Update value every 1 second

    return () => clearInterval(interval)
  }, [])

  return <Progress p={0} size='xs' color='cyan' value={value} />
}
