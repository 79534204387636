import { useRef } from 'react'
import { rem, useMantineTheme } from '@mantine/core'
import { useStores } from '../../utils/use_stores'
import { analytics } from '../../services/analytics_service'
import { observer } from 'mobx-react'
import { print } from '../../utils/print'
import { IClaim } from '../../models/claim/claim'
import DocumentsTree from './documents_tree'
import ReaderTabLayout from '../shared/reader_tab_layout'
import {
  IconDownload,
  IconTrash,
  IconArchive,
  IconArchiveOff,
} from '@tabler/icons-react'
import { modal } from '../../utils/modal'

export interface DocumentsTabProps {
  claim: IClaim
}

function DocumentsTab({ claim }: DocumentsTabProps) {
  const theme = useMantineTheme()
  const { navStore, documentStore, authStore, claimStore } = useStores()
  const tabLayoutRef = useRef<HTMLDivElement>(null)
  const selectedDocument = documentStore.selectedArchiveDocument

  const downloadDocument = async () => {
    if (!selectedDocument || !selectedDocument.url) return

    try {
      const response = await fetch(selectedDocument.url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = selectedDocument.name || 'download'
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      const uid = authStore.user?.account_id
      analytics.error(error, uid, claimStore.currentClaimId ?? undefined)
    }
  }

  const deleteDocument = async () => {
    if (!selectedDocument) return

    modal.open(
      "Sorry, we can't delete this document",
      'Please contact support to request deletion.',
      'manual_delete',
      async () => {
        print('Contact support')
      }
    )
  }

  const updateDocumentStatus = async (status: string) => {
    if (!selectedDocument) return

    selectedDocument.update({ status })
    documentStore.handleDocumentUpdateAndSelect(selectedDocument, 'archive')
  }

  const allCurrentDocuments = documentStore.currentDocuments
  const documentVersions = allCurrentDocuments.filter(other_document => 
      other_document.type === selectedDocument?.type && 
      other_document.stage === selectedDocument?.stage
      ).sort((a, b) => b.dates?.created_date - a.dates?.created_date)
  
  return (
    <div style={{ minHeight: '90vh' }} ref={tabLayoutRef}>
      <ReaderTabLayout
        selectedDocument={
          selectedDocument
          // selectedDocument?.processing ? null : selectedDocument
        }
        menu={[
          {
            label: 'Download',
            color: theme.colors.black[5],
            icon: <IconDownload style={{ width: rem(14), height: rem(14) }} />,
            action: downloadDocument,
          },
          {
            label: 'Delete',
            color: 'red',
            icon: <IconTrash style={{ width: rem(14), height: rem(14) }} />,
            action: deleteDocument,
          },
          {
            label:
              selectedDocument?.status === 'active' ? 'Archive' : 'Unarchive',
            color: theme.colors.black[5],
            icon:
              selectedDocument?.status === 'active' ? (
                <IconArchive style={{ width: rem(14), height: rem(14) }} />
              ) : (
                <IconArchiveOff style={{ width: rem(14), height: rem(14) }} />
              ),
            action: () =>
              updateDocumentStatus(
                selectedDocument?.status === 'active' ? 'archived' : 'active'
              ),
          },
        ]}
        controlPanel={
          <DocumentsTree containerRef={tabLayoutRef} />
        }
        position="left"
        documentVersions={documentVersions}
      />
    </div>
  )
}

export default observer(DocumentsTab)
