import {
  Text,
  Group,
  useMantineTheme,
  Stack,
  Card,
  List,
  Grid,
  Tooltip,
  Modal,
  Button,
} from '@mantine/core'
import { IconPaperclip, IconDownload } from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { observer } from 'mobx-react'
import {
  IconListDetails,
  IconScript,
  IconUserExclamation,
  IconUserShield,
} from '@tabler/icons-react'
import { ICharge } from '../../models/claim/charge'
import { IInvoice } from '../../models/claim/invoice'
import { IClaim } from '../../models/claim/claim'
import AttachmentThumbnail from '../claim_dialogue/attachment_thumbnail'
import { IDocument } from '../../models/document/document'
import { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { print } from '../../utils/print'
import { analytics } from 'services/analytics_service'

import React from 'react'

export interface ClaimDetailsSectionProps {
  claim: IClaim
}

function ClaimDetailsSection({ claim }: ClaimDetailsSectionProps) {
  const theme = useMantineTheme()
  const { documentStore } = useStores()
  const calculationsDocument = documentStore.calculationsDocument

  const [attachmentModal, setAttachmentModal] = useState(false)
  const [selectedAttachment, setSelectedAttachment] =
    useState<IDocument | null>(null)

  const openAttachmentModal = () => setAttachmentModal(true)
  const closeAttachmentModal = () => setAttachmentModal(false)
  const [numPages, setNumPages] = useState<number | null>(null)

  const openAttachment = async (document: IDocument | null) => {
    if (!document?.url) return
    setSelectedAttachment(document)
    openAttachmentModal()
  }

  const downloadAttachment = async () => {
    if (!selectedAttachment || !selectedAttachment.url) return

    try {
      const response = await fetch(selectedAttachment.url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = selectedAttachment.name || 'download'
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      analytics.error(error)
    }
  }
  //@ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  // TODO: Display invoices & line items within table here - already displayed in calculations document so low priority.
  return (
    <>
      <Group gap={'sm'} justify='flex-start' align='flex-start'>
        {/* CONTRACT */}
        
        {/* <Card withBorder={false} radius='md' maw={200} miw={150}>
          <Card.Section withBorder={false} inheritPadding py='xs'>
            <Group justify='flex-start' gap={'xs'}>
              <IconScript
                color={theme.colors.gray[6]}
                size={14}
                stroke={3}
              ></IconScript>
              <Text c={theme.colors.gray[6]} fw={600} size='xs'>
                CONTRACT
              </Text>
            </Group>
          </Card.Section>
          {claim.contract ? (
            <Stack gap={'sm'}>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Invoices
                </Text>
                {claim.contract?.invoices?.length ? (
                  <List>
                    {claim.contract.invoices.map((invoice: IInvoice) => {
                      return (
                        <Text
                          c={theme.colors.black[6]}
                          fw={500}
                          size='sm'
                          key={invoice.id}
                        >{`Invoice for £${invoice.total_amount} due by ${invoice.readableDateDue}`}</Text>
                      )
                    })}
                  </List>
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No invoices yet
                  </Text>
                )}
              </Stack>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Charges
                </Text>
                {claim.contract?.charges?.length ? (
                  <List>
                    {claim.contract.charges.map((charge: ICharge, idx) => {
                      return (
                        <Text
                          c={theme.colors.black[6]}
                          fw={500}
                          size='sm'
                          key={idx}
                        >{`Charge of ${charge?.original_amount} at ${charge.readableDate}`}</Text>
                      )
                    })}
                  </List>
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No charges yet
                  </Text>
                )}
              </Stack>
            </Stack>
          ) : (
            <Text c={theme.colors.black[6]} size='sm'>
              No contract
            </Text>
          )}
        </Card> */}

        <Card withBorder={false} radius='md' maw={200} miw={150}>
          <Card.Section withBorder={false} inheritPadding py='xs'>
            <Group justify='flex-start' gap={'xs'}>
              <IconScript
                color={theme.colors.gray[6]}
                size={14}
                stroke={3}
              ></IconScript>
              <Text c={theme.colors.gray[6]} fw={600} size='xs'>
                TOTALS
              </Text>
            </Group>
          </Card.Section>
          {claim?.state?.totals ? (
            <Stack gap={'sm'}>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Outstanding
                </Text>
                {claim.state?.totals?.outstanding ? (
                  <Text c={theme.colors.black[6]} fw={500} size='sm'>
                    {'£' + claim.state?.totals?.outstanding}
                  </Text>
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No outstanding amount yet
                  </Text>
                )}
              </Stack>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Principal Amount
                </Text>
                {claim.state?.totals?.principal_amount ? (
                  <Text c={theme.colors.black[6]} fw={500} size='sm'>
                    {'£' + claim.state?.totals?.principal_amount}
                  </Text>
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No principal amount yet
                  </Text>
                )}
              </Stack>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Interest Accrued
                </Text>
                {claim.state?.totals?.interest_accrued ? (
                  <Text c={theme.colors.black[6]} fw={500} size='sm'>
                    {'£' + claim.state?.totals?.interest_accrued}
                  </Text>
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No interest accrued yet
                  </Text>
                )}
              </Stack>
              <Stack gap={0}>
                <Text c={theme.colors.gray[5]} size='sm'>
                  Interest Calculations
                </Text>
                {calculationsDocument && calculationsDocument.document_id ? (
                  <AttachmentThumbnail
                    documentId={calculationsDocument.document_id}
                    onClick={openAttachment}
                    index={0}
                  />
                ) : (
                  <Text c={theme.colors.black[6]} size='sm'>
                    No document available yet
                  </Text>
                )}
              </Stack>
            </Stack>
          ) : (
            <Text c={theme.colors.black[6]} size='sm'>
              No totals
            </Text>
          )}
        </Card>
        {/* CLAIMANTS & DEFENDANTS */}
        <Stack>
          {/* CLAIMANTS */}
          <Card withBorder={false} radius='md' maw={200} miw={150}>
            <Card.Section withBorder={false} inheritPadding py='xs'>
              <Group justify='flex-start' gap={'xs'}>
                <IconUserExclamation
                  color={theme.colors.gray[6]}
                  size={14}
                  stroke={3}
                ></IconUserExclamation>
                <Text c={theme.colors.gray[6]} fw={600} size='xs'>
                  CREDITORS
                </Text>
              </Group>
            </Card.Section>
            {claim.claimants.length > 0 ? (
              claim.claimants.map((claimant, index) => {
                return (
                  <Tooltip
                    key={index}
                    label={
                      <Stack align='flex-start' justify='flex-start' gap={'xs'}>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Name:
                          </Text>
                          <Text size='xs'>{claimant.name ?? 'Unknown'}</Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Postcode:
                          </Text>
                          <Text size='xs'>
                            {claimant.address?.postcode ?? 'Unknown'}
                          </Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Email:
                          </Text>
                          <Text size='xs'>{claimant.email ?? 'Unknown'}</Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Business:
                          </Text>
                          <Text size='xs'>
                            {claimant.business_description ?? 'Unknown'}
                          </Text>
                        </Group>
                      </Stack>
                    }
                  >
                    <Card withBorder p={'xs'} radius='md' maw={350} miw={150}>
                      <Text c={theme.colors.black[6]} fw={500} size='sm'>
                        {claimant.name}
                      </Text>
                    </Card>
                  </Tooltip>
                )
              })
            ) : (
              <Text c={theme.colors.black[6]} size='sm'>
                No claimants
              </Text>
            )}
          </Card>
          {/* DEFENDANTS */}
          <Card withBorder={false} radius='md' maw={200} miw={150}>
            <Card.Section withBorder={false} inheritPadding py='xs'>
              <Group justify='flex-start' gap={'xs'}>
                <IconUserShield
                  color={theme.colors.gray[6]}
                  size={14}
                  stroke={3}
                ></IconUserShield>
                <Text c={theme.colors.gray[6]} fw={600} size='xs'>
                  DEBTORS
                </Text>
              </Group>
            </Card.Section>
            {claim.defendants.length > 0 ? (
              claim.defendants.map((defendant, index) => {
                return (
                  <Tooltip
                    key={index}
                    label={
                      <Stack align='flex-start' justify='flex-start' gap={'xs'}>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Name:
                          </Text>
                          <Text size='xs'>{defendant.name ?? 'Unknown'}</Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Postcode:
                          </Text>
                          <Text size='xs'>
                            {defendant.address?.postcode ?? 'Unknown'}
                          </Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Email:
                          </Text>
                          <Text size='xs'>{defendant.email ?? 'Unknown'}</Text>
                        </Group>
                        <Group gap={'xs'}>
                          <Text c={theme.colors.gray[4]} fw={500} size='xs'>
                            Business:
                          </Text>
                          <Text size='xs'>
                            {defendant.business_description ?? 'Unknown'}
                          </Text>
                        </Group>
                      </Stack>
                    }
                  >
                    <Card withBorder p={'xs'} radius='md' maw={350} miw={150}>
                      <Text c={theme.colors.black[6]} fw={500} size='sm'>
                        {defendant.name}
                      </Text>
                    </Card>
                  </Tooltip>
                )
              })
            ) : (
              <Text c={theme.colors.black[6]} size='sm'>
                No defendants
              </Text>
            )}
          </Card>
        </Stack>

        {/* DETAILS */}
        <Card withBorder={false} radius='md' maw={500} miw={300}>
          <Card.Section withBorder={false} inheritPadding py='xs'>
            <Group justify='flex-start' gap={'xs'}>
              <IconListDetails
                color={theme.colors.gray[6]}
                size={14}
                stroke={3}
              ></IconListDetails>
              <Text c={theme.colors.gray[6]} fw={600} size='xs'>
                DETAILS
              </Text>
            </Group>
          </Card.Section>
          {claim.details ? (
            <Grid grow>
              <Grid.Col key={'col-1'} span={1}>
                <Stack>
                  <Stack gap={0}>
                    <Text c={theme.colors.gray[5]} size='sm'>
                      Claim created on
                    </Text>

                    {claim.state?.readableDateCreated ? (
                      <Text c={theme.colors.black[6]} fw={500} size='sm'>
                        {claim.state?.readableDateCreated ?? 'Date unknown'}
                      </Text>
                    ) : (
                      <Text c={theme.colors.black[6]} size='sm'>
                        Date unknown
                      </Text>
                    )}
                  </Stack>

                  <Stack gap={0}>
                    <Text c={theme.colors.gray[5]} size='sm'>
                      Summary
                    </Text>

                    {claim.details.base_claim_details?.summary ? (
                      <Text c={theme.colors.black[6]} fw={500} size='sm'>
                        {claim.details.base_claim_details?.summary}
                      </Text>
                    ) : (
                      <Text c={theme.colors.black[6]} size='sm'>
                        No summary
                      </Text>
                    )}
                  </Stack>

                  {claim.state?.isPastClaimForm ? (
                    <Stack gap={0}>
                      <Text c={theme.colors.gray[5]} size='sm'>
                        Preferred court
                      </Text>
                      {/* TODO: FIX FRONTEND HERE - .court_names has changed to .preferred_court and is now string, not list. */}
                      {claim.details.post_action_base_details?.court_names
                        ?.length ? (
                        claim.details.post_action_base_details?.court_names.map(
                          (court) => {
                            return (
                              <Text
                                span
                                c={theme.colors.black[6]}
                                fw={500}
                                size='sm'
                              >
                                {court}
                              </Text>
                            )
                          }
                        )
                      ) : (
                        <Text c={theme.colors.black[6]} size='sm'>
                          No courts assigned
                        </Text>
                      )}
                    </Stack>
                  ) : null}

                  {claim.state?.isPastClaimForm ? (
                    <Stack gap={0}>
                      <Text c={theme.colors.gray[5]} size='sm'>
                        Settlements
                      </Text>

                      {claim.details.post_action_base_details?.numbers
                        ?.length ? (
                        <List>
                          {claim.details.post_action_base_details?.numbers.map(
                            (number: string) => {
                              return (
                                <Text
                                  c={theme.colors.black[6]}
                                  fw={500}
                                  size='sm'
                                >
                                  {number}
                                </Text>
                              )
                            }
                          )}
                        </List>
                      ) : (
                        <Text c={theme.colors.black[6]} size='sm'>
                          No claim numbers assigned
                        </Text>
                      )}
                    </Stack>
                  ) : null}
                </Stack>
              </Grid.Col>
            </Grid>
          ) : (
            <Text c={theme.colors.black[6]} size='sm'>
              No details
            </Text>
          )}
        </Card>
      </Group>
      <Modal
        centered
        size='80%'
        opened={attachmentModal}
        onClose={closeAttachmentModal}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        withCloseButton={true}
        title={
          <Group gap={'sm'}>
            <IconPaperclip color={theme.colors.gray[5]} size={20} stroke={2} />
            <Text size='md' fw={400} c={theme.colors.gray[5]}>
              Attachment:
            </Text>
            <Text size='lg' fw={500}>
              {selectedAttachment?.name}
            </Text>
            <Button
              variant='default'
              size='sm'
              onClick={downloadAttachment}
              leftSection={<IconDownload size={16} stroke={2} />}
            >
              Download
            </Button>
          </Group>
        }
      >
        <Stack
          align='center'
          justify='center'
          p={'md'}
          bg={theme.colors.gray[3]}
          style={{ borderRadius: 5 }}
        >
          <Document
            file={selectedAttachment?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              print(error)
              alert('Error while loading document! ' + error.message)
            }}
            onSourceError={(error) => {
              print(error)
              alert('Error while retrieving document source! ' + error.message)
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <React.Fragment key={`page_${index + 1}`}>
                <Page width={700} pageIndex={index} />
                {index !== numPages! - 1 && (
                  <div style={{ margin: '1rem 0' }} />
                )}{' '}
                {/* This adds a gap between pages */}
              </React.Fragment>
            ))}
          </Document>
        </Stack>
      </Modal>
    </>
  )
}

export default observer(ClaimDetailsSection)
