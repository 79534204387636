import { observer } from 'mobx-react'
import {
  Affix,
} from '@mantine/core'
import { useElementSize, useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'


import { useEffect, useState } from 'react'
import { useStores } from 'utils/use_stores'

function SidebarHandle({ direction }: { direction: string }) {
  // Set up the root store and rehydrates it with data stored in the browser's local storage

  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  // const { hovered, ref } = useHover()
  const [hovered, setHovered] = useState(false)

  const { navStore, claimStore } = useStores()

  // const { ref: widthRef, height: barHeight, width: barWidth } = useElementSize()

  // useEffect(() => {
  //   // if (direction === 'left') {
  //   //   navStore.setNavbarWidth(barWidth)
  //   // } else {
  //   //   navStore.setDocbarWidth(barWidth)
  //   // }
  // }, [barWidth])

  // useEffect(() => {
  //   // navStore.setNavBarWidth(200);
  //   console.log('hovered', hovered)
  // }, [hovered])

  const rsMouseDownHandler = (e: any) => {
    const x = e.clientX
    // const sbWidth = window.getComputedStyle(sidebarRef.current).width;
    const initialWidth =
      direction === 'left' ? navStore.navbarWidth : navStore.docbarWidth

    // Disable text selection
    document.body.style.userSelect = 'none'

    const mouseMoveHandler = (e: any) => {
      let dx = x - e.clientX
      dx = e.clientX - x // Resix=ze from right to left

      const newWidth =
        direction === 'left' ? initialWidth + dx : initialWidth - dx

      if (direction === 'left') {
        if (newWidth >= 400) {
          navStore.setNavbarWidth(400)
          return
        }
        if (newWidth >= 200) {
          navStore.setNavbarWidth(newWidth)
          return
        }
        navStore.closeNavbar()
        return
      } else {
        if (claimStore.currentClaimReadyToSend) {
          if (newWidth >= 225) {
            navStore.setDocbarWidth(newWidth)
          } else {
            navStore.closeDocbar()
          }
        } else {
          if (newWidth >= 225) {
            navStore.setDocbarWidth(newWidth)
          } else {
            navStore.closeDocbar()
          }
        }
      }
    }

    const mouseUpHandler = () => {
      document.removeEventListener('mouseup', mouseUpHandler)
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.body.style.userSelect = ''
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }

  // useEffect(() => {
  //   navStore.setNavbarWidth(navStore.navbarWidth)
  //   navStore.setDocbarWidth(navStore.docbarWidth)
  //   navStore.closeDocbar()
  // }, [])

  return (
    <Affix
      position={{
        top: '0',
        left: direction === 'left' ? 0 : undefined,
        right: direction === 'left' ? undefined : 0,
      }}
      zIndex={direction === 'left' ? 100 : 99}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: height - 16,
          backgroundColor: 'red',
        }}
      >
        <div
          className='resizer'
          onMouseDown={rsMouseDownHandler}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{
            // border: `1px solid ${direction === 'left' ? theme.colors.red[4] : theme.colors.blue[6]}`,
            position: 'absolute',
            top: 16,
            left: direction === 'left' ? navStore.navbarWidth - 5 : undefined,
            right:
              direction === 'right' ? navStore.docbarWidth - 18 : undefined,
            width: '40px',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'ew-resize',
          }}
        />

        <div
          style={{
            backgroundColor: hovered ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            transition: 'background-color 0.3s ease',
            borderRadius: '100px',
            position: 'absolute',
            top: 16,
            left: direction === 'left' ? navStore.navbarWidth + 13 : undefined,
            right: direction === 'right' ? navStore.docbarWidth - 3 : undefined,
            width: '5px',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            // boxShadow: hovered ? '0px 0px 10px 2px rgba(0, 255, 255, 0.5)' : 'none', // Add this line
          }}
        />
      </div>
    </Affix>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(SidebarHandle)

{
  /* <Transition transition='fade' mounted>
        {(transitionStyles) => (
          <Tooltip
            opened={tooltipOpen}
            label={<Text size='xs'>{tooltipLabel}</Text>}
            closeDelay={1}
            position={direction == 'left' ? 'right' : 'left'}
            offset={5}
            transitionProps={{ transition: 'fade', duration: 250 }}
            // bg={'blue'}
            // color=''
            opacity={0.55}
            style={{
              background: theme.colors.black[2],
              backgroundOpacity: 0.1,
              blur: 3,
            }}
          >
            <Button
              ref={ref}
              miw={'20px'}
              maw={'20px'}
              w={'20px'}
              h={height / 2}
              p={'0px'}
              my={'5px'}
              mr={direction == 'right' && expanded ? '0px' : '0'}
              ml={direction == 'left' && expanded ? '15px' : '0'}
              pos={'sticky'}
              top={'25%'}
              onClick={(event) => {
                setTooltipOpen(false)
                onClick(event)
              }}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
              variant='subtle'
              color={theme.colors.transparent[1]}
            >
              {direction == 'left' ? (
                <>
                  {expanded ? (
                    <IconChevronCompactLeft
                      color={theme.colors.black[5]}
                      style={{
                        opacity: hovered ? 1 : 0.5, // default low opacity
                      }}
                    />
                  ) : (
                    <IconChevronCompactRight
                      color={theme.colors.black[5]}
                      style={{
                        opacity: hovered ? 1 : 0.5, // default low opacity
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {expanded ? (
                    <IconChevronCompactRight
                      color={theme.colors.black[5]}
                      style={{
                        opacity: hovered ? 1 : 0.5, // default low opacity
                      }}
                    />
                  ) : (
                    <IconChevronCompactLeft
                      color={theme.colors.black[5]}
                      style={{
                        opacity: hovered ? 1 : 0.5, // default low opacity
                      }}
                    />
                  )}
                </>
              )}
            </Button>
          </Tooltip>
        )}
      </Transition> */
}
