import React, { useState } from 'react'
import {
  useNavigate,
} from 'react-router-dom'

import {
  Container,
  Text,
  useMantineTheme,
  Title,
  rem,
  SimpleGrid,
  Card,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
import {
  IconBolt,
  IconGavel,
  IconRobot,
} from '@tabler/icons-react'

const statementsData = [
  {
    title: 'Artifical Intelligence',
    description:
      'Machine Learning is positive and transformational, providing it is used correctly, and safely',
    icon: IconRobot,
  },
  {
    title: 'Justice',
    description: 'Access to justice is an essential legal right',
    icon: IconGavel,
  },
  {
    title: 'Technology',
    description:
      'Technology can help people vindicate their legitimate legal rights',
    icon: IconBolt,
  },
]

function AboutSection() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { navStore } = useStores()
  const [hoveredLink, setHoveredLink] = useState<string | null>(null)
  const navigate = useNavigate()

  const statements = statementsData.map((statement) => (
    <Card
      key={statement.title}
      shadow='md'
      radius='md'
      style={{
        padding: theme.spacing.xl,
        border: `1px solid ${theme.colors.gray[1]}`,
      }}
    >
      <statement.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.black[5]}
      />
      <Text
        style={{
          fontSize: theme.fontSizes.lg,
          fontWeight: 500,
          marginTop: theme.spacing.md,
          '::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.colors.blue[6],
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
          },
        }}
      >
        {statement.title}
      </Text>
      <Text
        style={{
          fontSize: rem(16),
          color: theme.colors.gray[6],
          marginTop: theme.spacing.sm,
        }}
      >
        {statement.description}
      </Text>
    </Card>
  ))

  return (
    <Container size='lg'>
      {/* <Title
        order={2}
        style={{
          fontSize: rem(40),
          fontWeight: 900,
          textAlign: 'center',
          marginTop: rem(40),
        }}
      >
        What we believe
      </Title>

      <SimpleGrid cols={{ base: 1, md: 3 }} spacing='xl' mt={50}>
        {statements}
      </SimpleGrid> */}

      <Title
        order={2}
        style={{
          fontSize: rem(40),
          fontWeight: 900,
          textAlign: 'center',
          // marginTop: rem(80),
        }}
      >
        How it works
      </Title>

      <Text
        style={{
          marginTop: rem(40),
          color: theme.colors.gray[6],
          textAlign: 'center',
          maxWidth: rem(900),
          margin: 'auto',
          '::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.colors.blue[6],
            width: rem(80),
            height: rem(2),
            marginTop: rem(10),
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
      >
        <br />
        Garfield combines the power of artificial intelligence with the expertise of an experienced lawyer.
        <br />
        <br />
        Once you are signed up, you can describe your claim to Garfield. Garfield will then draft your letters and guide you to a speedy debt recovery.
        <br />
        <br />
        You only need to pay if you would like to use the drafted letters.
      </Text>

      <Title
        order={2}
        style={{
          fontSize: rem(40),
          fontWeight: 900,
          textAlign: 'center',
          marginTop: rem(80),
        }}
      >
        Who we are
      </Title>

      <Text
        style={{
          marginTop: rem(40),
          color: theme.colors.gray[6],
          textAlign: 'center',
          maxWidth: rem(900),
          margin: 'auto',
          '::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.colors.blue[6],
            width: rem(80),
            height: rem(2),
            marginTop: rem(10),
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
      >
        We are a team of lawyers and software developers.
        <br />
        <br />
        Inspired by a vision of democratising access to justice, in May 2023, we
        set out to create a revolutionary product.
        <br />
        <br />
        We wanted to create a means by which everyone - from small businesses to
        larger companies - could easily, swiftly and cheaply pursue their debts
        through the English County Court.
        <br />
        <br />
        The product of this vision is Garfield.
      </Text>
    </Container>
  )
}

export default AboutSection
