import { observer } from 'mobx-react'
import {
  Space,
  Group,
  Paper,
  TextInput,
  Title,
  NumberInput,
  NumberInputHandlers,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { useRef } from 'react'
import {
  IconBriefcase2,
  IconBuildingSkyscraper,
} from '@tabler/icons-react'
import { useStores } from '../../utils/use_stores'
import { useNavigate } from 'react-router-dom'
import { DatePickerInput } from '@mantine/dates'
import { IUser } from '../../models/auth/user'

function OnboardingStep3() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { authStore } = useStores()
  const navigate = useNavigate()

  const type = authStore?.user?.type
  const name = authStore?.user?.entity_name
  const incNumber = authStore?.user?.inc_number
  const incDate = authStore?.user?.inc_date
    ? new Date(authStore?.user?.inc_date)
    : null
  const description = authStore?.user?.entity_description
  const vat = authStore?.user?.vat

  const handlersRef = useRef<NumberInputHandlers>(null)

  return (
    <form>
      <Paper ml={'20px'}>
        <Space h={'xs'} />
        <Group>
          {type === 'individual' ? (
            <IconBriefcase2 size={32} />
          ) : (
            <IconBuildingSkyscraper size={32} />
          )}
          <Title order={2}>{`Tell us about your ${
            type === 'individual' ? 'business' : 'organisation'
          }`}</Title>
        </Group>
        <Space h={'lg'} />
        <Space h={'lg'} />
        <Group grow>
          <TextInput
            mb={'sm'}
            value={name ?? undefined}
            id='name-input'
            label='Business name'
            placeholder='ACME Ltd.'
            onChange={(e) =>
              authStore.updateUser({
                entity_name: e.target.value,
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
          <TextInput
            mb={'sm'}
            value={vat ?? undefined}
            id='vat-input'
            label='VAT number (Optional)'
            placeholder='75469137932'
            onChange={(e) =>
              authStore.updateUser({
                vat: e.target.value,
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
        </Group>

        <TextInput
          mb={'sm'}
          value={description ?? undefined}
          id='description-input'
          label='Business description'
          placeholder='What does your business do?'
          onChange={(e) =>
            authStore.updateUser({
              entity_description: e.target.value,
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
        />
        <Group grow>
          <TextInput
            mb={'sm'}
            value={incNumber ?? undefined}
            id='inc-number-input'
            label={`Incorporation number${
              type === 'individual' ? ' (Optional)' : ''
            }`}
            placeholder='0115289042'
            onChange={(e) =>
              authStore.updateUser({
                inc_number: e.target.value,
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }
          />
          <DatePickerInput
            //@ts-ignore
            placeholder='January 1, 1975'
            dropdownType='popover'
            mb={'sm'}
            label={`Incorporation date${
              type === 'individual' ? ' (Optional)' : ''
            }`}
            value={incDate}
            onChange={(date) => {
              authStore.updateUser({
                inc_date: date?.toISOString(),
                uid: authStore.user?.uid,
              } as Partial<IUser>)
            }}
          />
        </Group>
        <Space h={'lg'} />
        <NumberInput
          mb={'sm'}
          label='Please estimate how many claims you may pursue with Garfield over the next 12 months'
          handlersRef={handlersRef}
          suffix=' claims'
          step={5}
          min={1}
          max={10000}
          defaultValue={10}
          onChange={(value) => {
            authStore.updateUser({
              claim_number_estimate: value,
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }}
        />
        <NumberInput
          mb={'sm'}
          label='Please estimate the aggregate value of those claims'
          handlersRef={handlersRef}
          prefix='£'
          step={20}
          min={5}
          max={10000}
          defaultValue={100}
          onChange={(value) => {
            authStore.updateUser({
              claim_value_estimate: value,
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }}
        />

        {/* <DateInput
          mb={'sm'}
          clearable
          value={incDate}
          id='inc-date-input'
          onChange={(date) =>
            authStore.updateUser({
              inc_date: date?.toISOString(),
              uid: authStore.user?.uid,
            } as Partial<IUser>)
          }
          label={`Incorporation date${
            type === 'individual' ? ' (Optional)' : ''
          }`}
          placeholder='Date input'
        /> */}
      </Paper>
    </form>
  )
}

// Wrap the returned function in an observer so it can react to the stores
export default observer(OnboardingStep3)
