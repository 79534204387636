import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { IconExclamationCircle } from '@tabler/icons-react'

export function ReaderError({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: any | null
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Card bg={'transparent'} radius={0}>
      <Stack mih={height} justify='flex-start' align='center' py={'xl'}>
        <ThemeIcon variant='light' radius='xl' size='xl' color='red'>
          <IconExclamationCircle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
        <Text size='xl' fw={500}>
          Garfield can't find this PDF!
        </Text>
        <Text c={theme.colors.gray[6]} maw={500}>
          {`There was an error retrieving this file. Please reload the
          page or contact support if the issue persists.`}
        </Text>
        <Text c={theme.colors.gray[6]}></Text>
        <Group>
          <Button
            onClick={() => {
              const url = window.location.origin
              window.location.assign(url)
            }}
          >
            Reload
          </Button>
          <Button
            variant='subtle'
            onClick={() => {
              const url = window.location.origin
              window.location.assign(url)
            }}
          >
            Contact support
          </Button>
        </Group>
      </Stack>
    </Card>
  )
}
