import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Container, useMantineTheme } from '@mantine/core'
import { useScrollIntoView, useViewportSize, useMediaQuery } from '@mantine/hooks'
import { useStores } from '../utils/use_stores'
import HeroSection from 'components/website/hero_section'
import AboutSection from 'components/website/about_section'
import FAQSection from 'components/website/faq_section'
import PricingSection from 'components/website/pricing_section'

function LandingPage() {
  const location = useLocation()
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()
  const { navStore } = useStores()
  const [hoveredLink, setHoveredLink] = useState<string | null>(null)
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { scrollIntoView: scrollIntoViewAbout, targetRef: aboutRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: isMobile ? 30 : 60,
    })
  const { scrollIntoView: scrollIntoViewFAQ, targetRef: faqRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: isMobile ? 30 : 60,
    })
  const { scrollIntoView: scrollIntoViewPricing, targetRef: pricingRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: isMobile ? 30 : 60,
    })


  useEffect(() => {
    const hash = location.hash.replace('#', '') // Remove the '#' character
    if (hash === 'about') {
      scrollIntoViewAbout()
    } else if (hash === 'faq') {
      scrollIntoViewFAQ()
    } else if (hash === 'pricing') {
      scrollIntoViewPricing()
    }
  }, [location])

  return (
    <Container
      style={{
        width: '100%',
        minHeight: height,
        paddingLeft: '16px',
        backgroundColor: theme.colors.white[5],
      }}
      p={0}
      m={0}
      fluid
    >
      <HeroSection />
      <div style={{ height: isMobile ? '60px' : '120px' }} ref={aboutRef} />
      <AboutSection />
      <div style={{ height: isMobile ? '60px' : '120px' }} ref={pricingRef} />
      <PricingSection />
      <div style={{ height: isMobile ? '60px' : '120px' }} ref={faqRef} />
      <FAQSection />
      <div style={{ height: isMobile ? '125px' : '250px' }} />
    </Container>
  )
}

export default LandingPage
