import React from 'react'
import {
  Container,
  Title,
  Box,
  Card,
  Group,
  Stack,
  Text,
  useMantineTheme,
  rem,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const DesktopPricingSection = () => {
  const theme = useMantineTheme()

  const chaserCard = (
    <Box pt='xl' pb='xl'>
      <Card h={rem(100)} shadow='sm' px='xl' py='xs' radius='md' withBorder>
        <Group h={'100%'} justify='center' preventGrowOverflow>
          <Stack h={'100%'} justify='center' align='start' gap={'xs'} flex={2}>
            <Title order={2}>Chaser</Title>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={3} gap={'xs'}>
            <p>
              {' '}
              A chaser is a letter sent to a debtor to remind them of an
              outstanding debt.{' '}
            </p>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={1} gap={'xs'}>
            <h2> £1 </h2>
          </Stack>
        </Group>
      </Card>
    </Box>
  )

  const lbaCard = (
    <Box pb='xl'>
      <Card
        h={rem(100)}
        margin-top={rem(100)}
        shadow='sm'
        px='xl'
        py='xs'
        radius='md'
        withBorder
      >
        <Group h={'100%'} justify='center' preventGrowOverflow>
          <Stack h={'100%'} justify='center' align='start' gap={'xs'} flex={2}>
            <Title order={2}>Letter Before Action</Title>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={3} gap={'xs'}>
            <p>
              {' '}
              A letter before action is a formal letter sent to a debtor to
              demand payment.{' '}
            </p>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={1} gap={'xs'}>
            <h2> £7.50 </h2>
          </Stack>
        </Group>
      </Card>
    </Box>
  )

  const claimFormCard = (
    <Box pb='xl'>
      <Card
        h={rem(100)}
        margin-top={rem(100)}
        shadow='sm'
        px='xl'
        py='xs'
        radius='md'
        withBorder
      >
        <Group h={'100%'} justify='center' preventGrowOverflow>
          <Stack h={'100%'} justify='center' align='start' gap={'xs'} flex={2}>
            <Title order={2}>Issued Claim Form</Title>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={3} gap={'xs'}>
            <p> We will prepare and issue a claim form for you. </p>
          </Stack>

          <Stack h={'100%'} justify='center' align='center' flex={1} gap={'xs'}>
            <h3 style={{ textAlign: 'center' }}> Coming Soon </h3>
          </Stack>
        </Group>
      </Card>
    </Box>
  )

  return (
    <Container size='sm'>
      <Title
        order={2}
        style={{
          fontSize: rem(40),
          fontWeight: 900,
          textAlign: 'center',
        }}
      >
        Pricing
      </Title>

      {chaserCard}
      {lbaCard}
      {claimFormCard}
    </Container>
  )
}

const MobilePricingSection = () => {
  const theme = useMantineTheme()

  const createMobileCard = (
    title: string,
    description: string,
    price: string
  ) => (
    <Box pb='md'>
      <Card shadow='sm' p='md' radius='md' withBorder>
        <Stack gap='xs'>
          <Title order={3} style={{ fontSize: rem(20) }}>
            {title}
          </Title>
          <Text size='sm' color={theme.colors.gray[6]}>
            {description}
          </Text>
          <Text fw={700} size='lg'>
            {price}
          </Text>
        </Stack>
      </Card>
    </Box>
  )

  return (
    <Container size='xs' p='md'>
      <Title
        order={2}
        style={{
          fontSize: rem(32),
          fontWeight: 900,
          textAlign: 'center',
          marginBottom: rem(20),
        }}
      >
        Pricing
      </Title>

      {createMobileCard(
        'Chaser',
        'A chaser is a letter sent to a debtor to remind them of an outstanding debt.',
        '£1'
      )}

      {createMobileCard(
        'Letter Before Action',
        'A letter before action is a formal letter sent to a debtor to demand payment.',
        '£7.50'
      )}

      {createMobileCard(
        'Issued Claim Form',
        'We will prepare and issue a claim form for you.',
        'Coming Soon'
      )}
    </Container>
  )
}

function PricingSection() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  if (isMobile) {
    return <MobilePricingSection />
  } else {
    return <DesktopPricingSection />
  }
}

export default PricingSection
