// directions_questionnaire_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const DirectionsQuestionnaireDetails = types
  .model('DirectionsQuestionnaireDetails', {
    response_date: types.maybeNull(FirestoreTimestamp),
  })
  .actions((self) => ({})) // No additional actions from the old model to integrate

export default DirectionsQuestionnaireDetails
export interface IDirectionsQuestionnaireDetails
  extends Instance<typeof DirectionsQuestionnaireDetails> {}
