import { Instance, types } from 'mobx-state-tree'

// Model of the route data
const Route = types
  .model('Route', {
    path: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    nav: types.maybeNull(types.boolean),
    document: types.maybeNull(types.boolean),
    header: types.maybeNull(types.boolean),
    footer: types.maybeNull(types.boolean),
    claimRoute: types.optional(types.boolean, false),
  })
  .actions((self) => ({}))

export default Route
export interface IRoute extends Instance<typeof Route> {}
