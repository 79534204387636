import {
  Container,
  Text,
  Divider,
  useMantineTheme,
  Title,
  Card,
  List,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import TermsText from '../components/terms_text'
import { useMediaQuery } from '@mantine/hooks'

function DesktopTermsPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Container
    style={{
      minHeight: height,
      // paddingLeft: '16px',
      backgroundColor: theme.colors.white[5],
    }}
    p={'md'}
    m={0}
    fluid
    >
      <Card
       shadow='sm'
       withBorder
       p={'xl'}
       radius={'md'}
       maw={width / 2}
       mx={'auto'}
      >
       <Card.Section p={'xl'}>
          <Title py={'md'} order={1} fw={800}>
            Terms of Service
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'xl'}>
          <TermsText />
        </Card.Section>
      </Card>
    </Container>
  )
}


function MobileTermsPage() {
  const theme = useMantineTheme()
  const { height } = useViewportSize()

  return (
    <Container
      style={{
        minHeight: height,
        backgroundColor: theme.colors.white[5],
      }}
      p={'md'}
      m={0}
      fluid
    >
      <Card shadow='sm' withBorder p={'md'} radius={'md'} mx={'auto'} style={{ width: '100%', maxWidth: '100vw' }}>
        <Card.Section p={'md'}>
          <Title py={'md'} order={2} fw={700}>
            TERMS OF SERVICE
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section p={'md'} style={{ overflowY: 'auto', overflowX: 'hidden'}}>
          <div style={{wordWrap: 'break-word'}}>
            <TermsText />
          </div>
        </Card.Section>
      </Card>
    </Container>
  )
}

function TermsPage() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`) 

  return isMobile ? <MobileTermsPage /> : <DesktopTermsPage />
}

export default TermsPage
