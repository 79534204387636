import { Paper, Card } from '@mantine/core'
import { observer } from 'mobx-react'
import { ReactElement, useState } from 'react'
import DocumentReader from './document_reader'
import { IDocument } from '../../models/document/document'
import { ResizableBox } from 'react-resizable'
import React from 'react'
import './resizable.css'
import { print } from '../../utils/print'
import { ErrorBoundary } from 'react-error-boundary'
import { ReaderError } from '../errors/reader_error'
import 'react-resizable/css/styles.css' // This line is crucial for default styles

type ReaderTabLayoutProps = {
  selectedDocument: IDocument | null | undefined
  menu: {
    label: string
    icon: JSX.Element | null
    color: string
    action: () => void
  }[]
  controlPanel: ReactElement | null
  position: 'left' | 'below'
  width?: number
  documentVersions?: IDocument[]
}

function ReaderTabLayout({
  selectedDocument,
  menu,
  controlPanel,
  position,
  width = 300,
  documentVersions
}: ReaderTabLayoutProps) {
  const [controlPanelWidth, setControlPanelWidth] = useState(width)
  const [resizing, setResizing] = useState(false)

  return (
    <Paper p={'sm'} bg={'transparent'}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          flexDirection: position === 'left' ? 'row' : 'column',
        }}
      >
        {controlPanel && position === 'left' && (
          <ResizableBox
            className='custom-box'
            width={controlPanelWidth}
            axis='x'
            minConstraints={[250, 100]}
            maxConstraints={[700, Infinity]}
            resizeHandles={['e']}
            onResize={(e, d) => {
              setControlPanelWidth(d.size.width)
            }}
            style={{
              overflow: 'auto',
              position: 'fixed',
              zIndex: 1,
            }}
          >
            <Card withBorder shadow='lg'>
              {controlPanel}
            </Card>
          </ResizableBox>
        )}

        <div
          style={{
            flex: 1,
            padding: controlPanel ? '0 20px' : '0 0',
            marginLeft:
              controlPanel && position === 'left' ? controlPanelWidth : 0, // Modify this line
            marginTop: controlPanel && position === 'below' ? '20px' : 0, // Add this line
            overflowY: 'auto',
          }}
        >
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <ReaderError
                error={error}
                resetErrorBoundary={resetErrorBoundary}
                // Add any other props you want to pass here
              />
            )}
            onError={(error) => print(error)}
          >
            <DocumentReader
              selectedDocument={selectedDocument}
              menu={menu}
              visible={!resizing}
              documentVersions={documentVersions}
            />
          </ErrorBoundary>
          {controlPanel && position === 'below' && (
            <div style={{ marginBottom: '20px' }}>
              <Card withBorder shadow='lg'>
                {controlPanel}
              </Card>
            </div>
          )}
        </div>
      </div>
    </Paper>
  )
}

export default observer(ReaderTabLayout)
