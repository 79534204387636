// Merged request_for_judgment_details.ts
import { Instance, types } from 'mobx-state-tree'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp' // Using the new model's import path

const COURT_DECISION = ['won', 'won_in_part', 'lost']

const RequestForJudgmentDetails = types
  .model('RequestForJudgmentDetails', {
    issue_date: types.maybeNull(FirestoreTimestamp),
    response_date: types.maybeNull(FirestoreTimestamp),
    response_type: types.maybeNull(
      types.enumeration(['won', 'won_in_part', 'lost'])
    ),
    // No additional fields from the old model need to be merged as the new model already contains all necessary fields.
  })
  .actions((self) => ({
    // Placeholder for any actions from the old model that need to be integrated.
    // Since the old model does not define any actions, this remains empty.
    // If there were actions in the old model, they would be adapted here with necessary modifications.
  }))

export default RequestForJudgmentDetails
export interface IRequestForJudgmentDetails
  extends Instance<typeof RequestForJudgmentDetails> {}
