import { getRoot, IStateTreeNode } from 'mobx-state-tree'
import { RootStore, RootStoreModel } from '../stores/root_store'

/**
 * Returns a RootStore object in strongly typed way
 * for stores to access other stores.
 */
export const getRootStore = (self: IStateTreeNode): RootStore => {
  return getRoot<typeof RootStoreModel>(self)
}
