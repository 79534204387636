import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Text,
  useMantineTheme,
  Button,
  Overlay,
  Title,
  rem,
  Burger,
  Drawer,
  Stack,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
import { useViewportSize, useMediaQuery } from '@mantine/hooks'

const DesktopHeroSection = ({ width, height }: { width: number, height: number }) => {
  const theme = useMantineTheme()
  const { navStore } = useStores()
  const [hoveredLink, setHoveredLink] = useState<string | null>(null)
  const navigate = useNavigate()

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: rem(180),
        paddingBottom: rem(130),
        backgroundColor: 'black',
        backgroundImage:
          'url(https://images.unsplash.com/photo-1589391886645-d51941baf7fb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Overlay color='#000' opacity={0.65} zIndex={1} />

      <div style={{ position: 'relative', zIndex: 1 }}>
        <Title
          style={{
            fontWeight: 800,
            fontSize: rem(80),
            paddingLeft: rem(20),
            paddingRight: rem(20),
            color: theme.colors.white[5],
            marginBottom: rem(10),
            textAlign: 'center',
          }}
        >
          Enter{' '}
          <Text
            component='span'
            inherit
            style={{ color: theme.colors.white[5] }}
          >
            Garfield
          </Text>
        </Title>

        <Container size={640}>
          <Text
            size='xl'
            style={{
              color: theme.colors.gray[0],
              textAlign: 'center',
              fontSize: rem(24),
            }}
          >
            Say goodbye to debt collection stress and hello to your new legal assistant.
          </Text>
        </Container>

        <div
          style={{
            marginTop: rem(30),
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
          }}
        >
          <Button
            style={{
              height: rem(60),
              fontSize: rem(20),
            }}
            variant='white'
            size='lg'
            onClick={() => {
              navStore.toggleWebFooter(true)
              navStore.toggleWebHeader(true)
              navigate('/signup')
            }}
          >
            Sign up today
          </Button>
        </div>
      </div>
    </div>
  )
}

const MobileHeroSection = () => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { navStore } = useStores()
  const [opened, { toggle, close }] = useDisclosure(false)

  const handleNavigation = (path: string) => {
    navStore.toggleWebFooter(true)
    navStore.toggleWebHeader(true)
    navigate(path)
    close()
  }

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: rem(80),
        paddingBottom: rem(60),
        backgroundImage:
          'url(https://images.unsplash.com/photo-1589391886645-d51941baf7fb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Overlay color='#000' opacity={0.65} zIndex={1} />

      <div style={{ position: 'relative', zIndex: 2, padding: rem(20) }}>
        <Title
          style={{
            fontWeight: 800,
            fontSize: rem(40),
            color: theme.colors.white[5],
            marginBottom: rem(10),
            textAlign: 'center',
            marginTop: rem(40),
          }}
        >
          Enter{' '}
          <Text
            component='span'
            inherit
            style={{ color: theme.colors.white[5] }}
          >
            Garfield
          </Text>
        </Title>

        <Text
          size='md'
          style={{
            color: theme.colors.gray[0],
            textAlign: 'center',
            fontSize: rem(16),
            marginBottom: rem(20),
          }}
        >
          Say goodbye to debt collection stress and hello to your new legal assistant.
        </Text>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              height: rem(50),
              fontSize: rem(16),
              width: '100%',
            }}
            variant='white'
            size='lg'
            onClick={() => handleNavigation('/signup')}
          >
            Sign up now
          </Button>
        </div>
      </div>

      <Drawer
        opened={opened}
        onClose={close}
        title="Menu"
        padding="xl"
        size="sm"
        position="right"
      >
        <Stack>
          <Button variant="subtle" onClick={() => handleNavigation('/about')}>About</Button>
          <Button variant="subtle" onClick={() => handleNavigation('/signin')}>Sign In</Button>
          <Button onClick={() => handleNavigation('/signup')}>Sign Up</Button>
        </Stack>
      </Drawer>
    </div>
  )
}

function HeroSection() {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)
  const { height, width } = useViewportSize()

  if (isMobile) {
    return <MobileHeroSection />
  } else {
    return <DesktopHeroSection width={width} height={height} />
  }
}

export default HeroSection
