import React from 'react'
import { Container, Center, Text, Divider, List } from '@mantine/core'

function AboutPage() {
  return (
    <Container
      fluid
      p={0}
      m={0}
      style={{ height: '100%', paddingLeft: '16px' }}
    >
      <Center style={{ height: '100%' }}>
        <Text>
          <h1>What we believe</h1>
          <Divider />
          <p></p>
          <List>
            <p>We believe that:</p>

            <List.Item>
              Artificial Intelligence and Machine Learning are positive and
              transformational, providing they are used correctly, and safely
            </List.Item>
            <List.Item>access to justice is an essential legal right</List.Item>
            <List.Item>
              technology can help people vindicate their legitimate legal rights
            </List.Item>
            <List.Item>and, most especially, in coffee</List.Item>
          </List>
          <br></br>

          <h1>Who we are</h1>
          <Divider />
          <p>We are a team of lawyers and software developers</p>
          <p>
            Founded by the vision of democratising access to the Courts, in May
            2023 we set out to create a revolutionary product
          </p>
          <p>We wanted to create X that does Y</p>
          <p>We are pleased to present: "Garfield"</p>
          <p>Also, we like coffee</p>
          <p>and cake, too, if you've got any</p>
        </Text>
      </Center>
    </Container>
  )
}

export default AboutPage
