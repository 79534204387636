import { Paper, Divider, ScrollArea } from '@mantine/core'
import { observer } from 'mobx-react'
import StageTimelineSection from './stage_timeline_section'
import ClaimIdSection from './claim_id_section'
import ClaimDetailsSection from './claim_details_section'
import { IClaim } from '../../models/claim/claim'
import { ErrorBoundary } from 'react-error-boundary'
import { print } from '../../utils/print'
import { StageTimelineError } from '../errors/stage_timeline_error'
import CostsTable from './costs_table'

export interface DetailsGridProps {
  claim: IClaim
}

function DetailsGrid({ claim }: DetailsGridProps) {
  return (
    <Paper p={'sm'} pt={'xl'}>
      <ScrollArea h={'100%'}>
        {/* CLAIM STAGE GRAPH */}
        <ErrorBoundary
          FallbackComponent={StageTimelineError}
          onError={(error) => print(error)}
        >
          <StageTimelineSection claim={claim} />
        </ErrorBoundary>

        <Divider my={'md'}></Divider>
        {/* DETAILS */}
        <ClaimDetailsSection claim={claim} />
        <Divider my={'md'}></Divider>
        {/* CLAIM ID */}
        <ClaimIdSection claim={claim} />
        <CostsTable claim={claim} />
      </ScrollArea>
    </Paper>
  )
}

export default observer(DetailsGrid)
