import { Instance, types } from 'mobx-state-tree'
import Address from './address'
import { FirestoreTimestamp } from '../../utils/firestore_timestamp'

const Party = types
  .model('Party', {
    name: types.maybeNull(types.string),
    birth_date: types.maybeNull(FirestoreTimestamp),
    address: types.maybeNull(Address),
    email: types.maybeNull(types.string),
    no_email_known: types.maybeNull(types.boolean),
    is_individual: types.maybeNull(types.boolean),
    business_description: types.maybeNull(types.string),
    business_description_unknown: types.maybeNull(types.boolean),
  })
  .actions((self) => ({})) // Assuming no specific actions to merge or modify

export default Party
export interface IParty extends Instance<typeof Party> {}
