import {
  Text,
  Button,
  Card,
  Group,
  Stack,
  useMantineTheme,
} from '@mantine/core'


import { modal } from '../../utils/modal'

export const ConfirmSignatureModal = ({
  text,
  title,
  onClick,
  onlySignDocument,
}: {
  text: React.ReactNode
  title: string
  onClick: () => {}
  onlySignDocument: () => Promise<void>
}) => {
  const theme = useMantineTheme()

  return (
    <Card p={'xs'} bg={'transparent'}>
      <Stack justify='center' align='center'>
        <Text fw={600} size='xl'>
          {title}
        </Text>
        <Text size='sm'>
          <p>{text}</p>
        </Text>
        <Group>
          <Button
            mt={'xs'}
            // maw={200}
            // bg={theme.colors.black[5]}
            variant='subtle'
            onClick={() => modal.close()}
          >
            Cancel
          </Button>
          <Button
            mt={'xs'}
            // maw={200}
            bg={theme.colors.black[5]}
            onClick={onClick}
          >
            Send the document
          </Button>
          <Button
            mt={'xs'}
            // maw={200}
            bg={theme.colors.black[5]}
            onClick={async () => {
              modal.close()
              await onlySignDocument()
            }}
          >
            Only sign
          </Button>
        </Group>
      </Stack>
    </Card>
  )
}
