import {
  Avatar,
  Badge,
  Card,
  Text,
  useMantineTheme,
  ActionIcon,
  rem,
} from '@mantine/core'
import styled from 'styled-components'

import { useElementSize, useHover } from '@mantine/hooks'
import { useStores } from '../../utils/use_stores'
import { IDocument } from '../../models/document/document'
import { observer } from 'mobx-react'
import { IconPencil, IconUserShield } from '@tabler/icons-react'
import { ISettlementDetails } from '../../models/document/settlement_details'
import { useContext } from 'react'
import { PageContext } from './settlement_navbar'
import { Timestamp } from 'firebase/firestore'

export interface SettlementCardProps {
  settlement: IDocument
}

function SettlementCard({ settlement }: SettlementCardProps) {
  const { memberStore, documentStore } = useStores()
  const theme = useMantineTheme()
  const { ref: sizeRef, width, height } = useElementSize()
  const { hovered, ref: hoverRef } = useHover()

  const selectedSettlement = documentStore.selectedSettlementDocument
  const details = settlement.details as ISettlementDetails
  const fromDebtor = settlement.creator === 'user'
  const { setPage, setIsEditing } = useContext(PageContext)

  const selectSettlement = () => {
    documentStore.selectDocument('settlement', settlement.document_id!)
  }
  const formatDate = (timestamp: Timestamp | null | Date) => {
    if (!timestamp) return '-'
    const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(-2)
    return `${day}/${month}/${year}`
  }

  return (
    <>
      <Card
        ref={sizeRef}
        mt={'xs'}
        shadow={selectedSettlement === settlement ? 'sm' : 'none'}
        withBorder
        w={'100%'}
        p={0}
        h={40}
        bg={selectedSettlement === settlement ? theme.colors.gray[2] : 'white'}
        style={{
          cursor: 'pointer',
          border:
            selectedSettlement === settlement
              ? `1.25px solid ${theme.colors.black[8]}`
              : hovered
                ? `1.25px solid ${theme.colors.gray[7]}`
                : `1.25px solid ${theme.colors.gray[3]}`,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={selectSettlement}
      >
        <CardGrid>
          <div style={{ flex: '0 0 40px', paddingLeft: '10px' }}>
            <Avatar
              maw={25}
              mah={25}
              bg={fromDebtor ? theme.colors.gray[8] : theme.colors.gray[6]}
              color='white'
              size={25}
              p={2}
              radius='xl'
            >
              {fromDebtor ? (
                <IconUserShield size={16} />
              ) : (
                memberStore.currentMember?.initials
              )}
            </Avatar>
          </div>
          <CardGridCol style={{ marginLeft: '10px' }}>
            <Text
              size='sm'
              fw={600}
              c={theme.colors.gray[5]}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {details.readableType ?? 'Parsing letter...'}
            </Text>
          </CardGridCol>
          <CardGridCol style={{ marginLeft: '10px' }}>
            <Text maw={10} size={'xs'} c={theme.colors.gray[7]}>
              {formatDate(details.deadline_date)}
            </Text>
          </CardGridCol>
          <CardGridCol style={{ display: 'flex', alignItems: 'center' }}>
            {details.type === 'counter_offer' && (
              <Badge variant='filled' color='yellow' radius='sm' size='xs'>
                COUNTERED
              </Badge>
            )}
            {details.type === 'acceptance' && (
              <Badge variant='filled' color='green' radius='sm' size='xs'>
                ACCEPTED
              </Badge>
            )}
            {details.type === 'rejection' && (
              <Badge variant='filled' color='red' radius='sm' size='xs'>
                REJECTED
              </Badge>
            )}
          </CardGridCol>
          <div style={{ flex: '0 0 40px', height: '100%' }}>
            {!fromDebtor && (
              <ActionIcon
                size={24}
                radius='sm'
                color={theme.colors.gray[6]}
                variant='subtle'
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
                style={{
                  backgroundColor: theme.colors.gray[2],
                  borderRadius: '0px',
                  padding: '0px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <IconPencil
                  style={{
                    width: rem(22),
                    height: rem(22),
                    color: theme.colors.gray[7],
                  }}
                  stroke={1.75}
                  onClick={() => {
                    setIsEditing(true)
                    setPage('letterEdit')
                    selectSettlement()
                  }}
                />
              </ActionIcon>
            )}
          </div>
        </CardGrid>
      </Card>
    </>
  )
}

const CardGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const CardGridCol = styled.div`
  flex: 1;
`

export default observer(SettlementCard)
