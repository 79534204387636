import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Flex,
  Container,
  Text,
  Space,
  Title,
  Stack,
  Modal,
  Paper,
  Anchor,
  Stepper,
} from '@mantine/core'
import { useDisclosure, useMediaQuery, useViewportSize } from '@mantine/hooks'
import { Group, ScrollArea } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import {
  IconChevronLeft,
  IconChevronRight,
  IconLogout,
} from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../utils/use_stores'
//@ts-ignore
//@ts-ignore
import background_image from '../assets/images/register.webp'
import { GarfieldLogoText } from '../components/shared/garfield_logo'
import OnboardingStep1 from '../components/onboarding/onboarding_step_1'
import OnboardingStep2 from '../components/onboarding/onboarding_step_2'
import OnboardingStep3 from '../components/onboarding/onboarding_step_3'
import OnboardingStep4 from '../components/onboarding/onboarding_step_4'
import OnboardingStep5 from '../components/onboarding/onboarding_step_5'
import OnboardingStep5B from '../components/onboarding/onboarding_step_5B'
import { ClaimStoreModel } from 'stores/claim_store'
import { modal } from 'utils/modal'
import { notification } from '../utils/notification'

function SignInPage() {
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  const desktop = useMediaQuery('(min-width: 56.25em)')

  const { claimStore, authStore, memberStore } = useStores()

  const navigate = useNavigate()

  const currentStep = authStore.user?.onboarding_step ?? 0

  const validateFields = () => {
    const {
      authEmail,
      authPassword,
      authConfirmPassword,
      tosAccepted,
      user,
    } = authStore
    switch (currentStep) {
      case 0:
        if (!tosAccepted) {
          notification.warning(
            'Terms of Service not accepted',
            'Please read the Terms of Service and check the box to continue'
          )
          return false
        }
          
        if (authEmail && authPassword && authConfirmPassword && tosAccepted)
          return true
        break
      case 1:
        if (user?.type != null) {
          return true
        } else {
          notification.warning(
            'Account type not selected',
            'Please choose between an individual or organisation account.'
          )
          return false
        }
        break
      case 2:
        if (user) {
          const { entity_name, entity_description, inc_date, inc_number, vat } =
            user
          if (user.type === 'individual') {
            if (entity_name && entity_description) {
              return true
            } else {
              notification.warning(
                'Some required fields are missing',
                'Please provide a valid Business Name and Business Description'
              )
              return false
            }
          } else {
            if (entity_name && entity_description && inc_date && inc_number) {
              return true
            } else {
              notification.warning(
                'Some required fields are missing',
                'Please provide a valid Business Name, Business Description, Incorporation Number and Incorporation Date.'
              )
              return false
            }
          }
        }
        break
      case 3:
        if (user?.registered_address) {
          const {
            postcode,
            country,
            first_line,
            second_line,
            town_city,
            county,
          } = user?.registered_address
          if (postcode && country && first_line && town_city && county) {
            if (user?.has_trading_address) {
              if (user.trading_address) {
                const {
                  postcode,
                  country,
                  first_line,
                  second_line,
                  town_city,
                  county,
                } = user?.trading_address
                if (postcode && country && first_line && town_city && county) {
                  return true
                }
              }
            } else {
              return true
            }
          } else {
            notification.warning(
              'Some required fields are missing',
              'Please provide a valid Postal Code, Country, First Line, County and Town.'
            )
            return false
          }
        }
        break
      case 4:
        const name = memberStore.getCurrentName()
        if (user?.verified === 'approved' && name) {
          return true
        } else {
          notification.warning(
            'How should we call you?',
            'Please provide your full name so we can create your Garfield profile.'
          )
          return false
        }
        break
      case 5:
        break
      case 6:
        break
      case 7:
        break
    }
    notification.warning(
      'Fields are invalid or incomplete',
      'Please make sure to provide all required fields.'
    )
    return false
  }

  const nextStep = async () => {
    const valid = validateFields()

    if (valid) {
      if (currentStep == 0) {
        const accountId = await authStore.signUp()
        if (accountId) {
          authStore.subscribeToAccount(accountId)
          authStore.updateOnboardingStep(
            currentStep < 6 ? currentStep + 1 : currentStep
          )
        }
      } else if (currentStep == 4) {
        modal.loading(true)
        const claimId = await claimStore.createClaim()
        if (claimId) {
          authStore.updateUser({
            onboarded: true,
            verified: 'approved',
            uid: authStore.user?.uid,
          })
          await claimStore.setCurrentClaim(claimId)
          navigate(`/claims/${claimId}`)
        }
        modal.loading(false)
      } else {
        authStore.updateOnboardingStep(
          currentStep < 6 ? currentStep + 1 : currentStep
        )
      }
      
    }
  }

  const prevStep = async () => {
    if (currentStep == 1) {
      openSignOutModal()
    } else {
      authStore.updateOnboardingStep(
        currentStep > 0 ? currentStep - 1 : currentStep
      )
    }
  }

  const [steps, setSteps] = useState([
    <Stepper.Step withIcon={false} key={'step-1'}>
      <OnboardingStep1 />
    </Stepper.Step>,
    <Stepper.Step withIcon={false} key={'step-2'}>
      <OnboardingStep2 />
    </Stepper.Step>,
    <Stepper.Step withIcon={false} key={'step-3'}>
      <OnboardingStep3 />
    </Stepper.Step>,
    <Stepper.Step withIcon={false} key={'step-4'}>
      <OnboardingStep4 />
    </Stepper.Step>,
    <Stepper.Step withIcon={false} key={'step-4'}>
      <OnboardingStep5B />
    </Stepper.Step>,
  ])

  const [signOutModal, { open: openSignOutModal, close: closeSignOutModal }] =
    useDisclosure(false)

  return (
    <>
      <Modal
        centered
        size='20%'
        opened={signOutModal}
        onClose={closeSignOutModal}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        withCloseButton={false}
        py={'md'}
      >
        <Stack align='center' justify='center' w={'100%'}>
          <Title order={3}>Are you sure?</Title>
          <Text>
            You will have to create a different account or sign in to this one
            again with the same password and email.
          </Text>
        </Stack>

        <Space h={'xl'}></Space>
        <Group grow gap='sm'>
          <Button variant='subtle' onClick={closeSignOutModal}>
            Cancel
          </Button>
          <Button
            leftSection={<IconLogout size={18} />}
            onClick={async () => await authStore.signOut()}
          >
            Sign out
          </Button>
        </Group>
      </Modal>
      <Flex justify='start' m={0} p={0} gap={'sm'} bg={theme.colors.white[5]}>
        {desktop ? (
          <Paper
            w={'100%'}
            // bg={theme.colors.black[5]}
            m={'sm'}
            radius={'md'}
            style={{
              backgroundImage: `url(${background_image})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
            }}
          >
            <Paper
              w={'100%'}
              h={'100%'}
              p={'xl'}
              style={{
                background: `rgba(0, 0, 0, 0.5)`,
              }}
            >
              <Stack justify='space-between' h={'100%'}>
                <GarfieldLogoText
                  size={'md'}
                  variant={'light'}
                  onClick={() => navigate('/')}
                  type='full'
                />
                <Text c={theme.colors.gray[4]} size='sm' ta='start' mt={5}>
                  Already have an account?{' '}
                  <Anchor
                    size='sm'
                    component='button'
                    fw={600}
                    c={theme.colors.white[5]}
                    onClick={() => navigate('/signin')}
                  >
                    Sign in
                  </Anchor>
                </Text>
              </Stack>
            </Paper>
          </Paper>
        ) : null}

        <Container
          h={height - 25}
          mah={height - 25}
          w={'100%'}
          my={'sm'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            miw={desktop ? 500 : 100}
            maw={desktop ? 600 : 1920}
            h={'100%'}
            radius={'sm'}
            p={'xl'}
          >
            <ScrollArea>
              <Stack justify='space-between' mah={900} mih={300}>
                <Stepper
                  size='xs'
                  active={currentStep}
                  // onStepClick={setCurrentStep}
                  allowNextStepsSelect={false}
                  iconSize={0}
                  style={{ marginLeft: '-20px' }}
                >
                  {steps}
                  <Stepper.Completed>
                    Completed, click back button to get to previous step
                  </Stepper.Completed>
                </Stepper>
              </Stack>
            </ScrollArea>
            <Group justify='start' mt='xl' gap={'xs'}>
              {currentStep > 0 ? (
                <Button
                  leftSection={<IconChevronLeft size={18} />}
                  variant='subtle'
                  onClick={prevStep}
                >
                  {currentStep > 1 ? 'Back' : 'Sign out'}
                </Button>
              ) : null}

              <Button
                rightSection={<IconChevronRight size={22} />}
                onClick={nextStep}
                disabled={
                  currentStep > 3 && authStore.user?.verified !== 'approved'
                }
              >
                {currentStep > 3 ? 'Launch Garfield' : 'Next step'}
              </Button>
            </Group>
          </Paper>
        </Container>
      </Flex>
    </>
  )
}

export default observer(SignInPage)
