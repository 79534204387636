import { useEffect, useState, useRef } from 'react'
import {
  Text,
  Group,
  useMantineTheme,
  Loader,
  Stack,
  Card,
  Center,
  Container,
  SimpleGrid,
} from '@mantine/core'
import { observer } from 'mobx-react'
import { IDocument } from '../../models/document/document'
import { Document as PDF, Page } from 'react-pdf'
import { useStores } from '../../utils/use_stores'
import { firebase } from '../../services/firebase_service'

function LetterheadReader({
  letterheadPrefix,
}: {
  letterheadPrefix: string
}) {
  const theme = useMantineTheme()
  const { documentStore } = useStores()
  const [loadingDocument, setLoadingDocument] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const [letterheadUrl, setLetterheadUrl] = useState<string | null>(null)

  const loadLetterhead = async () => {
    if (!letterheadPrefix) return
    setLoadingDocument(true)
    const url = await firebase.getStorageUrl(letterheadPrefix)
    if (!url) return
    setLetterheadUrl(url)
    setLoadingDocument(false)
  }

  useEffect(() => {
    loadLetterhead()
  }, [letterheadPrefix])

  console.log('letterheadUrl', letterheadUrl)

  if (!letterheadPrefix) {
    return (
      <>
        <Group
          grow
          preventGrowOverflow={false}
          w={'100%'}
          // h={height - 170}
          align='center'
          justify='flex-start'
        >
          <Center>
            <Text size='lg' fw={500} c={theme.colors.gray[4]}>
              No file selected
            </Text>
          </Center>
        </Group>
      </>
    )
  }

  return (
    <div ref={containerRef} style={{ overflow: 'hidden' }}>
      <Stack style={{ overflow: 'hidden' }}>
        {loadingDocument ? (
          <Loader color={theme.colors.gray[5]} size={'xs'} />
        ) : true ? (
          <PDF
            file={letterheadUrl}
            onLoadSuccess={() => {}}
            loading={
              <Container>
                <Center>
                  <Loader color={theme.colors.gray[5]} size={'xs'} />
                </Center>
              </Container>
            }
          >
            <SimpleGrid cols={1} spacing='md'>
              <Card
                withBorder
                p={0}
                radius='20px'
                onClick={() => {}}
                ref={null}
              >
                <Page pageIndex={0} scale={0.8} />
              </Card>
            </SimpleGrid>
          </PDF>
        ) : (
          <Card bg={theme.colors.gray[2]} radius={0} />
        )}
      </Stack>
    </div>
  )
}

export default observer(LetterheadReader)