import {
  Card,
  Flex,
  Group,
  Space,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { IconExclamationCircle } from '@tabler/icons-react'

export function StageTimelineError({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: any | null
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const theme = useMantineTheme()
  const { height, width } = useViewportSize()

  return (
    <Card
      withBorder={true}
      radius='md'
      w={'100%'}
      style={{ border: `2px solid ${theme.colors.red[1]}` }}
    >
      <Flex justify='start' align='start'>
        <ThemeIcon variant='light' radius='xl' size='xl' color='red'>
          <IconExclamationCircle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
        <Space w={'md'}></Space>
        <Stack gap={'xs'}>
          <Group>
            <Text size='md' mt={0} fw={700}>
              Error displaying claim stage
            </Text>
          </Group>
          <Group>
            <Text span c='dimmed' size='md'>
              Garfield is having trouble fetching this claim's stage. Please
              reload the page or contact support.
            </Text>
          </Group>
        </Stack>
      </Flex>
    </Card>
  )
}
