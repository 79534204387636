// import React, { useContext } from 'react'

import {
  createTheme,
  MantineThemeOverride,
  rgba,
} from '@mantine/core'

const componentsOveride: MantineThemeOverride['components'] = {
  Text: {
    styles: (theme: any, params: any) => ({
      root: {
        '&:hover': {
          backgroundColor:
            params.variant === 'light'
              ? theme.colors.red[5]
              : theme.colors.red[5],
        },
      },
    }),
  },
}

// Mantine theme override
export const theme = createTheme({
  components: componentsOveride,
  focusRing: 'never',
  fontFamily: 'Inter, sans-serif',
  scale: 1,
  white: '#fafcff',
  black: '#16161B',
  colors: {
    transparent: [
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
      rgba('#ffffff', 0.0),
    ],
    white: [
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
      '#fafcff',
    ],
    black: [
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
      '#16161B',
    ],
    accent: [
      '#1C7ED6',
      '#339AF0',
      '#4DABF7',
      '#74C0FC',
      '#A5D8FF',
      '#D0EBFF',
      '#91A7FF',
      '#748FFC',
      '#5C7CFA',
      '#4C6EF5',
    ],
  },
  primaryShade: { light: 5, dark: 5 },
  primaryColor: 'black',
  headings: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 'bold',
  },
  defaultRadius: 'sm',
  cursorType: 'pointer',
  defaultGradient: {
    from: '#0D86F0',
    to: '#79C3FF',
    deg: 180,
  },
})
