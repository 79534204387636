import { rem, useMantineTheme, Card, Stepper } from '@mantine/core'
import { observer } from 'mobx-react'
import {
  IconArrowFork,
  IconClockPause,
  IconDots,
  IconFilePlus,
  IconHeartHandshake,
  IconMail,
  IconMessageReply,
  IconQuestionMark,
  IconScale,
  IconScript,
  IconZoomScan,
  IconInfoCircle
} from '@tabler/icons-react'
import { IClaim } from '../../models/claim/claim'
import { CLAIMSTAGE } from '../../models/claim/claim_state'

export interface StageTimelineSectionProps {
  claim: IClaim
}

function StageTimelineSection({ claim }: StageTimelineSectionProps) {
  const theme = useMantineTheme()

  const previousStage = claim?.state!.pastStage
  const currentStage = claim?.state!.stage

  const previousLabel = claim?.state!.getStageDisplay(previousStage as CLAIMSTAGE)
  const currentLabel = claim?.state!.getStageDisplay(currentStage as CLAIMSTAGE)

  const getDisplayIcon = (stage: CLAIMSTAGE) => {
    const iconsDict = {
      [CLAIMSTAGE.triage]: <IconZoomScan style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.invalid_claim]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.initial_approach_await_decision]: <IconInfoCircle style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.chaser_info_needed]: <IconInfoCircle style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.chaser_ready]: <IconFilePlus style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.chaser_await_sending]: <IconMessageReply style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.chaser_await_response]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_info_needed]: <IconInfoCircle style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_ready]: <IconFilePlus style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_await_sending]: <IconMessageReply style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_await_response]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.claim_form_blocker]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,


      [CLAIMSTAGE.correspondence_info_needed]: <IconMail style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.correspondence_ready]: <IconMail style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.correspondence_await_response]: <IconMail style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_response_await_decision]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.negotiate_lba_settlement_offer]: <IconHeartHandshake style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.stalling_response_await_decision]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.case_resolved]: <IconHeartHandshake style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.lba_settlement_accepted]: <IconHeartHandshake style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.negotiate_lba_request_for_time_or_indulgence]: <IconHeartHandshake style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.accept_lba_request_for_time_or_indulgence]: <IconHeartHandshake style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.claim_form_info_needed]: <IconScript style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.claim_form_ready]: <IconScript style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.claim_form_await_response]: <IconClockPause style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.request_for_judgment_info_needed]: <IconScale style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.request_for_judgment_ready]: <IconScale style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.request_for_judgment_await_response]: <IconScale style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.request_for_judgment_response_decision]: <IconScale style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.defence_response_info_needed]: <IconMessageReply style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.counterclaim_response_info_needed]: <IconArrowFork style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.admission_info_needed]: <IconArrowFork style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.reply_to_defence_ready]: <IconMessageReply style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.reply_to_defence_and_counterclaim_ready]: <IconArrowFork style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.notice_of_proposed_allocation_info_needed]: <IconArrowFork style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.directions_questionnaire_info_needed]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.directions_questionnaire_ready]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.await_court_standard_directions]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.court_bundle_info_needed]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.court_bundle_ready]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.await_court_decision]: <IconMessageReply style={{ width: rem(18), height: rem(18) }} />,
      [CLAIMSTAGE.broken]: <IconQuestionMark style={{ width: rem(18), height: rem(18) }} />,
    }
    return iconsDict.hasOwnProperty(stage) ? iconsDict[stage] : <IconFilePlus style={{ width: rem(18), height: rem(18) }} />;
  }

  return (
    <Card withBorder={false} radius='md' miw={150}>
      <Stepper
        active={!previousLabel ? 0 : 1}
        size='xs'
        // onStepClick={setActive}
        // completedIcon={getDisplayIcon(previousStage as CLAIMSTAGE)}
      >
        {previousLabel ? (
          <Stepper.Step
            icon={getDisplayIcon(previousStage as CLAIMSTAGE)}
            label={previousLabel}
          ></Stepper.Step>
        ) : null}

        <Stepper.Step
          icon={getDisplayIcon(currentStage as CLAIMSTAGE)}
          label={currentLabel}
        />

        <Stepper.Step
          icon={
            <IconDots
              color={theme.colors.gray[5]}
              style={{ width: rem(18), height: rem(18) }}
            />
          }
        />
      </Stepper>
    </Card>
  )
}

export default observer(StageTimelineSection)
